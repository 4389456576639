import React, { useEffect, useState } from "react";
import { Link, Element, scroller } from "react-scroll";
import { TutorialSidebarData } from "../TutorialSidebarData";
import { Events, scrollSpy } from "react-scroll";
import { modelContentData, pathContentData } from "./pathContentData";
import { setIsScrolling } from "../../store/actions/layoutAction";
import { useDispatch, useSelector } from "react-redux";

export const DocumentationsSidebar = ({ toggle, open,activeSections }) => {
    // console.log(activeSections,'active')
    const [activeSection, setActiveSection] = useState("");
    const { isScrolling } = useSelector((state) => state.layout);

    const handleSetActive = (to) => {  
        setActiveSection(to);
        // toggle();
    };
    const dispatch = useDispatch();
    useEffect(()=>{
        setActiveSection(activeSections);
    },[activeSections])
    const handleResize = () => {
        // alert(window.innerWidth);
        if (window.innerWidth >= 1200) {
            dispatch(setIsScrolling(-180));
            // setSideBar(-180);
            // offsetSidebar = -180;
            // console.log("xl");
        } else if (window.innerWidth >= 320 && window.innerWidth < 1200) {
            dispatch(setIsScrolling(-70));
            // setSideBar(-90);
            // offsetSidebar = -90;
            // console.log("lg");
            // } else if (window.innerWidth < 992 && window.innerWidth >= 320) {
            //     dispatch(setIsScrolling(-590));
            // setSideBar(-590);
            // offsetSidebar = -590;
            // console.log("md");
        } else {
        }
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();
        Events.scrollEvent.register("scroll", (to) => {
            setActiveSection(to);
        });
        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove("scroll");
        };
    }, []);

    return (
        <>
            {!open && (
                <div className="documentation-sidebar">
                    <div className="text-right my-2 ">
                        <span
                            className="fs--24 fw--500 font-poppins cursor-pointer"
                            onClick={toggle}
                        >
                            ×
                        </span>
                    </div>
                    <h4 className="fs--20 fw--500  text-muted font-poppins">
                        {" "}
                        API Reference
                    </h4>
                    <Link
                        className="fs--14 fw--400 text-muted font-poppins"
                        key="intro1"
                        to="introduction"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onSetActive={handleSetActive}
                    >
                        Introduction
                    </Link>

                    <div className="text-justify">
                        {pathContentData?.map((section, index) => (
                            <React.Fragment key={index}>
                                {index == 0 && (
                                    <h4 className="fs--20 fw--500 text-muted font-poppins py-3">
                                        {section.header}
                                    </h4>
                                )}

                                <Link
                                    className="fs--14 fw--400 text-muted font-poppins"
                                    key={index}
                                    to={section.route}
                                    activeClass="active"
                                    spy={true}
                                    smooth={true}
                                    offset={isScrolling}
                                    duration={500}
                                    onSetActive={handleSetActive}
                                >
                                    {section.route}
                                </Link>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="text-justify">
                        {modelContentData?.map((obj, index) => (
                            <React.Fragment key={index}>
                                {index == 0 && (
                                    <h4 className="fs--20 fw--500 text-muted font-poppins py-3">
                                        {obj.header}
                                    </h4>
                                )}

                                <Link
                                    className="fs--14 fw--400 text-muted font-poppins"
                                    key={index}
                                    to={obj.section}
                                    activeClass="active"
                                    spy={true}
                                    smooth={true}
                                    offset={isScrolling}
                                    duration={500}
                                    onSetActive={handleSetActive}
                                >
                                    {obj.section}
                                </Link>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};
