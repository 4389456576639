import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import apitutorial3 from "../../assets/img/api-tutorial-3.png";

const BuildingScorecard = () => {
    return (
        <>
            <div>
                <h3 className="fs--24 fw--700 font-poppins">
                    VI. Building The Scorecard Component
                </h3>
                <p className="fs--14 fw--300 font-poppins">
                    In this tutorial section, we will walk you through the
                    creation of a Scorecard component step bystep. This
                    component displays a golf course scorecard, including the
                    hole distances for differenttee box colors. We will use the
                    Golfbert library to fetch course data.
                </p>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    Step 1: Add getCourseScorecardById method to Golfbert class
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    Before proceeding with the creation of the Scorecard
                    component, you'll need to add a new methodto the{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        Golfbert
                    </code>{" "}
                    class in the{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        lib/Golfbert.js
                    </code>{" "}
                    file. This method is used to fetch the coursescorecard by
                    ID.
                </p>
                <p className="fs--14 fw--300 font-poppins">
                    Open the{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        lib/Golfbert.js
                    </code>{" "}
                    file and add the following method to the{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        Golfbert
                    </code>{" "}
                    class:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`getCourseScorecardById(id) {
    return this.request({
        method: 'GET',
        path: /v1/courses/{id}/scorecard
    });
}
`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <p className="fs--14 fw--300 font-poppins py-3">
                    Now that you've added the getCourseScorecardById method to
                    the Golfbert class, you canproceed with creating the
                    Scorecard component.
                </p>

                <h2 className="fs--20 fw--500 font-poppins my-4">
                    Step 2: Create a new file and import necessary libraries and
                    components
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    First, create a new file named{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        Scorecard.js
                    </code>{" "}
                    inside the{" "}
                    <code className="fs--14 fw--300 font-poppins">Pages</code>{" "}
                    directory of your project.
                </p>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Next, import the required libraries and components at the
                    beginning of the{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        Scorecard.js
                    </code>{" "}
                    file:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`import React, { useEffect } from 'react';
import Golfbert from '../lib/Golfbert';
`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    Step 3: Create the Scorecard function component
                </h2>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Define a new function component named Scorecard that takes
                    props as its argument:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`function Scorecard(props) {
    // ...
}
`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    Step 4: Initialize state
                </h2>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Inside the Scorecard component, initialize the state using
                    React.useState for searchText, courses,teeboxColors, and
                    scorecard:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`let [searchText, setSearchText] = React.useState(18813);
let [courses, setCourses] = React.useState([]);
let [teeboxColors, setTeeboxColors] = React.useState([]);
const holes = Array.from({ length: 18 }, (_, index) => index + 1);
let [scorecard, setScorecard] = React.useState([]);

`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    Step 5: Fetch courses on page load
                </h2>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Use the useEffect hook to call the getCourses function when
                    the component mounts:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`useEffect(() => {
        getCourses();
    }, []);
`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    Step 6: Define the getCourses function
                </h2>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Create a function named getCourses that fetches the course
                    scorecard by ID using the Golfbertlibrary:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`const getCourses = () => {
    // ...
}
`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Inside this function, create a new instance of Golfbert with
                    your API key, access key, and secret key.Call the
                    getCourseScorecardById method with the searchText state as
                    an argument. Then, processthe received data to extract
                    unique tee box colors and create the scorecard array.
                    Finally, updatethe teeboxColors and scorecard state
                    variables:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`setTeeboxColors([]);
setScorecard([]);
const golfbert = new Golfbert({
    apiKey: 'your_api_key',
    accessKey: 'your_access_key',
    secretKey: 'your_secret_key',
});
golfbert
.getCourseScorecardById(searchText)
.then((data) => {
    console.log(data)
    
    // Get colors
    let colors = data.holeteeboxes.map((teebox) => { return teebox.color; })
    const uniqueColors = [...new Set(Object.values(colors))];
    
    setTeeboxColors(uniqueColors);
    
    // Get scorecard
    let scorecard = data.holeteeboxes
        .map((hole) => {
            return hole
        }) 
        .sort((a, b) => a.holenumber - b.holenumber)
        
    setScorecard(scorecard);
})
.catch((err) => console.error(err));`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    Step 7: Define the getHoleDistance function
                </h2>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Create a function named getHoleDistance that takes color and
                    holeNumber as its arguments. Thisfunction returns the
                    distance of a hole based on the provided tee box color and
                    hole number:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`// function to get hole distance based on color and hole number
const getHoleDistance = (color, holeNumber) => {
    const matchingHoles = scorecard.filter(hole => hole.color === color && hole.holenumb
    
    if (matchingHoles.length === 0) {
        // no holes match the given color and hole number
        return '-';
    } else {
        // only one hole matches the given color and hole number
        return matchingHoles[0].length; 
        }
    }`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    Step 8: Return the JSX for the Scorecard component
                </h2>
                <p className="fs--14 fw--300 font-poppins py-2">
                    In the return statement of the Scorecard component, render a
                    search input field, a search button,and a table to display
                    the course scorecard:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`return (
    <div className="course" style={{margin: '25px'}}>
        <div style={{margin: '25px'}}>
            <input type="search" placeholder="Course Id" value={searchText} onChange={ e =
            <button onClick={getCourses}>Search</button>
        </div>
        <table id="courses">
            <thead>
                <tr>
                    <th>Hole</th>
                    {holes.map(hole => (
                        <th key={hole}>{hole}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {teeboxColors.map(color => (
                    <tr key={color}>
                        <td>{color}</td>
                        {holes.map(hole => (
                            <td key={hole}>{getHoleDistance(color, hole)}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    Step 9: Export the Scorecard component
                </h2>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Export the Scorecard component so it can be used in other
                    parts of your application:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`export default Scorecard;`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <p className="fs--14 fw--300 font-poppins py-2">
                    With these steps, you have successfully created a Scorecard.
                </p>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    Step 10: Import and display the Scorecard component in
                    App.js
                </h2>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Now that you've created the Scorecard component, you need to
                    import it into your{" "}
                    <code className="fs--14 fw--300 font-poppins">App.js</code>{" "}
                    fileand display it inside a{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        TabPanel
                    </code>
                    .
                </p>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Open the{" "}
                    <code className="fs--14 fw--300 font-poppins ">App.js</code>{" "}
                    file and add the following import statement at the beginning
                    of the file:
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={`import Scorecard from './pages/Scorecard';`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <p className="fs--14 fw--300 font-poppins py-2">
                    Next, find the place where you want to display the{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        Scorecard
                    </code>{" "}
                    component and add it inside a{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        TabPanel
                    </code>
                    :
                </p>
                <div className="w-100 py-3">
                    <CodeBlock
                        text={` <TabPanel>
    <Scorecard />
</TabPanel>`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <p className="fs--14 fw--300 font-poppins py-2">
                    With these changes, your Scorecard component will be
                    displayed inside the specified{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        TabPanel
                    </code>{" "}
                    inyour application.
                </p>
                <div className="shadow py-3 shadow ">
                    <img src={apitutorial3} className="img-fluid" />
                </div>
            </div>
        </>
    );
};

export default BuildingScorecard;
