import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

function HolesIndexs({
    holes,
    scorecard,
    polygons,
    handlePageNavigation,
    selectedIndex,
    course,
    callFrom,
}) {
    const handleNavigation = async (direction) => {
        const newIndex =
            direction === "next" ? selectedIndex + 1 : selectedIndex - 1;

        const hole = holes[newIndex];
        const paths = polygons[hole?.id];
        const golfInfo = scorecard.filter((ele) => ele.holeid === hole.id);

        await handlePageNavigation(`${course?.id}:Hole_${newIndex + 1}`, {
            hole,
            paths,
            golfInfo,
            holes,
            polygons,
            scorecard,
            course,
        });
    };
    const handleHolePageNavigation = async (index) => {
        const hole = holes[index];
        const paths = polygons[hole?.id];
        const golfInfo = scorecard.filter((ele) => ele.holeid === hole.id);
        await handlePageNavigation(`${course?.id}:Hole_${index + 1}`, {
            hole,
            paths,
            golfInfo,
            holes,
            polygons,
            scorecard,
            course,
        });
    };
    return (
        <>
            <div className="d-flex  my-2  align-items-center justify-content-between">
                <div className="order-1 order-lg-1 mb-3 d-flex">
                    <div className="py-1 px-2 fsw-4 bg-light border border-success index_btns index_btns-first">
                        <Link to={`/courses/holes/${course.id}`}>Index</Link>
                    </div>
                </div>
                <div className="order-2 order-lg-2 d-md-flex d-none justify-content-center flex-wrap mb-3 px-0   ">
                    {holes.map((hole, index) => {
                        const paths = polygons[hole.id];
                        const golfInfo = scorecard.filter(
                            (ele) => ele.holeid === hole.id
                        );

                        return (
                            <div
                                className={`index_btns fsw-4 ${
                                    selectedIndex == index
                                        ? "border border-site-primary text-site-primary"
                                        : ""
                                }`}
                                key={index}
                                onClick={() =>
                                    handlePageNavigation(
                                        course.id + `:Hole_${index + 1}`,
                                        {
                                            hole,
                                            paths,
                                            golfInfo,
                                            holes,
                                            polygons,
                                            scorecard,
                                            course,
                                        }
                                    )
                                }
                            >
                                {index + 1}
                            </div>
                        );
                    })}
                </div>
                <div className="order-3 order-lg-3 d-flex justify-content-end mb-3">
                    <button
                        disabled={selectedIndex + 1 == 1}
                        className="index_btns fsw-4 py-1 px-2 ml-1 cursor-pointer index_btns"
                        onClick={() => {
                            callFrom === "SingleMap"
                                ? handleNavigation("previous")
                                : handleHolePageNavigation(0);
                        }}
                    >
                        {<i class="fa fa-caret-left" aria-hidden="true"></i>}
                    </button>

                    <button
                        disabled={selectedIndex + 1 == 18}
                        className="index_btns fsw-4 py-1 px-2 ml-1 cursor-pointer index_btns"
                        onClick={() => {
                            callFrom === "SingleMap"
                                ? handleNavigation("next")
                                : handleHolePageNavigation(17);
                        }}
                    >
                        {<i class="fa fa-caret-right" aria-hidden="true"></i>}
                    </button>
                </div>
            </div>

            <div className="d-md-none d-flex flex-wrap  ">
                {holes.map((hole, index) => {
                    const paths = polygons[hole.id];
                    const golfInfo = scorecard.filter(
                        (ele) => ele.holeid === hole.id
                    );

                    return (
                        <div
                            className={`index_btns fsw-4 ${
                                selectedIndex == index
                                    ? "border border-site-primary text-site-primary"
                                    : ""
                            }`}
                            key={index}
                            onClick={() =>
                                handlePageNavigation(
                                    course?.id + `:Hole_${index + 1}`,
                                    {
                                        hole,
                                        paths,
                                        golfInfo,
                                        holes,
                                        polygons,
                                        scorecard,
                                        course,
                                    }
                                )
                            }
                        >
                            {index + 1}
                        </div>
                    );
                })}
            </div>
        </>
    );
}
export default HolesIndexs;
