import { toast } from "react-toastify";
import { RepositoryFactory } from "../../Repository/RepositoryFactory";
import { LOGIN, LOGOUT, REG_LOADER } from "../types";
import { updatePassword } from "./UserActions";
let Auth = RepositoryFactory.get("auth");
let Subscribe = RepositoryFactory.get("subscribe");

export const login =
    (payload, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
        dispatch(authLoader(true));
        try {
            const response = await Auth.userLogin(payload);
            const { data } = response;
            if (data?.success) {
                localStorage.setItem("token", JSON.stringify(data.data.token));
                localStorage.setItem("authUser", JSON.stringify(data.data));
                dispatch({
                    type: LOGIN,
                    payload: { id: data.data.id, ...data.data },
                });
                onSuccess();
                dispatch(authLoader(false));
            }
        } catch (error) {
            dispatch(authLoader(false));
            toast.error(
                error?.response?.data?.message ||
                    error?.data?.message ||
                    error.message ||
                    "Something Went Wrong"
            );
            console.log(error);
            onError(error.response.data.message);
        } finally {
            dispatch(authLoader(false));
        }
    };

export const registerUser =
    (payload, subscriptionPayload, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            const { email, password } = payload;
            const { data } = await Auth.registerUser(payload);
            const userId = data?.data?.id;

            if (subscriptionPayload) {
                subscriptionPayload.userId = userId;

                await Subscribe.subscribePlan(subscriptionPayload);
                await dispatch(login({ email, password }));
            }

            onSuccess();
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error?.data?.message ||
                    error?.message ||
                    "Something Went Wrong"
            );
            // if (error.response) {
            //     toast.error(error.response.data.message);
            // }
            dispatch(registerLoader(false));
        }
    };

export const forgetPassword =
    (email, onSuccess = () => {}) =>
    async (dispatch) => {
        console.log("forgetPassword");

        dispatch(authLoader(true));

        try {
            let payload = {
                email,
            };
            const response = await Auth.forgetPassword(payload);
            const { data } = response;
            if (data.success) {
                onSuccess();
                dispatch(authLoader(false));
            }
        } catch (error) {
            dispatch(authLoader(false));
            toast.error(
                error?.response?.data?.message ||
                    error?.data?.message ||
                    error.message ||
                    "Something Went Wrong"
            );
            console.error(error.message);
        } finally {
            dispatch(authLoader(false));
        }
    };

export const resetPassword =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch(authLoader(true));
        try {
            // console.log({ payload });
            const response = await Auth.resetPassword(payload);
            const { data } = response;
            if (data.success) {
                onSuccess();
                dispatch(authLoader(false));
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error?.data?.message ||
                    error.message ||
                    "Something Went Wrong"
            );
            dispatch(authLoader(false));
            console.error(error.message);
        } finally {
            dispatch(authLoader(false));
        }
    };

export const logout =
    (onSuccess = () => {}) =>
    async (dispatch) => {
        // console.log("reach");
        dispatch({
            type: LOGOUT,
        });
        onSuccess();
    };

export const authLoader = (val) => async (dispatch) => {
    dispatch({ type: "AUTH_LAODER", payload: val });
};
export const registerLoader = (val) => async (dispatch) => {
    dispatch({ type: REG_LOADER, payload: val });
};
