import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Button, Col, Row } from "reactstrap";
import EnterpriseAPI from "../../components/EnterpriseAPI";
import PlanCard from "../../components/PlanCard";
import { useSelector } from "react-redux";

const PlansPricing = () => {
    const history = useHistory();
    const { uid, user } = useSelector((state) => state.authUser);
    return (
        <>
            {/* <ApiNavbar /> */}

            <Row className="bg-white w-100 m-0">
                <Col className="pt-5 pb-1" sm="12">
                    <div className="text-center">
                        <h1 className="fs--38 fw--700 font-poppins py-3">
                            API Plans
                        </h1>

                        {/* <Link
                            to="/api/subscribe/test"
                            className="fs--16 fw--400 font-poppins text-site-primary py-4"
                        > */}
                        {user?.api_plan === "test" ||
                        user?.stripe_customer_id ? (
                            <p className="fs--18 fw--400 font-poppins">
                                Need full access to the API? Switch your plan
                                below.
                            </p>
                        ) : (
                            <>
                                {" "}
                                <p className="fs--18 fw--400 font-poppins">
                                    Get started today with a free trial or sign
                                    up for a paid plan.
                                </p>
                                <Button
                                    className="py-3 px-5 btn-custom my-3"
                                    onClick={() => {
                                        let data = {
                                            price: "",
                                            month: "",
                                            priceId: "test",
                                        };

                                        history.push({
                                            pathname: "/api/subscribe/test",
                                            state: data,
                                        });
                                    }}
                                >
                                    Try our sample course for Free
                                </Button>
                            </>
                        )}
                        {/* </Link> */}
                    </div>
                </Col>
            </Row>

            <Row className=" w-100 m-0 pb-5">
                {/* <Col className="py-3" sm="12">
                    <div className="text-center">
                        <p className="fs--18 fw--300 font-poppins">
                            Integrate detailed golf course maps with optimized
                            GPS data in your app or website.
                        </p>
                    </div>
                </Col> */}
                <Col sm="12">
                    <PlanCard />
                    <div>
                        <hr></hr>
                    </div>
                </Col>
                <Col sm="12" className="w-100 m-0 py-5 px-lg-narrow px-wide justify-content-center">
                    <EnterpriseAPI />
                </Col>
            </Row>
            {/* <Row className="py-5 bg-white w-100 m-0">
                <Col sm="12" className="py-3 bg-white"></Col>
            </Row> */}

            {/* <ApiFooter /> */}
        </>
    );
};

export default PlansPricing;
