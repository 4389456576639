import { CITIES, STATES } from "../types";

const initialState = {
    states: [],
    cities: [],
};

export default function statesCitiesReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case STATES:
            return {
                ...state,
                states: payload,
            };
        case CITIES:
            return {
                ...state,
                cities: payload,
            };
        default:
            return {
                ...state,
            };
    }
}
