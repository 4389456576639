import {
    COURSES,
    COURSE_HOLES,
    COURSE_LOADER,
    LOAD_MORE_COURSES,
    QUICK_SEARCH,
    SELECTED_COURSE,
} from "../types";

const initialState = {
    courseHole: [],
    selectedCourse: {},
    courses: [],
    quickSearch: {},
    courseLoader: false,
};

/*
    Any action related to Profile will go here.
*/

export default function courseReducer(state = initialState, { type, payload }) {
    switch (type) {
        case COURSE_HOLES:
            return {
                ...state,
                courseHole: payload,
            };
        case COURSES:
            return {
                ...state,
                courses: payload,
            };
        case LOAD_MORE_COURSES:
            return {
                ...state,
                courses: [...state.courses, ...payload],
            };
        case QUICK_SEARCH:
            return {
                ...state,
                quickSearch: payload,
            };
        case COURSE_LOADER:
            return {
                ...state,
                courseLoader: payload,
            };
        case SELECTED_COURSE:
            return {
                ...state,
                selectedCourse: payload,
            };

        default:
            return { ...state };
    }
}
