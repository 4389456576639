import React, { useState } from "react";
import {
    Card,
    CardBody,
    Button,
    Col,
    Container,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import Navbar from "../../components/global/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../store/actions/authAction";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import { SignInFooter } from "../../components/SignInFooter";

const ResetPassword = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { authLoading } = useSelector((state) => state.authUser);
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (password !== confirm || password == "" || confirm == "") {
            toast.error("Passwords must be the same");
        } else {
            let obj = {
                password,
                token,
            };
            dispatch(
                resetPassword(obj, () => {
                    toast.success("You password is been reset");
                    history.push("/auth/signin");
                })
            );
        }
    };

    return (
        <div className="main-bg">
            <Navbar />
            <Container>
                <Row className="forget d-flex justify-content-center align-item-center">
                    <Col md="8" className="p-0">
                        <Card className="forget__main">
                            <div className="forget__body">
                                <CardBody>
                                    <h1 className="mb-4">Reset your password?</h1>
                                    <Row>
                                        {/* <Col md="12">
                                    <p className="text-justify">
                                        Please enter the email address
                                        you used when you registered for
                                        an account, and we'll send you
                                        password reset instructions.
                                    </p>
                                </Col> */}
                                        <Col md="12" className="mb-3">
                                            <Label className="sub-form-label">
                                                New Password
                                            </Label>
                                            <Input
                                                className="py-4 signIn__input"
                                                placeholder="Enter New Password"
                                                type="password"
                                                value={password}
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                            ></Input>
                                        </Col>
                                        <Col md="12">
                                            <Label className="sub-form-label mt-3">
                                                Password Again Please
                                            </Label>
                                            <Input
                                                className="py-4 signIn__input"
                                                placeholder="Confirm New Password"
                                                type="password"
                                                value={confirm}
                                                onChange={(e) =>
                                                    setConfirm(e.target.value)
                                                }
                                            ></Input>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <Button
                                                color="site-lite-btn"
                                                className="forget__btn py-2 px-4 mt-4 shadow-sm"
                                                onClick={handleSubmit}
                                            >
                                                {authLoading ? (
                                                    <Spinner size="sm" />
                                                ) : (
                                                    "Reset"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </div>
                        </Card>
                    </Col>
                    <Col md="8">
                        <SignInFooter />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ResetPassword;
