import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
const Lisence = () => {
    return (
        <Container className="py-5 ">
            <Row className="license d-flex justify-content-center">
                <Col md="12" className="pb-5">
                    <h1 className="license__headline">
                        Golfbert Content and API License Agreement
                    </h1>
                    <p className="license__graytext">Last Modified: 6/30/2017</p>

                    <p>
                        This Golfbert Content and API License Agreement
                        ("Agreement") is entered into by and between Golfbert,
                        LLC ("Golfbert") and you ("Licensee"). "This Agreement
                        is effective as of the date of the earlier of either
                        clicking to accept the Agreement or use of any feature
                        of the Golfbert API ("Effective Date")."
                    </p>

                    <p>
                        Each party agrees that it has read and understands this
                        Agreement, and each party agrees to be bound by all of
                        its terms. This Agreement constitutes the entire
                        agreement between the parties with respect to the
                        subject matter of this Agreement, and this Agreement
                        replaces and supersedes any and all prior verbal
                        understandings, written communications or
                        representations relating to the subject matter hereof.
                        This Agreement may be amended only in a writing signed
                        by an authorized representative of both parties. The
                        Parties acknowledge that no representative of a Party
                        has authority to include promises or undertakings under
                        this Agreement that are not reflected expressly in
                        writing in this Agreement.
                    </p>

                    <p>
                        DEFINITIONS. Whenever used in this Agreement with
                        initial words capitalized, the following terms will have
                        the following specified meanings:
                    </p>

                    <ol>
                        <li className="legal-section">
                            <span className="license__lgtext">
                                SECTION 1. GENERAL.
                            </span>
                            <ol>
                                <li>
                                    <strong>ACTIVATION DATE.</strong> The date
                                    on or after the Effective Date when Golfbert
                                    has provided Licensee with access to the
                                    Content and API, hereinafterreferred to
                                    collectively as â€œAPIâ€ and Golfbert
                                    notifies Licensee that the API is available.
                                    If and to the extent Licensee was permitted
                                    access to the API prior to the Effective
                                    Date (under previous agreed terms including
                                    confidentiality and evaluation terms), the
                                    Activation Date will be the Effective Date
                                    of this Agreement.
                                </li>

                                <li>
                                    <strong>ADMINISTRATOR.</strong> The employee
                                    of Licensee described in Section 2.3 who is
                                    identified as Administrator.
                                </li>

                                <li>
                                    API.The application program interface,
                                    including all accompanying documentation,
                                    software, samples, specifications, and other
                                    related materials, as provided by Golfbert
                                    for use in automated requests, receipt, and
                                    processing of Content from Golfbert.
                                </li>

                                <li>
                                    <strong>CONTENT.</strong> The data and any
                                    other content of Golfbert provided to
                                    Licenseethrough the API or other means,
                                    including all mapping, location, GPS, GIS,
                                    contact, slope, handicap, tee box,
                                    historical data and all other materials.
                                </li>

                                <li>
                                    <strong>LICENSED MATERIALS.</strong> The
                                    API, Content, and any other content,
                                    software or other materials furnished by
                                    Golfbert to Licenseein connection with this
                                    Agreement, including, without limitation,
                                    any modification, enhancement or other
                                    derivative work of any of the foregoing
                                    furnished by Golfbert.
                                </li>

                                <li>
                                    <strong>LICENSEE API LOCATION.</strong> The
                                    street address of the location of Licensee's
                                    data center or storage servers where the
                                    Content will be received through the API and
                                    stored by Licensee. If the location of the
                                    Licensee's data center or storage servers is
                                    not available, the street address of the
                                    Licensee's primary business or residential
                                    location.
                                </li>

                                <li>
                                    <strong>
                                        LICENSEE INTERNAL BUSINESS PURPOSE.
                                    </strong>{" "}
                                    Internal use by Named Users for Licensee's
                                    investment research and similar purposes
                                    that does not make unauthorized use or
                                    disclose Content or portions thereof to
                                    third parties, and does not otherwise
                                    involve use that competes with Golfbert's
                                    operations as provider of the Content and
                                    other Licensed Materials.
                                </li>

                                <li>
                                    <strong>LICENSEE USER LOCATION.</strong> The
                                    street address of the location of Licensee's
                                    offices or facilities where Licensee's Named
                                    Users are based for employment purposes. For
                                    the avoidance of doubt, Named Users may be
                                    permitted access from remote locations while
                                    traveling or working from home provided that
                                    appropriate security and confidentiality is
                                    maintained during access to the Content, and
                                    such use complies with all other terms and
                                    conditions of the Agreement.
                                </li>

                                <li>
                                    <strong>NAMED USER.</strong> A natural
                                    person who is an employee or agent of
                                    Licenseeemployed at LicenseeUser Location
                                    and designated by Licensee as an authorized
                                    user of the Licensed Materials, whose access
                                    and use of the Licensed Materials is subject
                                    to the terms and conditions of this
                                    Agreement.
                                </li>

                                <li>
                                    <strong>TERM.</strong> The period described
                                    in Section 4.1.
                                </li>
                            </ol>
                        </li>

                        <li className="legal-section">
                            <span className="license__lgtext">
                                SECTION 2. LICENSE AND ACCEPTING THE TERMS.
                            </span>
                            <ol>
                                <li>
                                    <strong>
                                        CLICKING TO ACCEPT OR USING THE Golfbert
                                        API(s).
                                    </strong>{" "}
                                    In order to use the Golfbert API(s), you
                                    must agree to the terms of this Agreement
                                    by:
                                    <ol>
                                        <li>
                                            clicking to accept the Agreement,
                                            where this option is made available
                                            to you by Golfbert in the
                                            Serviceâ€™s user interface; or
                                        </li>
                                        <li>
                                            using the Golfbert API(s). You
                                            understand and agree that Golfbert
                                            will treat your use of the Golfbert
                                            API(s) as acceptance of the terms of
                                            this Agreement from that point
                                            onwards.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>U.S. LAW RESTRICTIONS.</strong> You
                                    may not use the Golfbert API(s) and may not
                                    accept the terms of this Agreement if you
                                    are a person barred from using Golfbertâ€™s
                                    API under United States law.
                                </li>

                                <li>
                                    <strong>AUTHORITY.</strong> You represent
                                    that you have full power, capacity, and
                                    authority to accept the terms of this
                                    Agreement. If you are accepting on behalf of
                                    your employer or another entity, you
                                    represent that you have full legal authority
                                    to bind your employer or such entity to the
                                    terms of this Agreement. If you don't have
                                    the legal authority to bind, please ensure
                                    that an authorized person from your entity
                                    consents to and accepts the terms of this
                                    Agreement.
                                </li>

                                <li>
                                    <strong>
                                        CONTENT DELIVERY AND LICENSE GRANT.
                                    </strong>{" "}
                                    Subject to the terms and conditions of this
                                    Agreement, Golfbert hereby: (a) agrees to
                                    deliver Content through the API to Licensee
                                    at Licensee API Location subject to this
                                    personal, nonexclusive, nontransferable,
                                    fee-bearing limited license during the Term
                                    to store and permit only Named Users to
                                    access and use Content solely for Licensee
                                    Internal Business Purposes; and (b) grants
                                    to Licensee a personal, nonexclusive,
                                    nontransferable, fee-bearing limited license
                                    during the Term to use and make derivative
                                    works of the API solely for the purpose of
                                    receiving Content and exercising the rights
                                    granted under subsection (a) of this Section
                                    2.4.
                                </li>

                                <li>
                                    <strong>LIMITATIONS.</strong> Golfbert
                                    reserves all rights in and to the Licensed
                                    Materials not specifically granted to
                                    Licensee under Section 2.1. Without limiting
                                    the generality of the foregoing, unless
                                    explicitly provided otherwise in this
                                    Agreement, the foregoing license does not
                                    include the right to, and Licensee will not,
                                    directly or indirectly: (a) distribute or
                                    make available or otherwise permit third
                                    parties to access the Content or API or any
                                    portions thereof; (b) use the Licensed
                                    Materials for any purpose other than
                                    Licensee Internal Business Purposes; (c)
                                    remove, obscure, or alter any notice of
                                    copyright, trademark, trade secret,
                                    confidentiality or other proprietary right
                                    of Golfbert related to the Licensed
                                    Materials; (d) download, or in any way
                                    circumvent a roundtrip to the API beyond
                                    what can be considered reasonable for
                                    offline use in mobile applications and only
                                    on a per one/two course basis and never in
                                    bulk; or (e) engage in or permit any
                                    unauthorized copying or use of any Licensed
                                    Materials.
                                </li>

                                <li>
                                    <strong>NAMED USER PASSWORDS.</strong>{" "}
                                    Golfbert will provide a unique key to
                                    Licensee for its Administrator to use in
                                    accessing the Content as provided through
                                    the API. Licenseeâ€™s designated
                                    Administrator must be a Named User who is
                                    also Licenseeâ€™s designated contact for
                                    maintaining Licenseeâ€™s Named User accounts
                                    and who as Administrator will have direct
                                    access to the API on Licenseeâ€™s behalf
                                    through Golfbertâ€™s designated transfer
                                    method. Licensee agrees to require each
                                    Named User to maintain the security of their
                                    respective access to Content and any
                                    portions thereof, and not to permit use of
                                    their Named User account by any individual
                                    other than the person identified by Licensee
                                    for that Named User account. Licensee will
                                    promptly notify Golfbert of any unauthorized
                                    access or use of passwords or any other
                                    breach of security known to Licensee with
                                    respect to the Content and API so the
                                    parties can work to address, mitigate, and
                                    remediate any such breach of security.
                                </li>

                                <li>
                                    <strong>
                                        END USER TERMS AND PRIVACY POLICY.
                                    </strong>{" "}
                                    If you develop a Golfbert API Implementation
                                    for use by other users, you must:
                                    <ol>
                                        <li>
                                            display to the users of your
                                            Golfbert API Implementation the link
                                            to Golfbertâ€™s License Agreement as
                                            presented through the Service or
                                            described in the Golfbert APIs
                                            Documentation;
                                        </li>

                                        <li>
                                            explicitly state in your Golfbert
                                            API Implementationâ€™s terms of use
                                            that, by using your Golfbert API
                                            Implementation, your users are
                                            agreeing to be bound by Golfbertâ€™s
                                            License Agreement;
                                        </li>

                                        <li>
                                            Golfbertâ€™s Privacy Policy. Your
                                            privacy policy must notify users
                                            that you are using the Golfbert
                                            API(s) and incorporate by reference
                                            Golfbertâ€™s Privacy Policy by
                                            including a link to Golfbertâ€™s
                                            then-current Privacy Policy (at
                                            https://www.golfbert.com/pages/privacy
                                            or such other URL as Golfbert may
                                            provide).
                                        </li>

                                        <li>
                                            Cookies. As noted in the
                                            Documentation, certain Golfbert
                                            API(s) store and access cookies and
                                            other information on end usersâ€™
                                            devices. If you use any of these
                                            cookie-enabled Golfbert API(s) in
                                            your Golfbert API Implementation,
                                            then for end users in the European
                                            Union, you must comply with the EU
                                            User Consent Policy.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>

                        <li className="legal-section">
                            <span className="license__lgtext">
                                SECTION 3. FEES.
                            </span>
                            <ol>
                                <li>
                                    <strong>AMOUNT.</strong> Licensee will pay
                                    to Golfbert the license fees and any other
                                    agreed amounts as set forth by the online
                                    choice made by Licensee in connection with
                                    this Agreement, as well as any applicable
                                    taxes other than those based on Golfbert's
                                    net income (such fees and amounts, including
                                    applicable taxes, are collectively referred
                                    to as "Fees"). Golfbert may prospectively
                                    change Fees charged from time-to-time on at
                                    least thirty (30) days' prior written notice
                                    to Licensee's email address associated with
                                    Licensee's account at Golfbert.com for
                                    contract notices or through a notice made
                                    through Licensee's online account with
                                    Golfbert. LICENSEE'S CONTINUED USE OF THE
                                    API AND CONTENT AFTER THE EFFECTIVE DATE OF
                                    SUCH INCREASE IN FEES MADE BY GOLFBERT WITH
                                    THE REQUIRED NOTICE CONSTITUTES LICENSEE'S
                                    BINDING ACCEPTANCE OF AND LIABILITY FOR SUCH
                                    FEES. In the event Licensee does not accept
                                    the change in Fees, it must discontinue all
                                    use of the Licensed Materials prior to the
                                    effective date of the change in Fees, and
                                    terminate this Agreement in accordance with
                                    Section 4.1(c) of this Agreement.
                                </li>

                                <li>
                                    <strong>PAYMENT.</strong> Payment is due for
                                    Fees under this Agreement in advance of
                                    usage. Licensee agrees to pay, and hereby
                                    authorizes Golfbert to charge and deduct,
                                    Fees when due from Licensee's payment
                                    account established with Golfbert for this
                                    purpose, including but not limited to
                                    charges to any payment card information
                                    Licensee provides for such purposes which
                                    may be used and disclosed by Golfbert to
                                    third parties as necessary to process
                                    payments due under this Agreement. Without
                                    limiting the foregoing, and in addition to
                                    any other remedies available for breach of
                                    payment terms of this Agreement, any amount
                                    not timely paid will be subject to finance
                                    charges at the rate of one and one half
                                    percent (1.5%) per month or the maximum rate
                                    permitted by applicable law, whichever is
                                    less, determined and compounded on a daily
                                    basis from the date due until the date paid.
                                    Notwithstanding any other provision of this
                                    Agreement, Golfbert may suspend its
                                    performance (including without limitation
                                    making Content available through the API)
                                    immediately at any time Fees are due and
                                    unpaid until such time as reasonably
                                    satisfactory arrangements are made with
                                    Golfbert in its sole and good faith
                                    discretion to assure payments of Fees will
                                    be current and timely made. Following any
                                    expiration or termination of this Agreement,
                                    Golfbert agrees to refund to Licensee within
                                    thirty (30) days any unused balance of
                                    Licensee's payment account without interest,
                                    and subject to any good faith set-off or
                                    claims Golfbert may have in relation to
                                    Licensee under this or other agreements with
                                    Golfbert.
                                </li>

                                <li>
                                    <strong>AUDITS.</strong> Licensee agrees to
                                    permit periodic audits upon reasonable
                                    notice and at mutually agreed times to
                                    permit Golfbert at its expense to conduct a
                                    reasonable review of records and procedures
                                    with appropriate personnel to ensure
                                    compliance with the terms and conditions of
                                    this Agreement, including without limitation
                                    restrictions as to permitted locations and
                                    users for Licensed Materials.
                                </li>
                            </ol>
                        </li>

                        <li className="legal-section">
                            <span className="license__lgtext">
                                SECTION 4. TERM AND TERMINATION.
                            </span>
                            <ol>
                                <li>
                                    <strong>TERM.</strong> The Initial Term of
                                    this Agreement will be for one (1) year
                                    commencing on the Activation Date and,
                                    unless earlier terminated as provided
                                    elsewhere in this Section 4, will
                                    automatically renew for additional one (1)
                                    year terms (each a "Renewal Term") unless
                                    either Party provides sixty (60) days
                                    written notice of its intent not to renew
                                    prior to the expiration of the then current
                                    Initial or Renewal Term. The Initial Term
                                    and any Renewal Terms are collectively
                                    referred to as the "Term".
                                </li>

                                <li>
                                    <strong>USAGE LIMITS.</strong>
                                    <ol>
                                        <li>
                                            <strong>API Calls.</strong> If
                                            Licensee selected the Single Course
                                            plan, included with this Agreement,
                                            Licensee may make up to 10,000 calls
                                            per month to the Golfbert API. If
                                            Licensee selected the All Courses
                                            plan, included with this Agreement,
                                            Licensee may make up to 100,000
                                            calls per month to the Golfbert API.
                                            <ol>
                                                <li>
                                                    Additional API calls.
                                                    Licensee shall pay Golfbert
                                                    additional fees for any API
                                                    calls exceeding the quota
                                                    agreed to by Licensee when
                                                    Licensee clicks to accept or
                                                    begins using the Golfbert
                                                    APIs.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <strong>Rate.</strong> Licensee may
                                            not exceed 100 requests per second.{" "}
                                        </li>
                                        <li>
                                            <strong>Burst.</strong> Licensee
                                            will not be allowed more than 1,000
                                            concurrent connections at any given
                                            time.
                                        </li>
                                        <li>
                                            <strong>Quota.</strong> The pricing
                                            tier includes up to the amount of
                                            requests listed and agreed to by
                                            Licensee upon Licensee clicking to
                                            accept or using the Golfbert APIs.
                                            Exceeding the allotted amount will
                                            incur additional cost.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>TERMINATION.</strong> This Agreement
                                    may be terminated prior to the expiration of
                                    the Term as follows:
                                    <ol>
                                        <li>
                                            In the event that either party
                                            materially breaches any of its
                                            duties or obligations under this
                                            Agreement, and does not
                                            substantially cure such breach
                                            within thirty (30) days (or, in the
                                            case of a payment default by
                                            Licensee, within five (5) days)
                                            after being given written notice
                                            specifying the breach, then the
                                            non-breaching party may terminate
                                            the Term by giving written notice of
                                            termination to the breaching party;
                                        </li>

                                        <li>
                                            Either party may terminate upon
                                            written notice in the event that the
                                            other party files for bankruptcy,
                                            makes an assignment for the benefit
                                            of creditors, has a receiver
                                            appointed, or becomes insolvent and
                                            unable to pay its debts when due;
                                        </li>
                                        <li>
                                            By Licensee upon the effective date
                                            of any permitted increase in Fees by
                                            Golfbert as provided in Section 3.1,
                                            in the event Licensee elects to
                                            terminate this Agreement rather than
                                            accept the increase in Fees, but
                                            only if Licensee gives written
                                            notice at least fifteen (15) days'
                                            prior to such effective date.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>EFFECT OF TERMINATION.</strong> Upon
                                    any expiration or termination of this
                                    Agreement, the license set forth in Section
                                    2 will automatically terminate and Licensee
                                    agrees to immediately cease to access, use
                                    and storage of the API and Content and
                                    otherwise immediately cease all use of and
                                    take all commercially reasonable efforts to
                                    expunge the Licensed Materials. Sections 1,
                                    3.2, 3.3, 5, 6.3, 7, 8 and 9 will survive
                                    the expiration or termination of this
                                    Agreement.
                                </li>
                            </ol>
                        </li>

                        <li className="legal-section">
                            <span className="license__lgtext">
                                SECTION 5. GOLFBERT'S PROPRIETARY RIGHTS.
                            </span>
                            <ol>
                                <li>
                                    <strong>OWNERSHIP.</strong> Licensee
                                    acknowledges that, as between the parties,
                                    the Licensed Materials and all right, title
                                    and interest therein belong exclusively to
                                    Golfbert (and Golfbert's licensors and
                                    suppliers, if any). Licensee agrees to treat
                                    the Content and API as "CONFIDENTIAL
                                    INFORMATION" belonging to Golfbert under
                                    this Agreement and agrees as a condition of
                                    access to the Licensed Materials to only use
                                    them as licensed under this Agreement, and
                                    to keep the Content and API confidential
                                    using the same degree of care Licensee uses
                                    to protect its own confidential information,
                                    but in no case less than reasonable care.
                                    Without limiting the foregoing, Licensee
                                    agrees to take all commercially reasonable
                                    steps necessary to protect the Licensed
                                    Materials from any unauthorized access,
                                    copying, distribution, publication,
                                    transmission, display or use.
                                </li>

                                <li>
                                    <strong>NON DISCLOSURE.</strong>
                                    <ol>
                                        <li>
                                            <strong>Scope.</strong> The Licensee
                                            shall use the Confidential
                                            Information exclusively for the
                                            Authorized Purpose, and for no other
                                            purpose. Golfbert retains the
                                            exclusive ownership and intellectual
                                            property rights to its Confidential
                                            Information, and no license or any
                                            other interest in Confidential
                                            Information is granted or implied by
                                            this Agreement. Any Confidential
                                            Information disclosed hereunder is
                                            provided AS-IS with all faults and
                                            defects, and without any express or
                                            implied representation or warranty
                                            of any kind whatsoever, including
                                            without limitation that it does not
                                            infringe any third partyâ€™s
                                            intellectual property rights, that
                                            it is accurate or complete, or that
                                            such information will be suitable
                                            for Licenseeâ€™s purposes.
                                        </li>

                                        <li>
                                            <strong>Restrictions.</strong>{" "}
                                            Licensee agrees to protect the
                                            Confidential Information with the
                                            same degree of care, but no less
                                            than a reasonable degree of care,
                                            that it uses to protect its own
                                            Confidential Information. Licensee
                                            shall not disclose or otherwise
                                            distribute the Confidential
                                            Information to any third party at
                                            any time or for any reason. Licensee
                                            agrees that it will disclose the
                                            Confidential Information only to its
                                            employees, agents, consultants, or
                                            contractors who have a specific need
                                            to know regarding the Authorized
                                            Purpose and only to the extent
                                            necessary for such purpose, and that
                                            it will first require any recipients
                                            of Confidential Information to agree
                                            in writing to the non-disclosure and
                                            confidentiality restrictions imposed
                                            upon the Confidential Information by
                                            this Agreement. Licensee agrees to
                                            provide Golfbert, in writing, the
                                            identities of any employees, agents,
                                            consultants, or contractors Licensee
                                            discloses Confidential Information
                                            to within three (3) days of
                                            disclosure of such information.
                                            Licensee further agrees that upon
                                            written demand, Licensee shall
                                            provide Golfbert written
                                            verification that all employees,
                                            agents, consultants, or contractors
                                            Licensee discloses Confidential
                                            Information to have agreed to the
                                            terms of this Agreement.
                                        </li>

                                        <li>
                                            <strong>Exceptions.</strong> The
                                            obligations and restrictions herein
                                            shall not apply to Confidential
                                            Information that falls within any of
                                            the following exceptions, provided a
                                            receiving party proves by credible
                                            written evidence that such
                                            information:
                                            <ol>
                                                <li>
                                                    is or becomes part of the
                                                    public domain through no
                                                    fault of a receiving party;
                                                </li>

                                                <li>
                                                    was known by receiving party
                                                    prior to the disclosure by
                                                    Golfbert;
                                                </li>

                                                <li>
                                                    was independently developed
                                                    without use of or reference
                                                    to Golfbertâ€™s Confidential
                                                    Information by a receiving
                                                    partyâ€™s employees who had
                                                    no access to Golfbertâ€™s
                                                    Confidential Information;
                                                </li>

                                                <li>
                                                    has been properly received
                                                    from a third party who is
                                                    not under any obligation to
                                                    maintain the confidentiality
                                                    of such information, and
                                                    without breach of this
                                                    Agreement by a receiving
                                                    party; and/or is required to
                                                    be disclosed by law.
                                                </li>

                                                <li>
                                                    If Confidential Information
                                                    is required to be disclosed
                                                    by law, such as by an order
                                                    or notice from a court or
                                                    regulatory agency, Licensee
                                                    will notify Golfbert in
                                                    writing of such requirement.
                                                </li>
                                            </ol>
                                        </li>

                                        <li>
                                            <strong>Enforcement.</strong>{" "}
                                            Licensee agrees that Golfbert may be
                                            irreparably harmed and money damages
                                            may be inadequate compensation in
                                            the event the Licensee breaches any
                                            provision of this Agreement. In such
                                            event, Golfbert shall have the
                                            right, in addition to other
                                            available remedies, to seek specific
                                            performance and injunctive and other
                                            equitable relief. The provisions of
                                            this Section shall survive the
                                            termination of this Agreement.
                                        </li>

                                        <li>
                                            Return of Materials. Upon the
                                            Golfbertâ€™s request, the Licensee
                                            shall promptly return or uninstall
                                            all copies of Golfbert's
                                            Confidential Information, whether in
                                            tangible or machine readable form,
                                            including local and cached data in
                                            apps and servers. Licensee shall
                                            thereafter retain no copies,
                                            transcriptions or summaries of
                                            Golfbert's Confidential Information.
                                            Licensee shall discontinue use of
                                            Golfbertâ€™s API, including any beta
                                            application or features.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>

                        <li className="legal-section">
                            <span className="license__lgtext">
                                SECTION 6. WARRANTIES.
                            </span>
                            <ol>
                                <li>
                                    <strong>GOLFBERT.</strong> Golfbert warrants
                                    to Licensee that: (a) Golfbert has the
                                    necessary power and authority to enter into
                                    and perform its obligations under this
                                    Agreement; and (b) this Agreement has been
                                    duly authorized and executed by Golfbert and
                                    constitutes a valid, binding and enforceable
                                    agreement of Golfbert.
                                </li>

                                <li>
                                    <strong>LICENSEE.</strong> Licensee warrants
                                    to Golfbert that: (a) Licensee has the
                                    necessary power and authority to enter into
                                    and perform its obligations under this
                                    Agreement; and (b) this Agreement has been
                                    duly authorized and executed by Licensee and
                                    constitutes a valid, binding and enforceable
                                    agreement of Licensee.
                                </li>

                                <li>
                                    <strong>DISCLAIMER.</strong> ALL LICENSED
                                    MATERIALS AND SERVICES PROVIDED UNDER THIS
                                    AGREEMENT ARE PROVIDED ON AN "AS IS" AND "AS
                                    AVAILABLE" BASIS. EXCEPT AS EXPRESSLY STATED
                                    IN THIS AGREEMENT, THERE ARE NO WARRANTIES,
                                    CONDITIONS, GUARANTIES OR REPRESENTATIONS,
                                    WHETHER EXPRESS OR IMPLIED, IN LAW OR IN
                                    FACT, ORAL OR IN WRITING. WITHOUT LIMITING
                                    THE FOREGOING, GOLFBERT MAKES NO WARRANTY,
                                    EXPRESS OR IMPLIED, AS TO THE ACCURACY OF
                                    THE CONTENT, THE RESULTS THAT MAY BE
                                    OBTAINED FROM THE USE OF THE LICENSED
                                    MATERIALS OR SERVICES PROVIDED HEREUNDER,
                                    AND GOLFBERT EXPRESSLY DISCLAIMS ANY
                                    CONDITION OF QUALITY AND ANY IMPLIED
                                    WARRANTIES OF TITLE, NON-INFRINGEMENT,
                                    ACCURACY, MERCHANTABILITY OR FITNESS FOR A
                                    PARTICULAR PURPOSE. LICENSEE HEREBY
                                    ACKNOWLEDGES THAT IT HAS NOT RELIED UPON ANY
                                    WARRANTY, CONDITION, GUARANTY OR
                                    REPRESENTATION MADE BY GOLFBERT, EXCEPT AS
                                    SPECIFICALLY SET FORTH IN THIS AGREEMENT.
                                </li>
                            </ol>
                        </li>

                        <li className="legal-section">
                            <span className="license__lgtext">
                                SECTION 7. INDEMNIFICATION.
                            </span>
                            <ol>
                                <li>
                                    <strong>BY LICENSEE.</strong> Licensee will
                                    defend, indemnify and hold harmless
                                    Golfbert, its officers, directors, employees
                                    and agents, from and against any and all
                                    liabilities, damages, awards, settlements,
                                    losses, claims and expenses, including
                                    reasonable attorney fees and expenses and
                                    costs of investigation (collectively,
                                    "Damages") resulting from third-party claims
                                    that arise out of any unauthorized use or
                                    disclosure of the Licensed Materials by
                                    Licensee including by any of Licensee's
                                    Named Users, employees, or agents.
                                </li>

                                <li>
                                    <strong>BY GOLFBERT.</strong> Golfbert will
                                    defend, indemnify and hold harmless
                                    Licensee, its officers, directors, employees
                                    and agents from and against any and all
                                    Damages resulting from third-party claims
                                    alleging that the Licensed Materials in the
                                    form provided by Golfbert to Licensee, if
                                    used by Licensee in the manner permitted in
                                    this Agreement during the Term, infringes
                                    any third-party intellectual property right,
                                    provided that the relevant claim: (a) does
                                    not arise from any modification to the
                                    Licensed Materials made by Licensee or any
                                    third party, or (b) does not arise from the
                                    combination of the Licensed Materials with
                                    other content, products, services or
                                    technology not supplied by Golfbert.
                                </li>

                                <li>
                                    <strong>PROCEDURES.</strong> Each party's
                                    obligation to defend and indemnify the other
                                    party under this Section 7 will be
                                    contingent upon the indemnified party (a)
                                    notifying the other party of the claim
                                    promptly after being made aware of the
                                    claim, (b) allowing the indemnifying party
                                    to control the defense and settlement of the
                                    claim, (c) assisting the indemnifying party,
                                    at its request and expense, in the defense
                                    and settlement of the claim, and (d) not
                                    settling the claim without the prior written
                                    consent of the indemnifying party, such
                                    consent not to be unreasonably withheld or
                                    delayed.
                                </li>
                            </ol>
                        </li>

                        <li className="legal-section">
                            <span className="license__lgtext">
                                SECTION 8. LIMITATIONS OF LIABILITY.
                            </span>
                            <ol>
                                <li>
                                    <strong>GENERAL.</strong> ALTHOUGH GOLFBERT
                                    ENDEAVORS TO MAKE REASONABLE EFFORTS TO
                                    ASSESS THE ACCURACY AND RELIABILITY OF THE
                                    LICENSED MATERIALS, GOLFBERT DOES NOT
                                    GUARANTEE SUCH ACCURACY OR RELIABILITY, AND
                                    LICENSEE ACKNOWLEDGES THAT GOLFBERT, ITS
                                    EMPLOYEES, AGENTS AND LICENSORS WILL NOT BE
                                    LIABLE FOR ANY DAMAGES SUFFERED OR INCURRED
                                    BY LICENSEE, ANY CUSTOMER OF LICENSEE OR ANY
                                    OTHER PERSON OR ENTITY ARISING OUT OF ANY
                                    FAULT, INTERRUPTION OR DELAY IN ANY SERVICE
                                    OR OUT OF ANY INACCURACY, ERROR OR OMISSION
                                    IN ANY LICENSED MATERIALS SUPPLIED TO
                                    LICENSEE, HOWEVER SUCH FAULTS,
                                    INTERRUPTIONS, DELAYS, INACCURACIES, ERRORS
                                    OR OMISSIONS ARISE, UNLESS DUE TO GOLFBERT'S
                                    GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.
                                </li>

                                <li>
                                    <strong>LIMITATION ON DAMAGES.</strong>{" "}
                                    EXCEPT FOR A PARTY'S INDEMNIFICATION
                                    OBLIGATIONS UNDER THIS AGREEMENT, AND
                                    WITHOUT LIMITING EITHER PARTY'S LIABILITY
                                    FOR ANY INFRINGEMENT OR MISAPPROPRIATION OF
                                    ANY INTELLECTUAL OR PROPRIETARY PROPERTY
                                    RIGHTS OF THE OTHER PARTY (INCLUDING,
                                    WITHOUT LIMITATION, LICENSEE'S UNAUTHORIZED
                                    USE, COPYING OR DISTRIBUTION OF ANY LICENSED
                                    MATERIALS), UNDER NO CIRCUMSTANCES WILL
                                    EITHER PARTY BE LIABLE FOR ANY INDIRECT,
                                    INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR
                                    CONSEQUENTIAL DAMAGES WITH RESPECT TO THE
                                    SUBJECT MATTER OF THIS AGREEMENT, INCLUDING,
                                    WITHOUT LIMITATION, LOST PROFITS, LOSS OF
                                    USE, BUSINESS INTERRUPTION, LOSS OF DATA OR
                                    COST OF COVER, REGARDLESS OF WHETHER SUCH
                                    DAMAGES COULD HAVE BEEN FORESEEN OR
                                    PREVENTED.
                                </li>

                                <li>
                                    <strong>LIMITATION OF LIABILITY.</strong>{" "}
                                    Except with respect to Golfbert's
                                    indemnification obligations under Section
                                    7.2 of this Agreement, in no event shall
                                    Golfbert, or its officers, directors,
                                    agents, and employees, be liable to Licensee
                                    or its officers, directors, agents and
                                    employees, for any damages of any sort for
                                    claims arising under or related to this
                                    Agreement, in excess of the amount of Fees
                                    actually paid by Licensee to Golfbert under
                                    this Agreement.
                                </li>
                            </ol>
                        </li>

                        <li className="legal-section">
                            <span className="license__lgtext">
                                SECTION 9. MISCELLANEOUS.
                            </span>
                            <ol>
                                <li>
                                    <strong>FORCE MAJEURE.</strong> Neither
                                    party will be liable for or be in breach of
                                    this Agreement (other than a breach of a
                                    payment obligation) on account of any delay
                                    or failure to perform as required under this
                                    Agreement as a result of any cause,
                                    condition or circumstance beyond such
                                    party's reasonable control.
                                </li>

                                <li>
                                    <strong>ASSIGNMENT.</strong> Licensee may
                                    not assign this Agreement, in whole or in
                                    part, by operation of law or otherwise,
                                    without the prior written consent of
                                    Golfbert in its sole discretion. Any
                                    attempted assignment in violation of this
                                    section shall be null and void. Subject to
                                    the foregoing restriction on assignment,
                                    this Agreement will be binding upon, inure
                                    to the benefit of and be enforceable by the
                                    parties and their respective successors and
                                    assigns.
                                </li>

                                <li>
                                    <strong>
                                        RELATIONSHIP OF THE PARTIES.
                                    </strong>{" "}
                                    Nothing in this Agreement will be construed
                                    to make the parties partners, joint
                                    ventures, representatives or agents of each
                                    other. The parties hereunder are acting in
                                    performance of this Agreement as independent
                                    contractors engaged in the operation of
                                    their own respective businesses. Nothing in
                                    this Agreement is intended to, or will,
                                    create any third party beneficiaries,
                                    whether intended or incidental and neither
                                    party will make any representations to the
                                    contrary.
                                </li>

                                <li>
                                    <strong>NOTICES.</strong> Any notice or
                                    other communication under this Agreement
                                    given by either party to the other party
                                    will be in writing and delivered in person
                                    or mailed, properly addressed and stamped
                                    with required postage, or by electronic
                                    mail, to the intended recipient at its
                                    address specified in Licenseeâ€™s account
                                    with Golfbert.com and to the attention of
                                    the person who executed this Agreement on
                                    behalf of that party. Either party may from
                                    time to time change its address or
                                    designated individual by giving the other
                                    party notice of the change.
                                </li>

                                <li>
                                    <strong>WAIVER.</strong> No term, provision
                                    or clause of this Agreement will be deemed
                                    waived and no breach excused unless such
                                    waiver or consent will be in writing and
                                    executed by a duly authorized representative
                                    of each party. Any consent by any party to,
                                    or waiver of, a breach by the other, whether
                                    express or implied, will not constitute a
                                    consent to, waiver of, or excuse for any
                                    different or subsequent breach.
                                </li>

                                <li>
                                    <strong>ENTIRE AGREEMENT.</strong> This
                                    Agreement constitutes the entire agreement,
                                    and supersedes any and all prior agreements,
                                    between Licensee and Golfbert related to the
                                    Licensed Materials. No amendment of any of
                                    the provisions of this Agreement will be
                                    valid unless set forth in a written
                                    instrument signed by both parties.
                                </li>

                                <li>
                                    <strong>GOVERNING LAW/VENUE.</strong> This
                                    Agreement shall be governed by and construed
                                    in accordance with the laws of the State of
                                    Washington as it applies to contracts
                                    executed and performed within the State of
                                    Washington by parties domiciled in the State
                                    of Washington, without reference or regard
                                    to the choice of law or conflict of laws
                                    rules of any jurisdiction. Licensee hereby
                                    irrevocably and unconditionally agrees to
                                    submit any legal action or proceeding
                                    relating to this Agreement to the
                                    non-exclusive general jurisdiction of the
                                    courts of the State of Washington located in
                                    King County and the courts of the United
                                    States located in the Western District of
                                    Washington and, in any such action or
                                    proceeding, consent to jurisdiction in such
                                    courts and waive any objection to the venue
                                    in any such court. Licensee agrees that
                                    service of process upon any of them in any
                                    such action or proceeding may be made by
                                    United States mail, certified or registered,
                                    return receipt requested, postage prepaid.
                                </li>

                                <li>
                                    <strong>VALIDITY.</strong> If any provision
                                    of this Agreement is found by a court of
                                    competent jurisdiction to be invalid, void
                                    or unenforceable, the remaining provisions
                                    of this Agreement shall remain in full force
                                    and effect, and the affected provisions
                                    shall be revised so as to reflect the
                                    original intent of the parties hereunder to
                                    the extent permitted by applicable law.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </Col>
            </Row>
        </Container>
    );
};
export default Lisence;
