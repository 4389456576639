import React, { useState } from "react";

import { Link, Element, scroller } from "react-scroll";
import Introduction from "./Introduction";
import DocumentationsPathContent from "./DocumentationsPathContent";
import { modelContentData, pathContentData } from "./pathContentData";
import { Col, Row } from "reactstrap";
import DocumentationModelContent from "./DocumentationModelContent";
import { FaBars } from "react-icons/fa";

const DocumentationMainContent = ({ toggle, open }) => {
    return (
        <>
            <Element name="introduction">
                <Row>
                    <Col xs="12" lg="6" className="section pt-5">
                        <Introduction />
                    </Col>
                    <Col xs="12" lg="6" className="bg-site-bgDark">
                        <div>
                            <h6 className="fs--14 fw--400 font-poppins text-white my-lg-5 my-4">
                                API Endpoint
                            </h6>
                        </div>
                        <div>
                            <span className="fs--14 fw--400 font-poppins text-white">
                                Schemes:
                            </span>{" "}
                            <span className="fs--14 fw--400 font-poppins text-muted text-site-muted">
                                https
                            </span>
                        </div>
                        <div>
                            <span className="fs--14 fw--400 font-poppins text-white">
                                Version:
                            </span>{" "}
                            <span className="fs--14 fw--400 font-poppins text-muted text-site-muted ">
                                1.0.13
                            </span>
                        </div>
                    </Col>
                </Row>
            </Element>

            {pathContentData?.map((obj, index) => (
                <>
                    <Element name={obj.route}>
                        <DocumentationsPathContent data={obj} index={index} />
                    </Element>
                </>
            ))}

            {modelContentData?.map((obj, index) => (
                <>
                    <Element name={obj.section}>
                        <DocumentationModelContent data={obj} index={index} />
                    </Element>
                </>
            ))}
        </>
    );
};

export default DocumentationMainContent;
