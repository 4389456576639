import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";

import { DotPulse } from "@uiball/loaders";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LoadMoreCourses } from "../../store/actions/HolesAction";

function CoursesTable({ courseData }) {
    console.log("courseData: ", courseData);
    const history = useHistory();
    const dispatch = useDispatch();
    const { quickSearch, courseLoader } = useSelector((state) => state.course);
    const [courseCount, setCoursesCount] = useState(courseData?.length);

    useEffect(() => {
        setCoursesCount(courseData?.length);
    }, [quickSearch]);

    return (
        <>
            {/* {loader ? (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                    <Spinner> </Spinner>
                </div>
            ) : (
                <> */}
            <Row>
                <Col className="px-md-0">
                    <table className="table table-hover mb-0 fs--14">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Course Name</th>
                                <th scope="col">Maps</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courseData && courseData.length > 0 ? (
                                courseData?.map((item, id) => (
                                    <tr key={id}>
                                        <td
                                            scope="row"
                                            className="align-middle"
                                        >
                                            {id + 1}
                                        </td>
                                        <td className="align-middle text-site-primary link-hover ">
                                            <div>
                                                <a
                                                    // href="#"
                                                    onClick={() => {
                                                        item?.isMapAvailable &&
                                                            history.push({
                                                                pathname: `/courses/holes/${item.id}`,
                                                                state: {
                                                                    course: item,
                                                                },
                                                            });
                                                    }}
                                                    className={` custom-a-hover fsw-4 ${
                                                        item?.isMapAvailable
                                                            ? "cursor-pointer"
                                                            : ""
                                                    }`}
                                                >
                                                    {item.name}
                                                </a>
                                            </div>
                                            <div>
                                                <small>
                                                    <span
                                                        // href="#"
                                                        //className="custom-a-hover cursor-pointer text-muted"
                                                        className="text-muted"
                                                    >
                                                        {item?.address?.city} ,{" "}
                                                    </span>
                                                    <span
                                                        //href="#"
                                                        //className="custom-a-hover cursor-pointer text-muted"
                                                        className="text-muted"
                                                    >
                                                        {" "}
                                                        {
                                                            item?.address?.state
                                                        }{" "}
                                                    </span>
                                                </small>
                                            </div>
                                        </td>
                                        <td className="align-middle text-site-primary">
                                            {item?.isMapAvailable ? (
                                                <a
                                                    className="cursor-pointer custom-a-hover fsw-4"
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: `/courses/holes/${item.id}`,
                                                            state: {
                                                                course: item,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    Available
                                                </a>
                                            ) : (
                                                <h6 className="text-muted">
                                                    Not Available
                                                </h6>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td></td>
                                    <td>No Course Found</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Col>
            </Row>{" "}
            <div className="text-center">
                {courseCount != 0 && !(courseCount < 25) && (
                    <Button
                        disabled={courseLoader}
                        size="md"
                        className="bg-site-bgGreen shadow-sm border-0  my-2"
                        onClick={(event) => {
                            dispatch(
                                LoadMoreCourses(
                                    {
                                        ...quickSearch,
                                        marker: courseData[
                                            courseData.length - 1
                                        ]?.id,
                                    },
                                    (count) => {
                                        setCoursesCount(count);
                                    }
                                )
                            );
                        }}
                    >
                        {courseLoader ? (
                            <DotPulse size={25} speed={1.3} color="white" />
                        ) : (
                            "Load More"
                        )}
                    </Button>
                )}
            </div>
            {/* </>
            )} */}
        </>
    );
}

export default CoursesTable;
