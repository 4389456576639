import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Col, Collapse, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import ApiNavbar from "../components/global/ApiNavbar";
const faqs = [
    {
        id: "1",
        question: "How do I obtain an API key?",
        answer: "API keys are generated upon subscribing to the API service. Your keys are emailed to the email address on file during the subscription process. Please check your email for your API keys. Keys can take up to 48 hours to be generated.",
    },
    {
        id: "2",
        question: "What happens if I lose my API private key?",
        answer: "Your API private key was emailed to you during the subscription process. If for some reason you cannot locate your registration email that includes the private token, you will need to create a new account to have a new token generated. Please contact us for assistance.",
    },
    {
        id: "3",
        question:
            "How can I render the golf hole maps like they are in the examples?",
        answer: `The API allows you read access to the Golfbert golf course maps geodata. Once you have the API calling correctly, you can render the maps any way you see fit. In most cases, using an SVG plugin such as <a href="#">D3.js<a/> works best, as in our hole examples. You will be able to apply color fills, strokes, texture patterns and more however you like!`,
    },
    {
        id: "4",
        question:
            "Can I use a satellite image instead of SVG graphics on my golf hole maps?",
        answer: `Absolutely. You can render your hole maps on top of a <a href="#">Javascript Maps API<a/> instead of a graphical SVG plugin if you wish. You should be aware that satellite images can be heavy and may cause your app to perform slower during the rendering process. We recommend using an SVG plugin to reduce weight.`,
    },
    {
        id: "5",
        question:
            "Can I see an example of my favorite golf course using the API?",
        answer: `In most cases, yes. Browse Golfbert.com's <a href="/api/overview">golf course database<a/> to see thousands of examples of existing golf courses rendered with the same API data.`,
    },
    {
        id: "6",
        question: "How many courses are in the golf course database?",
        answer: "We have streamlined our data efforts to offer the most optimized set of courses for app developers. We have already completed the most popular golf courses in the United States — over 12,500 — and are adding around 200 new courses per month!",
    },
    {
        id: "7",
        question: "Can I request a course to be added to the API database?",
        answer: "We are constantly adding new courses to the API database. If you notice a course is missing, you can always request new courses to be added to our API. If you are an active subscriber in the monthly All Courses tier, we guarantee completion of up to five new course mapping requests per month.",
    },
    {
        id: "8",
        question: "What countries does the API include?",
        answer: `Currently the API is limited to the United States, but we have received numerous requests for additional countries. If you have a country request, please <a href="/api/support">contact us<a/> and we will prioritize our expansion list.`,
    },
    {
        id: "9",
        question: "The API just isn't doing what I want. Can someone help me?",
        answer: `Of course! Please <a href="/api/support">contact us<a/> and we will help you wil anything you need.`,
    },
    // Add more FAQs here
];

const FAQ = () => {
    const [open, setOpen] = useState("");

    const toggle = (id) => {
        setOpen((prevOpen) => (prevOpen === id ? "" : id));
    };

    const renderArrow = (id) => {
        if (open === id) {
            return <FaChevronDown />;
        } else {
            return <FaChevronRight />;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* <ApiNavbar /> */}
            <div className="faq mb-5">
                <Container className="py-2">
                    <h1>API FAQ</h1>
                    <p>
                        Below are some frequently asked questions. If you are
                        still having trouble using the API, please{" "}
                        <Link className="faq__link-to" to="/api/support">
                            contact us
                        </Link>{" "}
                        and we will help you out.
                    </p>
                    {faqs.map(({ id, question, answer }) => (
                        <Row key={id} className="my-3">
                            <Col
                                md="12"
                                onClick={() => toggle(id)}
                                className={`faq__head-question d-flex ${
                                    open === id ? "faq__ques-active" : ""
                                }`}
                            >
                                <div className="pr-3 fs--16 fw--400">
                                    {renderArrow(id)}
                                </div>
                                <div className="faq__main-ques">{question}</div>
                            </Col>
                            <Collapse isOpen={open === id}>
                                <Col>
                                    <div
                                        className="fs--14 f--400 font-poppins text-muted "
                                        dangerouslySetInnerHTML={{
                                            __html: answer,
                                        }}
                                    />
                                </Col>
                            </Collapse>
                        </Row>
                    ))}
                </Container>
            </div>
        </>
    );
};

export default FAQ;
