// UserProfileDropdown.js

import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Media,
} from "reactstrap"; // Assuming you're using Reactstrap
import { logout } from "../../store/actions/authAction";

const UserProfileDropdown = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                    <img
                        height={30}
                        width={30}
                        className="rounded-circle"
                        alt="..."
                        src={
                            "https://i.imgur.com/b08hxPY.png"
                        }
                    />
                </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                    to="/main/setting"
                    tag={Link}
                    className="d-flex  align-items-center"
                >
                    <i className="fas fa-cog mr-2"></i>
                    <span>Settings</span>
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem
                    href="#pablo"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                            logout(() => {
                                history.push("api/overview");
                            })
                        );
                    }}
                >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default UserProfileDropdown;
