import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import { Link } from "react-router-dom";

const TestApiKey = () => {
    return (
        <div>
            <h2 className="fs--20 fw--500 font-poppins">
                Testing Your API key
            </h2>
            <p className="fs--16 fw--400 font-poppins text-muted">
                In order to make sure you are using the API key as required and
                you are signing your requests correctly, you can try to hit the
                courses endpoint as a test:{" "}
                <Link
                    to="/api/documentations"
                    className="fs--16 fw--400 font-poppins text-site-primary px-2"
                    //  href="https://api.golfbert.com/v1/courses"
                >
                    https://api.golfbert.com/v1/courses
                </Link>
                .
            </p>
            <p className="fs--16 fw--400 font-poppins text-muted py-3">
                If you have indeed done everything right, you will see a http
                response with body that includes:
            </p>
            <div className="w-100">
                <CodeBlock
                    text={`[ {"id":1,"address":{"country":"U...
`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--16 fw--400 font-poppins text-muted py-3">
                Great Job!
            </p>
        </div>
    );
};

export default TestApiKey;
