import React, { useState } from "react";
import {
    Card,
    CardBody,
    Button,
    Col,
    Container,
    Label,
    Input,
    Row,
    Spinner,
} from "reactstrap";
import Navbar from "../../components/global/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { forgetPassword } from "../../store/actions/authAction";
import { SignInFooter } from "../../components/SignInFooter";

const Forgetpassword = () => {
    const dispatch = useDispatch();
    const { authLoading } = useSelector((state) => state.authUser);

    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email !== "") {
            dispatch(
                forgetPassword(email, () => {
                    setEmail("");
                    toast.success("Email sent please check your email");
                })
            );
        } else {
            toast.error("Please enter your email first");
        }
    };
    return (
        <div className="main-bg">
            <Navbar />
            <Container>
                <Row className="forget d-flex justify-content-center align-item-center">
                    <Col md="8" className="p-0">
                        <Card className="forget__main">
                            <div className="forget__body">
                                <CardBody>
                                    <h1 className="mb-4">Forgot your password?</h1>
                                    <Row>
                                        <Col md="12">
                                            <p className="text-justify">
                                                Please enter the email address
                                                you used when you registered for
                                                an account, and we'll send you
                                                password reset instructions.
                                            </p>
                                        </Col>
                                        <Col md="12">
                                            <Label className="sub-form-label">
                                                Email Address
                                            </Label>
                                            <Input
                                                className="py-4 signIn__input"
                                                placeholder="Enter email adress"
                                                type="email"
                                                value={email}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                            ></Input>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <Button
                                                color="site-lite-btn"
                                                className="forget__btn py-2 px-4 mt-4 shadow-sm"
                                                onClick={(e) => handleSubmit(e)}
                                            >
                                                {" "}
                                                {authLoading ? (
                                                    <Spinner size="sm">
                                                        {" "}
                                                    </Spinner>
                                                ) : (
                                                    "Send"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </div>
                        </Card>
                    </Col>
                    <Col md="8">
                        <SignInFooter />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Forgetpassword;
