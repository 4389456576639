import React, { useState } from "react";
import searchIcon from "../../assets/img/vector.svg";
import Navbar from "./Navbar";
import { Button, Col, Container, Form, Row } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { CourseNameData } from "../../utils/CoursesFilterData";
import { getFilteredCourses } from "../../store/actions/HolesAction";
import { DotPulse } from "@uiball/loaders";

const Headerlanding = () => {
    const { user } = useSelector((state) => state.authUser);
    const { courseLoader } = useSelector((state) => state.course);
    const history = useHistory();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState("");

    // const SearchCourseNameFilter = (courseName) => {
    //     const filteredData = CourseNameData.filter((item) => {
    //         if (courseName) {
    //             return item.courseName
    //                 .toLowerCase()
    //                 .includes(courseName.toLowerCase());
    //         }
    //     });
    //     return filteredData;
    // };

    const HeaderHandleSubmit = (e) => {
        e.preventDefault();
        // const searchData = SearchCourseNameFilter(filter);
        // history.push("/courses/search", {
        //     searchData: searchData,
        //     searchKey: filter,
        // });
        const obj = {
            name: filter,
            state: "",
            city: "",
            limit: 25,
            offset: 0,
        };

        dispatch(
            getFilteredCourses(obj, () => {
                history.push("/courses/search", {
                    searchData: [],
                    // quickSearch: quickSearch,
                });
                setFilter("");
            })
        );
    };

    return (
        <>
            <header className="header mt-5 position-relative vh-100">
                <Navbar />
                <div className="header__bg">
                    <div className="header__bg-image">
                        <Container className="vh-100">
                            <Row
                                // md="2"
                                // xs="1"
                                className="f-row position-relative custom-index m-0 h-100"
                            >
                                <Col sm="8" className="m-md-auto my-4">
                                    <div className="f-coll ">
                                        <Form onSubmit={HeaderHandleSubmit}>
                                            <div className="row has-search rounded mt-3 px-2">
                                                <Col
                                                    md="11"
                                                    xs="10"
                                                    className="px-0"
                                                >
                                                    <input
                                                        required
                                                        disabled={courseLoader}
                                                        type="text"
                                                        className="form-control text-light"
                                                        placeholder="Search golf courses"
                                                        value={filter}
                                                        onChange={(e) =>
                                                            setFilter(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Col>
                                                <Col
                                                    md="1"
                                                    xs="2"
                                                    className="cursor-pointer text-right pr-1"
                                                >
                                                    {courseLoader ? (
                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                            <DotPulse
                                                                size={25}
                                                                speed={1.3}
                                                                color="white"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <button
                                                            type="submit"
                                                            className="bg-transparent border-0"
                                                        >
                                                            <img
                                                                className="text-light"
                                                                src={searchIcon}
                                                                // onClick={
                                                                //     HeaderHandleSubmit
                                                                // }
                                                            />
                                                        </button>
                                                    )}
                                                </Col>
                                            </div>
                                        </Form>
                                        <span className="fs--13 fw--600 font-archivo text-site-bgGreen">
                                            For Developers
                                        </span>
                                        <h1 className="fs--55 fw--600 text-white">
                                            The Golf Course Maps Db.
                                        </h1>
                                        <h2 className="fs--21 fw--200 text-site-muted">
                                            Build golf course maps in your app
                                            with our geodata API.{" "}
                                        </h2>
                                        {user?.api_plan !== "all" && (
                                            <Button
                                                color="site-bgGreen"
                                                className="text-dark rounded-0"
                                                onClick={() => {
                                                    history.push("/api/plans");
                                                }}
                                            >
                                                {user?.api_plan ===
                                                "single"
                                                    ? "Upgrade your plan"
                                                    : "Get an API Key"}
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                                <Col sm="4" className="f-colr">
                                    {/* <img src={topphone} className="mt-n5" /> */}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Headerlanding;
