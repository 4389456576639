import React, { useEffect, useState } from "react";

import { CodeBlock, dracula } from "react-code-blocks";
import { Link, Element, scroller } from "react-scroll";
import { Badge, Col, Row } from "reactstrap";
import { Events, scrollSpy } from "react-scroll";

const DocumentationModelContent = ({ data, index }) => {
    const [activeSection, setActiveSection] = useState("");

    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    useEffect(() => {
        Events.scrollEvent.register("scroll", (to) => {
            setActiveSection(to);
        });
        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove("scroll");
        };
    }, []);
    return (
        <>
            <Row className="">
                <Col xs="12" lg="6" className="pb-5">
                    {index == 0 && (
                        <>
                            <div>
                                <h6 className="fs--22 fw--400 font-poppins path">
                                    Models
                                </h6>
                            </div>
                        </>
                    )}
                    <div>
                        <div className="my-3 mx-2">
                            <span className="fs--20 fw--500 font-poppins">
                                {data?.section}:
                            </span>
                            <span className="font-italic fs--20 fw--500 ml-1">
                                object
                            </span>
                        </div>
                        <div className="pl-4">
                            <div>
                                <h6 className="path-title py-2">PROPERTIES</h6>
                            </div>

                            <div>
                                {data?.properties?.map((property, index) => (
                                    <div key={index}>
                                        {Object.entries(property).map(
                                            ([key, value]) => (
                                                <div
                                                    key={key}
                                                    className="d-flex align-items-center"
                                                >
                                                    <span className="fs--16 fw--500 font-poppins">
                                                        {`${key}:`}&nbsp;
                                                    </span>
                                                    {value?.route ? (
                                                        <Link
                                                            className="fs--14 fw--400 font-poppins text-site-primary mr-3"
                                                            key={value?.route}
                                                            to={value?.route}
                                                            activeClass="active"
                                                            spy={true}
                                                            smooth={true}
                                                            offset={-70}
                                                            duration={500}
                                                            onSetActive={
                                                                handleSetActive
                                                            }
                                                        >
                                                            {value?.route}
                                                        </Link>
                                                    ) : (
                                                        // <span className="fs--18 fw--400 font-italic text-site-primary ">
                                                        //     {value?.route}
                                                        // </span>
                                                        <>
                                                            <span className="fs--16 fw--400 font-italic text-muted">
                                                                {value?.split(
                                                                    "#"
                                                                )[0] &&
                                                                    value?.split(
                                                                        "#"
                                                                    )[0]}
                                                            </span>
                                                            <span className="fs--16 fw--400 font-italic ml-2">
                                                                <Badge className="bg-danger">
                                                                    {value?.split(
                                                                        "#"
                                                                    )[1] &&
                                                                        value?.split(
                                                                            "#"
                                                                        )[1]}
                                                                </Badge>
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr />
                </Col>
                <Col xs="12" lg="6" className="bg-site-bgDark  "></Col>
            </Row>
        </>
    );
};

export default DocumentationModelContent;
