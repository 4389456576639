import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import {
    Button,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import { getFilteredCourses } from "../../store/actions/HolesAction";

function SearchFilter({ SearchCourseNameFilter }) {
    const { courses, quickSearch, courseLoader } = useSelector(
        (state) => state.course
    );

    const { states } = useSelector((state) => state.citiesStates);

    const dispatch = useDispatch();
    const param = useParams();

    const initialFilter = {
        name: "",
        state: "",
        city: "",
        limit: 25,
        offset: 0,
    };
    const [filter, setFilter] = useState(initialFilter);

    const areAllFieldsEmpty = () => {
        return (
            !filter.name?.trim() &&
            !filter.state?.trim() &&
            !filter.city?.trim()
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            getFilteredCourses(filter, (data) => {
                SearchCourseNameFilter(data);
            })
        );
    };

    useEffect(() => {
        if (courses.length > 0) {
            SearchCourseNameFilter(courses);
        } else {
            // setFilter(initialFilter);
            SearchCourseNameFilter("");
        }
    }, [courses]);
    useEffect(() => {
        if (Object.keys(quickSearch).length !== 0) {
            setFilter(quickSearch);
        }
    }, [quickSearch]);
    return (
        <>
            <Container className="px-4 px-lg-0 bg-transparent">
                <Row className="py-4">
                    <h1>
                        Golf Courses{" "}
                        {param?.state ? `in ${param?.state}` : ""}
                    </h1>
                </Row>

                <Form className="" onSubmit={handleSubmit}>
                    <Row className="align-items-center py-5 border-top border-bottom">
                        <Col xl={6} className="my-2 px-2">
                            <Label className="sub-form-label">
                                Course Name
                            </Label>
                            <Input
                                id="CourseName"
                                name="CourseName"
                                placeholder=""
                                type="text"
                                className="signIn__input"
                                value={filter.name}
                                onChange={(e) =>
                                    setFilter({
                                        ...filter,
                                        name: e.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col xl={6} className="my-2 px-2">
                            <Row className="align-items-center mb-0">
                                <Col xl={4}>
                                    <Label className="sub-form-label">
                                        State
                                    </Label>
                                    <Select
                                        isClearable={true}
                                        className="signIn__input"
                                        placeholder="Nationwide"
                                        isSearchable={true}
                                        name="select"
                                        options={states.map((item) => {
                                            return {
                                                value: item?.state,
                                                label: item?.state,
                                            };
                                        })}
                                        onChange={(selectedOption) =>
                                            setFilter((pre) => ({
                                                ...pre,
                                                state: selectedOption?.value,
                                            }))
                                        }
                                    />
                                </Col>
                                <Col xl={8}>
                                    <Row className="p-0 m-0 mt-2">
                                        <Label className="sub-form-label">
                                            City
                                        </Label>
                                    </Row>
                                    <Row className="p-0 m-0">
                                        <Col xs={8} className="p-0 m-0">
                                            <Input
                                                id="city"
                                                name="city"
                                                placeholder=""
                                                type="text"
                                                className="signIn__input"
                                                value={filter.city}
                                                onChange={(e) =>
                                                    setFilter((pre) => ({
                                                        ...pre,
                                                        city: e.target.value,
                                                    }))
                                                }
                                            />
                                        </Col>
                                        <Col xs={4} className="p-0 m-0 align-items-right">
                                            <Button
                                                disabled={courseLoader || areAllFieldsEmpty()}
                                                type="submit"
                                                className="bg-light text-site-primary shadow border-0 rounded py-2 px-4 ml-3"
                                            >
                                                {courseLoader ? (
                                                    <Spinner size={"sm"}> </Spinner>
                                                ) : (
                                                    "Submit"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    );
}

export default SearchFilter;
