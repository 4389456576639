import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import {StatData} from '../../utils/HolesScoreAndStatData';
import { FaRegChartBar } from 'react-icons/fa';

function Stats() {
  return (
    <>
      <Container className="px-0 bg-transparent mb-5">
                <Row className="py-2">
                    <Col className="table-responsive-sm px-md-0">
                        <table className="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Score</th>
                                    <th scope="col">Per Rnd Avg (All Rnds)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {StatData.map((item, id) => {
                                    return (
                                        <tr key={id}>
                                            <td className={`fsw-4 ${item.subPerAndAvg.includes('%') ? 'text-bearish' : ''} `}>
                                                {item.scoreName}
                                            </td>
                                            <td className={`fsw-4 ${item.subPerAndAvg.includes('%') ? 'text-bearish' : ''}`}>
                                                <span className="mx-1">{item.perAndAvg}</span>
                                                <span className="mx-1">{item.subPerAndAvg}</span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>

                <Row className="py-2">
                    <Col className="text-right">
                        <a href='#' target="_blank" className="custom-a-hover mx-2"><b className="text-site-primary fsw-4"><FaRegChartBar /> View all my stats</b></a>
                    </Col>
                </Row>
                
            </Container>
    </>
  )
}

export default Stats
