import React from "react";
import { Link } from "react-router-dom";

const Introduction = () => {
    return (
        <div>
            <h3 className="fs--24 fw--600 font-poppins text-muted">
                Golfbert API
            </h3>
            <p className="fs--16 fw--400 font-poppins text-muted pt-5">
                This API allows you to access rich golf course information,
                including gps coordinates and geo polygons of courses holes.
                This API can only be used in conjunction with a valid api token,
                access key and secret. More information can be found&nbsp;<Link to="/api/overview"  className="fs--16 fw--400 font-poppins text-site-primary ">here</Link>
.
            </p>
            <div>
                <h6 className="fs--18 fw--400 font-poppins path">
                    Paths
                </h6>
            </div>
            <hr />
        </div>
    );
};

export default Introduction;
