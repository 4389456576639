import React from "react";
import { Col, Row } from "reactstrap";
import Subscribe from "../../components/global/Subscribe";

const SubscribeTest = () => {
    return (
        <>
            <Row className="w-100 h-100 m-0 api-pages">
                <Col xs="12 ">
                    <Subscribe
                        subtitle="Test Course"
                        singleCourse="Sample Course subscribers:"
                        price="$0"
                        course="Sample Golf Course"
                    />
                </Col>
            </Row>
        </>
    );
};

export default SubscribeTest;
