import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Row,
    Spinner,
    UncontrolledTooltip,
} from "reactstrap";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { planCardData } from "../utils/data";
import { getUserSubscription } from "../store/actions/subscribeAction";

const PlanCard = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user, authLoading } = useSelector((state) => state.authUser);
    const [open, setOpen] = useState(true);
    const [id, setId] = useState();
    const [subscriptionDetails, setSubscriptionDetails] = useState("");
    // console.log("subscriptionDetails: ", subscriptionDetails);

    const currentPlanId =
        subscriptionDetails?.currentSubscription?.plan_id;
    const nextPlanId =
        subscriptionDetails?.subscriptionSchedule?.plan_id;

    const toggle = () => {
        setOpen(!open);
    };
    const [cardData, setCardData] = useState(planCardData);
    // console.log("cardData: ", cardData);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (user?.stripe_customer_id) {
            dispatch(
                getUserSubscription(user?.stripe_customer_id, (res) => {
                    // console.log("res: ", res);
                    setSubscriptionDetails(res);
                })
            );
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 915);

            const updatedCardData = cardData?.map((item) => {
                return { ...item, active: false };
            });

            // console.log("updatedCardData: ", updatedCardData);
            setCardData(updatedCardData);
            if (window.innerWidth > 915) {
                const updatedCardData = cardData?.map((item) => {
                    return { ...item, active: true };
                });

                setCardData(updatedCardData);
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            {authLoading ? (
                <div className="text-center">
                    <Spinner> </Spinner>
                </div>
            ) : (
                <Row className="justify-content-center py-4 ">
                    {cardData?.map((obj, index) => (
                        <>
                            <Col lg="3" md="4" sm="6" className="mb-5">
                                <div className="text-center">
                                    <h5 className="fs--14 fw--500 text-site-primary font-poppins">
                                        {obj.header}
                                    </h5>

                                    <Card
                                        className=" plan-card shadow plan-card2 bg-site-lite-btn "

                                        // className={`${
                                        //     index == 1 ? "plan-card2" : "plan-card"
                                        // } shadow my-2`}
                                    >
                                        <div className="text-center ">
                                            <h6 className="title my-0">
                                                {obj.nickname}
                                            </h6>
                                        </div>
                                        <div className="py-3 bg-white">
                                            <h6 className="subTitle">
                                                {obj.subTitle}
                                            </h6>

                                            <h6 className="fs--24 fw--500 font-poppins">
                                                $ {obj.unit_amount}
                                            </h6>

                                            <h6 className="fs--14 fw--500 text-muted">
                                                {obj.recurring.interval ===
                                                "one-time fee"
                                                    ? "one-time fee"
                                                    : "per " +
                                                      obj.recurring.interval}
                                            </h6>
                                        </div>
                                        <div className="text-center py-2">
                                            <Button
                                                className="text-dark rounded-1 btn btn-site-bgGreen px-4 py-2"
                                                disabled={
                                                    obj?.id === currentPlanId ||
                                                    obj?.id === nextPlanId ||
                                                    user?.api_plan ===
                                                        obj?.id?.replace(/^api-/g, "").trim()
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    let data = {
                                                        price: obj.unit_amount,
                                                        month: obj.recurring.interval,
                                                        priceId: obj.id,
                                                    };

                                                    history.push({
                                                        pathname: obj.path,
                                                        state: data,
                                                    });
                                                }}
                                            >
                                                { obj?.id === currentPlanId
                                                    ? "Your Current Plan"
                                                    : obj?.id === nextPlanId
                                                    ? "Your Next Plan"
                                                    : user?.api_plan ===
                                                        obj?.id?.replace(/^api-/g, "").trim()
                                                    ? "Your Current Plan" : obj?.btnText
                                                }
                                            </Button>
                                        </div>
                                        {/* <div className="bg-site-lite-btn my-1"></div> */}
                                    </Card>
                                    {isMobile && (
                                        <>
                                            <div className="d-flex justify-content-center align-items-center my-2">
                                                <h6 className="fs--16 fw--500 text-site-primary mr-1">
                                                    {obj.active ? (
                                                        <FaMinusCircle />
                                                    ) : (
                                                        <FaPlusCircle />
                                                    )}
                                                </h6>
                                                <h6
                                                    className="fs--14  fw--500 font-poppins text-site-primary cursor-pointer my-2"
                                                    onClick={() => {
                                                        let temp = [
                                                            ...cardData,
                                                        ];
                                                        temp[index].active =
                                                            !obj.active;
                                                        setCardData(temp);
                                                    }}
                                                >
                                                    {obj.active
                                                        ? "Hide Features"
                                                        : "Show Features"}
                                                </h6>
                                            </div>
                                        </>
                                    )}

                                    {obj?.active && (
                                        <>
                                            <div className="my-4 text-center">
                                                <h6 className="fs--16 fw--400 font-poppins">
                                                    {obj?.callPerMon}
                                                </h6>
                                                <h6 className="fs--12 fw--400 text-muted">
                                                    {obj?.additionalApi}
                                                </h6>
                                                {index !== 0 && (
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={`Tooltip-${index}`}
                                                        autohide={false}
                                                    >
                                                        {obj?.description}
                                                    </UncontrolledTooltip>
                                                )}
                                                <div className="d-flex justify-content-center py-2">
                                                    <h6 className="fs--16 fw--400 font-poppins ">
                                                        {obj?.accessCourse}
                                                    </h6>

                                                    {index !== 0 && (
                                                        <h6
                                                            className="fs--16 fw--400 ml-1 cursor-pointer"
                                                            id={`Tooltip-${index}`}
                                                        >
                                                            {obj?.icon}
                                                        </h6>
                                                    )}
                                                </div>
                                            </div>
                                            <hr></hr>

                                            <div className="my-4 text-center">
                                                <h6 className="fs--16 fw--500 font-poppins">
                                                    {obj.support}
                                                </h6>
                                                <h6 className="fs--14 fw--400 font-poppins">
                                                    {obj?.bussinessDay}
                                                </h6>
                                                <h6 className="fs--14 fw--400 font-poppins">
                                                    {obj?.newCourse}
                                                </h6>
                                                <h6 className="fs--14 fw--400 font-poppins">
                                                    {obj?.submit}
                                                </h6>
                                                <h6 className="fs--14 fw--400 font-poppins">
                                                    {obj?.report}
                                                </h6>
                                            </div>
                                            {index === 2 && (
                                                <>
                                                    <hr></hr>
                                                </>
                                            )}

                                            <div className="my-4 text-center">
                                                <h6 className="fs--16 fw--500 font-poppins">
                                                    {obj.maintenanceFees}
                                                </h6>
                                                <h6 className="fs--12 fw--300 font-poppins">
                                                    {obj?.businessNeed}
                                                </h6>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </>
                    ))}
                </Row>
            )}
        </>
    );
};

export default PlanCard;
