import React from "react";

const Introduction = () => {
    return (
        <div>
            <h3 className="fs--24 fw--700 font-poppins">I. Introduction</h3>
            <p className="fs--16 fw--300 font-poppins">
                Welcome to this tutorial on building a Golf Course Management
                React App using the Golfbert API and Google Maps! In this
                tutorial, we will walk through the process of creating a sample
                React application that displays course data and renders holes
                using Google Maps.
            </p>
            <p className="fs--14 fw--300 font-poppins">
                The sample app we will build has four tabs:
            </p>
            <ol className="fs--14 fw--300 font-poppins">
                <li>First Request</li>
                <li>Course List</li>
                <li>Scorecard</li>
                <li>Holes</li>
            </ol>
            <div>
                <h2 className="fs--18 fw--500 font-poppins my-4">
                    Overview of the Golfbert API
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    Golfbert is a powerful golf GPS API that provides access to
                    a wide range of golf course data,including course details,
                    scorecards, and hole information. By leveraging Golfbert
                    API, we cancreate a seamless experience for users looking to
                    manage their golf courses, keep scores, andnavigate holes
                    with ease.
                </p>
            </div>
            <div>
                <h2 className="fs--18 fw--500 font-poppins my-4">
                    Overview of the React App with Google Maps
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    We will build a React app that fetches and displays data
                    from the Golfbert API and integratesGoogle Maps to render
                    the locations of holes on the selected golf course. The app
                    will have aresponsive design and provide a seamless user
                    experience across various devices.
                </p>
            </div>
            <div>
                <h2 className="fs--18 fw--500 font-poppins my-4">
                    App Functionality and Features
                </h2>
                <ul className="fs--14 fw--300 font-poppins">
                    <li>
                        Make API requests to Golfbert and display course data
                    </li>
                    <li>Search and filter golf courses</li>
                    <li>
                        Show scorecards with basic scorekeeping functionality
                    </li>
                    <li>
                        Render hole locations on Google Maps with navigation
                    </li>
                </ul>
            </div>
            <div>
                <h2 className="fs--18 fw--500 font-poppins my-4">
                    Prerequisites
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    Before starting this tutorial, make sure you have the
                    following:
                </p>
                <ol className="fs--14 fw--300 font-poppins">
                    <li>
                        Basic knowledge of JavaScript, React, and Google Maps
                        API
                    </li>
                    <li>Node.js and npm installed on your system</li>
                    <li>A valid Google Maps API key</li>
                    <li>A Golfbert API key</li>
                </ol>
                <p className="fs--14 fw--300 font-poppins">
                    With these prerequisites in place, let's get started on
                    building our Golf Course Management ReactApp!
                </p>
            </div>
        </div>
    );
};

export default Introduction;
