import React, { useEffect, useState } from "react";
import { Link, Element, scroller } from "react-scroll";
import { TutorialSidebarData } from "../TutorialSidebarData";
import { Events, scrollSpy } from "react-scroll";
import { useDispatch } from "react-redux";
import { setIsScrolling } from "../../store/actions/layoutAction";
import { useSelector } from "react-redux";

// let offsetSidebar = 0;
const TutorialSidebar = ({ isExpanded, setIsExpanded, type }) => {
    // const handleClick = (sectionId) => {
    //     scroller.scrollTo(sectionId, {
    //         smooth: true,
    //         duration: 500,
    //     });
    // };

    const [activeSection, setActiveSection] = useState("");

    const { isScrolling } = useSelector((state) => state.layout);
    const dispatch = useDispatch();

    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const handleResize = () => {
        // alert(window.innerWidth);
        if (window.innerWidth >= 1200) {
            dispatch(setIsScrolling(-180));
            // setSideBar(-180);
            // offsetSidebar = -180;
            // console.log("xl");
        } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
            dispatch(setIsScrolling(-90));
            // setSideBar(-90);
            // offsetSidebar = -90;
            // console.log("lg");
        } else if (window.innerWidth < 992 && window.innerWidth >= 320) {
            if (window.innerHeight < 700 && window.innerHeight >= 600) {
                dispatch(setIsScrolling(-750));
            } else if (window.innerHeight < 850 && window.innerHeight >= 700) {
                dispatch(setIsScrolling(-850));
            } else if (window.innerHeight < 920 && window.innerHeight >= 850) {
                dispatch(setIsScrolling(-950));
            } else if (window.innerHeight < 1040 && window.innerHeight >= 920) {
                dispatch(setIsScrolling(-1050));
            } else {
            }
            // setSideBar(-590);
            // offsetSidebar = -590;
            // console.log("md");
        } else {
        }
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();
        Events.scrollEvent.register("scroll", (to) => {
            setActiveSection(to);
        });
        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove("scroll");
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // useEffect(() => {
    //     window.addEventListener("resize", handleResize);
    // }, []);

    return (
        <div className="sidebar overflow-auto mr-lg-4">
            <div className="">
                <h4 className="fs--24 fw--600 font-poppins">API Tutorial</h4>
                <p className="fs--16 fw--400 text-muted font-poppins my-3">
                    React + Google Maps
                </p>
                <hr className=""></hr>
            </div>
            <div>
                {/* {isScrolling} */}
                {TutorialSidebarData.map((link) => (
                    <Link
                        key={link.id}
                        to={link.section}
                        className="fs--14 fw--400 text-muted font-poppins cursor-pointer navlink-wrap"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={isScrolling}
                        duration={500}
                        onSetActive={handleSetActive}
                        onClick={() => {
                            type && setIsExpanded(!isExpanded);
                        }}
                    >
                        {link.name}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default TutorialSidebar;
