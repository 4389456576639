import {
    COUPON_LOADER,
    LOGIN,
    LOGOUT,
    REG_LOADER,
    UPDATE_PROFILE,
} from "../types";

const initialState = {
    user: null,
    uid: null,
    authLoading: false,
    regLoading: false,
    couponLoading: false,
};

/*
    Any action related to Profile will go here.
*/

export default function authUserReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case LOGIN:
            return {
                ...state,
                user: payload,
                uid: payload.id,
            };
        case LOGOUT:
            localStorage.removeItem("auth");
            localStorage.removeItem("token");
            return {
                ...state,
                user: null,
                uid: null,
            };
        case UPDATE_PROFILE:
            // For Example, any thing
            return {
                ...state,
                user: payload,
            };
        case "AUTH_LAODER":
            // For Example, any thing
            return {
                ...state,
                authLoading: payload,
            };
        case REG_LOADER:
            return {
                ...state,
                regLoading: payload,
            };
        case COUPON_LOADER:
            return {
                ...state,
                couponLoading: payload,
            };

        default:
            return { ...state };
    }
}
