import React, { useEffect, useState } from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import { Link, Element, scroller } from "react-scroll";

import { Badge, Col, Row } from "reactstrap";
import { Events, scrollSpy } from "react-scroll";

const DocumentationsPathContent = ({ data, index }) => {
    const [activeSection, setActiveSection] = useState("");

    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    useEffect(() => {
        Events.scrollEvent.register("scroll", (to) => {
            setActiveSection(to);
        });
        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove("scroll");
        };
    }, []);
    return (
        <>
            <Row>
                <Col xs="12" lg="6" className="section">
                    {index == 0 && (
                        <>
                            <div>
                                <h6 className="fs--18 fw--400 font-poppins path">
                                    Paths
                                </h6>
                            </div>
                            <hr />
                        </>
                    )}
                    <div className="px-lg-0 px-md-0">
                        <div className="my-3">
                            <h3 className="fs--18 fw--500 font-poppins">
                                {data.route}
                            </h3>
                        </div>
                        <div className="pl-0 pl-lg-4">
                            <div>
                                <h6 className="path-title py-2">PATH</h6>
                                <p className="fs--14 f--400 font-poppins text-muted">
                                    {data.path}
                                </p>
                            </div>

                            <div>
                                <h6 className="path-title py-2">DESCRIPTION</h6>
                                <div
                                    className="fs--14 f--400 font-poppins text-muted "
                                    dangerouslySetInnerHTML={{
                                        __html: data.Description,
                                        
                                    }}
                                />
                            </div>
                            {data?.requestParameter?.length > 0 && (
                                <>
                                    <h6 className="path-title py-2">
                                        REQUEST PARAMETERS
                                    </h6>
                                    <hr></hr>
                                    {data?.requestParameter?.map(
                                        (obj, index) => (
                                            <>
                                                <div>
                                                    <h6 className="fs--14 fw--500 mt-4 font-poppins">
                                                        {obj.params}
                                                    </h6>
                                                    <div className="d-flex align-items-center">
                                                        {obj.required && (
                                                            <div className="mr-2">
                                                                <Badge className="bg-danger">
                                                                    required
                                                                </Badge>
                                                            </div>
                                                        )}

                                                        <div>
                                                            <h6 className="fs--14 fw--400 font-poppins text-muted mb-0">
                                                                {
                                                                    obj.description
                                                                }
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="my-2" />
                                                <div>
                                                    <div>
                                                        <span className="fs--14 fw--400 font-poppins text-muted">
                                                            type
                                                        </span>
                                                        <span className="fs--14 fw--400 font-poppins text-muted ml-lg-4">
                                                            {obj.paramsType}
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr className="my-2" />
                                                <div>
                                                    <div>
                                                        <span className="fs--14 fw--400 font-poppins text-muted">
                                                            in
                                                        </span>
                                                        <span className="fs--14 fw--400 font-poppins text-muted ml-2">
                                                            {obj.paramsIn}
                                                        </span>
                                                    </div>
                                                </div>
                                                {index !==
                                                    data?.requestParameter
                                                        ?.length -
                                                        1 && (
                                                    <hr className="my-2" />
                                                )}
                                            </>
                                        )
                                    )}
                                </>
                            )}
                            <div>
                                <div className="py-1">
                                    <h6 className="path-title ">RESPONSES</h6>
                                    <hr />
                                </div>

                                {data?.response?.map((obj, index) => (
                                    <>
                                        <div>
                                            <h6 className="fs--18 fw--500 font-poppins ">
                                                {obj.statusCode}
                                            </h6>
                                            <div>
                                                <Link
                                                    className="fs--14 fw--400 font-poppins text-site-primary mr-3"
                                                    key={obj.section}
                                                    to={obj.section}
                                                    activeClass="active"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={500}
                                                    onSetActive={
                                                        handleSetActive
                                                    }
                                                >
                                                    {obj?.section}
                                                </Link>
                                                {/* <span className="fs==14 fw--400 font-poppins text-site-primary mr-3">
                                                    {obj.title}
                                                </span> */}

                                                <span className="fs--14 fw--400 font-poppins">
                                                    {obj.description}
                                                </span>
                                            </div>
                                        </div>
                                        {index !== data.response.length - 1 && (
                                            <hr />
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col
                    xs="12"
                    lg="6"
                    className="bg-site-bgDark d-flex flex-column justify-content-end section-code px-2"
                >
                    {data?.response?.map((obj) => (
                        <>
                            <div class="code-tip">
                                <div>
                                    <h6 className="fs--14 fw--400 font-poppins text-white my-4">
                                        {obj.responseStatus}
                                    </h6>
                                </div>

                                <CodeBlock
                                    text={
                                        obj.reponseCode
                                            ? obj.reponseCode.toString()
                                            : ""
                                    }
                                    language="jsx"
                                    theme={dracula}
                                    showLineNumbers={false}
                                />
                            </div>
                        </>
                    ))}
                </Col>
            </Row>
        </>
    );
};

export default DocumentationsPathContent;
