import {
    GoogleMap,
    useLoadScript,
    Marker,
    Polygon,
    Polyline,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { MAPS_KEY } from "../../../constant";

function PolygonMap({
    hole,
    paths,
    height,
    showPolyline,
    selectedTeeBox,
    zoom,
    isCalculate,
    setYdsToGreen,
    index,
    callFrom,
    setYdsToTee,
}) {
    // console.log("zoom: ", zoom);
    const { rotation } = hole;
    const [mousePath, setMousePath] = useState({
        lat: 34.0522,
        lng: -118.2437,
    });
    const centerCoords = {
        lat: (hole?.range?.y?.max + hole?.range?.y?.min) / 2,
        lng: (hole?.range?.x?.max + hole?.range?.x?.min) / 2,
    };
    const [center, setCenter] = useState(centerCoords);
    const flagCoords = {
        lat: hole?.flagcoords?.lat,
        lng: hole?.flagcoords?.long,
    };

    const getPolygonStyle = (surfaceType) => {
        switch (surfaceType) {
            case "Green":
                return {
                    strokeColor: "#92d65b",
                    fillColor: "#92d65b",
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    rotation: (rotation * 180) / Math.PI,
                };
            case "Fairway":
                return {
                    strokeColor: "#66be50",
                    fillColor: "#66be50",
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    rotation: (rotation * 180) / Math.PI,
                };
            case "Sand":
                return {
                    strokeColor: "#ffffff",
                    fillColor: "#ffffff",
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    rotation: (rotation * 180) / Math.PI,
                };
            case "Woods":
                return {
                    strokeColor: "#0c0e0f",
                    fillColor: "#0c0e0f",
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    rotation: (rotation * 180) / Math.PI,
                };
            case "Water":
                return {
                    strokeColor: "#5296f2",
                    fillColor: "#5296f2",
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    rotation: (rotation * 180) / Math.PI,
                };
            default:
                return {
                    strokeColor: "#94a1a9",
                    fillColor: "#94a1a9",
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    rotation: (rotation * 180) / Math.PI,
                };
        }
    };

    const handleMouseOver = (event) => {
        const lat = event.latLng?.lat();
        const lng = event.latLng?.lng();
        setMousePath({ lat, lng });
    };

    function calculateDistance(point1, point2) {
        const toRadians = (angle) => (angle * Math.PI) / 180;

        const R = 6371; // Earth radius in kilometers
        const lat1 = toRadians(point1.lat);
        const lon1 = toRadians(point1.lng);
        const lat2 = toRadians(point2.lat);
        const lon2 = toRadians(point2.lng);

        const dLat = lat2 - lat1;
        const dLon = lon2 - lon1;

        const a =
            Math.sin(dLat / 2) ** 2 +
            Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c; // Distance in kilometers

        return (distance * 1093.61).toFixed();
    }

    let flagstick = {
        lat: hole?.flagcoords?.lat,
        lng: hole?.flagcoords?.long,
    };

    let minCoords = {
        lat: hole?.range?.y.min,
        lng: hole?.range?.x.min,
    };
    function findCenter(coords1, coords2) {
        const x = (coords1.lng + coords2.lng) / 2;
        const y = (coords1.lat + coords2.lat) / 2;

        return {
            lat: y,
            lng: x,
        };
    }
    let holeCenter = findCenter(flagstick, minCoords);
    let newVectors = rotatePointsAroundFarthest(
        hole.vectors.filter((i) => i.type !== "Flag"),
        flagCoords,
        rotation
    );

    function rotatePointsAroundFarthest(points, flagCoords, angle) {
        const distances = points.map((point) => {
            const lat1 = flagCoords.lat;
            const lon1 = flagCoords?.lng || flagCoords?.lng;
            const lat2 = point.lat;
            const lon2 = point.long;
            const R = 6371;
            const dLat = ((lat2 - lat1) * Math.PI) / 180;
            const dLon = ((lon2 - lon1) * Math.PI) / 180;
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos((lat1 * Math.PI) / 180) *
                    Math.cos((lat2 * Math.PI) / 180) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const d = R * c;
            return d;
        });

        const farthestIndex = distances.indexOf(Math.max(...distances));
        const farthestPoint = points[farthestIndex];

        const rotatedPoints = points.map((point, index) => {
            // if (index === farthestIndex) return point;

            return rotatePointByPoint(point, flagCoords, angle);
        });

        return rotatedPoints;
    }
    function rotatePointByPoint(point, rotationPoint, radians) {
        const relativeX =
            point.long - (rotationPoint?.long ?? rotationPoint?.lng);
        const relativeY = point.lat - rotationPoint.lat;
        const rotatedX =
            relativeX * Math.cos(radians) - relativeY * Math.sin(radians);
        const rotatedY =
            relativeX * Math.sin(radians) + relativeY * Math.cos(radians);
        const x = rotatedX + (rotationPoint?.long ?? rotationPoint?.lng);
        const y = rotatedY + rotationPoint.lat;
        return {
            type: point.type,
            lat: y,
            long: x,
        };
    }
    function rotatePointByPoint1(point, rotationPoint, radians) {
        const relativeX = point?.lng - rotationPoint?.lng;
        const relativeY = point?.lat - rotationPoint.lat;
        const rotatedX =
            relativeX * Math.cos(radians) - relativeY * Math.sin(radians);
        const rotatedY =
            relativeX * Math.sin(radians) + relativeY * Math.cos(radians);
        const x = rotatedX + (rotationPoint?.long ?? rotationPoint?.lng);
        const y = rotatedY + rotationPoint?.lat;
        return {
            lat: y,
            lng: x,
        };
    }

    function changeKeyToLng(coordinatesArray) {
        const modifiedCoordinates = coordinatesArray.map((coord) => {
            let point = { lat: coord?.lat, lng: coord?.long };
            let rotatedPoint = rotatePoints(point, center, rotation);

            return rotatedPoint;
        });

        return modifiedCoordinates;
    }
    function rotatePoints(point, center, radians) {
        const translatedX = point.lng - center.lng;
        const translatedY = point.lat - center.lat;
        const rotatedX =
            translatedX * Math.cos(radians) - translatedY * Math.sin(radians);
        const rotatedY =
            translatedX * Math.sin(radians) + translatedY * Math.cos(radians);
        const newLng = rotatedX + center.lng;
        const newLat = rotatedY + center.lat;

        return { lat: newLat, lng: newLng };
    }
    const path = [
        flagCoords, // Point 1
        mousePath, // Point 2
        rotatePointByPoint1(selectedTeeBox, flagCoords, rotation), // Point 3
    ];

    useEffect(() => {
        if (showPolyline) {
            setYdsToGreen(calculateDistance(center, mousePath));
            setYdsToTee(calculateDistance(selectedTeeBox, mousePath));
        }
    }, [mousePath]);
    useEffect(() => {
        setCenter({
            lat: hole?.flagcoords?.lat,
            lng: hole?.flagcoords?.long,
        });
    }, [hole]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: MAPS_KEY //,
        // ...otherOptions
    });
    if (loadError) { return <div>Error loading maps</div>; }
    if (!isLoaded) { return <div>Loading maps...</div>; }

    return (
        <div className="App" style={{ height: height }}>
            <div
                id="map-container"
                style={{
                    width: "100%",
                    height: "100%",
                    transformOrigin: "center",
                }}
            >
                {(
                    <GoogleMap
                        onMouseMove={showPolyline ? handleMouseOver : null}
                        mapContainerClassName="App-map rounded"
                        center={center}
                        zoom={zoom}
                        heading={rotation * 180 / Math.PI}
                        options={{
                            fullscreenControlOptions: false,
                            zoomControlOptions: true,
                            backgroundColor: "#283032",
                            draggable: true,
                            draggingCursor: "grabbing",
                            scrollwheel: true,
                            styles: [
                                {
                                    elementType: "all",
                                    stylers: [{ visibility: "off" }],
                                },
                            ],
                            zoomControl: true,
                            fullscreenControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            scrollwheel: true,
                            gestureHandling: "auto",
                            // gestureHandling: zoom > 17 ? "" : "none",
                        }}
                        version="weekly"
                        on
                    >
                        {showPolyline && (
                            <Polyline
                                path={path}
                                options={{
                                    strokeColor: "#fff",
                                    fillColor: "#ffff",
                                    strokeOpacity: 0.8,
                                    fillOpacity: 0.6,
                                    zIndex: 999,
                                    strokeWeight: 1,
                                }}
                            />
                        )}
                        {paths?.map((p, index) => {
                            return (
                                <Polygon
                                    key={index}
                                    className="polygon"
                                    path={changeKeyToLng(p.points)}
                                    // onMouseUp={onEdit}
                                    options={getPolygonStyle(p.surfaceType)}
                                    // onUnmount={onUnmount}
                                />
                            );
                        })}

                        <Marker
                            position={center}
                            icon={{
                                url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                                    `<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="20" height="30" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
<path d="M1594 5111 c-46 -20 -54 -47 -54 -187 0 -126 1 -132 25 -158 l25 -27 0 -2087 0 -2088 -110 -17 c-235 -37 -378 -116 -411 -227 -43 -143 147 -270 466 -309 375 -46 767 63 816 228 44 146 -128 270 -430 311 l-91 13 0 2088 0 2088 25 25 c24 24 25 29 25 160 0 128 -1 136 -24 163 -23 27 -28 28 -133 30 -59 2 -118 -1 -129 -6z m-4 -4761 l0 -70 120 0 120 0 0 70 c0 39 3 70 8 70 4 0 47 -7 96 -15 113 -18 206 -50 251 -88 30 -24 33 -31 24 -49 -16 -30 -126 -77 -236 -100 -140 -30 -387 -30 -527 0 -106 23 -220 71 -236 101 -24 45 117 113 290 140 97 15 90 20 90 -59z"/>
<path d="M2490 5073 c-75 -6 -187 -33 -259 -63 -84 -34 -200 -109 -240 -156 l-31 -35 0 -654 c0 -642 0 -655 20 -675 32 -32 67 -25 128 25 232 191 532 213 831 61 25 -13 115 -67 199 -119 163 -102 259 -147 379 -178 225 -57 427 21 518 200 l25 51 -2 650 c-3 708 0 671 -60 685 -30 8 -51 -10 -112 -95 -72 -101 -195 -133 -349 -91 -110 30 -184 66 -348 169 -172 107 -238 141 -344 175 -135 43 -248 59 -355 50z"/>
</g>
</svg>
      `
                                )}`,
                            }}
                        />

                        {newVectors.map((ele, index) => {
                            return (
                                <Marker
                                    key={index}
                                    position={{
                                        lat: ele.lat,
                                        lng: ele.long,
                                    }}
                                    icon={{
                                        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                                            `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20">
                        <circle cx="10" cy="10" r="9" stroke="#94a1a9" stroke-width="2" fill="#fff" />
                        <circle cx="10" cy="10" r="5" fill="${ele.type}" />
                      </svg>
      `
                                        )}`,
                                    }}
                                />
                            );
                        })}
                    </GoogleMap>
                )}
            </div>
        </div>
    );
}

export default PolygonMap;
