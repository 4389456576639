import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import { Link } from "react-router-dom";

const Paging = () => {
    return (
        <div>
            <h2 className="fs--20 fw--500 font-poppins">Paging</h2>
            <p className="fs--16 fw--400 font-poppins text-muted py-2">
                Paging allows for quick retrieval of information in manageable
                chunks. Let's see how you can page the courses API.
            </p>
            <p className="fs--16 fw--400 font-poppins text-muted py-2">
                Let's say your initial call is:{" "}
                <Link
                    to="/api/documentations"
                    className="fs--16 fw--400 font-poppins text-site-primary"
                >
                    https://api.golfbert.com/v1/courses
                </Link>
                . The result looks something like this:
            </p>
            <div className="w-100 py-3">
                <CodeBlock
                    text={`[
    {
        "id": 1,
        "name": "Highland Park Golf Course",
    },
    {
        "id": 2,
        "name": "Shoal Creek Golf Club",
    },
    {
        "id": 3,
        "name": "Pebble Brook Golf Course",
    },
    
    â€¦
    
    {
        "id": 9,
        "name": "Shark's Tooth Golf Club",
    },
    {
        "id": 10,
        "name": "Hoover Country Club",
    }
    ]
    `}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>

            <p className="fs--16 fw--400 font-poppins text-muted py-3">
                Here, as you can see, the API returns the first 10 courses
                available, each with a unique <i>id</i> attribute. The last one
                you encounter has id: 10 because the default page size is 10. To
                get the next 10 courses, you must call:
            </p>
            <p>
                <Link
                    to="/api/documentations"
                    className="fs--16 fw--400 font-poppins text-site-primary py-3"
                   
                >
                    https://api.golfbert.com/v1/courses?marker=10
                </Link>
            </p>
            <p className="fs--16 fw--400 font-poppins text-muted py-3">
                This tells the api to start returning courses from id 10,
                exclusive. The result will look like:
            </p>

            <div className="w-100 py-3">
                <CodeBlock
                    text={`[
    {
        "id": 11,
        "name": "Oxmoor Valley Golf Course - Ridge",
    }
    ]
`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>

            <p className="fs--16 fw--400 font-poppins text-muted py-3">
                The last id you get is 19 (not shown here). Let's say you want
                to get the next page but actually shrink the page size. You can
                use the limit parameter like this:{" "}
                <Link
                    to="/api/documentations"
                    className="fs--16 fw--400 font-poppins text-site-primary"
                    // href="https://api.golfbert.com/v1/courses?marker=19&limit=5"
                >
                    https://api.golfbert.com/v1/courses?marker=19&limit=5
                </Link>
                . The resulting set will start with course id 20 and end with
                24. Both marker and limit parameters are optional but sometimes
                you must use them to receive information in small chunks.
            </p>
            <p className="fs--16 fw--400 font-poppins text-muted py-3">
                Now you know how to page!
            </p>
        </div>
    );
};

export default Paging;
