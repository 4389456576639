import React from "react";
import { Button, Col, Row } from "reactstrap";
import EnterpriseImg from "../assets/img/icon-enterprise.svg";
import { Link } from "react-router-dom";

const EnterpriseAPI = () => {
    return (
        <>
            <Row className="py-3">
                <Col sm="12">
                    <div className="text-center">
                        <h1 className="fs--24 fw--700 font-poppins py-3">
                            Enterprise API
                        </h1>

                        <div className="text-center ">
                            <img
                                className="img-fluid enterprise-img"
                                src={EnterpriseImg}
                            />
                        </div>

                        <h6 className="fs--16 fw--400 font-poppins py-3">
                            Premier account with access to all golf courses in
                            the database and preferred customer support
                        </h6>
                        <div className="text-justify px-md-5">
                            <h6 className="fs--16 fw--400 font-poppins  px-md-5">
                                For larger scale projects, we offer
                                fully-customized API plans to fit the needs of
                                any size business. Enterprise plans come with a custom set of
                                    API calls per month. Included with this plan is access to data for
                                all golf courses in the database — our staff
                                will continue to add courses and manage the
                                course data.
                            </h6>
                        </div>
                        <Link
                            to="/api/support"
                            className="fs--14 fw--400 font-poppins text-site-primary py-4"
                        >
                            <Button className="py-3 px-5 btn-custom fs--18 fw--500  my-5">
                                Inquire about our Enterprise plan
                            </Button>
                        </Link>
                        <h6 className="fs--14 fw--400 font-poppins text-muted">
                            Enterprise API plans include 1 business day response
                            time, 10 new course requests per month, and the
                            ability to submit updates and report errors.
                        </h6>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default EnterpriseAPI;
