import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
const Privacy = () => {
    return (
        <Container className="py-5">
            <Row className="privacy">
                <Col md="12" className="legal">
                    <h1 className="privacy__headline">
                        Golfbert Privacy Policy
                    </h1>

                    <p>
                        <strong>Effective Date: May 1, 2009</strong>
                    </p>
                    <p>
                        Your privacy is important to Golfbert ("Golfbert", "we"
                        or "us"). This Privacy and Information Security Policy
                        ("the Policy") discusses the personally identifying
                        information ("PII") that we collect about you when you
                        use our website http://www.Golfbert.com ("the Site") and
                        the other services we provide on or through the Site
                        (together with the Site, the "Service"). This Policy is
                        part of and incorporated into the Golfbert Terms of Use
                        (collectively, the "Terms").
                    </p>

                    <p className="privacy__lgtext">Policy highlights:</p>
                    <p>
                        We will not collect PII about you through the Service
                        unless you disclose such information to us;
                    </p>
                    <p>
                        We will not knowingly disclose PII about you that we
                        collect through the Service to third parties, except
                        under the limited circumstances described in this
                        Policy; and
                    </p>
                    <p>
                        We will take reasonable steps to protect the security of
                        the PII we collect from you through the Service.
                    </p>
                    <p>
                        These Policy highlights are qualified by full reference
                        to the terms and conditions described below.
                    </p>
                    <p></p>

                    <p>
                        BY PROVIDING PII OR OTHER INFORMATION TO US OR BY USING
                        OUR SERVICE, YOU AGREE TO THIS POLICY AND THE PRACTICES
                        DESCRIBED IN THE POLICY.
                    </p>
                    <ol>
                        <li>
                            <strong>INFORMATION YOU GIVE US.</strong>
                            <ol>
                                <li>
                                    The Types of Information Golfbert Collects.
                                    We collect any information you enter on the
                                    Service or give us in any other way (such as
                                    through an email, survey, or letter). The
                                    information that we collect varies depending
                                    upon how you use our Service. Our account
                                    registration process requires that you
                                    provide us with your user name, birthday and
                                    email address, and that you create and
                                    provide us with a password. We may also
                                    request other information, such as but not
                                    limited to, your actual first and last name,
                                    gender, and zip code. You may access and
                                    update certain of your personal information
                                    or account settings by accessing "My
                                    Account" or "My Profile" or a similar link
                                    on the Site.
                                </li>
                                <li>
                                    Information in Public Areas of the Site.
                                    Please keep in mind that whenever you
                                    voluntarily provide information in public
                                    areas of the Service, such as the user
                                    profile pages or forums, that information
                                    can be viewed and possibly used by others.
                                    We therefore advise visitors not to disclose
                                    contact information, including phone number,
                                    email address, street address or instant
                                    messenger address (within these public
                                    areas) that they do not wish to share. We
                                    are not responsible for PII you choose to
                                    submit in these public areas. Also, we may
                                    highlight content you post to the public
                                    areas of the Service such as reviews or
                                    comments, such as by featuring your content
                                    on the Site's home page.
                                </li>
                                <li>
                                    Minors. We do not knowingly collect or
                                    solicit personal information from anyone
                                    under the age of 13 or knowingly allow such
                                    persons to register. If you are under 13, do
                                    not attempt to register for our Site or send
                                    any information about yourself to us. We
                                    recommend that prospective users between the
                                    ages of 13 and 17 ask their parents or other
                                    legal guardians for permission before
                                    sending any information about themselves to
                                    anyone over the Internet.
                                </li>
                            </ol>
                        </li>

                        <li>
                            <strong>AUTOMATIC INFORMATION COLLECTION.</strong>
                            <ol>
                                <li>
                                    Cookies. Like many websites, we use
                                    "cookies." Cookies are alphanumeric
                                    identifiers that we transfer to your
                                    computer's hard drive through your Web
                                    browser to enable our systems to recognize
                                    your browser. We use persistent, identifying
                                    cookies to remember your information and to
                                    link your activities to you. While you can
                                    take steps to warn off, block or disable
                                    cookies, if you do, the Service may not
                                    function and appear as we have designed it.
                                    Nonetheless, if you want to take these
                                    steps, you can do so by following the
                                    instructions associated with your browser.
                                    Our cookies do not collect PII that you
                                    provide to us during the registration
                                    process, but they do collect other
                                    information such as the following:
                                </li>
                                <ol>
                                    <li>
                                        the domain name and host from which you
                                        access the Internet and the Internet
                                        address of the site from which you
                                        direct-linked to ours;
                                    </li>
                                    <li>
                                        the date and time you access the Site
                                        and pages you visit;
                                    </li>
                                    <li>
                                        your computer's IP address and
                                        information about its operating system,
                                        platform and the Web browser type and
                                        version you use;
                                    </li>
                                    <li>
                                        demographic and other non-personally
                                        identifiable profile information about
                                        you; and
                                    </li>
                                    <li>
                                        information to combat fraud or misuse.
                                    </li>
                                </ol>
                                <li>
                                    Pixel Tags. In our email to you, we use
                                    "pixel tags" to determine if your email
                                    software or service can display
                                    html-formatted email (this helps us optimize
                                    the size of our email messages and conserve
                                    bandwith).
                                </li>
                                <li>
                                    Collection of Information by Third Parties.
                                    Our Site may include third-party
                                    advertising, links to other websites, and
                                    other content from third party businesses.
                                    The content posted by these parties will be
                                    reasonably identifiable as coming from a
                                    third party. We do not provide any PII to
                                    these advertisers, third-party websites, or
                                    other businesses, although on occasion we
                                    may mutually share non-personally
                                    identifiable (e.g., demographic) information
                                    to facilitate delivery of relevant
                                    advertisements. These third-party websites,
                                    businesses, and advertisers, or advertising
                                    companies working on their behalf, sometimes
                                    use technology to deliver (or "serve") the
                                    advertisements that appear on our Site
                                    directly to your browser. They automatically
                                    receive your IP address when this happens.
                                    They may also use cookies, JavaScript, web
                                    beacons (also known as action tags or
                                    single-pixel gifs), and other technologies
                                    to measure the effectiveness of their ads
                                    and to personalize or optimize advertising
                                    content. We do not have access to or control
                                    over cookies or other technologies that they
                                    may use, and the information practices of
                                    these advertisers and third-party websites
                                    or businesses are not covered by this Policy
                                    but are covered by their respective privacy
                                    politices. Some, but not all, third party
                                    advertising companies provide a mechanism to
                                    opt-out of their technology. For more
                                    information and an identification of
                                    advertisers that provide an opt-out
                                    mechanism, please click the following:
                                </li>
                                <li>
                                    Promotions, Sweepstakes, and Contests. From
                                    time to time, we may host a promotion,
                                    sweepstake, or contest on the Service. You
                                    may be asked to provide personal information
                                    or permit the transfer to a third party of
                                    your personal information in connection with
                                    such promotion, sweepstake or contest. It
                                    will be disclosed at the point of collection
                                    or transfer who is collecting or
                                    transferring the information and whose
                                    privacy statement applies, and it will be
                                    your choice whether or not you wish to
                                    permit such transfer or collection of
                                    personal information to a third party.
                                </li>
                            </ol>
                        </li>

                        <li>
                            <strong>
                                GOLFBERT'S USE AND RETENTION OF YOUR
                                INFORMATION.
                            </strong>
                            <ol>
                                <li>
                                    Use of PII. We use your PII for purposes
                                    such as responding to your requests,
                                    monitoring and providing the Service,
                                    maintaining your Golfbert account, providing
                                    you with advertising and non-advertising
                                    content that we believe would be of interest
                                    to you, enforcing our rights and the rights
                                    of third parties, and investigating and
                                    ensuring compliance with the Terms. We also
                                    may use your PII to contact you regarding
                                    your account, such as to inform you that you
                                    have received an email from another Golfbert
                                    member, or to forward user comments to you
                                    about the content that you have posted. We
                                    may also email Golfbert newsletters, and
                                    similar communications about the Service.
                                </li>
                                <li>
                                    Opting-Out. Subject to the exceptions noted
                                    below, at any time, you may modify the types
                                    of communications you wish to receive by
                                    changing your preferences as part of your
                                    account on the Site. In addition, in our
                                    marketing or promotional e-mails to you, we
                                    will include instructions on how to
                                    unsubscribe and/or inform us of your
                                    preferences if you do not want to receive
                                    any marketing or promotional e-mails from
                                    us. We reserve the right to send you
                                    customary business communications such as
                                    acceptance/rejection notices regarding
                                    content submissions, general system and
                                    administrative messages regarding the
                                    Service, address confirmations, and
                                    transactional information about your
                                    account, and we may not offer you the
                                    opportunity to opt-out of receiving such
                                    communications so long as you are registered
                                    with the Site.
                                </li>
                                <li>
                                    Use of Other Information. We use non-PII
                                    about our users for purposes such as
                                    measuring the number of visitors to sections
                                    of our Site, making the Service more useful
                                    to visitors and delivering targeted
                                    advertising and non-advertising content. We
                                    use IP addresses to analyze trends,
                                    administer the Site, track a user's
                                    movement, and gather broad demographic
                                    information for aggregate, non-personally
                                    identifiable use.
                                </li>
                                <li>
                                    Retention of Information. We retain
                                    information for as long as required, allowed
                                    or we believe it useful, but do not
                                    undertake retention obligations. We may
                                    dispose of information in our discretion
                                    without notice, subject to applicable law
                                    that specifically requires the handling or
                                    retention of information. You must keep your
                                    own, separate back-up records.
                                </li>
                            </ol>
                        </li>

                        <li>
                            <strong>GOLFBERT'S SHARING OF INFORMATION.</strong>{" "}
                            Golfbert shares information with third parties, but
                            only as described in this Section 4.
                            <ol>
                                <li>
                                    Advertisers and Others. We share
                                    non-personally identifiable information with
                                    advertisers, service providers, and other
                                    persons with whom we conduct business.
                                    Similarly, these advertisers, service
                                    providers, and other persons may share with
                                    us non-PII about you that they have
                                    independently gathered or acquired.
                                </li>
                                <li>
                                    Third Party Services. We contract with
                                    affiliated and non-affiliated third parties
                                    to provide services to us or to you on our
                                    behalf. Examples include providing marketing
                                    assistance, providing customer service,
                                    sending Golfbert emails to you, removing
                                    repetitive information from customer lists,
                                    and analyzing data. These third parties may
                                    have access to your PII for use solely in
                                    connection with these authorized business
                                    activities.
                                </li>
                                <li>
                                    Business Transfer. As we develop our
                                    business, we might sell or buy businesses or
                                    their assets, or engage in acquisitions,
                                    mergers, restructurings, changes of control,
                                    or similar transactions. In such
                                    transactions, customer information generally
                                    is one of the transferred business assets,
                                    and your PII may be subject to such a
                                    transfer. Also, in the unlikely event of a
                                    bankruptcy, customer information may be
                                    transferred to a bankruptcy trustee or
                                    debtor in possession and then to a
                                    subsequent purchaser.
                                </li>
                                <li>
                                    Affiliates. We may share PII, non-PII and
                                    other information with our corporate
                                    affiliates. Corporate affiliates are those
                                    entities that we control, are controlled by
                                    us, or are under common control with us and
                                    any joint venture in which any of the
                                    controlled entities may participate from
                                    time to time. Any use of your information by
                                    our corporate affiliates is subject to this
                                    Policy or another policy no less protective
                                    of your privacy. Conversely, we may obtain
                                    information about you from our corporate
                                    affiliates or from your visits to other
                                    websites that we or our corporate affiliates
                                    control.
                                </li>
                                <li>
                                    Compliance and Safety. We may release user
                                    information when we believe release is
                                    appropriate to:
                                    <ol>
                                        <li>
                                            comply with applicable law, and to
                                            respond to legal process, including
                                            subpoenas, search warrants and court
                                            orders, and to respond to or
                                            otherwise address written complaints
                                            of copyright infringement;
                                        </li>
                                        <li>
                                            make disclosures under programs
                                            intended to prevent potential crimes
                                            or investigate persons;
                                        </li>
                                        <li>
                                            enforce or apply agreements,
                                            including the Terms; or
                                        </li>
                                        <li>
                                            protect the rights, property, or
                                            safety of Golfbert, our users, or
                                            others, including exchanging
                                            information with other companies for
                                            fraud protection and credit risk
                                            reduction.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <strong>INFORMATION SECURITY.</strong>
                            <ol>
                                <li>
                                    Your Obligations. You must protect against
                                    unauthorized access to your password and to
                                    your computer. Please sign off when finished
                                    using a shared computer. Your emails to us
                                    are not necessarily secure against
                                    interception so do not include sensitive
                                    information in them. We generally will not
                                    ask for passwords in an email and you should
                                    assume that if you get such a request, it
                                    might be fraudulent. YOU MUST DO WHAT YOU
                                    REASONABLY CAN TO ENSURE THE SECURITY OF
                                    YOUR PERSONAL INFORMATION.
                                </li>
                                <li>
                                    Our Security Measures. We take reasonable
                                    precautions to protect the security of our
                                    users' information. We have physical,
                                    electronic, and managerial procedures to
                                    help safeguard, prevent unauthorized access,
                                    maintain data security, and correctly use
                                    your information. However, neither people
                                    nor security systems are foolproof,
                                    including encryption systems. In addition,
                                    people can commit intentional crimes, make
                                    mistakes or fail to follow policies.
                                    Therefore, while we use reasonable efforts
                                    to protect your PII, we cannot guarantee its
                                    absolute security. If applicable law imposes
                                    any non-disclaimable duty to protect your
                                    PII, you agree that intentional misconduct
                                    will be the standards used to measure our
                                    compliance with that duty.
                                </li>
                            </ol>
                        </li>

                        <li>
                            <strong>LINKS.</strong> The Service contains links
                            to other sites. We are not responsible for the
                            privacy practices of such other sites. We encourage
                            you to be aware when you leave our Site and to read
                            the privacy statements of every web site that
                            collects your personally identifiable information.
                            This Policy applies only to information collected by
                            the Service.
                        </li>

                        <li>
                            <strong>
                                CONDITIONS OF USE, NOTICES, AND REVISIONS.
                            </strong>
                            <ol>
                                <li>
                                    Privacy Concerns. Your use of the Service
                                    and any dispute over privacy is subject to
                                    the Terms. If you have any concern about
                                    privacy at Golfbert, please send us a
                                    thorough description to
                                    customerservice@Golfbert.com, and we will
                                    try to resolve it. If you think we are in
                                    default of the Terms, you may contact us at
                                    Our Legal Notices Address in Section 8
                                    below.
                                </li>
                                <li>
                                    Notice Required by Law. In those
                                    jurisdictions that permit email notice, if
                                    we are required to disclose or provide
                                    notice of invasion of certain security
                                    systems, we will do so by email to the most
                                    current email address provided by you to us.
                                    It is critical that you keep your email
                                    contact information correct and updated with
                                    Golfbert at all times.
                                </li>
                                <li>
                                    Identity Theft. If you believe that you are
                                    a victim of identity theft entitled by law
                                    to request information from us, write us at
                                    our Legal Notice address (see the next
                                    section of this Policy) and we will let you
                                    know what additional information you must
                                    provide to us. After we have received that
                                    information, we will supply without charge
                                    the information legally required to be
                                    disclosed that we then have, subject to
                                    applicable law and reserving all of our
                                    rights and defenses.
                                </li>
                                <li>
                                    Revisions. We reserve the right to change
                                    our Policy at any time. Non-material changes
                                    and clarifications will take effect
                                    immediately. If we make non-material
                                    changes, we will indicate at the top of this
                                    page the revised Policy's new effective
                                    date. If we make material changes, we will
                                    notify you by email or through notice on the
                                    home page of the Site, and will also
                                    indicate so at the top of this page. The
                                    material changes will take effect 30 days
                                    after such notice. We encourage you to refer
                                    to this Policy at least every 30 days to
                                    check for material changes to this Policy.
                                </li>
                            </ol>
                        </li>

                        <li>
                            CONTACT INFORMATION AND INFORMATION ABOUT
                            ENFORCEMENT OF OUR POLICY. For customer service
                            matters (including questions relating to this
                            Policy), you may email us at
                            customerservice@Golfbert.com. To send us a legal
                            notice, mail it by certified mail (return receipt
                            requested) to: Golfbert Legal Department, 5555 Hill
                            Road., Brentwood, TN 37027. When we need to contact
                            you, including when we give a legal notice, we may
                            do so to an email address that we have on file for
                            you or to your Golfbert email account, or we may
                            post notice on the Site. You must keep your email
                            address information accurate. If we make a material
                            change to this Policy, we will notify you by posting
                            notice to the Site for at least 30 days and sending
                            you an email to your Golfbert email account or to
                            the non-Golfbert email address that we have on
                            record for you.
                        </li>
                    </ol>

                    <p>
                        Golfbert is a trademark or registered trademark of
                        Golfbert, LLC. in the United States and other countries.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default Privacy;
