import React from "react";
import { Button, Col, Row } from "reactstrap";
import iconsamplecourse from "../assets/img/icon-sample-course.svg";
import iconsamplehole from "../assets/img/icon-sample-hole.svg";
import iconsamplescorecard from "../assets/img/icon-sample-scorecard.svg";
import apimessage from "../assets/img/icon-api.svg";
import { Link } from "react-router-dom";
const CodeSample = () => {
    const sampleData = [
        {
            image: iconsamplecourse,
            btnText: "Building a golf course",
            btnLink: "./examples#golf-course",
        },
        {
            image: iconsamplehole,
            btnText: "Building a golf hole",
            btnLink: "./examples#golf-hole",
        },
        {
            image: iconsamplescorecard,
            btnText: "Building a golf score card",
            btnLink: "./examples#golf-scorecard",
        },
        {
            image: apimessage,
            btnText: "API Tutorial",
            btnLink: "./tutorial",
        },
    ];
    return (
        <>
            <Row>
                <Col>
                    <div className="text-center text-dark">
                        <h2 className="fs--32 fw--600  font-poppins">
                            Code Samples
                        </h2>
                        <p className="fs--16 fw--400  font-poppins">
                            See below for some examples on how to call data from the API.{" "}
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="sample-code justify-content-center">
                {sampleData.map((obj) => (
                    <>
                        <Col
                            className="m-0 p-3 sample-code__card"
                            lg="3"
                            md="6"
                            sm="12"
                        >
                            <div className="text-center">
                                <Link
                                    to={obj.btnLink}
                                    className="text-site-primary fs--16 fw--500"
                                >
                                    <img
                                        src={obj.image}
                                        className="sample-code__img"
                                    />
                                </Link>
                            </div>

                            <div className="text-center mt-2">
                                {/* <Button as="a" href={obj.btnLink} className="py-2 px-3">
                                    {obj.btnText}
                                </Button> */}
                                <Link
                                    to={obj.btnLink}
                                    className="text-site-primary fs--16 fw--500"
                                >
                                     {obj.btnText}
                                </Link>
                            </div>
                        </Col>
                    </>
                ))}
            </Row>
            {/* <Row className="my-4">
                <Col className="bg-site-lite-green  mx-4">
                    <div className="d-flex align-items-center  px-3 py-2">
                        <div>
                            <img src={apimessage} />
                        </div>
                        <div className="py-3 ml-4">
                            <p className="fs--16 fw--400 font-poppins text-muted">
                                <strong>API Tutorial —</strong> check out our
                                detailed{" "}
                                <Link
                                    to="/api/tutorial"
                                    className="text-site-primary"
                                >
                                    tutorial
                                </Link>{" "}
                                building a React app using the Golfbert API with
                                Google Maps
                            </p>
                        </div>
                    </div>
                </Col>
            </Row> */}
        </>
    );
};

export default CodeSample;
