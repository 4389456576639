import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import ApiNavbar from "../../components/global/ApiNavbar";

const Support = () => {
    return (
        <>
            {/* <ApiNavbar /> */}
            <div className="support">
                <Container>
                    <h1>API Support</h1>
                    <p support__head-text>
                        Please see our{" "}
                        <Link to="/api/faq" className="link-to">
                            FAQ
                        </Link>{" "}
                        for immediate help with the API. If you need further
                        assistance, please contact us below and we'll do our
                        best to help.
                    </p>
                    <Row>
                        <Col className="">
                            <Button
                                className="support__support-button"
                                color="site-lite-btn"
                            >
                                <a
                                    className=" px-1 py-2 text-site-primary text-decoration-none"
                                    href="mailto:apisupport@golfbert.com"
                                >
                                    apisupport@golfbert.com
                                </a>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="8">
                            <h4>Access to all golf courses</h4>
                            <p className="support__para-text">
                                We offer access to all courses in the database
                                if you're building an app or website for golfers
                                across the entire United States. Email us at
                                <a
                                    className=" px-1 py-2 link-to"
                                    href="mailto:apisupport@golfbert.com?Subject=Access to complete Golfbert Database"
                                >
                                    apisupport@golfbert.com
                                </a>
                                for access to all courses.
                            </p>
                            <h4>Enterprise access</h4>
                            <p className="support__para-text">
                                If your business requires larger amounts of API
                                calls, we offer a Premier account with access to
                                all golf courses in the database and preferred
                                customer support. Email us at{" "}
                                <a
                                    className=" px-1 py-2 link-to"
                                    href="mailto:apisupport@golfbert.com?Subject=Golfbert Enterprise Access"
                                >
                                    apisupport@golfbert.com
                                </a>
                                for premier access.
                            </p>
                            <h4>Static data</h4>
                            <p className="support__para-text">
                                We also offer the ability to purchase a single,
                                static snapshot of our entire golf course
                                database for a one-time fee, with the option to
                                purchase annual updates as new golf courses are
                                added and elements of existing golf courses are
                                updated. We can further customize the data set
                                to fit your business needs. Email us at{" "}
                                <a
                                    className=" px-1 py-2 link-to"
                                    href="mailto:apisupport@golfbert.com?Subject=Golfbert Static Data Purchase"
                                >
                                    apisupport@golfbert.com
                                </a>
                                to inquire about static data purchases.
                            </p>
                        </Col>
                        <Col md="1"></Col>
                        <Col md="3" className="support__side">
                            <h4>Resources</h4>
                            <Link to="/api/faq" className="support__link-tofAQ">
                                FAQ
                            </Link>
                            <h4>Example Holes</h4>
                            <div>
                                <Link to="/courses/George_W_Dunne_National_Golf_Course/8428:Hole_18" className="support__link-to">
                                    George W. Dunne National Golf Course — Oak
                                    Forest, IL
                                </Link>
                                <br />
                                <Link to="/courses/Cypress_Point/1883:Hole_16" className="support__link-to">
                                    Cypress Point — Pebble Beach, CA
                                </Link>
                                <br />
                                <Link to="/courses/TPC_at_Eagle_Trace/3496:Hole_12" className="support__link-to">
                                    TPC at Eagle Trace — Pompano Beach, FL
                                </Link>
                                <br />
                                <Link to="/courses/White_Horse_Golf_Club/17551:Hole_3" className="support__link-to">
                                    White Horse Golf Club — Kingston, WA
                                </Link>
                                <br />
                                <Link to="/courses/Circling_Raven_Golf_Club_At_Coeur_D_039_Alene_Casino/5363:Hole_10" className="support__link-to">
                                    Circling Raven Golf Club — Worley, ID
                                </Link>
                                <Link to="/courses/Calusa_Lakes_Golf_Club/3914:Hole_11" className="support__link-to">
                                    Calusa Lakes Golf Club — Nokomis, FL{" "}
                                </Link>
                                <br />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Support;
