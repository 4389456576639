import React from "react";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { Col, Row } from "reactstrap";
import apimessage from "../../assets/img/api-message.png";
import { GuidesData } from "./GuideData";

const GuidesMainContent = () => {
    return (
        <>
            <div>
                {/* <Row>
                    <Col className="bg-site-lite-green">
                        <div className="d-flex align-items-center  px-3 py-2">
                            <div>
                                <img src={apimessage} />
                            </div>
                            <div className="py-3 ml-4">
                                <p className="fs--16 fw--400 font-poppins text-muted">
                                    <strong>API Tutorial —</strong> check out
                                    our detailed{" "}
                                    <Link
                                        to="/api/tutorial"
                                        className="text-site-primary"
                                    >
                                        tutorial
                                    </Link>{" "}
                                    building a React app using the Golfbert API
                                    with Google Maps
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row> */}
                {GuidesData?.map((obj) => (
                    <>
                        <Element name={obj?.section} className="section-guides">
                            {obj?.component}
                        </Element>
                    </>
                ))}
            </div>
        </>
    );
};

export default GuidesMainContent;
