import React from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Button,
    Container,
    Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import warning from "../../assets/img/icon-warning.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { cancelSubscription } from "../../store/actions/subscribeAction";
const CancelMembership = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let { user, authLoading } = useSelector((state) => state.authUser);
    const handleCancel = (event) => {
        event.preventDefault();
        dispatch(
            cancelSubscription(user?.id, () => {
                history.push("/main/setting");
            })
        );
    };
    return (
        <div className="bg-membership cancelMembership">
            <Container fluid>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col md="7">
                        <Card className="my-5">
                            <Container>
                                <CardBody className="py-5">
                                    <Row>
                                        <Col md="12" className="text-center">
                                            <h1>Cancel membership</h1>
                                        </Col>
                                        <Col
                                            md="12"
                                            className="text-center  text-muted"
                                        >
                                            <p>
                                                Are you sure you want to cancel and lose access to all this amazing  golf course geodata? If your business needs more time to ramp up, please reach out to our team for <a href="mailto:apisupport@golfbert.com?Subject=Pricing Assistance">custom pricing assistance</a>.
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="text-center mt-5">
                                            <Link
                                                to="/main/setting"
                                            >
                                                <Button
                                                    color="site-bgGreen"
                                                    className="cancelMembership__btn-member__btn"
                                                >
                                                    Keep API active
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="text-center mt-2">
                                            <Button
                                                color="site-lite-btn"
                                                className="mr-1 cancelMembership__btn-member__btn py-2"
                                                onClick={handleCancel}
                                            >
                                                {authLoading ? (
                                                    <Spinner size={"sm"}>
                                                        {" "}
                                                    </Spinner>
                                                ) : (
                                                    "Cancel Subscription"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Container>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CancelMembership;
