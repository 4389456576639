import React from "react";
import Subscribe from "../../components/global/Subscribe";
import { Col, Row } from "reactstrap";

const SubscribeSingle = () => {
    return (
        <>
            <Row className="w-100 h-100 m-0 api-pages">
                <Col xs="12 ">
                    <Subscribe
                        subtitle="Single Course — $9 per month"
                        singleCourse=" Single Course subscribers:"
                        price="$9"
                        course="Single Golf Course"
                    />
                </Col>
            </Row>
        </>
    );
};

export default SubscribeSingle;
