import React, { useState } from "react";
import {
    Card,
    CardBody,
    Button,
    Col,
    Container,
    Label,
    Input,
    Row,
    Spinner,
    InputGroup,
    Form,
} from "reactstrap";
import Navbar from "../../components/global/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/authAction";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { SignInFooter } from "../../components/SignInFooter";
import { FaEyeSlash, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
// State to toggle password visibility

const SignIn = () => {
    const dispatch = useDispatch();
    const { authLoading } = useSelector((state) => state.authUser);
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    // console.log({ authLoading });

    // const handleKeyDown = (e) => {
    //     console.log("e: ", e);
    //     if (e.key === "Enter") {
    //         handleLogin(e); // Call the login function on pressing Enter
    //     }
    // };
    const handleLogin = (e) => {
        e.preventDefault();
        let obj = {
            email,
            password,
        };
        dispatch(
            login(
                obj,
                () => {
                    toast.success("Login successfully");
                    setEmail("");
                    setPassword("");
                    history.push("/main/setting");
                },
                (res) => {
                    toast.error(res);
                }
            )
        );
    };

    return (
        <>
            <div className="main-bg">
                <Navbar />
                    <Container>
                        <Row className="forget d-flex justify-content-center align-item-center">
                            <Col md="8" className="p-0">
                                <Form onSubmit={handleLogin}>
                                <Card className="signIn__main">
                                    <div className="signIn__body">
                                        <CardBody>
                                            <h1 className="mb-4">Sign In</h1>
                                            <Row>
                                                <Col md="12">
                                                    <Input
                                                        className="signIn__input py-4"
                                                        placeholder="Email address or username"
                                                        type="text"
                                                        value={email}
                                                        onChange={(e) =>
                                                            setEmail(
                                                                e.target.value
                                                            )
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="12">
                                                    <InputGroup className="mt-3 password_fied">
                                                        <Input
                                                            className="signIn__input py-4"
                                                            placeholder="Password"
                                                            type={
                                                                showPassword
                                                                    ? "text"
                                                                    : "password"
                                                            } // Toggle between text and password
                                                            value={password}
                                                            onChange={(e) =>
                                                                setPassword(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            required
                                                        />
                                                        <span
                                                            className="append"
                                                            onClick={() =>
                                                                setShowPassword(
                                                                    !showPassword
                                                                )
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {showPassword ? (
                                                                <FaRegEyeSlash />
                                                            ) : (
                                                                <FaRegEye />
                                                            )}
                                                        </span>
                                                    </InputGroup>
                                                    {/* <Input
                                                    className="mt-3 signIn__input py-4"
                                                    placeholder="Password"
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) =>
                                                        setPassword(
                                                            e.target.value
                                                        )
                                                    }
                                                /> */}
                                                </Col>
                                            
                                                <Col
                                                    md="12"
                                                    className="mt-3 signIn__input-check px-5"
                                                >
                                                    <Input type="checkbox"></Input>
                                                    
                                                        <span className="mx-2">Remember me</span>
                                                        <a
                                                            href="/auth/forgetpassword"
                                                            className="signIn__input-password-help mx-2"
                                                        >
                                                            Password Help
                                                        </a>
                                                    
                                                </Col>
                                                <Col
                                                    xs="12"
                                                    className="d-flex justify-content-end px-3"
                                                >
                                                    <Button
                                                        color="site-lite-btn"
                                                        className="signIn__btn py-2 px-4 shadow-sm"
                                                        type="submit"
                                                        // onClick={handleLogin}
                                                    >
                                                        {authLoading ? (
                                                            <Spinner size="sm">
                                                                {" "}
                                                            </Spinner>
                                                        ) : (
                                                            "Sign In"
                                                        )}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </div>
                                </Card>
                            </Form>
                        </Col>
                        <Col md="8">
                            <SignInFooter />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default SignIn;
