import { useLocation } from "react-router-dom";
import MainFooter from "../components/global/MainFooter";
import Navbar from "../components/global/Navbar";
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "cookies-next";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { logout } from "../store/actions/authAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Main = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const mainContent = React.useRef(null);
    const { uid } = useSelector((state) => state.authUser);
    const location = useLocation();
    // React.useEffect(() => {
    //     document.documentElement.scrollTop = 0;
    //     document.scrollingElement.scrollTop = 0;
    //     mainContent.current.scrollTop = 0;
    // }, [location]);

    useEffect(() => {
        if (
            location.pathname != "/api/tutorial" ||
            location.pathname != "/api/documentations" ||
            location.pathname != "/api/guides"
        ) {
            window.scrollTo(0, 0);
        } else {
        }
    }, [location]);
    // console.log(props.children);
    useEffect(() => {
        const token = localStorage.getItem("token"); // Assuming you store token in 'authToken' cookie
        // console.log("token: ", token);
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                // console.log("decodedToken: ", decodedToken);
                const currentTime = Math.floor(new Date().getTime() / 1000); // Current time in seconds
                console.log("currentTime: ", currentTime);
                if (decodedToken.exp < currentTime) {
                    dispatch(logout()); // Token is expired, log out
                    toast.warning("Token Expired");
                    history.push("/login"); // Optionally redirect user to login page
                }
            } catch (error) {
                console.error("Invalid token:", error);
                dispatch(logout());
            }
        }
    }, [location]);
    return (
        <>
            <Navbar />
            <div className="children">{props.children}</div>
            <MainFooter />
        </>
    );
};

export default Main;
