import React, { useEffect, useState } from "react";
import { Link, Element, scroller } from "react-scroll";
import { NavLink } from "react-router-dom";
import { TutorialSidebarData } from "../TutorialSidebarData";
import { Events, scrollSpy } from "react-scroll";
import { GuidesData } from "./GuideData";
import { useDispatch, useSelector } from "react-redux";
import { setIsScrolling } from "../../store/actions/layoutAction";

const apiPage = [
    {
        path: "/api/documentations",
        name: "Documentation",
    },
    {
        path: "/api/tutorial",
        name: "Tutorial",
    },
    {
        path: "/api/documentations",
        name: "API Reference",
    },
    {
        path: "/api/documentations",
        name: "Paths",
    },
    {
        path: "/api/documentations",
        name: "Schema Definition",
    },
];

const GuidesSidebar = ({ isExpanded, setIsExpanded, type, isOpen }) => {
    const [activeSection, setActiveSection] = useState("");
    const { isScrolling } = useSelector((state) => state.layout);

    const handleSetActive = (to) => {
        setActiveSection(to);
    };
    const dispatch = useDispatch();

    const handleResize = () => {
        // alert(window.innerWidth);
        if (window.innerWidth >= 1200) {
            dispatch(setIsScrolling(-180));
            // setSideBar(-180);
            // offsetSidebar = -180;
            // console.log("xl");
        } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
            dispatch(setIsScrolling(-90));
            // setSideBar(-90);
            // offsetSidebar = -90;
            // console.log("lg");
        } else if (window.innerWidth < 992 && window.innerWidth >= 320) {
            if (window.innerHeight < 700 && window.innerHeight >= 600) {
                dispatch(setIsScrolling(-750));
            } else if (window.innerHeight < 850 && window.innerHeight >= 700) {
                dispatch(setIsScrolling(-850));
            } else if (window.innerHeight < 920 && window.innerHeight >= 850) {
                dispatch(setIsScrolling(-950));
            } else if (window.innerHeight < 1040 && window.innerHeight >= 920) {
                dispatch(setIsScrolling(-1050));
            } else {
            } // setSideBar(-590);
            // offsetSidebar = -590;
            // console.log("md");
        } else {
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
        Events.scrollEvent.register("scroll", (to) => {
            setActiveSection(to);
        });
        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove("scroll");
        };
    }, []);

    // useEffect(() => {
    //     window.addEventListener("resize", handleResize);
    // }, [windowWidth]);

    return (
        <div className="sidebar">
            <div className="">
                <h4 className="fs--24 fw--600 font-poppins py-1">API Guides</h4>
            </div>
            {/* {isScrolling} */}
            <div className="text-justify ">
                {GuidesData.map((link) => (
                    <Link
                        key={link.id}
                        to={link.section}
                        className="fs--16 fw--400  fd text-muted font-poppins cursor-pointer"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={isScrolling}
                        duration={500}
                        onSetActive={handleSetActive}
                        onClick={() => {
                            type && setIsExpanded(!isExpanded);
                        }}
                    >
                        {link.name}
                    </Link>
                ))}
            </div>
            {/* <div className="text-justify d-xl-none d-block">
                {GuidesData.map((link) => (
                    <Link
                        key={link.id}
                        to={link.section}
                        className="fs--16 fw--400 ff text-muted font-poppins cursor-pointer"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-90}
                        duration={500}
                        onSetActive={handleSetActive}
                        onClick={() => {
                            type && setIsExpanded(!isExpanded);
                        }}
                    >
                        {link.name}
                    </Link>
                ))}
            </div> */}
            {/* {isExpanded && (
                <div className="text-justify py-2">
                    {apiPage?.map((link, index) => (
                        <NavLink
                            // key={index}
                            to={link.path}
                            className="fs--16 fw--400 fb font-poppins text-site-primary cursor-pointer"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-240}
                            duration={500}
                            onSetActive={handleSetActive}
                            // onClick={() => {
                            //     type && setIsExpanded(!isExpanded);
                            // }}
                        >
                            {link.name}
                        </NavLink>
                    ))}
                </div>
            )} */}
        </div>
    );
};

export default GuidesSidebar;
