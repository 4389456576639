import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Spinner } from "reactstrap";
import SearchTable from "../../../components/courses/CoursesTable";
import { getFilteredCourses } from "../../../store/actions/HolesAction";

function CityCoursesPage() {
    const params = useParams();
    const dispatch = useDispatch();
    const { courses, courseLoader } = useSelector((state) => state.course);
    let { city, state } = params;

    useEffect(() => {
        const obj = {
            name: "",
            state,
            city,
            limit: 25,
            offset: 0,
        };

        dispatch(getFilteredCourses(obj, () => {}));
    }, []);
    return (
        <Container className="py-5">
            <h1 className="my-3">
                Golf Courses in {city}, {state}
            </h1>
            {courseLoader ? (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                    <Spinner> </Spinner>
                </div>
            ) : (
                <SearchTable courseData={courses} />
            )}
        </Container>
    );
}

export default CityCoursesPage;
