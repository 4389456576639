import { Col, Container, Row, Table } from "reactstrap";

const GolfCourseTable = ({ data }) => {
    const holeNumbers = Array.from({ length: 18 }, (_, i) => i + 1);
    holeNumbers.splice(9, 0, "Out");
    holeNumbers.push("In", "Total");
  

    const uniqueTeeboxTypes = [...new Set(data.map((item) => item.teeboxtype))];
    const additionalValues = ["M's Hcp", "M's Par", "W's Par", "W's Hcp"];
    const teeboxTypes = uniqueTeeboxTypes.concat(additionalValues);
    

    const getCellValue = ({ teeboxType, property, holeNumber }) => {
        
        if (holeNumber === "Out") {
            const sumOut = holeNumbers.slice(0, 9).reduce(
                (sum, hole) =>
                    sum +
                        parseInt(
                            getCellValue({
                                teeboxType,
                                property,
                                holeNumber: hole,
                            }),
                            10
                        ) || 0,
                0
            );
            return sumOut;
        } else if (holeNumber === "In") {
            // console.log(">>>>", getCellValue("Senior Women's", "length", 1));
            const sumIn = holeNumbers.slice(10, 19).reduce(
                (sum, hole) =>
                    sum +
                        parseInt(
                            getCellValue({
                                teeboxType,
                                property,
                                holeNumber: hole,
                            }),
                            10
                        ) || 0,
                0
            );
            return sumIn;
        } else if (holeNumber === "Total") {
            return (
                getCellValue({ teeboxType, property, holeNumber: "Out" }) +
                getCellValue({ teeboxType, property, holeNumber: "In" })
            );
        }

        const rowData = data.find(
            (row) =>
                row.teeboxtype === teeboxType && row.holenumber === holeNumber
        );

        return rowData ? rowData[property] : "?";
    };
    function getIconByColor(teebox) {
        switch (teebox) {
            case "Pro's":
                return "gold";
            case "Women's":
                return "red";
            case "Championship":
                return "blue";
            case "Men's":
                return "white";
            default:
                return "";
        }
    }
    const createObj = (teebox, property, holeNumber) => {
        // console.log("holeNumber: ", holeNumber);
        if (teebox === "M's Hcp") {
            return {
                teeboxType: "Men's",
                property: "handicap",
                holeNumber,
            };
        } else if (teebox === "M's Par") {
            return {
                teeboxType: "Men's",
                property: "par",
                holeNumber,
            };
        } else if (teebox === "W's Par") {
            return {
                teeboxType: "Women's",
                property: "par",
                holeNumber,
            };
        } else if (teebox === "W's Hcp") {
            return {
                teeboxType: "Women's",
                property: "handicap",
                holeNumber,
            };
        } else {
            // console.log("data ", {
            //     teeboxType: teebox,
            //     property,
            //     holeNumber,
            // });
            return {
                teeboxType: teebox,
                property,
                holeNumber,
            };
        }
    };

    const DontShowSum = [
        "Out",
        "In",
        "Total",
        "M's Par",
        "W's Par",
        "M's Hcp",
        "W's Hcp",
    ];
    return (
        <>
            <Container fluid className="bg-waring font-poppins px-0">
                <Row xs={2} className="my-1 bg-white">
                    <Col
                        lg={3}
                        md={3}
                        sm={3}
                        className="pr-0 px-md-0 border-right scorecard-table-flex"
                    >
                        <Table>
                            <thead>
                                <tr className="bg-table-row ">
                                    <th className="sticky-column bg-table-row over-flow-hidden fw--300 fs--11">
                                        Teebox(Slope/Rating)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {teeboxTypes.map((teebox) => {
                                    return (
                                        <tr>
                                            <td
                                                className={`sticky-column  ${
                                                    teebox == "M's Par" ||
                                                    teebox == "W's Par"
                                                        ? "bg-table-row fw--700"
                                                        : ""
                                                }`}
                                            >
                                                <div
                                                    className={`d-flex align-items-center fs--11 fw-300`}
                                                >
                                                    {![
                                                        "M's Hcp",
                                                        "M's Par",
                                                        "W's Par",
                                                        "W's Hcp",
                                                    ].includes(teebox) && (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="15"
                                                            height="15"
                                                            viewBox="0 0 20 20"
                                                            className="mr-2"
                                                        >
                                                            <circle
                                                                cx="10"
                                                                cy="10"
                                                                r="9"
                                                                stroke="#8fa2bb"
                                                                stroke-width="1"
                                                                fill="white"
                                                            />
                                                            <circle
                                                                cx="10"
                                                                cy="10"
                                                                r="5"
                                                                fill={`${getIconByColor(
                                                                    teebox
                                                                )}`}
                                                            />
                                                        </svg>
                                                    )}
                                                    {teebox}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                    <Col
                        lg={9}
                        md={9}
                        sm={9}
                        className="table-responsive pl-0 px-md-0 scorecard-table-flex"
                    >
                        {" "}
                        <Table responsive>
                            <thead>
                                <tr className="bg-table-row ">
                                    {/* <th className="sticky-column bg-table-row">
                                        Teebox (Slope/Rating)
                                    </th> */}
                                    {holeNumbers.map((holeNumber) => (
                                        <th
                                            key={holeNumber}
                                            className={`fs--11 ${
                                                ["Out", "In", "Total"].includes(
                                                    holeNumber
                                                )
                                                    ? "fw--400"
                                                    : ""
                                            } 
                                           
                                            `}
                                        >
                                            {holeNumber}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {teeboxTypes.map((teebox) => {
                                    return (
                                        <tr>
                                            {holeNumbers.map(
                                                (holeNumber, index) => {
                                                    let obj = createObj(
                                                        teebox,
                                                        "length",
                                                        holeNumber
                                                    );
                                                    return (
                                                        <td
                                                            key={holeNumber}
                                                            className={`${
                                                                teebox ==
                                                                    "M's Par" ||
                                                                teebox ==
                                                                    "W's Par"
                                                                    ? "fs--11 fw--600 bg-table-row"
                                                                    : "fs--11 fw--300"
                                                            }`}
                                                        >
                                                            {DontShowSum.includes(
                                                                holeNumber
                                                            ) &&
                                                            DontShowSum.includes(
                                                                teebox
                                                            )
                                                                ? ""
                                                                : getCellValue(
                                                                      obj
                                                                  )}
                                                        </td>
                                                    );
                                                }
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default GolfCourseTable;
