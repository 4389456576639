import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import apimessage from "../../assets/img/api-message.png";
import { ExampleCodeUI } from "../../components/ExampleCodeUI";
import buildingGolfCourse from "../../assets/img/api-sample-course-hd.png";
import golfMap from "../../assets/img/golfMap.JPG";
import scoreCard from "../../assets/img/api-sample-scorecard-hd.png";
import CheckGolfCourse from "../../components/CheckGolfCourse";
import ApiFooter from "../../components/global/ApiFooter";
import ApiNavbar from "../../components/global/ApiNavbar";

export const Example = () => {
    return (
        <>
            {/* <ApiNavbar /> */}
            <Row className="bg-white w-100 m-0  justify-content-center example">
                <Col sm="10" xs="11" className="py-5">
                    <div className="my-2 pt-4">
                        <h1 className="font-poppins fw--700 fs--24">
                            API Examples
                        </h1>

                        <p className="fs--16 fw--400 font-poppins">
                            See below for some examples on how to call data from
                            the API. Some additional development may be required
                            to render the maps using an SVG service such as
                            &nbsp;
                            {/* <Link to="" className="text-site-primary">
                                D3.js
                            </Link>{" "} */}
                            <a className="text-site-primary" href="#">
                                D3.js
                            </a>
                            &nbsp;or over a satellite image using an additional
                            &nbsp;
                            {/* <Link to="" className="text-site-primary">
                                {" "}
                                mapping API.
                            </Link> */}
                            <a className="text-site-primary" href="https://developers.google.com/maps/documentation/javascript/" target="new">
                                mapping API.
                            </a>
                        </p>
                    </div>

                    {/* <div className="d-flex align-items-center example__api-tutorail px-3 py-4 my-4">
                        <div>
                            <img src={apimessage} />
                        </div>
                        <div className="py-3 ml-2">
                            <p className="fs--16 fw--400 font-poppins text-muted">
                                <strong>API Tutorial —</strong> check out our
                                detailed{" "}
                                <Link
                                    to="/api/tutorial"
                                    className="text-site-primary"
                                >
                                    tutorial
                                </Link>{" "}
                                building a React app using the Golfbert API with
                                Google Maps
                            </p>
                        </div>
                    </div> */}
                </Col>
            </Row>
            <Row className="w-100 m-0 py-5 justify-content-center">
                <Col sm="10" xs="11" className="my-2" id="golf-course">
                    <ExampleCodeUI
                        title="Building a golf course"
                        image={buildingGolfCourse}
                        code={`1. Find a course based on GPS coordinates
awscurl --access_key **** --secret_key **** -X GET 
https://api.golfbert.com/v1/courses?lat=47.382\&long=-122.267 -H "x-api-key: ***" 
'Response code: 200\n'
{ 
  "resources": [ 
    { 
      "id": 4803, 
      "address": { 
        "country": "USA", 
        "street": "2020 W Meeker Ave", 
        "city": "Kent", 
        "state": "Washington", 
        "zip": "98032" 
      },
      "name": "Riverbend Golf Complex", 
      "phonenumber": "(253) 854 3673", 
      "coordinates": { 
        "lat": 47.3821, 
        "long": -122.2676 
      } 
    } 
  ] 
}
  
2. Get course teeboxes

awscurl --access_key **** --secret_key **** -X GET 
https://api.golfbert.com/v1/courses/4803/teeboxes -H "x-api-key: ***" 
'Response code: 200\n'
{ 
  "resources": [ 
    { 
      "courseid": 4803, 
      "color": "Blue", 
      "teeboxtype": "Championship", 
      "slope": 121, 
      "rating": 71.4 
      },
    { 
      "courseid": 4803, 
      "color": "White", 
      "teeboxtype": "Men's", 
      "slope": 120, 
      "rating": 69.6 
      },
    { 
      "courseid": 4803, 
      "color": "Red", 
      "teeboxtype": "Women's", 
      "slope": 118, 
      "rating": 71.2 
    } 
  ] 
}`}
                    />
                </Col>
                <Col sm="10" xs="11" className="my-2" id="golf-hole">
                    <ExampleCodeUI
                        title="Building a golf hole"
                        image={golfMap}
                        courseId={4803}
                        holeId={25506}
                        code={`1. Get all holes for a specific course 
awscurl --access_key **** --secret_key ****  -X GET 
https://api.golfbert.com/v1/courses/4803/holes -H "x-api-key: ****" 
'Response code: 200\n'
{ 
  "resources": [ 
    { 
      "id": 25506, 
      "number": 18, 
      "courseid": 4803, 
      "rotation": 2.04479566062, 
      "range": { 
        "x": { 
          "min": -122.2707111, 
          "max": -122.269083337 
        },
        "y": { 
          "min": 47.3838734109, 
          "max": 47.3896241104
        } 
      },
      "dimensions": { 
        "width": 960, 
        "height": 960 
      },
      "vectors": [ 
        { 
          "type": "Flag", 
          "lat": 47.3893912619, 
          "long": -122.2692596912384 
        },
        { 
          "type": "Blue", 
          "lat": 47.3838734109, 
          "long": -122.269259691
        },
        { 
            "type": "White", 
          "lat": 47.3843622628, 
          "long": -122.269388009 
        },
        { 
          "type": "Red", 
          "lat": 47.3850228683, 
          "long": -122.269657507 
        } 
      ],
      "flagcoords": { 
        "lat": 47.38135479698122, 
        "long": -122.26435018374639 
      }  
    } 
  ] 
}

2. Get hole polygon information for specific hole

awscurl --access_key **** --secret_key **** -X GET 
https://api.golfbert.com/v1/holes/25506/polygons -H "x-api-key: ***" 
'Response code: 200\n'
{ 
  "resources": [ 
    { 
      "holeid": 25506, 
      "surfacetype": "Green", 
      "polygon": [ 
        { 
          "lat": 47.3814806732001, 
          "long": -122.26437270641327 
        },
        { 
          "lat": 47.38148793784317, 
          "long": -122.2643968462944 
        },
        { 
          "lat": 47.38148975400378, 
          "long": -122.26441696286201 
        },
        { 
          "lat": 47.38148793784317, 
          "long": -122.26443707942963
        },
        { 
          "lat": 47.381483397441365, 
          "long": -122.26445451378822 
        },
        { 
          "lat": 47.38147340855601, 
          "long": -122.26447060704231 
        },
        { 
          "lat": 47.38145887926487, 
          "long": -122.26448804140091 
        },
        { 
          "lat": 47.381439809564135, 
          "long": -122.26449877023697 
        },
        { 
          "lat": 47.38142073985653, 
          "long": -122.26450279355049 
        },
        { 
          "lat": 47.38139531356897, 
          "long": -122.26449877023697 
        },
        { 
          "lat": 47.38136716302202, 
          "long": -122.26448267698288 
        },
        { 
          "lat": 47.38133356396243, 
          "long": -122.26447463035583
        },
        { 
          "lat": 47.381290884045065, 
          "long": -122.26447463035583
        },
        { 
          "lat": 47.38126182535814, 
          "long": -122.2644692659378 
        },
        { 
          "lat": 47.381238215163215, 
          "long": -122.26446121931076 
        },
        { 
          "lat": 47.38122550197696, 
          "long": -122.2644491493702 
        },
        { 
          "lat": 47.38121823729771, 
          "long": -122.2644317150116 
        },
        { 
          "lat": 47.38121188070257, 
          "long": -122.26439818739891 
        },
        { 
          "lat": 47.38120915644726, 
          "long": -122.26436868309975 
        },
        { 
          "lat": 47.38120915644726, 
          "long": -122.26433515548706 
        },
        { 
          "lat": 47.38121823729771, 
          "long": -122.26429894566536 
        },
        { 
          "lat": 47.38124003133243, 
          "long": -122.26424127817154 
        },
        { 
          "lat": 47.38126636577903, 
          "long": -122.26418629288673 
        },
        { 
          "lat": 47.38128725171007, 
          "long": -122.26415008306503 
        },
        { 
          "lat": 47.381299964881435, 
          "long": -122.26413533091545 
        },
        { 
          "lat": 47.38131540229967, 
          "long": -122.2641272842884 
        },
        { 
          "lat": 47.381340828625774, 
          "long": -122.26412996649742 
        },
        { 
          "lat": 47.38136171452732, 
          "long": -122.26414069533348 
        },
        { 
          "lat": 47.3813798761741, 
          "long": -122.2641621530056 
        },
        { 
          "lat": 47.381392589323156, 
          "long": -122.26419970393181 
        },
        { 
          "lat": 47.38140621055085, 
          "long": -122.26423993706703 
        },
        { 
          "lat": 47.38142618834512, 
          "long": -122.26429358124733 
        },
        { 
          "lat": 47.38146432774951, 
          "long": -122.26434588432312 
        },
        { 
          "lat": 47.3814806732001, 
          "long": -122.26437270641327 
        } 
      ] 
    } 
  ] 
}`}
                    />
                </Col>
                <Col sm="10" xs="11" className="my-2" id="golf-scorecard">
                    <ExampleCodeUI
                        title="Building a golf course scorecard"
                        image={scoreCard}
                        code={`awscurl --access_key **** --secret_key **** -X GET
https://api.golfbert.com/v1/courses/4803/scorecard -H "x-api-key: ***" 
'Response code: 200\n'
{ 
  "courseid": 4803, 
  "holeteeboxes": [ 
    { 
      "holeid": 25489, 
      "holenumber": 1, 
      "color": "Blue", 
      "length": 347, 
      "par": 4, 
      "handicap": 15, 
      "teeboxtype": "Championship" 
    },
    { 
      "holeid": 25489, 
      "holenumber": 1, 
      "color": "White", 
      "length": 329, 
      "par": 4, 
      "handicap": 15, 
      "teeboxtype": "Men's" 
    },
    { 
      "holeid": 25506, 
      "holenumber": 18, 
      "color": "Red", 
      "length": 464, 
      "par": 5, 
      "handicap": 2, 
      "teeboxtype": "Women's" 
    } 
  ],
  "coursename": "Riverbend Golf Complex" 
}`}
                    />
                </Col>
            </Row>

            <Row className=" w-100 m-0 py-4 justify-content-center ">
                <Col sm="10" xs="11" className="my-4">
                    <CheckGolfCourse />
                </Col>
            </Row>
            {/* <ApiFooter /> */}
        </>
    );
};
