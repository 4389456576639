import React, { useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import HolesIndexs from "../../../components/courses/HolesIndexs";
import TeeBoxes from "../../../utils/TeeBoxes";
import PolygonMap from "./PolygonMap";
import { useDispatch } from "react-redux";
import { GetSingleCourseData } from "../../../store/actions/HolesAction";

function CourseSinglePage() {
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [course_id, hole_number] = params.id.split("_");

    const location = useLocation();
    const mapData = location.state;
    const [reduxData, setRreduxData] = useState("-1");

    let { hole, paths, holes, polygons, scorecard, golfInfo, course } =
        mapData || reduxData;

    const [selectedHole, setSelectedHole] = useState(hole?.vectors[1]);
    const [toGreenDistance, setToGreenDistance] = useState("");
    const [ydsToGreen, setYdsToGreen] = useState("");
    const [ydsToTee, setYdsToTee] = useState("");
    const [zoom, setZoom] = useState(18);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        if (mapData === undefined) {
            dispatch(
                GetSingleCourseData(
                    course_id.split(":")[0],
                    hole_number,
                    (res) => {
                        setRreduxData(res);
                    }
                )
            );
        }
    }, []);

    const handlePageNavigation = (id, mapData) => {
        history.push({
            pathname: `/courses/${
                course?.name?.replaceAll(" ", "_") || "course_name"
            }/${id}`,
            state: mapData,
        });
    };

    const [icons, setIcons] = useState(false);

    const handleIcons = () => {
        setIcons(!icons);
    };
    const [scale, setScale] = useState(1); // Initial scale factor

    const handleZoomIn = () => {
        if (zoom < 18.4) {
            setZoom((pre) => pre + 0.4);
        }
    };

    const handleZoomOut = () => {
        if (zoom > 16.6) {
            setZoom((pre) => pre - 0.4);
        }
    };

    useEffect(() => {
        if (golfInfo && selectedHole) {
            const distance = golfInfo.find(
                (teebox) =>
                    teebox.color.toLowerCase() ===
                    selectedHole.type.toLowerCase()
            )?.length;

            setToGreenDistance(distance);
        }
    }, [golfInfo, selectedHole]);
    useEffect(() => {
        if (golfInfo && hole) setSelectedHole(hole?.vectors[1]);
    }, [golfInfo, hole]);
    if (mapData === undefined && reduxData == "-1") {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <Spinner> </Spinner>
            </div>
        );
    } else if (mapData === undefined && reduxData == "No Hole Found..!") {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <h1>{reduxData}</h1>
            </div>
        );
    } else {
        return (
            <>
                <Container className="hole-detail" fluid>
                    <Row className="px-5 py-2 m-0 hole-detail-pagination">
                        <Col className="hole-detail-pagination">
                            {course && (
                                <HolesIndexs
                                    callFrom={"SingleMap"}
                                    selectedIndex={Number(
                                        hole_number - 1
                                    )}
                                    holes={holes}
                                    polygons={polygons}
                                    scorecard={scorecard}
                                    course={course}
                                    handlePageNavigation={
                                        handlePageNavigation
                                    }
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="px-5 py-2 m-0 hole-detail-pagination">
                        <Col xs="12" lg="4" xl="3" className="px-2 px-lg-5">
                            <Row className="card p-0">
                                <Col>
                                    <Row className="d-flex flex-nowrap align-items-center">
                                        <div className="">
                                            <p className="h1 text-md-center">
                                                {hole_number}
                                            </p>
                                        </div>
                                        <div className="mx-2">
                                            <span className="text-truncate fsw-4 mb-0 mx-3">
                                                Par{" "}
                                                <span>
                                                    {golfInfo[1]?.par || "?"}
                                                </span>
                                            </span>
                                            <span className="text-truncate fsw-4 mb-0 mx-3">
                                                Hcp{" "}
                                                <span>
                                                    {golfInfo[1]?.handicap ||
                                                        "?"}
                                                </span>
                                            </span>
                                        </div>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <a
                                            // href="#"
                                            className="cursor-pointer h5 custom-a-hover course-name"
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/courses/holes/${course?.id}`,
                                                    state: mapData,
                                                });
                                            }}
                                        >
                                            {course?.name}
                                        </a>
                                        <ul className="course-info fs--14">
                                            <li>{course?.address?.street}</li>
                                            <li>
                                                <a
                                                    // href="#"
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        history.push(
                                                            `/courses/search/${course?.address?.state}/${course?.address?.city}`
                                                        );
                                                    }}
                                                >
                                                    {course?.address?.city}
                                                </a>{", "}
                                                <a
                                                    // href="#"
                                                    onClick={() => {
                                                        history.push(
                                                            `/courses/search/${course?.address?.state}`
                                                        );
                                                    }}
                                                    className="cursor-pointer"
                                                >
                                                    {course?.address?.state}
                                                </a>{" "}
                                                {course?.address?.zip}
                                            </li>
                                            <li><i class="fas fa-phone"></i> {course?.phonenumber}</li>
                                        </ul>
                                    </Row>
                                    <Row className="py-4">
                                        {golfInfo?.map((item, index) => (
                                            <div
                                                key={index}
                                                className="d-flex align-items-center hole-detail-teebox"
                                            >
                                                <div className="h-100 mr-2 pt-1">
                                                    <TeeBoxes
                                                        width={20}
                                                        Height={20}
                                                        type={item?.color}
                                                    />
                                                </div>
                                                <span className="h3 mr-2">
                                                    {item?.length}
                                                </span>
                                                <small className="fs--12">YDS</small>
                                            </div>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs="12" lg="8" xl="9" className="p-2">
                            <Row className="card align-items-center App-map-container">
                                <Col>
                                    <Row className="justify-content-center flex-nowrap">
                                        <Col
                                            className="bg_holes_profile p-0 m-0 text-md-center position-relative"
                                            style={{ overflow: "hidden" }}
                                        >
                                            <div
                                                className="w-25  h-100 position-absolute text-left p-3 text-white"
                                                style={{ zIndex: 1 }}
                                            >
                                                <div className="toggle">
                                                    <input
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={() =>
                                                            setIsChecked(
                                                                !isChecked
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        htmlFor=""
                                                        className={`mb-0 ${
                                                            isChecked
                                                                ? "onbtn"
                                                                : "ofbtn"
                                                        }`}
                                                    >
                                                        {/* <small>
                                                            {isChecked
                                                                ? "On"
                                                                : "Off"}
                                                        </small> */}
                                                    </label>
                                                </div>
                                                <Row className="mt-3 mb-5">
                                                    <Col
                                                        md={isChecked ? 6 : 12}
                                                    >
                                                        <p className="h4 mb-0">
                                                            {isChecked
                                                                ? ydsToGreen ||
                                                                  toGreenDistance
                                                                : toGreenDistance}
                                                        </p>
                                                        <p className="mb-0 text-muted fs--12">
                                                            yds
                                                        </p>
                                                        <p className="mb-0 text-muted fs--12">
                                                            to green
                                                        </p>
                                                    </Col>
                                                    <Col md="6">
                                                        {isChecked && (
                                                            <>
                                                                <p className="h4 mb-0">
                                                                    {ydsToTee ||
                                                                        "000"}
                                                                </p>
                                                                <p className="mb-0 text-muted fs--12">
                                                                    yds
                                                                </p>
                                                                <p className="mb-0 text-muted fs--12">
                                                                    to tee
                                                                </p>
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                                {/* <div className="bg-full-light text-center rounded-pill custom-zoom-style py-1 my-3 text-nowrap">
                                                    <Row className="d-flex justify-content-center align-items-center">
                                                        <Col xs="12" className="m-1">
                                                            <span className="fs--3 text-truncate px-1">
                                                                Zoom
                                                            </span>
                                                        </Col>
                                                        <Col xs="12" className="m-1">
                                                            <Button
                                                                className="border-0 p-0 mx-1"
                                                                onClick={
                                                                    handleZoomOut
                                                                }
                                                            >
                                                                <FaMinus className="bg-light text-site-primary rounded-pill h2 p-2 mb-0" />
                                                            </Button>
                                                        </Col>
                                                        <Col xs="12" className="m-1">
                                                            <Button
                                                                className="border-0 p-0 mx-1"
                                                                onClick={
                                                                    handleZoomIn
                                                                }
                                                            >
                                                                <FaPlus className="bg-light text-site-primary rounded-pill h2 p-2 mb-0" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>   */}
                                                <div className="bg-full-light text-center hole-detail-select py-1 my-3">
                                                    <div
                                                        className="cursor-pointer mb-1"
                                                        onClick={handleIcons}
                                                    >
                                                        <TeeBoxes
                                                            type={
                                                                selectedHole?.type
                                                            }
                                                            height={20}
                                                            width={20}
                                                        />
                                                    </div>

                                                    {icons &&
                                                        hole.vectors
                                                            .filter(
                                                                (hole) =>
                                                                    hole.type !==
                                                                        selectedHole.type &&
                                                                    hole.type !==
                                                                        "Flag"
                                                            )
                                                            .map(
                                                                (
                                                                    hole,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            hole.type
                                                                        }
                                                                        className={`cursor-pointer mb-1  ${
                                                                            index ===
                                                                                0 &&
                                                                            "border-top border-site-gray"
                                                                        } `}
                                                                        onClick={() => {
                                                                            setSelectedHole(
                                                                                hole
                                                                            );
                                                                            handleIcons();
                                                                        }}
                                                                    >
                                                                        <TeeBoxes
                                                                            type={
                                                                                hole.type
                                                                            }
                                                                            height={
                                                                                20
                                                                            }
                                                                            width={
                                                                                20
                                                                            }
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                </div>
                                            </div>
                                            {/*  */}
                                            <div>
                                                {selectedHole && (
                                                    <PolygonMap
                                                        height={500}
                                                        hole={hole}
                                                        paths={paths}
                                                        showPolyline={isChecked}
                                                        zoom={zoom}
                                                        callFrom={
                                                            "singleCourse"
                                                        }
                                                        isCalculate={isChecked}
                                                        setYdsToTee={
                                                            setYdsToTee
                                                        }
                                                        setYdsToGreen={
                                                            setYdsToGreen
                                                        }
                                                        selectedTeeBox={{
                                                            lat: selectedHole.lat,
                                                            lng: selectedHole.long,
                                                        }}
                                                    ></PolygonMap>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="my-5">
                                <Col>
                                    <small class="footnote">
                                        *Yardages listed as the crow flies, tee
                                        distance as straight lines through the
                                        center of the fairway.
                                    </small>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </>
        );
    }
}

export default CourseSinglePage;
