import axios from "axios";
import { BASE_URL } from "../constant";

const baseURL = `${BASE_URL}`;

const getToken = () => {
    let ls = JSON?.parse(localStorage?.getItem("token"));

    if (ls && ls != null) {
        return `Bearer ${ls}`;
    } else {
        return "";
    }
};

let axiosObj;
axiosObj = axios.create({
    baseURL,
    // headers: {
    //     Authorization: getToken(),
    // },
});

axiosObj.interceptors.request.use((config) => {
    config.headers["Authorization"] = getToken();
    return config;
});

export default axiosObj;
