import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import flashsuccess from "../../assets/img/flash-success.png";

import { registerLoader, registerUser } from "../../store/actions/authAction";
import {
    checkCoupon,
    getFetchCustomer,
    updatePlan,
} from "../../store/actions/subscribeAction";
import {
    getStripeToken,
    inputWhenLogin,
    inputWithoutLogin,
} from "../../utils/data";
import CardOptions from "./CardOptions";
import { STRIPE_PUBLIC_KEY } from "../../constant";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const Subscribe = (props) => {
    const location = useLocation();
    const { priceId } = location.state;
    const [isAgreed, setIsAgreed] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const { authLoading, user, uid, couponLoading, regLoading } = useSelector(
        (state) => state.authUser
    );

    const initialData = {
        phone: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        dateOfBirth: "",
        userName: "",
        companyName: "",
    };
    const [registerData, setregisterData] = useState(initialData);

    const [webaddress, setWebaddress] = useState("");
    const [coupon, setCoupon] = useState("");
    const [validCoupon, setValidCoupon] = useState(false);
    const [selectedOption, setSelectedOption] = useState("ExistingCard");
    const [cardDetails, setCardDetails] = useState("");

    const [inputData, setInputData] = useState(
        user ? inputWhenLogin : inputWithoutLogin
    );

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setregisterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const isValidCoupon = (event) => {
        event.preventDefault();

        dispatch(
            checkCoupon(coupon, (res) => {
                setValidCoupon(res);
            })
        );
    };
    const createStripePayload = async (elements, stripe) => {
        if (priceId === "test") {
            return { priceId, userId: uid };
        } else if (
            user &&
            user?.stripe_customer_id &&
            selectedOption === "ExistingCard"
        ) {
            return {
                customerId: user.stripe_customer_id,
                priceId: priceId,
                userId: uid,
            };
        } else {
            const stripeToken = await getStripeToken(elements, stripe);
            return {
                token: stripeToken,
                priceId: priceId,
                userId: uid,
            };
        }
    };
    const createUserObject = () => {
        if (user) {
            return {
                fullname:
                    registerData?.firstName + " " + registerData?.lastName,
                phone1: registerData?.phone,
                date_of_birth: user?.date_of_birth,
            };
        } else {
            return {
                username: registerData?.userName,
                password: registerData?.password,
                email: registerData?.email,
                fullname:
                    registerData?.firstName + " " + registerData?.lastName,
                phone1: registerData?.phone,
                date_of_birth: registerData?.dateOfBirth,
            };
        }
    };

    const onCompleted = (message) => {
        toast.success(message);
        setWebaddress("");
        setregisterData(initialData);
        dispatch(registerLoader(false));
        history.push("/main/setting");
    };
    const handleSignUp = async (e, elements, stripe) => {
        e.preventDefault();

        if (registerData?.password !== registerData.confirmPassword && !user) {
            toast.error("Passwords must be the same");
            return;
        }
        dispatch(registerLoader(true));
        const stripePayload = await createStripePayload(elements, stripe);

        const obj = createUserObject();

        const isEmpty = Object.values(obj).some(
            (value) => value === "" || value === undefined
        );
        const paymentConfirmation = Object.values(stripePayload).some(
            (value) => value === "" || value === undefined
        );
        if (isEmpty) {
            toast.error("Error: Some fields are empty.");
            if (
                paymentConfirmation &&
                (selectedOption === "NewCard" || !user)
            ) {
                toast.error("Error: payment Required.");
            }
        } else if (paymentConfirmation && priceId !== "test") {
            toast.error("Error: payment Required.");
        } else {
            const userData = {
                ...obj,
                webaddress: webaddress,
                company: registerData?.companyName,
            };
            const payload = validCoupon
                ? { ...stripePayload, coupon }
                : stripePayload;
            if (user) {
                dispatch(
                    updatePlan(
                        userData,
                        payload,
                        user.stripe_customer_id,
                        () => {
                            onCompleted("Updated Successfully");
                        }
                    )
                );
            } else {
                // console.log("Payload ::", userData);
                dispatch(
                    registerUser(userData, payload, () => {
                        onCompleted("Registered Successfully");
                    })
                );
            }
        }
    };

    useEffect(() => {
        dispatch(registerLoader(false));
        if (user) {
            if (user?.stripe_customer_id) {
                dispatch(
                    getFetchCustomer(user?.stripe_customer_id, (res) => {
                        setCardDetails(res);
                    })
                );
            }
            const name = user.fullname?.split(' ') || [''];
            setregisterData((prev) => ({
                ...prev,
                phone: user?.phone1,
                email: user?.email,
                firstName: name[0],
                lastName: name[1],
                companyName: user?.company,
            }));
            setWebaddress(user?.webaddress);
        }
    }, []);
    return (
        <>

            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ elements, stripe }) => (

                        <Row className="w-100 m-0 py-5 px-lg-narrow justify-content-center bg-white">

                            <Col className="p-0">

                                <div className="text-center">
                                    <h1 className="fs--32  fw--700 font-poppins">
                                        Golf Course Maps API
                                    </h1>
                                    <h6 className="fs--16 fw--500 text-muted font-poppins pt-2">
                                        {" "}
                                        {props.subtitle}
                                    </h6>
                                </div>

                                <Row className="py-5">
                                    <Col xs={12} lg={6} className="px-5 mb-5">
                                        <Row>
                                            <Col className="p-0" md="12">
                                                <div className="my-3">
                                                    <h4 className="fs--18 fw--600 font-poppins">
                                                        Create your API Profile
                                                    </h4>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="sub-inputs">
                                            {inputData?.map((obj) => (
                                                <Col md="12" className="p-0 mb-3">
                                                    <Label className="sub-form-label">
                                                        {obj.label}
                                                    </Label>
                                                    <Input
                                                        className="signIn__input py-4"
                                                        type={obj.type}
                                                        name={obj.name}
                                                        placeholder={
                                                            obj.placeholder
                                                        }
                                                        value={
                                                            registerData[obj.name]
                                                        }
                                                        onChange={handleInputChange}
                                                    />
                                                </Col>
                                            ))}
                                            <Col md="12" className="p-0 mb-3">
                                                <FormGroup>
                                                    <Label className="sub-form-label">
                                                        Website
                                                    </Label>
                                                    <Input
                                                        className="signIn__input py-4"
                                                        placeholder="Web Address"
                                                        type="text"
                                                        value={webaddress}
                                                        onChange={(e) =>
                                                            setWebaddress(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {props.course !== "Sample Golf Course" && (
                                            <>
                                                <Row>
                                                    <Col className="p-0" md="12">
                                                        <div className="my-2">
                                                            <h4 className="fs--18 fw--600 font-poppins">
                                                                Payment Information
                                                            </h4>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* // Stripe */}

                                                {/* card option  */}

                                                {/* ///// */}
                                                {cardDetails && (
                                                    <>
                                                        <CardOptions
                                                            selectedOption={
                                                                selectedOption
                                                            }
                                                            setSelectedOption={
                                                                setSelectedOption
                                                            }
                                                        />
                                                        {selectedOption ===
                                                            "ExistingCard" && (
                                                            <div className="p-2 border d-flex justify-content-between rounded">
                                                                <small className="mb-0">
                                                                    {
                                                                        cardDetails?.brand
                                                                    }
                                                                </small>
                                                                <small className="mb-0">
                                                                    ******
                                                                    {
                                                                        cardDetails?.last4
                                                                    }
                                                                </small>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {/* stripe */}
                                                {(selectedOption === "NewCard" ||
                                                    !cardDetails) && (
                                                    <>
                                                        <Row>
                                                            <Col className="px-0 mb-2">
                                                                <Label className="sub-form-label">
                                                                    Card Number
                                                                </Label>
                                                                <CardNumberElement
                                                                    required
                                                                    options={{
                                                                        placeholder:
                                                                            "Enter card number",
                                                                        showIcon: true,
                                                                        style: {
                                                                            base: {
                                                                                // backgroundColor: "#232733",
                                                                                fontSize:
                                                                                    "16px",
                                                                            },
                                                                            invalid:
                                                                                {
                                                                                    color: "#9e2146",
                                                                                },
                                                                        },
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="px-0">
                                                                <Label className="sub-form-label">
                                                                    Expires
                                                                </Label>
                                                                <CardExpiryElement
                                                                    required
                                                                    options={{
                                                                        placeholder:
                                                                            "MM/YY",
                                                                        showIcon: true,
                                                                        style: {
                                                                            base: {
                                                                                fontSize:
                                                                                    "16px",
                                                                            },
                                                                            invalid:
                                                                                {
                                                                                    color: "#9e2146",
                                                                                },
                                                                        },
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col  className="px-0">
                                                                <Label className="sub-form-label">
                                                                    CVC
                                                                </Label>
                                                                <CardCvcElement
                                                                    required
                                                                    options={{
                                                                        placeholder:
                                                                            "CVC",
                                                                        showIcon: true,
                                                                        style: {
                                                                            base: {
                                                                                fontSize:
                                                                                    "16px",
                                                                                borderColor:
                                                                                    "#ff0000",
                                                                            },
                                                                            onFocus: {
                                                                                borderColor:
                                                                                    "#ff0000",
                                                                            },
                                                                            invalid:
                                                                                {
                                                                                    color: "#9e2146",
                                                                                },
                                                                        },
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>{" "}
                                                        {/* // add coupon  */}
                                                        <Row>
                                                            <Col className="p-0 my-1">
                                                                <Input
                                                                    className={`signIn__input py-4 mt-1 ${
                                                                        validCoupon
                                                                            ? "text-site-primary"
                                                                            : "text-dark"
                                                                    }`}
                                                                    type="text"
                                                                    name="coupon"
                                                                    placeholder="Coupon Code"
                                                                    disabled={
                                                                        isValidCoupon
                                                                    }
                                                                    value={coupon}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setCoupon(
                                                                            e.target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className="p-0">
                                                                <Button
                                                                    disabled={
                                                                        !coupon
                                                                    }
                                                                    className={`border border-none float-right coupon-button my-2 ${
                                                                        coupon
                                                                            ? "btn-success"
                                                                            : "btn-secondary"
                                                                    }`}
                                                                    onClick={(
                                                                        event
                                                                    ) =>
                                                                        isValidCoupon(
                                                                            event
                                                                        )
                                                                    }
                                                                >
                                                                    {couponLoading ? (
                                                                        <Spinner size="sm"></Spinner>
                                                                    ) : (
                                                                        "Apply coupon"
                                                                    )}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        {/* //// */}
                                                    </>
                                                )}
                                                {/* ///// */}
                                            </>
                                        )}
                                        <Row className="my-5">
                                            <Col className="p-0" xs="2">
                                                <Input
                                                    type="checkbox"
                                                    className="mx-2"
                                                    checked={isAgreed}
                                                    onChange={() => {
                                                        setIsAgreed(!isAgreed);
                                                    }}
                                                ></Input>
                                            </Col>
                                            <Col className="p-0" xs="10">
                                                <div>
                                                    {" "}
                                                    {props.course ===
                                                    "Sample Golf Course" ? (
                                                        <p className="fw--400 font-poppins text-muted  ml-4">
                                                            I agree to the{" "}
                                                            <Link
                                                                to="/api/license"
                                                                className="text-site-primary  fw--500"
                                                            >
                                                                Golfbert API License
                                                                Agreement
                                                            </Link>{" "}
                                                        </p>
                                                    ) : (
                                                        <span className="fs--14 fw--400 font-poppins text-muted  ml-1">
                                                            I agree to the{" "}
                                                            <Link
                                                                to="/api/license"
                                                                className="text-site-primary"
                                                            >
                                                                Golfbert API License
                                                                Agreement
                                                            </Link>{" "}
                                                            and understand that this
                                                            is a recurring
                                                            subscription. I will be
                                                            billed automatically on
                                                            a recurring basis until
                                                            I cancel.
                                                        </span>
                                                    )}
                                                    {!isAgreed && (
                                                        <p className="text-danger fs--12 font-poppins p-2 mt-3 border border-danger">
                                                            *This field is required.
                                                        </p>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            {props.course ===
                                            "Sample Golf Course" ? (
                                                <Col className="p-0 mb-4" md="12">
                                                    <span className="fs--16 fw--400 text-justify font-poppins text-muted">
                                                        Once we have reviewed your
                                                        request, we will issue you
                                                        an API Key that will give
                                                        you access to our sample
                                                        course. If you have any
                                                        questions, please refer to
                                                        our{" "}
                                                        <Link
                                                            to="/api/guides"
                                                            className="text-site-primary  fw--500"
                                                        >
                                                            Guides
                                                        </Link>{" "}
                                                        or you can{" "}
                                                        <Link
                                                            to="/api/support"
                                                            className="text-site-primary fw--500"
                                                        >
                                                            contact us
                                                        </Link>{" "}
                                                        for further assistance.
                                                    </span>
                                                </Col>
                                            ) : (
                                                <>
                                                    {" "}
                                                    {}
                                                </>
                                            )}
                                        </Row>
                                        <Row>
                                            <Col className="px-0">
                                                <div className="text-center">
                                                    <Button
                                                        disabled={
                                                            regLoading || !isAgreed
                                                        }
                                                        className="text-dark rounded-1 btn btn-site-bgGreen px-5 py-3 btn btn-secondary subscribe-form-button"
                                                        onClick={(e) => {
                                                            handleSignUp(
                                                                e,
                                                                elements,
                                                                stripe
                                                            );
                                                        }}
                                                    >
                                                        {regLoading ? (
                                                            <Spinner size="sm">
                                                                {" "}
                                                            </Spinner>
                                                        ) : (
                                                            "Subscribe to API"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={12} lg={6}>
                                        <div className="purchase-summary p-3">
                                            <h4 className="fs--18 fw--600 font-poppins">
                                                Purchase Summary
                                            </h4>
                                            <ul class="sub-details">
                                                <li>
                                                    <h6 className="fs--14 fw--400 font-poppins text-muted">
                                                        Your credit card will be
                                                        charged{" "}
                                                        <strong>
                                                            {props.price}
                                                        </strong>{" "}
                                                        and will automatically renew
                                                        monthly at {props.price}{" "}
                                                        until you notify us
                                                        otherwise. This
                                                        subscription is
                                                        non-refundable.
                                                    </h6>
                                                </li>
                                                {props.course === "Single Golf Course" && (
                                                <li className="my-4">
                                                        <Row>
                                                            <Col
                                                                md="12"
                                                                className="fs--14 fw--400 font-poppins text-muted"
                                                            >
                                                                {/* <div className="text-center">
                                                                    <img
                                                                        src={flashsuccess}
                                                                        alt=""
                                                                    />
                                                                </div> */}
                                                                <div>
                                                                        <strong>
                                                                            {props.singleCourse}{" "}
                                                                        </strong>
                                                                        Before proceeding,
                                                                        please{" "}
                                                                        <Link
                                                                            to="/courses/search"
                                                                            className="text-site-primary "
                                                                        >
                                                                            search for your
                                                                            preferred course
                                                                        </Link>{" "}
                                                                        to confirm we have it
                                                                        mapped. If your course
                                                                        is not yet mapped, our
                                                                        mapping team will
                                                                        prioritize it to the top
                                                                        of the queue, in the
                                                                        order it was received.
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                </li>
                                                )}
                                                <li className="fs--14 fw--400  font-poppins text-muted ">
                                                        Golf Course Maps API
                                                        — {props.course}
                                                </li>
                                                <li className="fs--14 fw--400  font-poppins text-muted ">
                                                        {props.price} today
                                                </li>
                                                <li>
                                                    <h6 className="fs--14 mt-4 fw--600  font-poppins text-site-primary">
                                                        *API keys are generated and sent to you within 48 hours.
                                                    </h6>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </ElementsConsumer>
            </Elements>
        </>
    );
};

export default Subscribe;
