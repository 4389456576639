import React from "react";
import { Link, Element, scroller } from "react-scroll";

import { TutorialSidebarData } from "./TutorialSidebarData";

const TutorialMainContent = () => {
    return (
        <>
            <div>
                {TutorialSidebarData?.map((obj) => (
                    <>
                        <Element name={obj.section} className="section">
                            {obj.component}
                        </Element>
                    </>
                ))}
            </div>
        </>
    );
};

export default TutorialMainContent;
