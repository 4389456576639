import { BuildingApp } from "./tutorial/BuildingApp";
import BuildingScorecard from "./tutorial/BuildingScorecard";
import { CreateGolfbertClass } from "./tutorial/CreateGolfbertClass";
import CreateHoleMap from "./tutorial/CreateHoleMap";
import { DevelopmentEnvironment } from "./tutorial/DevelopmentEnvironment";
import { ImplementCourseList } from "./tutorial/ImplementCourseList";
import Introduction from "./tutorial/Introduction";

export const TutorialSidebarData = [
    {
        id: "link1",
        section: "introduction",
        name: "I.Introduction",
        component: <Introduction />,
    },
    {
        id: "link2",
        section: "developmentEnvironment",
        name: "II.Setting up the development environment",
        component: <DevelopmentEnvironment />,
    },
    {
        id: "link3",
        section: "createGolfbertClass",
        name: "III.Creating the Golfbert Class",
        component: <CreateGolfbertClass />,
    },
    {
        id: "link4",
        section: "buildingApp",
        name: "IV.Building the App -First Request",
        component: <BuildingApp />,
    },
    {
        id: "link5",
        section: "implementCourseList1",
        name: "V.Implementing the Course List Component",
        component: <ImplementCourseList />,
    },
    {
        id: "link6",
        section: "buildingScorecard",
        name: "VI.Building the Scorecard Component",
        component: <BuildingScorecard />,
    },
    {
        id: "link7",
        section: "createHoleMap",
        name: "VII.Create the Hole Map Component",
        component: <CreateHoleMap />,
    },
];
