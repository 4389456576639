import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

export const FurtherDevelopment = () => {
    const { user } = useSelector((state) => state.authUser);
    return (
        <>
            <Row>
                <Col>
                    <div className="text-center mb-3">
                        <h1 className="fs--32 fw--600  font-poppins">Further Development</h1>
                        <p className="fs--16 fw--400">
                            Once you are calling the API correctly and have some
                            golf course maps in your app or website, the fun
                            really starts. Here are some examples of what can
                            achieved by developing with our maps geodata.
                        </p>
                        {/* <p>
                            Just some things to think about — feel free to
                            <Link to="" className="text-site-primary ml-1">
                                bounce an idea off us,
                            </Link>{" "}
                            and start experimenting!
                        </p> */}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={6}>
                    <ul className="my-5 bullet-list">
                        <li className="fs--16 fw--400">
                            Check distances to / from any point on the hole
                        </li>
                        <li className="fs--16 fw--400">
                            Distance to front, center, back of green
                        </li>
                        <li className="fs--16 fw--400">
                            Distance from any tee box
                        </li>
                        <li className="fs--16 fw--400">
                            Distance from the golfer's position via Location
                            Services
                        </li>
                        <li className="fs--16 fw--400">
                            Polygon checks: determine if the golfer's current
                            position is inside a specific polygon: "Golfer's
                            ball landed in the left rough"
                        </li>
                        <li className="fs--16 fw--400">
                            Calculate statistics on course usage: "67.5% of
                            drives ended up on the left side of the fairway"
                        </li>
                        <li className="fs--16 fw--400">
                            Hole rotation: display hole maps green up, tee box
                            down
                        </li>
                        <li className="fs--16 fw--400">
                            Course layout: display entire course at once, or
                            layout in a grid hole-by-hole
                        </li>
                    </ul>
                </Col>
                <Col sm={12} lg={6}>
                    <div className="my-5 px-5 landing-footer-action">
                        <p className="fs--16 fw--600">
                            Build golf course maps in your app with our geodata API.
                        </p>
                        <Link
                            to="/api/plans"
                            >
                            <Button className="btn-custom py-2 px-3">
                                {
                                    user?.api_plan === "single"
                                        ? "Upgrade your plan"
                                        : "Get an API Key"
                                }
                            </Button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="pb-5">
                <Col>
                    <div className="text-center">
                        <h6 className="fs--16 fw--400 font-poppins py-4">
                            <strong>Need help? </strong>
                            <Link
                                to="/api/support"
                                className="text-site-primary"
                            >
                                Contact us
                            </Link>{" "}
                            and weil assist you with anything you need.
                        </h6>
                    </div>
                </Col>
            </Row>
        </>
    );
};
