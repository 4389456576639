export const pathContentData = [
    { header: "Paths" },
    {
        route: "v1/ping",
        path: "GET /status",
        Description:
            "Pings the Golfbert API to determine status. A 200-type of response signifies success.",

        response: [
            {
                statusCode: "200 OK",
                title: "ping",
                section: "ping",
                description: "successful ping",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "message": "string"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "ping failed",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },
    {
        route: "v1/courses",
        path: "GET /v1/courses/",
        Description: `Retrieves a paginated list of courses. The request can be further parameterized to filter courses by name, city, state, zipcode, or gps coordinates. General course information can be found <a className="text-site-primary"  href="/api/overview">here</a>.`,
        requestParameter: [
            {
                params: "limit",
                description: "maximum number of results to return",
                paramsType: "integer(int32) default:10",
                paramsIn: "query",
            },
            {
                params: "marker",
                description:
                    "marker of where to start returning results (in this case course id)",
                paramsType: "integer (int32)",
                paramsIn: "query",
            },
            {
                params: "name",
                description:
                    "return only courses that match this name e.g. Torrey Pines",
                paramsType: "string",
                paramsIn: "query",
            },
            {
                params: "city",
                description:
                    "return only courses within given city e.g. Chicago",
                paramsType: "string",
                paramsIn: "query",
            },
            {
                params: "state",
                description:
                    "return only courses within given state e.g. Alabama",
                paramsType: "string",
                paramsIn: "query",
            },
            {
                params: "zipcode",
                description:
                    "return only courses within given zip code e.g. 99205",
                paramsType: "string",
                paramsIn: "query",
            },
            {
                params: "lat",
                description:
                    "return only courses within given latitude and longitude. The params lat and long must be specified together otherwise the api will fail",
                paramsType: "number (double)",
                paramsIn: "query",
            },
            {
                params: "long",
                description:
                    "return only courses within given latitude and longitude. The params lat and long must be specified together otherwise the api will fail",
                paramsType: "number (double)",
                paramsIn: "query",
            },
        ],
        response: [
            {
                statusCode: "200 OK",
                title: "listofcourses",
                section: "course",
                description: "array of courses",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "message": "string"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving courses",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },

    {
        route: "v1/courses/{id}",
        path: "GET /v1/courses/{id}",
        Description:
            "Retrieves a course's details. This call expands on the information returned by the course listings call.",
        requestParameter: [
            {
                params: "id",
                required: "required",
                description:
                    "the id of the course whose information to be returned",
                paramsType: "integer(int32)",
                paramsIn: "path",
            },
        ],
        response: [
            {
                statusCode: "200 OK",
                title: "course",
                section: "course",
                description: "course object information",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "address": {
        "$ref": "#/definitions/address"
    },
    "coordinates": {
        "$ref": "#/definitions/point"
    },
    "id": "integer(int32)",
    "name": "string",
    "phonenumber": "string"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving course details",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },

    {
        route: "v1/courses/{id}/holes",
        path: "GET /v1/courses/{id}/holes",
        Description: "Retrieves a course's holes' information.",
        requestParameter: [
            {
                params: "id",
                required: "required",
                description: "the course id whose holes info to return",
                paramsType: "integer(int32)",
                paramsIn: "path",
            },
        ],
        response: [
            {
                statusCode: "200 OK",
                title: "listofholes",
                section: "listofholes",
                description: "array of hole objects",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "resources": "array"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving course's holes",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },

    {
        route: "v1/courses/{id}/scorecard",
        path: "GET /v1/courses/{id}/scorecard",
        Description:
            `Retrieves a course's scorecard. An example of information returned can be found <a href="#" >here<a>.`,
        requestParameter: [
            {
                params: "id",
                required: "required",
                description: "the course id whose scorecard to return",
                paramsType: "integer(int32)",
                paramsIn: "path",
            },
        ],
        response: [
            {
                statusCode: "200 OK",
                title: "scorecard",
                section: "scorecard",
                description: "failed while retrieving course's scorecard",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "courseid": "integer(int32)",
    "coursename": "string",
    "holeteeboxes": "array"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving course's scorecard",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },

    {
        route: "v1/courses/{id}/teeboxes",
        path: "GET /v1/courses/{id}/teeboxes",
        Description: "Retrieves a course's teeboxes.",
        requestParameter: [
            {
                params: "id",
                required: "required",
                description: "the course id",
                paramsType: "integer(int32)",
                paramsIn: "path",
            },
        ],
        response: [
            {
                statusCode: "200 OK",
                title: "listofcourseteeboxes",
                section: "listofcourseteeboxes",
                description: "list of course teeboxes presented as an array",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "resources": "array"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving course's teeboxes",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },

    {
        route: "v1/holes",
        path: "GET /v1/holes/",
        Description: "Retrieves a paginated list of holes.",
        requestParameter: [
            {
                params: "courseId",
                required: "required",
                description: "the course whose holes to return",
                paramsType: "integer(int32)",
                paramsIn: "query",
            },
            {
                params: "limit",

                description: "maximum number of results to return",
                paramsType: "integer(int32) default:18",
                paramsIn: "query",
            },
            {
                params: "marker",

                description:
                    "marker of where to start returning results (in this case hole number)",
                paramsType: "integer(int32) default:'1'",
                paramsIn: "query",
            },
        ],
        response: [
            {
                statusCode: "200 OK",
                title: "listofholes",
                section: "listofholes",
                description: "array of holes",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "resources": "array"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving holes",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },

    {
        route: "v1/holes/{id}",
        path: "GET /v1/holes/{id}",
        Description: "Retrieves a hole's details including geo information",
        requestParameter: [
            {
                params: "id",
                required: "required",
                description: "the hole id",
                paramsType: "integer(int32)",
                paramsIn: "path",
            },
        ],
        response: [
            {
                statusCode: "200 OK",
                title: "hole",
                section: "hole",
                description: "array of holes",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "courseid": "integer(int32)",
    "dimensions": {
        "$ref": "#/definitions/holedimensions"
    },
    "flagcoords": {
        "$ref": "#/definitions/point"
    },
    "id": "integer(int32)",
    "number": "integer(int32)",
    "range": {
        "$ref": "#/definitions/holerange"
    },
    "rotation": "number(double)",
    "vectors": "array"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving hole details",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },

    {
        route: "v1/holes/{id}/polygons",
        path: "GET /v1/holes/{id}/polygons",
        Description:
            "Retrieves a holes's polygons. This information can be used to render the hole accurately on top of a map.",
        requestParameter: [
            {
                params: "id",
                required: "required",
                description: "the hole id",
                paramsType: "integer(int32)",
                paramsIn: "path",
            },
        ],
        response: [
            {
                statusCode: "200 OK",
                title: "listofholepolygons",
                section: "listofholepolygons",
                description: "hole polygons array",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "resources": "array"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving hole details",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },

    {
        route: "v1/holes/{id}/teeboxes",
        path: "GET /v1/holes/{id}/teeboxes",
        Description: "Retrieves a holes's teeboxes.",
        requestParameter: [
            {
                params: "id",
                required: "required",
                description: "the hole id",
                paramsType: "integer(int32)",
                paramsIn: "path",
            },
            {
                params: "color",
                description: "teebox color to filter by",
                paramsType: "string",
                paramsIn: "query",
            },
            {
                params: "teeboxtype",
                description: "teebox type to filter by",
                paramsType: "string",
                paramsIn: "query",
            },
            {
                params: "par",
                description: "only show holes with a specific par",
                paramsType: "integer(int 32)",
                paramsIn: "query",
            },
            {
                params: "handicap",
                description: "only show holes with a specific handicap",
                paramsType: "integer(int 32)",
                paramsIn: "query",
            },
        ],
        response: [
            {
                statusCode: "200 OK",
                title: "listofholeteeboxes",
                section: "listofholeteeboxes",
                description: "hole teeboxes array",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "resources": "array"
}`,
                  
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving hole's teeboxes",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },

    {
        route: "v1/teeboxcolors",
        path: "GET /v1/teeboxcolors",
        Description: "Retrieves all available teebox colors",

        response: [
            {
                statusCode: "200 OK",
                title: "listofteeboxcolors",
                section: "listofteeboxcolors",
                description: "arrays of all existing teebox colors",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "resources": "array"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving teebox colors",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },

    {
        route: "v1/teeboxtypes",
        path: "GET /v1/teeboxtypes",
        Description:
            "Retrieves all available teebox types. This serves as an enum of all available teebox types",

        response: [
            {
                statusCode: "200 OK",
                title: "listofteeboxtypes",
                section: "listofteeboxtypes",
                description: "arrays of all possible teebox types",
                responseStatus: "Response Example (200 OK)",
                reponseCode: `{
    "resources": "array"
}`,
            },
            {
                statusCode: "400 Bad Request",
                title: "GeneralErrorResponse",
                section: "GeneralErrorResponse",
                description: "failed while retrieving hole details",
                responseStatus: "Response Example (400 Bad Request)",
                reponseCode: `{
    "message": "string"
}`,
            },
        ],
    },
];

export const modelContentData = [
    { header: "Schema Definitions" },
    {
        section: "address",
        properties: [
            {
                street: "string",
            },
            {
                city: "string",
            },
            {
                state: "string",
            },
            {
                zip: "string#required",
                // required: "required",
            },
            {
                country: "string",
            },
        ],
    },
    {
        section: "course",
        properties: [
            {
                id: "integer(int32)#required",
            },
            {
                name: "string#required",
            },
            {
                address: {
                    route: "address",
                },
            },
            {
                phonenumber: "string",
            },
            {
                coordinates: {
                    route: "point",
                },
            },
        ],
    },

    {
        section: "courseteebox",
        properties: [
            {
                courseteebox: " integer (int32)#required",
            },
            {
                courseid: "string",
            },
            {
                color: "number (double)",
            },
            {
                slope: "string",
            },
            {
                rating: "number (double)",
            },

            {
                teeboxtype: "string",
            },
        ],
    },

    {
        section: "GeneralErrorResponse",
        properties: [
            {
                message: "string#required",
            },
        ],
    },

    {
        section: "hole",
        properties: [
            {
                id: "integer (int32)#required",
            },
            {
                number: "integer (int32)#required",
            },
            {
                courseid: "integer (int32)#required",
            },
            {
                rotation: "number (double)",
            },
            {
                vectors: "object[]",
            },

            {
                range: {
                    route: "holerange",
                },
            },
            {
                dimensions: {
                    route: "holedimensions",
                },
            },
            {
                flagcoords: {
                    route: "point",
                },
            },
        ],
    },
    {
        section: "holedimensions",
        properties: [
            {
                width: "integer (int32)",
            },
            {
                height: "integer (int32)",
            },
        ],
    },

    {
        section: "holepolygon",
        properties: [
            {
                holeid: "integer (int32)#required",
            },
            {
                surfacetype: "string",
            },
            {
                polygon: "object[]",
            },
        ],
    },

    {
        section: "holerange",
        properties: [
            {
                x: "object",
            },
            {
                y: "object",
            },
        ],
    },

    {
        section: "holeteebox",
        properties: [
            {
                holeid: "integer (int32)#required",
            },
            {
                holenumber: "integer (int32)",
            },
            {
                teeboxtype: "string",
            },
            {
                color: "string",
            },
            {
                length: "integer (int32)",
            },
            {
                par: "integer (int32)",
            },
            {
                handicap: "integer (int32)",
            },

            {
                coordinates: {
                    route: "point",
                },
            },
        ],
    },

    {
        section: "listofcourses",
        properties: [
            {
                resources: " object[]#required",
            },
        ],
    },
    {
        section: "listofcourseteeboxes",
        properties: [
            {
                resources: " object[]#required",
            },
        ],
    },
    {
        section: "listofholepolygons",
        properties: [
            {
                resources: " object[]#required",
            },
        ],
    },
    {
        section: "listofholes",
        properties: [
            {
                resources: " object[]#required",
            },
        ],
    },
    {
        section: "listofholeteeboxes",
        properties: [
            {
                resources: " object[]#required",
            },
        ],
    },
    {
        section: "listofteeboxcolors",
        properties: [
            {
                resources: "string[]#required",
            },
        ],
    },
    {
        section: "listofteeboxtypes",
        properties: [
            {
                resources: "string[]#required",
            },
        ],
    },
    {
        section: "ping",
        properties: [
            {
                message: "string#required",
            },
        ],
    },
    {
        section: "point",
        properties: [
            {
                lat: "number (double)#required",
            },
            {
                long: "number (double)#required",
            },
            {
                type: "string",
            },
        ],
    },

    {
        section: "scorecard",
        properties: [
            {
                courseid: "number (double)#required",
            },
            {
                coursename: "string (double)#required",
            },
            {
                holeteeboxes: "object[]",
            },
        ],
    },
];
