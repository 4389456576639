import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Form,
    Spinner,
} from "reactstrap";
import { updateContact } from "../../store/actions/UserActions";

const UpdateContact = ({ contact, setContact, toggleModal }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem("authUser"));
    // const {loading} = useSelector((state)=>state.)
    const initialData = {
        email: user?.email,
        fullname: user?.fullname,
        address: user?.address,
        city: user?.city,
        state: user?.state,
        zip_code: user?.zip_code,
        date_of_birth: user?.date_of_birth?.substring(0, 10),
        company: user?.company,
        webaddress: user?.webaddress,
        // date_of_birth: user?.date_of_birth,
    };

    const [formData, setFormData] = useState(initialData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // useEffect(() => {
    //     const item = JSON.parse(localStorage.getItem("authUser"));
    //     console.log({ item });
    // }, []);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        // console.log({ formData });
        dispatch(
            updateContact(formData, () => {
                // console.log("done");
                setContact(false);
                setLoading(false);
            })
        );
    };

    return (
        <div>
            <Modal
                className="contact-modal"
                isOpen={contact}
                toggle={toggleModal}
            >
                <ModalHeader
                    className="contact-modal__head"
                    toggle={toggleModal}
                >
                    <h4>Update Contact Information</h4>
                </ModalHeader>
                <ModalBody className="contact-modal__modal-scrollable">
                    <Form>
                        <Row>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        Email
                                    </Label>
                                    <Input
                                        className="signIn__input contact-modal__modal-input"
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        Full Name
                                    </Label>
                                    <Input
                                        className="signIn__input"
                                        type="text"
                                        name="fullname"
                                        value={formData.fullname}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        Address
                                    </Label>
                                    <Input
                                        className="signIn__input"
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        City
                                    </Label>
                                    <Input
                                        className="signIn__input"
                                        type="text"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        State
                                    </Label>
                                    <Input
                                        className="signIn__input"
                                        type="text"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        Zip Code
                                    </Label>
                                    <Input
                                        className="signIn__input"
                                        type="number"
                                        name="zip_code"
                                        value={formData.zip_code}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        Date of birth
                                    </Label>
                                    <Input
                                        className="signIn__input"
                                        type="date"
                                        name="date_of_birth"
                                        defaultValue={formData?.date_of_birth}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        Company
                                    </Label>
                                    <Input
                                        className="signIn__input"
                                        type="text"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        Webaddress
                                    </Label>
                                    <Input
                                        className="signIn__input"
                                        type="text"
                                        name="webaddress"
                                        value={formData.webaddress}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        disabled={loading}
                        onClick={handleFormSubmit}
                    >
                        {loading ? (
                            <Spinner size={"sm"} className="mx-3">
                                {" "}
                            </Spinner>
                        ) : (
                            "Update"
                        )}
                    </Button>
                    <Button color="site-lite-btn" onClick={toggleModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default UpdateContact;
