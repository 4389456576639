import React from "react";

const Introduction = () => {
    return (
        <div className="my-4">
            <h2 className="fs--20 fw--500 font-poppins guides-intro-header">Introduction</h2>
            <p className="fs--16 fw--400 font-poppins text-muted">
                Golfbert provides a REST API that includes hole information and
                geolocation for the top golf courses across the USA. Below is an
                overview and some quick examples of how to get up and running
                with the Golfbert API.
            </p>
            <p className="fs--16 fw--400 font-poppins text-muted">
                You can use this API to retrieve:
            </p>
            <ol>
                <li className="fs--14 fw--400 font-poppins text-muted">
                    Course information
                    <ul className="fs--14 fw--400 font-poppins text-muted">
                        <li>Course name</li>
                        <li>Address</li>
                        <li>Handicap</li>
                        <li>Slope and rating</li>
                        <li>Location</li>
                    </ul>
                </li>
                <li className="fs--14 fw--400 font-poppins text-muted">
                    Hole information
                    <ul className="fs--14 fw--400 font-poppins text-muted">
                        <li>Yardage</li>
                        <li>Par</li>
                        <li>Handicap</li>
                        <li>Teeboxes (color, tee box type, yardage)</li>
                    </ul>
                </li>
                <li className="fs--14 fw--400 font-poppins text-muted">
                    Geolocation polygons for course surfaces
                    <ul className="fs--14 fw--400 font-poppins text-muted">
                        <li>Greens</li>
                        <li>Fairways</li>
                        <li>Sand traps</li>
                        <li>Stone</li>
                        <li>Trees and wooded areas</li>
                        <li>Water hazards</li>
                    </ul>
                </li>
            </ol>
        </div>
    );
};

export default Introduction;
