import React, { useState } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    FormGroup,
    Label,
    Form,
    Input,
    // InputGroupAddon,
    Spinner,
} from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../store/actions/UserActions";

const ChangePassword = ({ password, setPassword, passwordToggle }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = React.useState({
        current: "",
        new: "",
        confirm: "",
    });

    const [showPassword, setShowPassword] = React.useState({
        current: false,
        new: false,
        confirm: false,
    });

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const handleClickShowPassword = (field) => () => {
        setShowPassword({ ...showPassword, [field]: !showPassword[field] });
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log({ oldPassword, newPassword, confirmNewPassword });
        if (
            newPassword !== confirmNewPassword ||
            newPassword == "" ||
            confirmNewPassword == ""
        ) {
            toast.error("Password and Confirm Password must be same");
        } else {
            let obj = {
                oldPassword: oldPassword,
                password: newPassword,
            };

            dispatch(
                updatePassword(obj, () => {
                    toast.success("Password updated successfully");
                    setOldPassword("");
                    setNewPassword("");
                    setConfirmNewPassword("");
                    setPassword(false);
                    setLoading(false);
                })
            );
        }
    };
    return (
        <div>
            <Modal
                className="contact-modal"
                isOpen={password}
                toggle={passwordToggle}
            >
                <ModalHeader
                    className="contact-modal__head"
                    toggle={passwordToggle}
                >
                    <h4>Change Password</h4>
                </ModalHeader>
                <ModalBody className="contact-modal__modal-scrollable">
                    <p className="contact-modal__password-para">
                        Please enter your current password along with the
                        password you wish to change it to. Your new password
                        will go into effect immediately.
                    </p>
                    <Form>
                        <Row>
                            <Col lg="12" md="12" className="mb-3">
                                <>
                                    <Label className="sub-form-label">
                                        Current Password
                                    </Label>
                                    <Input
                                        className="signIn__input contact-modal__modal-input position-relative"
                                        type={
                                            showPassword.current
                                                ? "text"
                                                : "password"
                                        }
                                        name="current"
                                        value={oldPassword}
                                        onChange={(e) =>
                                            setOldPassword(e.target.value)
                                        }
                                    />
                                    <i
                                        style={{ right: 25, bottom: 10 }}
                                        className={` ${
                                            showPassword.current
                                                ? "fas fa-eye-slash"
                                                : "fas fa-eye"
                                        } cursor-pointer position-absolute`}
                                        onClick={handleClickShowPassword(
                                            "current"
                                        )}
                                    ></i>
                                    {/* <InputGroupAddon
                                        addonType="append"
                                        className="float-right mt-n4"
                                    >
                                        <IconButton
                                            className="mt-n3"
                                            onClick={handleClickShowPassword(
                                                "current"
                                            )}
                                        >
                                            {showPassword.current ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputGroupAddon> */}
                                </>
                            </Col>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        New Password
                                    </Label>
                                    <Input
                                        className="signIn__input position-relative"
                                        type={
                                            showPassword.new
                                                ? "text"
                                                : "password"
                                        }
                                        name="lastName"
                                        value={newPassword}
                                        onChange={(e) =>
                                            setNewPassword(e.target.value)
                                        }
                                    />{" "}
                                    <i
                                        style={{ right: 25, bottom: 27 }}
                                        className={` ${
                                            showPassword.new
                                                ? "fas fa-eye-slash"
                                                : "fas fa-eye"
                                        } cursor-pointer position-absolute`}
                                        onClick={handleClickShowPassword("new")}
                                    ></i>
                                    {/* <InputGroupAddon
                                        addonType="append"
                                        className="float-right mt-n4"
                                    >
                                        <IconButton
                                            className="mt-n3"
                                            onClick={handleClickShowPassword(
                                                "new"
                                            )}
                                        >
                                            {showPassword.new ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputGroupAddon> */}
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12">
                                <FormGroup>
                                    <Label className="sub-form-label">
                                        Password Again Please
                                    </Label>
                                    <Input
                                        className="signIn__input position-relative"
                                        type={
                                            showPassword.confirm
                                                ? "text"
                                                : "password"
                                        }
                                        name="email"
                                        value={confirmNewPassword}
                                        onChange={(e) =>
                                            setConfirmNewPassword(
                                                e.target.value
                                            )
                                        }
                                    />
                                    <i
                                        style={{ right: 25, bottom: 27 }}
                                        className={` ${
                                            showPassword.confirm
                                                ? "fas fa-eye-slash"
                                                : "fas fa-eye"
                                        } cursor-pointer position-absolute`}
                                        onClick={handleClickShowPassword(
                                            "confirm"
                                        )}
                                    ></i>
                                    {/* <InputGroupAddon
                                        addonType="append"
                                        className="float-right mt-n4"
                                    >
                                        <IconButton
                                            className="mt-n3"
                                            onClick={handleClickShowPassword(
                                                "confirm"
                                            )}
                                        >
                                            {showPassword.confirm ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputGroupAddon> */}
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className=""
                        onClick={handleUpdate}
                    >
                        {loading ? (
                            <Spinner size="sm" className="mx-3" />
                        ) : (
                            "Update"
                        )}
                    </Button>
                    <Button color="site-lite-btn" onClick={passwordToggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ChangePassword;
