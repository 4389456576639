import { toast } from "react-toastify";
import { RepositoryFactory } from "../../Repository/RepositoryFactory";
import { CITIES, STATES } from "../types";
let statesCities = RepositoryFactory.get("statesCities");

export const getStatesCities = (stateName, onSuccess) => async (dispatch) => {
    try {
        const { data } = stateName
            ? await statesCities.fetchCities(stateName)
            : await statesCities.fetchStates();

        if (data.success) {
            dispatch({
                type: stateName ? CITIES : STATES,
                payload: data?.data,
            });
        }
    } catch (error) {
        toast.error(
            error?.response?.data?.message ||
                error?.data?.message ||
                error.message ||
                "Something Went Wrong"
        );
        console.error("error: ", error);
    }
};
