import React, { useState } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Label,
    Col,
    FormGroup,
    Input,
    Form,
    Spinner,
} from "reactstrap";
import cards from "../../assets/img/cards.png";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { getStripeToken } from "../../utils/data";
import { updateCard } from "../../store/actions/subscribeAction";
import { useDispatch, useSelector } from "react-redux";
import { authLoader } from "../../store/actions/authAction";
import { STRIPE_PUBLIC_KEY } from "../../constant";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const UpdatePaymentInfo = ({ payment, paymentToggle, setCardDetails }) => {
    const dispatch = useDispatch();
    const { authLoading, user } = useSelector((state) => state.authUser);
    // const [day, setDay] = useState("7");
    // const [year, setYear] = useState("");
    // const handleDayChange = (event) => {
    //     setDay(event.target.value);
    // };
    const hanldeSubmit = async (event, elements, stripe) => {
        event.preventDefault();
        dispatch(authLoader(true));
        const token = await getStripeToken(elements, stripe);

        if (token) {
            dispatch(
                updateCard(
                    { customerId: user?.stripe_customer_id, token },
                    (res) => {
                        setCardDetails(res);
                        paymentToggle();
                    }
                )
            );
        }
    };
    return (
        <div className="payment-modal">
            <Modal
                className="contact-modal update-payment-modal"
                isOpen={payment}
                toggle={paymentToggle}
            >
                <ModalHeader
                    className="contact-modal__head"
                    toggle={paymentToggle}
                >
                    <h4>Update Payment Information</h4>
                </ModalHeader>
                <ModalBody className="contact-modal__modal-scrollable">
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                            {({ elements, stripe }) => (
                                <>
                                    <Row>
                                        <Col className="mb-3">
                                            <Label className="sub-form-label">
                                                Card Number
                                            </Label>
                                            <CardNumberElement
                                                required
                                                options={{
                                                    placeholder:
                                                        "Enter card number",
                                                    showIcon: true,
                                                    style: {
                                                        base: {
                                                            // backgroundColor: "#232733",
                                                            fontSize:
                                                                "16px",
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mb-3">
                                            <Label className="sub-form-label">
                                                Expires
                                            </Label>
                                            <CardExpiryElement
                                                required
                                                options={{
                                                    placeholder:
                                                        "MM/YY",
                                                    showIcon: true,
                                                    style: {
                                                        base: {
                                                            fontSize:
                                                                "16px",
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Label className="sub-form-label">
                                                CVC
                                            </Label>
                                            <CardCvcElement
                                                required
                                                options={{
                                                    placeholder: "CVC",
                                                    showIcon: true,
                                                    style: {
                                                        base: {
                                                            fontSize:
                                                                "16px",
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>{" "}
                                    <div className="d-flex justify-content-end my-4">
                                        <Button
                                            color="site-lite-btn"
                                            onClick={paymentToggle}
                                        >
                                            Cancel
                                        </Button>{" "}
                                         <Button
                                            onClick={(event) =>
                                                hanldeSubmit(
                                                    event,
                                                    elements,
                                                    stripe
                                                )
                                            }
                                        >
                                            {authLoading ? (
                                                <Spinner size={"sm"}></Spinner>
                                            ) : (
                                                "  Update"
                                            )}
                                        </Button>
                                    </div>
                                </>
                                // <Form>
                                //     <Row>
                                //         <Col lg="12" md="12">
                                //             <FormGroup>
                                //                 <div>
                                //                     <img
                                //                         className="contact-modal__payment-card"
                                //                         src={cards}
                                //                         alt=""
                                //                     />
                                //                 </div>
                                //                 <Input
                                //                     className="signIn__input contact-modal__modal-input"
                                //                     type="text"
                                //                     name="firstName"
                                //                 />
                                //             </FormGroup>
                                //         </Col>

                                //         <Col md="12">
                                //             <Row className="align-items-center ">
                                //                 <Col
                                //                     // xs="10"
                                //                     className="d-flex justify-content-between align-items-center  px-0"
                                //                 >
                                //                     <Row className="align-items-center">
                                //                         <Col
                                //                             // xs="12"
                                //                             md="3"
                                //                         >
                                //                             <h6 className="fs--14 fw--400 font-poppins text-muted mx-2">
                                //                                 <strong>
                                //                                     Expiration
                                //                                 </strong>
                                //                             </h6>
                                //                         </Col>

                                //                         <Col md="4">
                                //                             {" "}
                                //                             <Input
                                //                                 type="select"
                                //                                 className="select-input py-3"
                                //                                 selected={day}
                                //                                 onChange={(
                                //                                     e
                                //                                 ) => {
                                //                                     handleDayChange(
                                //                                         e
                                //                                     );
                                //                                 }}
                                //                             >
                                //                                 <option value="1">
                                //                                     1
                                //                                 </option>
                                //                                 <option value="2">
                                //                                     2
                                //                                 </option>
                                //                                 <option value="3">
                                //                                     3
                                //                                 </option>
                                //                                 <option value="4">
                                //                                     4
                                //                                 </option>
                                //                                 <option value="5">
                                //                                     5
                                //                                 </option>
                                //                                 <option value="6">
                                //                                     6
                                //                                 </option>
                                //                                 <option value="7">
                                //                                     7
                                //                                 </option>
                                //                                 <option value="8">
                                //                                     8
                                //                                 </option>
                                //                                 <option value="9">
                                //                                     9
                                //                                 </option>
                                //                                 <option value="10">
                                //                                     10
                                //                                 </option>
                                //                                 <option value="11">
                                //                                     11
                                //                                 </option>
                                //                                 <option value="12">
                                //                                     12
                                //                                 </option>
                                //                             </Input>
                                //                         </Col>
                                //                         <Col md="1">/</Col>

                                //                         <Col md="4">
                                //                             <Input
                                //                                 type="select"
                                //                                 className="select-input py-3"
                                //                             >
                                //                                 <option
                                //                                     value="2023"
                                //                                     selected
                                //                                 >
                                //                                     2023
                                //                                 </option>
                                //                                 <option value="2024">
                                //                                     2024
                                //                                 </option>
                                //                                 <option value="2025">
                                //                                     2025
                                //                                 </option>
                                //                                 <option value="2026">
                                //                                     2026
                                //                                 </option>
                                //                                 <option value="2027">
                                //                                     2027
                                //                                 </option>
                                //                                 <option value="2028">
                                //                                     2028
                                //                                 </option>
                                //                                 <option value="2029">
                                //                                     2029
                                //                                 </option>
                                //                                 <option value="2030">
                                //                                     2030
                                //                                 </option>
                                //                                 <option value="2031">
                                //                                     2031
                                //                                 </option>
                                //                                 <option value="2032">
                                //                                     2032
                                //                                 </option>
                                //                                 <option value="2033">
                                //                                     2033
                                //                                 </option>
                                //                                 <option value="2034">
                                //                                     2034
                                //                                 </option>
                                //                             </Input>
                                //                         </Col>
                                //                     </Row>
                                //                 </Col>
                                //                 <Col
                                //                     xs="12"
                                //                     md="2"
                                //                     className="p-0"
                                //                 >
                                //                     <Input
                                //                         className="signIn__input py-4 mr-2"
                                //                         placeholder="CVC"
                                //                     />
                                //                 </Col>
                                //             </Row>
                                //         </Col>
                                //     </Row>
                                // </Form>
                            )}
                        </ElementsConsumer>
                    </Elements>
                </ModalBody>

                {/* <ModalFooter>
                    <Button className="contact-modal__Update-btn">
                        Update
                    </Button>
                    <Button color="site-lite-btn" onClick={paymentToggle}>
                        Cancel
                    </Button>
                </ModalFooter> */}
            </Modal>
        </div>
    );
};

export default UpdatePaymentInfo;
