import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const SubscriptionStatus = ({ subscriptionDetails }) => {
    const { user } = useSelector((state) => state.authUser);
    if (user?.api_plan === "test") { return; }
    const currentSubscription = subscriptionDetails?.currentSubscription;
    const subscriptionSchedule = subscriptionDetails?.subscriptionSchedule;
    // console.log("subscriptionSchedule: ", subscriptionSchedule);

    const currentPeriodEnd = moment
        .unix(currentSubscription?.current_period_end)
        .format("MMMM DD, YYYY");
    const currentPlanNickname = currentSubscription?.nickname;
    const nextPlanNickname = subscriptionSchedule?.nickname;

    if (currentSubscription?.cancel_at) {
        return (
            <>
                <h3>My Subscription Status</h3>
                <p className="py-2 mb-0">
                    Your subscription is cancelled. You have access until{" "}
                    {moment
                        .unix(user?.api_active_until)
                        .format("MMMM DD, YYYY")}
                </p>
                <Link to="/api/plans" className="link-to">
                    Reactivate your Subscription
                </Link>{" "}
            </>
        );
    }

    return (
        <>
            <div className="mt-5">
                <h5>My Subscription Status</h5>
                {subscriptionSchedule ? (
                    <>
                        <span>
                            You are currently on the {currentPlanNickname} plan, and
                            your subscription will remain active until{" "}
                            {currentPeriodEnd}. After that, your plan will
                            automatically switch to {nextPlanNickname}
                        </span>
                    </>
                ) : (
                    <>
                        <span>
                            Your subscription is scheduled to renew automatically on{" "}
                            {currentPeriodEnd}
                        </span>

                        <div className="py-3">
                            <Link
                                to="/main/cancel-membership"
                                className="link-to fw--500"
                            >
                                Cancel your Subscription
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default SubscriptionStatus;
