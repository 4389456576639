import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
const Terms = () => {
    return (
        <Container className="py-5">
            <Row className="terms ">
                <Col md="12" color="" className="legal">
                    <h1 className="terms__headline">Golfbert Terms of Use</h1>
                    <p className="terms__graytext">Last Modified: 11/6/2015</p>

                    <p>
                        These terms and conditions of use ("Terms" or
                        "Agreement") are a binding contract between Golfbert,
                        LLC ("We", "Us", or "Our") and you ("You"). You must
                        agree to these Terms before using the Golfbert Website
                        (the "Site"). By using the Site, you agree to be bound
                        by these Terms as well as the{" "}
                        <a className="text-site-primary" href="/main/privacy">
                            Privacy Policy
                        </a>{" "}
                        on the Site. If you do not agree to all these terms, you
                        are not authorized to use the Site. Golfbert, LLC
                        provides the information and services on the Site to you
                        conditioned upon your acceptance, without modification,
                        of the terms.
                    </p>

                    <p className="terms__lgtext">Notice:</p>
                    <p>
                        The information available on the Site, including,
                        without limitation, reviews, opinions, directions,
                        guides, graphics, photographs, logos, audio or video
                        clips, and data (collectively "Content") may contain
                        information relating to a hazardous activity. Golfbert,
                        LLC does not certify or endorse the Content on the Site,
                        including without limitation, any opinion,
                        recommendation, advice, or any other information
                        contained in the Content.
                    </p>

                    <p>
                        Golfbert, LLC is not responsible for harm to persons or
                        property that results from your use of the Site,
                        including without limitation, use of any content on the
                        Site.
                    </p>

                    <p className="terms-lgtext">
                        By using the Site you agree to the following:
                    </p>
                    <ol>
                        <li>
                            <strong>
                                Agreement to Deal Electronically; Electronic
                                Communications and Notices.
                            </strong>
                            <p>
                                All of your transactions with or through the
                                Site may, at our option, be conducted
                                electronically from start to finish. If we
                                decide to proceed non-electronically, those
                                services will still be governed by the remainder
                                of these Terms unless you enter into different
                                terms on a form provided by us. If the law
                                allows you to withdraw this consent or if we are
                                ever required to deal with you
                                non-electronically, we reserve the right to
                                charge or increase fees and you agree to print
                                or make an electronic copy of the Terms and any
                                other contract or disclosure that we are
                                required to provide to you.
                            </p>

                            <p>
                                Except as otherwise provided in these Terms, we
                                will give you any notices regarding the Site by
                                posting them on the Site. You also authorize
                                Golfbert, LLC to send notices (including notice
                                of subpoenas or other legal process, if any) via
                                electronic mail. You must check the Site for
                                notices, and you will be considered to have
                                received a notice when it is posted on the Site,
                                or when sent by us via electronic mail, whether
                                or not received by you. You must keep your
                                address current and any notice sent by us to an
                                email address that you have provided to us will
                                be considered effective notice.
                            </p>
                        </li>

                        <li>
                            <strong>Subscription</strong>
                            <p>
                                Many of the Website's features and services are
                                subscription-based and require initial and
                                periodic payment via debit or credit card. You
                                should review the information provided about the
                                Subscription during the registration process.
                                You agree that if you purchase a Subscription,
                                you are responsible for paying any and all
                                charges relating to your Subscription until you
                                elect not to renew the Subscription.
                                Subscriptions renew automatically for the same
                                time period as the previous Subscription period
                                at the rate then in effect. Your renewal date is
                                set forth on the "Membership" section under the
                                "Account Settings" section of the Golfbert
                                website. This means that at the end of the
                                Subscription period, we will charge you
                                automatically for the next Subscription period
                                to guarantee uninterrupted benefits. The debit
                                or credit card you designated at registration
                                will be charged no more than 24 hours prior to
                                the expiration of the then current Subscription
                                period. If you want to avoid renewing a
                                Subscription, you agree that you must cancel 72
                                hours prior to the end of the then current
                                Subscription period. If you sign up for a free
                                trial, you must cancel 24 hours prior to the end
                                of your free trial to avoid automatic charges
                                associated with beginning your Subscription.
                            </p>

                            <p>
                                All Subscription fees, including renewals, are
                                non-refundable. Subscribers must be at least 18
                                years old or have the permission of a credit
                                card holder over 18 years old.
                            </p>

                            <p>
                                In order to subscribe, you will be asked to
                                provide certain registration details and/or
                                other information. It is a condition of your use
                                of the Website that all the information you
                                provide on the Website is correct, current and
                                complete. You agree that all information you
                                provide to register with this Website or
                                otherwise, including but not limited to through
                                the use of any interactive features on the
                                Website, is governed by our
                                <a
                                    className="text-site-primary"
                                    href="/main/privacy"
                                >
                                    Privacy Policy
                                </a>{" "}
                                on the Site.and you consent to all actions we
                                take with respect to your information consistent
                                with our Privacy Policy.
                            </p>

                            <p>
                                If you choose, or are provided with, a username,
                                password or any other piece of information as
                                part of our subscription process, you must treat
                                such information as confidential, and you must
                                not disclose it to any other person or entity.
                                You also acknowledge that your account is
                                personal to you and agree not to provide any
                                other person with access to this Website or
                                portions of it using your user name, password or
                                other security information. You agree to notify
                                us immediately of any unauthorized access to or
                                use of your username or password or any other
                                breach of security. You also agree to ensure
                                that you exit from your account at the end of
                                each session. You should use particular caution
                                when accessing your account from a public or
                                shared computer so that others are not able to
                                view or record your password or other personal
                                information.
                            </p>

                            <p>
                                We have the right to disable of any username,
                                password or other identifier, whether chosen by
                                you or provided by us, at any time if, in our
                                opinion, you have violated any provision of
                                these Terms of Use. You agree that any such
                                termination of access privileges may be done
                                without prior notice and may be done without
                                refund of any subscription fees, regardless of
                                any time remaining on the subscription.
                            </p>

                            <p>
                                Additional terms and conditions may also apply
                                to specific portions, services or features of
                                the Website, including subscriptions. All such
                                additional terms and conditions are hereby
                                incorporated by this reference into these Terms
                                of Use.
                            </p>
                        </li>

                        <li>
                            <strong>Privacy.</strong>
                            <p>
                                Our <a href="/pages/privacy">Privacy Policy</a>{" "}
                                is a part of this Agreement and its terms are
                                incorporated by this reference. Please read it
                                now (by clicking on Privacy Policy at the bottom
                                of any page). The policy explains how certain
                                information about you may be used. You
                                acknowledge and agree that Golfbert, LLC may
                                collect, use, exchange, and store information
                                about you and your orders in accordance with our
                                Privacy Policy.
                            </p>
                        </li>

                        <li>
                            <strong>Unauthorized Use of Your Password.</strong>
                            <p>
                                All instructions transmitted by or received from
                                anyone presenting your password on the Site are
                                binding to you. You agree that you are solely
                                responsible for all transactions or acts that
                                are validated through use of your password,
                                whether or not made with your knowledge or
                                authority. You agree to guard your password
                                carefully, with the full awareness that a
                                failure to keep it secure will enable others to
                                engage in transactions through the Site for
                                which you will be legally responsible. If you
                                ever suspect that someone may have obtained
                                access to your password who is not intended to
                                have authority to act on your behalf, please
                                contact Golfbert, LLC immediately. You authorize
                                Golfbert, LLC to refuse to accept any further
                                orders placed under that password on your behalf
                                and Golfbert, LLC will use commercially
                                reasonable efforts to block such orders.
                            </p>
                        </li>

                        <li>
                            <strong>Changes to the Agreement.</strong>
                            <p>
                                This Site is like a store: every time a customer
                                enters a store the customer is bound by the
                                rules in effect on the date of the customer's
                                visit. Similarly, at the Site, you are bound by
                                the version of this Agreement that is in effect
                                on the date of your visit or order. The terms of
                                this Agreement may change from time to time so
                                please review them when you visit the Site. We
                                will post a notice on the home page of the Site
                                for thirty (30) days if we change the terms of
                                this Agreement and your use of the Site after
                                the effective date of any change will constitute
                                your consent to the amended Agreement. For your
                                convenience, a link to the current Terms of Use
                                appears at the bottom of every page of the Site.
                            </p>
                        </li>

                        <li>
                            <strong>
                                License to Use the Site; Termination or
                                Cancellation.
                            </strong>
                            <p>
                                License: We hereby grant you the limited,
                                revocable, non-transferable, non-sublicenseable
                                license, under the rights Golfbert, LLC has in
                                the Content, to view and use the Site solely for
                                the purpose of acquiring information in
                                accordance with the Agreement. As between you
                                and Golfbert, LLC, we retain all right, title,
                                and interest in and to the Site. Except as
                                provided in this Agreement, permission to
                                reprint or electronically reproduce any content
                                in whole or in part for any other purpose is
                                expressly prohibited. The Site and all content
                                contained therein is protected by copyright and
                                intellectual property rights under both United
                                States and foreign laws and all rights not
                                expressly granted are reserved by Golfbert, LLC,
                                its affiliates, and its partners. Subject to
                                applicable law, Golfbert, LLC reserves the right
                                to suspend or deny, in its sole discretion, your
                                access to all or any portion of the Site with or
                                without notice.
                            </p>

                            <p>
                                The license in this Section 5 does not include
                                permission to copy the design elements, "look
                                and feel" or layout of the Site. Those elements
                                are protected by law, such as trade dress,
                                trademark, unfair competition, and other laws,
                                and may not be copied or imitated in any manner.
                                Except as expressly provided in this Agreement,
                                neither Golfbert, LLC nor any third party has
                                conferred upon you by implication, estoppel, or
                                otherwise, any license or right under any
                                patent, copyright, trademark, trade secret or
                                any other proprietary right.
                            </p>

                            <p>
                                Termination: We may, at any time and without
                                notice to you, terminate your access to the Site
                                or block your access to the Site if:
                            </p>

                            <ul>
                                <li>
                                    We believe in our sole discretion that you
                                    have violated these Terms or have otherwise
                                    engaged in any activities that may harm or
                                    damage the reputation, rights, person, or
                                    property of the Site, our users, or any
                                    other person;
                                </li>
                                <li>
                                    Requested by law enforcement or other
                                    government agencies; or
                                </li>
                                <li>
                                    Your account has extended periods of
                                    inactivity.
                                </li>
                            </ul>

                            <p>
                                If applicable law requires us to provide notice
                                of termination or cancellation, we may give
                                prior or subsequent notice by posting in on the
                                Site or by sending a communication to any
                                address (email or physical) that we have for you
                                in our records. Upon termination of your
                                account, your agreement with us under these
                                Terms will also terminate, except that the
                                following provisions survive the termination:
                                Section 6 ("Submission of Comments; Prohibited
                                Content and Conduct"); Section 8 ("Your
                                Representations and Warranties"); Section 10
                                ("Indemnity"); Section 11 ("Disclaimers of
                                Warranties"); Sec. 13 ("Exclusive Remedy; Damage
                                Exclusions &amp; Limitations"); Sec. 15
                                ("Miscellaneous; Entire Agreement"); and the
                                Privacy Policy.
                            </p>
                        </li>

                        <li>
                            <strong>
                                Submission of Comments; Prohibited Content and
                                Conduct.
                            </strong>
                            <p>
                                Any comments or information that you provide to
                                Golfbert, LLC, for example, feedback or ideas in
                                response to a customer survey regarding the
                                Site, product or content reviews, suggestions,
                                ideas, concepts, or other information are
                                collectively deemed "Submissions". None of the
                                Submissions will be subject to any obligation of
                                confidence on the part of Golfbert, LLC, and
                                Golfbert, LLC will not be liable for any use or
                                disclosure (including publication in any medium)
                                of any Submissions. You hereby grant Golfbert,
                                LLC a royalty-free, perpetual, irrevocable,
                                world-wide license to use, copy, reproduce,
                                create derivative works from, adapt, modify,
                                publish, edit, translate, sell, distribute,
                                transmit, transfer, publicly display, publicly
                                perform, and display the Submissions without any
                                limitation and in any media or any form now
                                known or later developed. Without limiting the
                                foregoing, Golfbert, LLC will be entitled to
                                unrestricted use of the Submissions for any
                                purpose, commercial or otherwise, without
                                compensation to the provider of the Submissions.
                                Further, Golfbert, LLC may sublicense these
                                rights to third parties.
                            </p>

                            <p>
                                Under no circumstance is Golfbert, LLC
                                responsible for any Submission to the Site or
                                for the content of that Submission, nor shall
                                Golfbert, LLC be held liable for any of the
                                claims made therein. We are not responsible for
                                the content or accuracy of any of these
                                Submissions, and the views and opinions they
                                express are solely those of the original
                                contributor.
                            </p>
                        </li>

                        <li>
                            <strong>Advertising on Golfbert, LLC:</strong>
                            <p>
                                If you are retailing other golf-related products
                                or services and wish to advertise those products
                                or services elsewhere on our Site, outside of
                                our forums, please visit our Advertising page.
                                All other Submissions or user-generated content
                                or posts to the Site, whether through the
                                forums, reviews, or any method or on any other
                                page of the Site must be free of advertising or
                                spamming. We reserve the right to edit or remove
                                any Submissions that violate these policies, or
                                to terminate your membership or right to access
                                the Site for such violations.
                            </p>
                        </li>

                        <li>
                            <strong>Moderation &amp; Posting Rules:</strong>
                            <p>
                                All commenting, reviewing and rating posts must
                                be in English. No other languages are permitted.
                            </p>

                            <p>
                                Although messages posted are not the
                                responsibility of Golfbert, LLC and we are not
                                responsible for the content or accuracy of any
                                of these messages, we reserve the right for
                                ourselves and our agents to edit or delete any
                                message for any or no reason whatsoever.
                                Generally we only moderate comments, reviews,
                                messages, Submissions, photos, videos, or posts
                                that are in breach of our rules or policies or
                                threads that have not been accessed for some
                                time. If you do find any reviews, messages,
                                Submissions, photos, videos, or posts are
                                objectionable then please contact us with a link
                                to the item in question. We are particularly
                                concerned with hate language and we will not
                                hesitate to ban frequent or extreme offenders
                                breaching any of our rules or policies.
                            </p>

                            <p>
                                You agree to refrain from swearing, profanity,
                                foul language, discourteous behavior, racial,
                                national or sexual slurs or other inappropriate
                                or illegal behavior on the Site. No links to
                                inappropriate content please.
                            </p>
                            <p>
                                You agree not to use the Site to post any
                                material which is illegal or that may generally
                                be deemed to be vulgar, defamatory, inaccurate,
                                harassing, hateful, threatening, invading of
                                others privacy, sexually oriented or violates
                                any laws. Our reference to swearing includes
                                acronyms and variations and misspellings. We
                                understand that sometimes posters will wish to
                                vehemently disagree with someone else's post.
                                This is fine but personal attacks or responses
                                resorting to vulgar language are not permitted.
                                Should a moderator or Site administrator
                                intercede in a thread due to it getting out of
                                hand, he she may optionally close the thread or
                                request that members make no more than one more
                                post on the subject (closing arguments.) You may
                                not continue such "flame" topics by starting a
                                new thread or post more than the one time as
                                directed but you may edit your existing posts as
                                desired should you have the ability to do so.
                            </p>
                        </li>

                        <li>
                            <strong>Intellectual Property Rights</strong>
                            <p>
                                The Website and its entire contents, features
                                and functionality (including but not limited to
                                all information, software, text, displays,
                                images, video and audio, and the design,
                                selection and arrangement thereof), are owned by
                                the Company, its licensors or other providers of
                                such material and are protected by United States
                                and international copyright, trademark, patent,
                                trade secret and other intellectual property or
                                proprietary rights laws.
                            </p>

                            <p>
                                These Terms of Use permit you to use the Website
                                for your personal, non-commercial use only. You
                                must not reproduce, distribute, modify, create
                                derivative works of, publicly display, publicly
                                perform, republish, download, store or transmit
                                any of the material on our Website, except as
                                follows:
                            </p>
                            <ul>
                                <li>
                                    your computer may temporarily store copies
                                    of such materials in RAM incidental to your
                                    accessing and viewing those materials;
                                </li>
                                <li>
                                    you may store files that are automatically
                                    cached by your Web browser for display
                                    enhancement purposes;
                                </li>
                                <li>
                                    you may print or download one copy of a
                                    reasonable number of pages of the Website
                                    for your own personal, non-commercial use
                                    and not for further reproduction,
                                    publication or distribution;
                                </li>
                                <li>
                                    if we provide desktop, mobile or other
                                    applications for download, you may download
                                    a single copy to your computer or mobile
                                    device solely for your own personal,
                                    non-commercial use, provided you agree to be
                                    bound by our end user license agreement for
                                    such applications; and
                                </li>
                                <li>
                                    if we provide social media features with
                                    certain content, you make take such actions
                                    as are enabled by such features.
                                </li>
                            </ul>
                            <p></p>

                            <p>You must not:</p>
                            <ul>
                                <li>
                                    modify copies of any materials from this
                                    site; or
                                </li>
                                <li>
                                    delete or alter any copyright, trademark or
                                    other proprietary rights notices from copies
                                    of materials from this site.
                                </li>
                            </ul>
                            <p></p>

                            <p>
                                You must not access or use for any commercial
                                purposes any part of the Website or any services
                                or materials available through the Website.
                            </p>

                            <p>
                                If you wish to make any use of material on the
                                Website other than that set out in this section,
                                please contact customer support at{" "}
                                <a href="mailto:info@golfbert.com">
                                    info@golfbert.com
                                </a>
                                .
                            </p>

                            <p>
                                If you print, copy, modify, download or
                                otherwise use or provide any other person with
                                access to any part of the Website in breach of
                                the Terms of Use, your right to use the Website
                                will cease immediately and you must, at our
                                option, return or destroy any copies of the
                                materials you have made. No right, title or
                                interest in or to the Website or any content on
                                the site is transferred to you, and all rights
                                not expressly granted are reserved by the
                                Company. Any use of the Website not expressly
                                permitted by these Terms of Use is a breach of
                                these Terms of Use and may violate copyright,
                                trademark and other laws.
                            </p>
                        </li>

                        <li>
                            <strong>Prohibited Content:</strong>
                            <p>
                                You must not post to the Site or provide any
                                Submissions that, as reasonably determined by
                                us, is or appears to be the following:
                            </p>
                            <ul>
                                <li>
                                    untrue, misleading, harmful, threatening,
                                    abusive, harassing, defamatory, vulgar,
                                    obscene, invasive to another person's
                                    privacy or protected data, hateful, or
                                    racially or otherwise objectionable;
                                </li>
                                <li>
                                    infringing upon a third party's intellectual
                                    property rights, including any patent,
                                    trademark, trade secret, copyright, right of
                                    publicity, including any content that is the
                                    subject of any claim of infringement;
                                </li>
                                <li>
                                    of a type that you do not have a right to
                                    transmit under any law or under contractual
                                    or fiduciary relationships, such as
                                    proprietary and confidential information;
                                </li>
                                <li>
                                    unsolicited, undisclosed or unauthorized
                                    advertising;
                                </li>
                                <li>
                                    software viruses or any other computer code,
                                    files, or programs designed to interrupt,
                                    destroy, or limit the functionality of any
                                    computer software or hardware or
                                    telecommunications equipment;
                                </li>
                                <li>
                                    data or information obtained through access
                                    that was not authorized by the owner, or
                                    that you are not authorized to post; or
                                </li>
                                <li>
                                    in violation of any applicable local, state,
                                    national or international law (including
                                    export laws).
                                </li>
                            </ul>
                            <p></p>
                        </li>

                        <li>
                            <strong>Prohibited Conduct:</strong>
                            <p>
                                You must not do, or attempt to do, any of the
                                following, as reasonably determined by us,
                                subject to applicable law:
                            </p>
                            <ul>
                                <li>
                                    access or use the Site in any way that is
                                    not in compliance with any applicable local,
                                    state, national or international law
                                    (including export laws), contracts,
                                    intellectual property rights or constitutes
                                    the commission of a tort, or for any purpose
                                    that is harmful or unintended (by us), or
                                    other than in full compliance with these
                                    Terms;
                                </li>
                                <li>
                                    access, tamper with, or use services or
                                    areas of the Site that you are not
                                    authorized to access;
                                </li>
                                <li>
                                    alter information on or obtained from the
                                    Site;
                                </li>
                                <li>
                                    tamper with postings, registration
                                    information, profiles, submissions or
                                    content belonging to the Site or other users
                                    of the Site;
                                </li>
                                <li>
                                    use any robot, spider, scraper or other
                                    automated means or interface not provided by
                                    us to access the Site or extract data or
                                    gather or use information, such as email
                                    addresses, available from the Site or
                                    transmit any unsolicited advertising, "junk
                                    mail," "spam," or "chain letters";
                                </li>
                                <li>
                                    frame any part of the Site, or link to the
                                    Site, or otherwise make it look like you
                                    have a relationship to us or that we have
                                    endorsed you or your content for any purpose
                                    except as expressly permitted in writing by
                                    us;
                                </li>
                                <li>
                                    impersonate or misrepresent your affiliation
                                    with any person or entity;
                                </li>
                                <li>
                                    reverse engineer any licensed software,
                                    application, games or any other aspect of
                                    the Site or do anything that might discover
                                    source code, or bypass or circumvent
                                    measures employed to prevent or limit access
                                    to any area, content or code of the Site;
                                </li>
                                <li>
                                    send to or otherwise impact us or the Site
                                    (or anything or anyone else) with harmful,
                                    illegal, deceptive or disruptive code such
                                    as a virus, "spyware," "adware" or other
                                    code that could adversely impact the Site or
                                    any recipient; or
                                </li>
                                <li>
                                    take any action which might impose a
                                    significant burden (as determined by us) on
                                    the Site's infrastructure or computer
                                    systems, or otherwise interfere with the
                                    ordinary operation of the Site.
                                </li>
                            </ul>
                            <p></p>

                            <p>
                                IF YOU SEND UNSOLICITED COMMERCIAL EMAIL OR
                                ADVERTISING, BULK EMAIL, SPAM, OR CHAIN LETTERS
                                (COLLECTIVELY, "UNSOLICITED EMAIL OR OTHER
                                COMMUNICATION") THROUGH THE SITE, YOU
                                ACKNOWLEDGE THAT YOU WILL HAVE CAUSED
                                SUBSTANTIAL HARM TO THE SITE, BUT THAT THE
                                AMOUNT OF THE HARM WOULD BE EXTREMELY DIFFICULT
                                TO ASCERTAIN. AS A REASONABLE ESTIMATION OF SUCH
                                HARM, YOU WILL PAY US $50 FOR EACH SUCH
                                UNSOLICITED EMAIL OR OTHER COMMUNICATION.
                            </p>
                        </li>

                        <li>
                            <strong>
                                Copyright Infringement Notification &amp; Other
                                Reports.
                            </strong>
                            <p>
                                Golfbert, LLC relies on users of the Site to
                                bring copyright violations to our attention.
                                Pursuant to Title 17, United States Code,
                                Section 512(c)(2), notifications of claimed
                                copyright infringement should be sent to our
                                Designated Agent. If you are aware of infringing
                                materials on the Site, please notify Golfbert,
                                LLC agent:
                            </p>
                            <ul>
                                <li>Golfbert, LLC</li>
                                <li>Attn: Legal Dept./Copyright Agent</li>

                                <li>5555 Hill Road</li>
                                <li>Nashville, TN 37027</li>
                            </ul>
                            <p></p>

                            <p>
                                To be effective, the Notification must include
                                the following:
                            </p>
                            <ul>
                                <li>
                                    A physical or electronic signature of a
                                    person authorized to act on behalf of the
                                    owner of an exclusive right that is
                                    allegedly infringed;
                                </li>
                                <li>
                                    Identification of the copyrighted work
                                    claimed to have been infringed, or if
                                    multiple copyrighted works at a single
                                    online Site are covered by a single
                                    notification, a representative list of such
                                    works at that Site;
                                </li>
                                <li>
                                    Identification of the material that is
                                    claimed to be infringing or to be the
                                    subject of infringing activity and that is
                                    to be removed or access to which is to be
                                    disabled, and information reasonably
                                    sufficient to permit Golfbert, LLC to locate
                                    the material;
                                </li>
                                <li>
                                    Information reasonably sufficient to permit
                                    Golfbert, LLC to contact you, such as an
                                    address, telephone number, and if available,
                                    an electronic mail address at which the you
                                    may be contacted;
                                </li>
                                <li>
                                    A statement that you have a good faith
                                    belief that use of the material in the
                                    manner complained about is not authorized by
                                    the copyright owner, its agent, or the law;
                                    and a statement that the information in the
                                    notification is accurate, and under penalty
                                    of perjury, that you are authorized to act
                                    on behalf of the owner of an exclusive right
                                    that is allegedly infringed.
                                </li>
                            </ul>
                            <p></p>

                            <p>
                                All inquiries not in compliance with the above
                                notice and procedure for making claims of
                                copyright infringement will receive no response.
                            </p>

                            <p>
                                Questions and comments about the Site (other
                                than claims of copyright infringement) should be
                                directed to our Contact Information page.
                            </p>
                        </li>

                        <li>
                            <strong>
                                Your Representations and Warranties.
                            </strong>
                            <p>
                                You represent and warrant for the benefit of
                                Golfbert, LLC and Golfbert, LLC's licensors and
                                suppliers that:
                            </p>
                            <ul>
                                <li>you are at least 13 years of age;</li>
                                <li>
                                    you possess the legal right and ability to
                                    enter into this Agreement and make the
                                    credit card charge on your own behalf or on
                                    behalf of any person for whom you are acting
                                    as agent and/or that you are authorized to
                                    use the password required for the Site;
                                </li>
                                <li>
                                    all information that you submit to us is
                                    true, accurate, and current and that you own
                                    all rights in your Submissions or,
                                    alternatively, you have sufficient rights in
                                    your Submissions to grant us the rights
                                    described in these Terms;
                                </li>
                                <li>
                                    you will pay all license fees, clearance
                                    fees, and other financial obligations of any
                                    kind, arising from any use of your
                                    Submissions;
                                </li>
                                <li>
                                    your Submissions are not defamatory, do not
                                    infringe the intellectual property rights,
                                    privacy, rights to publicity or any other
                                    legal or moral rights of any third party;
                                </li>
                                <li>
                                    you will keep your registration information
                                    current;
                                </li>
                                <li>
                                    you will be responsible for all use of your
                                    password even if such use was conducted
                                    without your authority or permission; and
                                </li>
                                <li>
                                    you will not use the Site for any purpose
                                    that is unlawful or prohibited by this
                                    Agreement.
                                </li>
                            </ul>
                            <p></p>
                        </li>

                        <li>
                            <strong>Promotions.</strong>
                            <p>
                                No promotion is offered by Golfbert, LLC unless
                                the promotion explicitly so states. You agree
                                that offers from other companies on the Site are
                                solely liable for their promotions and that
                                Golfbert, LLC is not liable. You also agree to
                                comply with all terms of a promotion. Note: some
                                promotions will be subject to quantity and other
                                restrictions, including without limitation, time
                                limits that could expire during your visit to
                                the Site. Golfbert, LLC reserves the right to
                                withdraw or refuse to honor any promotion that
                                has been altered or modified other than by
                                Golfbert, LLC.
                            </p>
                        </li>

                        <li>
                            <strong>Indemnity.</strong>
                            <p>
                                You agree to defend, indemnify and hold harmless
                                Golfbert, LLC, and their subsidiaries,
                                affiliates, officers, directors, employees,
                                agents, licensors, and suppliers, from and
                                against all third party claims, actions or
                                demands, liabilities, and settlements,
                                including, without limitation, reasonable legal
                                and accounting fees, arising in connection with
                                your use of the Site or resulting from, or
                                alleged to result from, your use of the Site or
                                your violation of this Agreement.
                            </p>
                        </li>

                        <li>
                            <strong>DISCLAIMERS OF WARRANTIES.</strong>
                            <p>
                                GOLFBERT, LLC PROVIDES THE SITE "AS IS" AND "AS
                                AVAILABLE". TO THE MAXIMUM EXTENT PERMITTED BY
                                LAW, GOLFBERT, LLC MAKES NO REPRESENTATIONS,
                                WARRANTIES OR CONDITIONS OF ANY KIND, EXPRESS OR
                                IMPLIED, AS TO THE OPERATION OF THE SITE OR THE
                                INFORMATION, GAMES, SOFTWARE, OR CONTENT
                                INCLUDED IN THE SITE. GOLFBERT, LLC MAKES NO
                                REPRESENTATIONS OR WARRANTIES THAT THE SITE WILL
                                BE UNINTERRUPTED, ERROR-FREE, VIRUS-FREE,
                                SECURE, OR TIMELY. TO THE MAXIMUM EXTENT
                                PERMITTED BY LAW, GOLFBERT, LLC EXPRESSLY
                                DISCLAIMS ALL REPRESENTATIONS, WARRANTIES OR
                                CONDITIONS OF ANY KIND, WHETHER EXPRESS OR
                                IMPLIED, INCLUDING THE IMPLIED REPRESENTATIONS,
                                WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                                FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                                NON-INFRINGEMENT AND THOSE ARISING FROM A COURSE
                                OF DEALING, TRADE, USAGE OR PERFORMANCE. SOME
                                JURISDICTIONS DO NOT ALLOW LIMITATIONS ON
                                IMPLIED WARRANTIES, AND THEREFORE SOME OF THE
                                ABOVE LIMITATIONS MAY NOT APPLY TO YOU. WE URGE
                                YOU TO KEEP BACKUP COPIES OF YOUR PERSONAL
                                CONTENT, IF ANY, THAT YOU MAINTAIN ON OR USE
                                WITH THE SITE. IF YOUR USE OF THE SITE RESULTS
                                IN THE NEED FOR SERVICING OR REPLACING PROPERTY,
                                MATERIAL, EQUIPMENT OR DATA, WE ARE NOT
                                RESPONSIBLE FOR ANY RESULTING COSTS OR DAMAGES.
                            </p>
                        </li>

                        <li>
                            <strong>Links to Third Party Sites.</strong>
                            <p>
                                As a convenience, we may provide links to
                                third-party websites from the Site. Golfbert,
                                LLC is not responsible for and does not endorse
                                the informational content or any products or
                                services available on any third-party web Site
                                and does not make any representations regarding
                                its content or accuracy. We are not liable for
                                any technological, legal or other consequences
                                that arise out of your visit or transactions on
                                any third-party or non-Golfbert, LLC web Sites.
                                Your use of third-party websites is at your own
                                risk and subject to the terms and conditions of
                                use for such Sites. This means that we are not
                                your agent and will not be a party to any
                                contract you enter.
                            </p>
                        </li>

                        <li>
                            <strong>
                                Exclusive Remedy; Damage Exclusions &amp;
                                Limitations.
                            </strong>
                            <p>
                                TO THE MAXIMUM EXTENT PERMITTED BY LAW, NONE OF
                                THE INDEMNIFIED PERSONS ARE LIABLE TO YOU OR ANY
                                OTHER PERSON FOR INDIRECT, INCIDENTAL, PUNITIVE,
                                EXEMPLARY, SPECIAL, STATUTORY, OR CONSEQUENTIAL
                                DAMAGES, LOST PROFITS, LOST REVENUE, LOSS OF
                                DATA, LOSS OF PRIVACY, LOSS OF GOODWILL OR ANY
                                OTHER LOSSES, EVEN IF ADVISED OF THE POSSIBILITY
                                OF SUCH DAMAGES AND EVEN IN THE EVENT OF FAULT,
                                TORT (INCLUDING NEGLIGENCE) OR STRICT OR PRODUCT
                                LIABILITY. WITHOUT LIMITING THE FOREGOING, IN NO
                                EVENT WILL THE AGGREGATE LIABILITY TO YOU OF THE
                                INDEMNIFIED PERSONS EXCEED, IN TOTAL, THE
                                AMOUNTS PAID BY YOU TO US.
                            </p>
                        </li>

                        <li>
                            <strong>
                                Tennessee State Law and Tennessee (Davidson
                                County) Forum; One-Year Statute of Limitations.
                            </strong>
                            <p>
                                This Agreement is governed by the laws of the
                                State of Tennessee U.S.A. Your consent to this
                                Agreement will include your consent to such law
                                and the jurisdiction of and venue in the courts
                                in Davidson County, Tennessee, U.S.A. in all
                                disputes arising out of or relating to this
                                Agreement. Use of the Site is not authorized in
                                any jurisdiction that does not give effect to
                                all provisions of this Agreement. Golfbert,
                                LLC's performance of this Agreement (including
                                the Privacy Policy) is subject to existing laws
                                and legal process, and you agree that Golfbert,
                                LLC may comply with law enforcement or
                                regulatory requests or requirements
                                notwithstanding any contrary term of this
                                Agreement. Any cause of action or claim you may
                                bring in connection with the Site, including
                                without limitation any Content, must be
                                commenced within one (1) year after the claim or
                                cause of action arises or such claim or cause of
                                action is barred. In any dispute between
                                Golfbert, LLC and you relating to the Site, the
                                prevailing party will be entitled to attorneys'
                                fees, costs and expenses.
                            </p>
                        </li>

                        <li>
                            <strong>Miscellaneous; Entire Agreement.</strong>
                            <p>
                                <strong>Assignment</strong>
                            </p>
                            <p>
                                These Terms are personal to you and you may not
                                transfer, assign or delegate them to anyone
                                without the express written permission of
                                Golfbert, LLC. Any attempt by you to assign,
                                transfer or delegate these Terms without the
                                express written permission of Golfbert, LLC will
                                be null and void. Golfbert, LLC has the right to
                                transfer, assign and delegate these Terms to one
                                or more third parties without your permission.
                            </p>
                            <p>
                                <strong>Entire Agreement</strong>
                            </p>
                            <p>
                                If any part of this Agreement is determined to
                                be invalid or unenforceable, then the invalid or
                                unenforceable provision will be deemed
                                superseded by a valid, enforceable provision
                                that most closely matches the intent of the
                                original provision, and the remainder of the
                                Agreement will continue in effect. The Agreement
                                (including any related consents or agreements
                                that you provide during your visit to the Site)
                                constitutes the entire agreement between you and
                                Golfbert, LLC with respect to the Site and that
                                item and supersedes all other (prior or
                                contemporaneous) communications and proposals,
                                whether electronic, oral or written, between you
                                and Golfbert, LLC regarding the Site and/or any
                                order you place through it.
                            </p>
                            <p>
                                <strong>No Waiver</strong>
                            </p>
                            <p>
                                The failure of any party to exercise or enforce
                                any right or provision of these Terms, including
                                any failure to act with respect to a breach,
                                will not constitute a waiver of such right or
                                provision or that party's right to act with
                                respect to subsequent or similar breaches. We
                                suggest that you print out a copy of these Terms
                                for your records.
                            </p>
                        </li>

                        <li>
                            <strong>Further Information.</strong>
                            <p>
                                If you have a complaint, you may contact us at
                                Golfbert Legal Department, 5555 Hill Road,
                                Brentwood, TN 37027, U.S.A.
                            </p>
                        </li>

                        <li>
                            <strong>Legal Notices.</strong>
                            <p>
                                Notice of Availability of Filtering Software:
                                All users are hereby informed by the provider of
                                this interactive computer service that parental
                                control protections (such as computer hardware,
                                software, or filtering services) are
                                commercially available that may assist in
                                limiting access to material that is harmful to
                                minors. A report detailing some of those
                                protections can be found at (Children's Internet
                                Protection Act: Report on the Effectiveness of
                                Internet Protection Measures and Safety
                                Policies).
                            </p>

                            <p>
                                Notice of No Harvesting or Dictionary Attacks
                                Allowed: YOU MAY VIOLATE FEDERAL LAW IF YOU: (i)
                                INITIATE THE TRANSMISSION TO GOLFBERT COMPUTERS
                                OR DEVICES OF A COMMERCIAL ELECTRONIC MAIL
                                MESSAGE (AS DEFINED IN THE U.S. "CAN-SPAM ACT OF
                                2003") THAT DOES NOT MEET THE MESSAGE
                                TRANSMISSION REQUIREMENTS OF THAT ACT; OR (ii)
                                ASSIST IN THE ORIGINATION OF SUCH MESSAGES
                                THROUGH THE PROVISION OR SELECTION OF ADDRESSES
                                TO WHICH THE MESSAGES WILL BE TRANSMITTED.
                            </p>

                            <p>
                                Notice Regarding Trademarks: The trademarks
                                Golfbert and all other trademarks listed below
                                or used in the Service are owned or used under
                                license by Golfbert, LLC and its affiliated
                                organizations. The names of third parties and
                                their products mentioned may be their
                                trademarks. You may not use any of the above or
                                other trademarks displayed on the Site or in any
                                Site content. All rights are reserved.
                            </p>
                        </li>

                        <li>
                            <strong>Trademarks</strong>
                            <p>
                                Golfbert is a trademark or registered trademark
                                of Golfbert, LLC. in the United States and other
                                countries. The Company name, the terms
                                "Golfbert," "CaddyTracker" and "Golfbert.com," ,
                                "CaddyTracker.com," and all related names,
                                logos, product and service names, designs and
                                slogans are trademarks of the Company or its
                                affiliates or licensors. You must not use such
                                marks without the prior written permission of
                                the Company. All other names, logos, product and
                                service names, designs and slogans on this
                                Website are the trademarks of their respective
                                owners.{" "}
                            </p>
                        </li>
                    </ol>
                </Col>
            </Row>
        </Container>
    );
};
export default Terms;
