import React from "react";

const RateLimit = () => {
    return (
        <div>
            <h2 className="fs--20 fw--500 font-poppins">Rate Limits</h2>
            <p className="fs--16 fw--400 font-poppins text-muted">
                Each account comes with rate limits (both daily and burst).
                Those are communicated when accounts are created and depend on
                tier of service purchased.
            </p>
        </div>
    );
};

export default RateLimit;
