import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import ApiNavbar from "../../components/global/ApiNavbar";
const Timeline = () => {
    return (
        <>
            {/* <ApiNavbar /> */}
            <div className="timeline">
                <div className="timeline__first-sec">
                    <Container>
                        <Row>
                            <Col md="12" className="text-center">
                                <h3>Timeline</h3>
                                <p>
                                    We are dedicated to sourcing the best
                                    geodata for every golf course in the world.
                                    As this is a massive task, we have
                                    prioritized the most popular golf courses in
                                    the United States first, followed by
                                    localized courses in the US and courses in
                                    other countries.
                                </p>
                                <p>
                                    We add approximately <strong>200 new golf courses</strong> per
                                    month. Below is an outline of where we've
                                    been and how we arrived here.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container>
                    <Row className="design-section">
                        <span className=" design-section__mid-year text-center  text-muted">
                            2024
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content" style={{ lineHeight: "1.2" }}>
                                <p>12,500 golf courses mapped.</p>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p className="text-right">
                                    <Link
                                        to=""
                                        className="design-section__timeline-link"
                                    >
                                        {" "}
                                        Golf course mapping
                                    </Link>{" "}
                                    continues weekly!
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content" style={{ lineHeight: "1.2" }}>
                                <p>Website redeveloped to focus on features for API customers. Sadly, golfer tools platform is terminated.</p>
                            </div>
                            <div className="timeline-component timeline-content text-lg-right">
                                <p className="text-right">
                                    Mapping volume increased to 200 courses per month.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2023
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content  text-lg-right">
                                <p className="text-right">
                                    Database closes in on most 18-hole courses.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    Mapping volume increased to 100 courses per month.
                                    </p>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p className="text-right">
                                    Golfbert Mapping Team increased again to keep up with.
                                demand.{" "}
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2020
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content">
                                <p className="text-right">
                                    Golf course mapping continues weekly!
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2019
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end">
                                <p className="text-right">
                                    8,000 golf courses mapped.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    Golfbert Mapping Team increased to keep up with
                                demand.
                                </p>
                            </div>
                        </div>

                        <span className=" design-section__mid-year text-center text-muted">
                            2018
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content">
                                <p className="text-right">
                                    Single course API released.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2017
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content">
                                <p className="text-right">
                                    API V1 released to all customers at
                                    <Link
                                        to="/api/overview"
                                        className="design-section__timeline-link"
                                    >
                                        {" "}
                                        golfbert.com/api.
                                    </Link>
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    Search API released.
                                </p>
                            </div>
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end">
                                <p className="text-right">
                                    API V1 released to BETA customers.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    API documentation and guides released at
                                    <Link
                                        to="/api/documentations"
                                        className="design-section__timeline-link"
                                    >
                                        {" "}
                                        golfbert.com/api/docs.
                                    </Link>
                                </p>
                            </div>
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end  text-lg-right ">
                                <p className="text-right">
                                    API production begins. Allows other golf
                                    developers access to Golfbert's golf course
                                    geodata and course information.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2016
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content">
                                <p className="text-right">
                                    7,000 golf courses mapped.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>
                        </div>

                        <span className=" design-section__mid-year text-center text-muted">
                            2015
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end">
                                <p className="text-right">
                                    Golfer tools interface redesigned at
                                    <Link
                                        to="/landing"
                                        className="design-section__timeline-link"
                                    >
                                        {" "}
                                        golfbert.com.
                                    </Link>
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    Responsive site design established at
                                    <Link
                                        to="/landing"
                                        className="design-section__timeline-link"
                                    >
                                        {" "}
                                        golfbert.com.
                                    </Link>
                                    to enhance mobile experience.
                                </p>
                            </div>
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            {/* <div className="timeline-circle"></div> */}
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content  text-lg-right">
                                <p className="text-right">
                                    Golf course database redesigned at
                                    <Link
                                        to="/landing"
                                        className="design-section__timeline-link"
                                    >
                                        golfbert.com.
                                    </Link>
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    In-app golfer stat tracking added to
                                    CaddyTracker for iOS.
                                </p>
                            </div>
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2014
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            {/* <div className="timeline-circle"></div> */}
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end">
                                <p className="text-right">
                                    QuickPlay added to CaddyTracker for iOS.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    CaddyTracker for iOS V3 launches.
                                </p>
                            </div>

                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end">
                                <p className="text-right">
                                    5,000 golf courses mapped.{" "}
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2013
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            {/* <div className="timeline-circle"></div> */}
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content">
                                <p className="text-right">
                                    Interactive stats charts added to
                                    <Link
                                        to=""
                                        className="design-section__timeline-link"
                                    >
                                        golfer tools.{" "}
                                    </Link>
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    3,500 golf courses mapped.
                                </p>
                            </div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2012
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            {/* <div className="timeline-circle"></div> */}
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end">
                                <p className="text-right">
                                    CaddyTracker for iOS V2 launches.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    2,500 golf courses mapped.
                                </p>
                            </div>

                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content">
                                <p className="text-right">
                                    Private groups added to{" "}
                                    <Link
                                        to=""
                                        className="design-section__timeline-link"
                                    >
                                        golfer tools.
                                    </Link>
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2011
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            {/* <div className="timeline-circle"></div> */}
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end">
                                <p className="text-right">
                                    1,000 golf courses mapped.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    AppSync added to{" "}
                                    <Link
                                        to=""
                                        className="design-section__timeline-link"
                                    >
                                        golfer tools.{" "}
                                    </Link>{" "}
                                    Allows automatic syncing of golfer rounds from
                                    iOS app with profiles at
                                    <Link
                                        to=""
                                        className="design-section__timeline-link"
                                    >
                                        golfbert.com.{" "}
                                    </Link>
                                </p>
                            </div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2010
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end">
                                <p className="text-right">
                                    CaddyTracker for iOS V1 launches.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    Caddytracker GPS golfer app for iOS production
                                begins.
                                </p>
                            </div>
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            {/* <div className="timeline-circle"></div> */}
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end">
                                <p className="text-right">
                                    First 100 golf courses mapped.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    Golf course hole maps added to
                                    <Link
                                        to=""
                                        className="design-section__timeline-link"
                                    >
                                        {" "}
                                        golfbert.com
                                    </Link>
                                    database.
                                </p>
                            </div>
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                        </div>

                        <span className=" design-section__mid-year text-center  text-muted">
                            2009
                        </span>
                        <div className="design-section__timeline-sec">
                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            {/* <div className="timeline-circle"></div> */}
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content d-flex justify-content-end">
                                <p className="text-right">
                                    Golf course mapping begins.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>

                            <div className="timeline-empty"></div>

                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-component timeline-content">
                                <p>
                                    Golf course mapping engine established.
                                </p>
                            </div>

                            <div className="timeline-empty"></div>
                            <div className="timeline-middle"></div>
                            <div className="timeline-component timeline-content"></div>
                            <div className="timeline-component timeline-content text-lg-right">
                                <p className="text-right">
                                    Golf course database V1 launched at
                                    <Link
                                        to=""
                                        className="design-section__timeline-link"
                                    >
                                        golfbert.com.{" "}
                                    </Link>{" "}
                                    Basic course information on 15,000+ golf courses
                                    in the United States.
                                </p>
                            </div>
                            <div className="timeline-middle">
                                <div className="timeline-circle"></div>
                            </div>
                            <div className="timeline-empty"></div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Timeline;
