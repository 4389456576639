import AuthRepository from "./AuthRepository";
import UserRepository from "./UserRepository";
import SubscriptionRepository from "./SubscriptionRepository";
import StateAndCityRepository from "./StateAndCityRepository";
import GolfbertRepository from "./GolfbertRepository";

const repositories = {
    user: UserRepository,
    auth: AuthRepository,
    subscribe: SubscriptionRepository,
    statesCities: StateAndCityRepository,
    golfbert: GolfbertRepository,
};

export const RepositoryFactory = {
    get: (name) => repositories[name],
};
