import { toast } from "react-toastify";
import { RepositoryFactory } from "../../Repository/RepositoryFactory";
import { authLoader, registerLoader } from "./authAction";
import { updateContact } from "./UserActions";
import { Try } from "@mui/icons-material";
import { COUPON_LOADER } from "../types";
import { findAllByAltText } from "@testing-library/react";

let Subscribe = RepositoryFactory.get("subscribe");
export const getFetchCustomer =
    (stripe_customer_id, onSuccess) => async (dispatch) => {
        try {
            let { data } = await Subscribe.fetchCustomer(stripe_customer_id);

            if (data?.success) {
                onSuccess(data?.data?.data.at(-1));
            } else {
                toast.error(data?.message);
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error?.data?.message ||
                    error?.message ||
                    "Something Went Wrong"
            );
            console.log("error: ", error);
        }
    };

// export const subscribe = (payload, onSuccess) => async (dispatch) => {
//     try {
//         const res = await Subscribe.subscribePlan(payload);
//     } catch (error) {
//         console.log("error: ", error);
//     }
// };

export const updatePlan =
    (payload, subscriptionPayload, customerId, onSuccess) =>
    async (dispatch) => {
        // dispatch(authLoader(true));

        try {
            if (dispatch(updateContact(payload))) {
                // subscribe(subscriptionPayload);

                const res = await Subscribe.subscribePlan(subscriptionPayload);
                await dispatch(updateContact({}));
                onSuccess();
            }
        } catch (error) {
            // dispatch(authLoader(false));
            dispatch(registerLoader(false));
            toast.error(
                error?.response?.data?.message ||
                    error?.data?.message ||
                    error.message ||
                    "Something Went Wrong"
            );
            console.error(error.message);
        }
    };

export const cancelSubscription =
    (user_id, onSuccess) => async (dispatch) => {
        dispatch(authLoader(true));
        try {
            await Subscribe.cancelSubscribePlan(user_id).then(
                async (res) => {
                    if (res.data.success) {
                        toast.success(
                            "Subscription Cancelled Successfully"
                        );
                        await dispatch(updateContact({}));
                        onSuccess();
                    } else {
                        toast.error(res.data.message);
                    }
                }
            );
        } catch (error) {
            console.log("error: ", error);
            toast.error(
                error?.response?.data?.message ||
                    error?.data?.message ||
                    error.message ||
                    "Something Went Wrong"
            );
            // toast.error("Token Expired. Please login again..");
            console.error("Error:", error.message);
        } finally {
            dispatch(authLoader(false));
        }
    };

// Remove this since it is never used?
// export const getSubscription =
//     (stripe_customer_id, onSuccess) => async (dispatch) => {
//         try {
//             const subscriptionDetailsResponse =
//                 await Subscribe.subscribeDetails(stripe_customer_id);
//             const subscriptionDetailsData = subscriptionDetailsResponse.data;
//             if (subscriptionDetailsData.success) {
//                 const subscription = subscriptionDetailsData.data[0];
//                 onSuccess(subscription);
//             } else {
//                 toast.error(subscriptionDetailsData.message);
//             }
//         } catch (error) {
//             toast.error(
//                 error?.response?.data?.message ||
//                     error?.data?.message ||
//                     error.message ||
//                     "Something Went Wrong"
//             );
//             console.log("error: ", error);
//         }
//     };

export const checkCoupon = (coupon, onSuccess) => async (dispatch) => {
    dispatch({ type: COUPON_LOADER, payload: true });
    try {
        await Subscribe.validateCoupon(coupon)
            .then((res) => {
                onSuccess(res.response.data.success);
            })
            .catch((res) => {
                toast.error(res.response.data.message);
            });
    } catch (error) {
        console.error("error: ", error);
    } finally {
        dispatch({ type: COUPON_LOADER, payload: false });
    }
};

export const updateCard = (payload, onSuccess) => async (dispatch) => {
    try {
        await Subscribe.updateCard(payload)
            .then((res) => {
                toast.success("Updated Successfully");
                onSuccess(res?.data?.data);
            })
            .catch((res) => {
                toast.error(res.data.message);
            });
    } catch (error) {
        toast.error(
            error.message ||
                error?.response?.data?.message ||
                "Something Went Wrong"
        );
        console.error("error: ", error);
    } finally {
        dispatch(authLoader(false));
    }
};

export const getUserSubscription =
    (stripe_customer_id, onSuccess) => async (dispatch) => {
        dispatch(authLoader(true));
        try {
            let { data } = await Subscribe.userSubscription(stripe_customer_id);
            // console.log("subscriptionData: ", data);
            if (data) {
                onSuccess(data?.data);
            }
        } catch (error) {
            console.error("error: ", error);
        } finally {
            dispatch(authLoader(false));
        }
    };
