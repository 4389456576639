import BlueIcon from '../assets/img/courses/icon_tee_blue.png';
import GoldIcon from '../assets/img/courses/icon_tee_gold.png';
import RedIcon from '../assets/img/courses/icon_tee_red.png';
import WhiteIcon from '../assets/img/courses/icon_tee_white.png';

export const StatData = [
    {
        scoreName: 'Avg. Score',
        perAndAvg: '0',
        subPerAndAvg: '',
    },
    {
        scoreName: 'GIR / Rnd',
        perAndAvg: '0',
        subPerAndAvg: '',
    },
    {
        scoreName: 'FIR / Rnd',
        perAndAvg: '0',
        subPerAndAvg: '',
    },
    {
        scoreName: 'Putts / Rnd',
        perAndAvg: '0',
        subPerAndAvg: '',
    },
    {
        scoreName: 'Eagles or Better',
        perAndAvg: '0',
        subPerAndAvg: '(0%)',
    },
    {
        scoreName: 'Birdies',
        perAndAvg: '0',
        subPerAndAvg: '(0%)',
    },
    {
        scoreName: 'Pars',
        perAndAvg: '0',
        subPerAndAvg: '(0%)',
    },
    {
        scoreName: 'Bogeys',
        perAndAvg: '0',
        subPerAndAvg: '(0%)',
    },
    {
        scoreName: 'Doubles or Worse',
        perAndAvg: '0',
        subPerAndAvg: '(0%)',
    },
];

export const ScoreSideBarData = [
    {
        title: 'Championship (?/?)',
        img: BlueIcon,
    },
    {
        title: 'Pro (?/?)',
        img: WhiteIcon,
    },
    {
        title: "Men's (?/?)",
        img: RedIcon,
    },
    {
        title: "Women's (?/?)",
        img: GoldIcon,
    },
    {
        title: "M's Hcp",
        img: '',
    },
    {
        title: "M's Par",
        img: '',
    },
    {
        title: "W's Par",
        img: '',
    },
    {
        title: "W's Hcp",
        img: '',
    },
] 

export const ScoreRightBarData = [
    {
        data1: '540',
        data2: '440',
        data3: '443',
        data4: '325',
        data5: '334',
        data6: '550',
        data7: '314',
        data8: '324',
        data9: '122',
        data10: '+',
        InScoreData: [
            {
                InData1: '?',
                InData2: '?',
                InData3: '?',
                InData4: '?',
                InData5: '?',
                InData6: '?',
                InData7: '?',
                InData8: '?',
                InData9: '?',
                InData10: '0',
            },
        ]
    },
    {
        data1: '560',
        data2: '780',
        data3: '035',
        data4: '435',
        data5: '234',
        data6: '667',
        data7: '878',
        data8: '787',
        data9: '923',
        data10: '+',
        InScoreData: [
            {
                InData1: '?',
                InData2: '?',
                InData3: '?',
                InData4: '?',
                InData5: '?',
                InData6: '?',
                InData7: '?',
                InData8: '?',
                InData9: '?',
                InData10: '0',
            },
        ]
    },
    {
        data1: '540',
        data2: '440',
        data3: '443',
        data4: '325',
        data5: '334',
        data6: '550',
        data7: '314',
        data8: '324',
        data9: '122',
        data10: '+',
        InScoreData: [
            {
                InData1: '?',
                InData2: '?',
                InData3: '?',
                InData4: '?',
                InData5: '?',
                InData6: '?',
                InData7: '?',
                InData8: '?',
                InData9: '?',
                InData10: '0',
            },
        ]
    },
    {
        data1: '560',
        data2: '780',
        data3: '035',
        data4: '435',
        data5: '234',
        data6: '667',
        data7: '878',
        data8: '787',
        data9: '923',
        data10: '+',
        InScoreData: [
            {
                InData1: '?',
                InData2: '?',
                InData3: '?',
                InData4: '?',
                InData5: '?',
                InData6: '?',
                InData7: '?',
                InData8: '?',
                InData9: '?',
                InData10: '0',
            },
        ]
    },
    {
        data1: '11',
        data2: '17',
        data3: '3',
        data4: '1',
        data5: '5',
        data6: '15',
        data7: '13',
        data8: '9',
        data9: '1',
        InScoreData: [
            {
                InData1: '?',
                InData2: '?',
                InData3: '?',
                InData4: '?',
                InData5: '?',
                InData6: '?',
                InData7: '?',
                InData8: '?',
                InData9: '?',
            },
        ]
    },
    {
        data1: '5',
        data2: '3',
        data3: '4',
        data4: '4',
        data5: '4',
        data6: '3',
        data7: '5',
        data8: '4',
        data9: '4',
        InScoreData: [
            {
                InData1: '?',
                InData2: '?',
                InData3: '?',
                InData4: '?',
                InData5: '?',
                InData6: '?',
                InData7: '?',
                InData8: '?',
                InData9: '?',
            },
        ]
    },
    {
        data1: '5',
        data2: '3',
        data3: '4',
        data4: '4',
        data5: '4',
        data6: '3',
        data7: '5',
        data8: '4',
        data9: '4',
        InScoreData: [
            {
                InData1: '?',
                InData2: '?',
                InData3: '?',
                InData4: '?',
                InData5: '?',
                InData6: '?',
                InData7: '?',
                InData8: '?',
                InData9: '?',
            },
        ]
    },
    {
        data1: '11',
        data2: '17',
        data3: '3',
        data4: '1',
        data5: '5',
        data6: '15',
        data7: '13',
        data8: '9',
        data9: '7',
        InScoreData: [
            {
                InData1: '?',
                InData2: '?',
                InData3: '?',
                InData4: '?',
                InData5: '?',
                InData6: '?',
                InData7: '?',
                InData8: '?',
                InData9: '?',
            },
        ]
    },
]