import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import HolesIndexs from "../../../components/courses/HolesIndexs";
import {
    GetCourseById,
    GetCourseHolesById,
    GetCourseScorecard,
    GetHolePolygonsById,
} from "../../../store/actions/HolesAction";
import HolesPage from "./CoursesLayout";
import PolygonMap from "./PolygonMap";

const HoleMap = () => {
    const dispatch = useDispatch();
    const { course_id } = useParams();

    const history = useHistory();
    const location = useLocation();

    const courseData = location.state?.course;

    let [course, setCourse] = useState(courseData);
    let [holes, setHoles] = useState([]);

    let [loader, setLoader] = useState(false);
    let [polygons, setPolygons] = useState({});

    ///scorecard
    let [teeboxColors, setTeeboxColors] = useState([]);
    let [scorecard, setScorecard] = useState([]);

    const handlePageNavigation = (id, mapData) => {
        history.push({
            pathname: `/courses/${course?.name.replaceAll(" ", "_")}/${id}`,
            state: mapData,
        });
    };
    const getPolygons = async (holeId) => {
        dispatch(
            GetHolePolygonsById(holeId, async (data) => {
                const allPolygons = await data.resources.map((resource) => {
                    return {
                        surfaceType: resource.surfacetype,
                        points: resource.polygon,
                    };
                });

                setPolygons((prevState) => ({
                    ...prevState,
                    [holeId]: allPolygons,
                }));
            })
        );
    };
    const getHoles = async (courseId) => {
        setHoles([]);
        setLoader(true);

        dispatch(
            GetCourseHolesById(courseId, async (data) => {
                setHoles(data.resources);
                // for (let hole of data.resources) {
                //     await getPolygons(hole.id);
                //     setLoader(false);
                // }
                data?.resources.forEach((hole) => getPolygons(hole.id));
                setLoader(false);
            })
        );
    };
    const getScorecardofCourses = (courseId) => {
        setTeeboxColors([]);
        setScorecard([]);
        dispatch(
            GetCourseScorecard(courseId, (data) => {
                console.log("data by api : ", data);

                let colors = data.holeteeboxes.map((teebox) => {
                    return teebox.color;
                });
                const uniqueColors = [...new Set(Object.values(colors))];

                setTeeboxColors(uniqueColors);

                // Get scorecard
                let scorecard = data.holeteeboxes
                    .map((hole) => {
                        return hole;
                    })
                    .sort((a, b) => a.holenumber - b.holenumber);

                setScorecard(scorecard);
            })
        );
    };
    const getCourse = (courseId) => {
        dispatch(
            GetCourseById(courseId, (res) => {
                // console.log("res: ", res);
                setCourse(res);
            })
        );
    };
    const renderHoles = (holeData) => (
        <div className="row">
            {holeData && holeData.length > 0 ? (
                holeData?.map((hole, index) => {
                    const paths = polygons[hole.id];
                    const info = scorecard.find(
                        (ele) =>
                            ele.holeid === hole.id && ele.teeboxtype === "Men's"
                    );
                    const golfInfo = scorecard.filter(
                        (ele) => ele.holeid === hole.id
                    );
                    return (
                        <div
                            className="col-lg-3 col-md-4 col-sm-6 col-12"
                            key={hole.id}
                            onClick={() =>
                                handlePageNavigation(
                                    course_id + `:Hole_${index + 1}`,
                                    {
                                        hole,
                                        paths,
                                        golfInfo,
                                        holes,
                                        polygons,
                                        scorecard,
                                        course,
                                    }
                                )
                            }
                        >
                            <div className="mb-3 position-relative">
                                {hole && paths?.length > 0 ? (
                                    <>
                                        <PolygonMap
                                            key={index}
                                            index={index}
                                            height={200}
                                            hole={hole}
                                            paths={paths}
                                            zoom={16.5}
                                        ></PolygonMap>{" "}
                                        <div
                                            className="bg-site-nav-link d-flex flex-column  p-2 rounded-lg position-absolute top-0"
                                            style={{
                                                top: "12px",
                                                left: "12px",
                                            }}
                                        >
                                            <small className="fs--14 fw--600 text-site-secondary">
                                                {info?.holenumber}
                                            </small>
                                            <small className="fs--12 text-site-footer-item">
                                                Par {info?.par}
                                            </small>
                                            <small className="fs--12 text-site-footer-item">
                                                {info?.length} yds
                                            </small>
                                            <small className="fs--12 text-site-footer-item">
                                                Hcp {info?.handicap}
                                            </small>
                                        </div>
                                    </>
                                ) : (
                                    <div
                                        className="w-100 h-100 bg-site-map text-white rounded d-flex justify-content-center align-items-center"
                                        style={{ minHeight: 180 }}
                                    >
                                        Map not available
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="w-100 h-100  text-black rounded d-flex justify-content-center align-items-center">
                    No maps have been created
                </div>
            )}
        </div>
    );

    useEffect(() => {
        if (holes.length === 0) {
            getHoles(course_id);
            getScorecardofCourses(course_id);
            getCourse(course_id);
        }
    }, []);

    const [halt, setHalt] = useState(true);

    useEffect(() => {
        if (!loader) {
            setHalt(false);
        }
    }, [loader]);

    return (
        <HolesPage>
            <div className="bg-white my-3 w-100">
                {/* Index and Navigation Buttons */}
                {course && (
                    <HolesIndexs
                        callFrom={"HoleMap"}
                        holes={holes}
                        course={course}
                        polygons={polygons}
                        scorecard={scorecard}
                        handlePageNavigation={handlePageNavigation}
                    />
                )}
                {/* "Out" Section */}
                <div className="row">
                    <h1 className="my-4 fs--28 px-4">
                        Out
                        {/* {JSON.stringify(window?.google)} */}
                    </h1>
                </div>
                {halt ? (
                    <div className="d-flex justify-content-center">
                        {/* <Spinner> </Spinner> */}d
                        <ShimmerSimpleGallery
                            row={1}
                            col={4}
                            card
                            imageHeight={200}
                        />
                    </div>
                ) : (
                    renderHoles(holes.slice(0, 9))
                )}

                {/* "In" Section */}
                <h4 className="mt-3">In</h4>
                {halt ? (
                    <div className="d-flex justify-content-center">
                        {/* <Spinner> </Spinner> */}
                        <ShimmerSimpleGallery
                            row={1}
                            col={4}
                            card
                            imageHeight={200}
                        />
                    </div>
                ) : (
                    renderHoles(holes.slice(9))
                )}
            </div>
        </HolesPage>
    );
};

export default HoleMap;
