import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import { Link } from "react-router-dom";
import apitutorial2 from "../../assets/img/api-tutorial-2.png";

export const ImplementCourseList = () => {
    return (
        <>
            <h3 className="fs--24 fw--700 font-poppins">
                V. Implementing the Course List Component
            </h3>
            <p className="fs--14 fw--300 font-poppins">
                In this section, we will walk through the process of building
                the Course component, which isresponsible for fetching and
                displaying course data from the Golfbert API.
            </p>

            <h2 className="fs--20 fw--500 font-poppins my-4">
                A. Import Dependencies and Initialize the Component
            </h2>
            <p className="fs--14 fw--300 font-poppins">
                Start by importing the necessary dependencies at the top of the
                file, such as React, useEffect, andthe Golfbert class:
            </p>
            <div className="w-100">
                <CodeBlock
                    text={`import React, { useEffect } from 'react';
import Golfbert from '../lib/Golfbert';`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-4">
                Next, initialize the Course functional component:
            </p>
            <div className="w-100">
                <CodeBlock
                    text={`function Course(props) {
// ...
}`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <h2 className="fs--20 fw--500 font-poppins">
                B. Manage State with React Hooks
            </h2>
            <p className="fs--14 fw--300 font-poppins">
                Use the useState hook to manage the state of the component.
                Declare two state variables:searchText for the search input
                value, and courses to store the fetched course data:
            </p>
            <div className="w-100">
                <CodeBlock
                    text={`let [searchText, setSearchText] = React.useState('');
let [courses, setCourses] = React.useState([]);`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <h2 className="fs--20 fw--500 font-poppins my-4">
                C. Fetch Course Data
            </h2>
            <p className="fs--14 fw--300 font-poppins py-4">
                Use the useEffect hook to fetch course data when the component
                mounts:
            </p>
            <div className="w-100">
                <CodeBlock
                    text={`useEffect(() => {
    getCourses();
}, []);`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-4">
                Define the getCourses function, which initializes a new Golfbert
                instance and calls the getCourses()method with the search
                parameters:
            </p>

            <div className="w-100">
                <CodeBlock
                    text={`const getCourses = () => {
    const golfbert = new Golfbert({
        apiKey: 'your_golfbert_api_key',
        accessKey: 'your_aws_access_key',
        secretKey: 'your_aws_secret_key',
    });
    
    golfbert
        .getCourses({ name: searchText, limit: 10, offset: 0 })
        .then((data) => {
            setCourses(data.resources);
        })
        .catch((err) => console.error(err));
    };`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-4">
                Remember to replace{" "}
                <code className="fs--14 fw--300 font-poppins">
                    'your_golfbert_api_key'
                </code>
                ,{" "}
                <code className="fs--14 fw--300 font-poppins">
                    'your_aws_access_key'
                </code>
                , and{" "}
                <code className="fs--14 fw--300 font-poppins">
                    'your_aws_secret_key'
                </code>{" "}
                with your actual keys.
            </p>
            <h2 className="fs--20 fw--500 font-poppins my-4">
                D. Render the Component
            </h2>
            <p className="fs--14 fw--300 font-poppins">
                Render the search input, search button, and course data in a
                table:
            </p>
            <div className="w-100">
                <CodeBlock
                    text={`return (
    <div className="course" style={{ margin: '25px' }}>
        <div style={{ margin: '25px' }}>
            <input
                type="search"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.currentTarget.value)}
            />
            <button onClick={getCourses}>Search</button>
        </div>
        <table id="courses"> 
            <thead>
                <tr>
                    <th>id</th>
                    <th>Course Name</th>
                    <th>Phone Number</th>
                    <th>Street</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zip</th>
                    <th>Country</th>
                    <th>lat</th>
                    <th>long</th>
                </tr>
            </thead>
            <tbody>
                {courses.map((course, index) => (
                    <tr key={index}>
                        <td>{course.id}</td>
                        <td>{course.name}</td>
                        <td>{course.phonenumber}</td>
                        <td>{course.address.street}</td>
                        <td>{course.address.city}</td>
                        <td>{course.address.state}</td>
                        <td>{course.address.zip}</td>
                        <td>{course.address.country}</td>
                        <td>{course.coordinates.lat}</td>
                        <td>{course.coordinates.long}</td>
                    </tr>
                ))}
            </tbody> 
        </table>
    </div>`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <h2 className="fs--20 fw--500 font-poppins my-4">
                E. Adding the getCourses Method to the Golfbert Class
            </h2>
            <p className="fs--14 fw--300 font-poppins">
                Before we can use the getCourses method in our Course component,
                we need to add it to theGolfbert class. Open the Golfbert.js
                file and add the following method to the class:
            </p>
            <div className="w-100">
                <CodeBlock
                    text={`getCourses(options) {
    const qs = options ? '?' + querystring.stringify(options) : '';
    
    return this.request({
        method: 'GET',
        path: /v1/courses{qs}
    });
}`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins">
                This method accepts a params object containing query parameters
                for the API request, such as thecourse name, limit, and offset.
                It then converts the parameters into a query string and sends a
                GET request to the <code>/courses</code> endpoint.
            </p>
            <h2 className="fs--20 fw--500 font-poppins my-4">
                F. Integrating the Course Component into the Main App
            </h2>
            <p className="fs--14 fw--300 font-poppins">
                Now that we have implemented the Course component, it's time to
                integrate it into the main Appcomponent. Replace the Courses
                import statement in the <code>App.js</code> file with the new
                Coursecomponent:
            </p>
            <div className="w-100">
                <CodeBlock
                    text={`import Course from './Pages/Course';`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins">
                Then, update the corresponding{" "}
                <code className="fs--14 fw--300 font-poppins">
                    &lt;TabPanel&gt;
                </code>{" "}
                to render the Course component instead of theCourses component:
            </p>
            <div className="w-100">
                <CodeBlock
                    text={`<TabPanel>
        <Course />
</TabPanel>`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    G. Testing the Application
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    At this point, your application should be fully functional.
                    To test it, start your development serverwith{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        npm start
                    </code>{" "}
                    or{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        yarn start
                    </code>
                    , and open your browser to{" "}
                    <code className="fs--14 fw--300 font-poppins p-1 ">
                        <Link
                            to="http://localhost:3000"
                            target="new"
                            className="bg-site-border-color text-site-primary"
                        >
                            http://localhost:3000
                        </Link>
                    </code>
                    . You shouldsee the application with four tabs: "First
                    Request," "Course List," "Scorecard," and "Hole."
                </p>
                <p className="fs--14 fw--300 font-poppins">
                    Click on the "Course List" tab, and you should see a search
                    input and a "Search" button. Enter acourse name or leave the
                    input blank to fetch all courses, and click the "Search"
                    button. Thefetched course data should appear in a table
                    below the search input.
                </p>
                <p className="fs--14 fw--300 font-poppins">
                    Now that you have successfully built a sample React app that
                    integrates with the Golfbert API and displays course data,
                    you can continue to expand your application by implementing
                    the"Scorecard" and "Hole" tabs, utilizing additional
                    Golfbert API endpoints, and adding more featuresto create a
                    comprehensive golf application.
                </p>
            </div>
            <div className="w-100 shadow p-2">
                <img src={apitutorial2} className="img-fluid" />
            </div>
        </>
    );
};
