import Repository from "./Repository";
import querystring from "querystring";

export default {
    getCourses(options) {
        const qs = options ? "?" + querystring.stringify(options) : "";
        return Repository.get(`/courses${qs}`);
    },
    getCourseScorecardById(id) {
        return Repository.get(`/course/${id}/scorecard`);
    },
    getHolePolygonsById(id) {
        return Repository.get(`/holes/${id}/polygons`);
    },
    getCourseHolesById(id) {
        return Repository.get(`/course/${id}/holes`);
    },
    getCourseById(id) {
        return Repository.get(`/course/${id}`);
    },
};
