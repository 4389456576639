// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import layoutReducer from "./layoutReducers";
import courseReducer from "./HolesReducer";
import statesCitiesReducer from "./statesCitiesReducer";

export let rootReducer = combineReducers({
    authUser: authUserReducer,
    layout: layoutReducer,
    course: courseReducer,
    citiesStates: statesCitiesReducer,
});

export default rootReducer;
