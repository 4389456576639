import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Collapse,
    Nav,
    Navbar as RC_Navbar,
    NavItem,
    NavbarBrand,
    NavbarToggler,
    Row,
    Container,
} from "reactstrap";
import Logo from "../../assets/img/Brand.svg";
import LogoWhite from "../../assets/img/logo-white.svg";
import searchIcon from "../../assets/img/vector.svg";
import { logout } from "../../store/actions/authAction";
import UserProfileDropdown from "./UserProfileDropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-scroll";
import { useHistory, NavLink } from "react-router-dom";
import SearchModel from "../courses/SearchModel";
import { COURSES, QUICK_SEARCH } from "../../store/types";
import { getCookie } from "cookies-next";
import { jwtDecode } from "jwt-decode";
import { ClearCoursesFilter } from "../../store/actions/HolesAction";
import { toast } from "react-toastify";

const Navbar = (props) => {
    const dispatch = useDispatch();
    const { uid, user } = useSelector((state) => state.authUser);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // Navbar Search Modal --Start
    const [searchModal, setSearchModal] = useState(false);
    const toggleSearch = () => setSearchModal(!searchModal);

    // Navbar Search Modal --End

    const history = useHistory();
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
            // console.log(windowWidth);
        };
    }, []);

    useEffect(() => {
        if (windowWidth >= 1200) {
            setIsOpen(false);
            if (isOpenSidebar) setIsOpenSidebar(false);
        } else {
            if (!isOpenSidebar) setIsOpenSidebar(true);
        }
    }, [windowWidth]);
    const [activeLink, setActiveLink] = useState("");

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            {windowWidth >= 1200 ? (
                <RC_Navbar className="navbar-style" expand="xl">
                    <div>
                        <NavbarBrand
                            className="navbar-header"
                            onClick={() => history.push("/api/overview")}
                        >
                            <img src={Logo} width="140" className="" />
                        </NavbarBrand>
                        <NavbarToggler
                            onClick={toggle}
                            className={
                                isOpen
                                    ? "fas fa-times-circle text-white"
                                    : "fa fa-ellipsis-v text-white"
                            }
                        />
                    </div>

                    <Nav
                        // className="mr- border border-site-bgGreen border-bottom-0 border-top-0 font-family-3"
                        className={`mr- border  border-site-bgGreen border-bottom-0 border-top-0 font-family-3 d-flex  justify-content-between   ${
                            isOpen ? "vh-100 bg-site-bgGreen sidebarToggle" : ""
                        }`}
                        navbar
                    >
                        <NavbarToggler
                            onClick={toggle}
                            className={
                                isOpen
                                    ? "fas fa-times-circle text-white"
                                    : "d-none"
                            }
                        />
                        <Link to="/api/overview">
                            <img
                                src={LogoWhite}
                                width="150"
                                className="d-block d-xl-none mx-auto my-3"
                            />
                        </Link>
                        <NavItem
                            className="my-0"
                            onClick={isOpenSidebar ? toggle : null}
                        >
                            <NavLink
                                to="/api/overview"
                                className="navlink my-0 mx-3 py-1"
                                activeClassName="navlink-active"
                            >
                                API Overview
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className="my-0"
                            onClick={isOpenSidebar ? toggle : null}
                        >
                            <NavLink
                                className="navlink my-0 mx-3 py-1"
                                to="/api/guides"
                                activeClassName="navlink-active"
                            >
                                Guides
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className="my-0"
                            onClick={isOpenSidebar ? toggle : null}
                        >
                            <NavLink
                                className="navlink my-0 mx-3 py-1"
                                to="/api/documentations"
                                activeClassName="navlink-active"
                            >
                                Documentation
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className="my-0"
                            onClick={isOpenSidebar ? toggle : null}
                        >
                            <NavLink
                                className="navlink my-0 mx-3 py-1"
                                to="/api/examples"
                                activeClassName="navlink-active"
                            >
                                Examples
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className="my-0"
                            onClick={isOpenSidebar ? toggle : null}
                        >
                            <NavLink
                                className="navlink my-0 mx-3 py-1"
                                to="/api/tutorial"
                                activeClassName="navlink-active"
                            >
                                Tutorial
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className="my-0"
                            onClick={isOpenSidebar ? toggle : null}
                        >
                            <NavLink
                                className="navlink my-0 mx-3 py-1"
                                activeClassName="navlink-active"
                                to="/api/plans"
                            >
                                Plans and Pricing
                            </NavLink>
                        </NavItem>
                        {isOpenSidebar && (
                            <>
                                <NavItem onClick={toggle}>
                                    <NavLink
                                        className="navlink my-0 mx-3 py-1"
                                        to="/api/faq"
                                        activeClassName="navlink-active"
                                    >
                                        FAQ
                                    </NavLink>
                                </NavItem>
                                <NavItem onClick={toggle}>
                                    <NavLink
                                        className="navlink my-0 mx-3 py-1"
                                        to="/api/support"
                                        activeClassName="navlink-active"
                                    >
                                        Support
                                    </NavLink>
                                </NavItem>
                                <NavItem onClick={toggle}>
                                    <NavLink
                                        className="navlink my-0 mx-3 py-1"
                                        to="/api/timeline"
                                        activeClassName="navlink-active"
                                    >
                                        Timeline
                                    </NavLink>
                                </NavItem>
                            </>
                        )}
                        <NavItem className="mx-auto d-xl-none d-flex">
                            <NavLink
                                className="my-0 py-1"
                                activeClassName="navlink-active"
                                to="/courses/search"
                                onClick={() => {
                                    dispatch(ClearCoursesFilter());
                                }}
                            >
                                Golf Course Db
                            </NavLink>
                        </NavItem>
                        {uid && (
                            <NavItem className="mx-auto d-xl-none d-flex">
                                <NavLink
                                    className="navlink my-0 mx-3 py-1"
                                    activeClassName="navlink-active"
                                    to="/main/setting"
                                >
                                    Setting
                                </NavLink>
                            </NavItem>
                        )}
                        <div
                            className={
                                isOpen
                                    ? "d-flex my-0 flex-column justify-content-between"
                                    : "d-none"
                            }
                        >
                            <Button
                                href="/auth/register"
                                color="site-bgGreen"
                                className="nav-btn text-site-bgDark mx-auto d-xl-flex d-none"
                            >
                                Register
                            </Button>
                            {user?.api_plan !== "all" && (
                                <Button
                                    href="/api/plans"
                                    color="site-bgDark"
                                    className="nav-btn text-site- mx-auto d-xl-none d-flex rounded"
                                    onClick={() => {
                                        history.push("/api/plans");
                                        toggle();
                                    }}
                                >
                                    {user?.api_plan === "single"
                                        ? "Upgrade your plan"
                                        : "Get an API Key"}
                                </Button>
                            )}

                            {!uid ? (
                                <Button
                                    className="navlink mx-0 navbar_button"
                                    activeClass="navlink-active"
                                    onClick={() => history.push("/auth/signin")}
                                >
                                    Login
                                </Button>
                            ) : (
                                <Button
                                    className="navlink mx-0 my-0 py-1 navbar_button"
                                    activeClass="navlink-active"
                                    onClick={() =>
                                        dispatch(
                                            logout(() => {
                                                history.push("api/overview");
                                            })
                                        )
                                    }
                                >
                                    Logout
                                </Button>
                            )}
                        </div>
                    </Nav>

                    <NavItem className="ml-3 d-xl-flex d-none">
                        <NavLink
                            className="navlink py-0 my-0"
                            activeClassName="navlink-active"
                            to="/courses/search"
                            onClick={() => {
                                dispatch(ClearCoursesFilter());
                            }}
                        >
                            Golf Course Db
                        </NavLink>
                    </NavItem>
                    <div className="d-xl-flex d-none">
                        {/* <NavItem className="mx-auto">
                        <Link
                            className="navlink "
                            activeClass="navlink-active"
                            to="#"
                        >
                            Golf Course Db
                        </Link>
                    </NavItem> */}
                        <div className="d-flex align-items-center cursor-pointer">
                            <span onClick={toggleSearch}>
                                <img
                                    className="form-control-feedback"
                                    width="15"
                                    src={searchIcon}
                                />
                            </span>
                            {user?.api_plan !== "all" && (
                                <Button
                                    onClick={() => {
                                        history.push("/api/plans");
                                    }}
                                    color="site-bgGreen"
                                    className="nav-btn text-site-bgDark mx-3"
                                >
                                    {user?.api_plan === "single"
                                        ? "Upgrade your plan"
                                        : "Get an API Key"}
                                </Button>
                            )}

                            <NavItem>
                                {!uid ? (
                                    <Link
                                        className="navlink mx-0 "
                                        activeClass="navlink-active"
                                        onClick={() =>
                                            history.push("/auth/signin")
                                        }
                                    >
                                        Login
                                    </Link>
                                ) : (
                                    //     <Button
                                    //     className="navlink mx-0 navbar_button"
                                    //     // activeClass="navlink-active"
                                    //     onClick={() => history.push("/auth/signin")}
                                    // >
                                    //     Login
                                    // </Button>
                                    <UserProfileDropdown />
                                )}
                            </NavItem>
                        </div>
                    </div>
                </RC_Navbar>
            ) : (
                <>
                    <RC_Navbar className="navbar-style" expand="xl">
                        {/* sidebar */}
                        <Collapse
                            isOpen={isOpen}
                            horizontal
                            className="collapse-setting"
                        >
                            <Nav
                                className={` border-none border-bottom-0 border-top-0 font-family-3  ${
                                    isOpen
                                        ? "bg-site-map vh-100 pt-5 sidebarToggle"
                                        : ""
                                }`}
                                navbar
                            >
                                <NavbarToggler
                                    onClick={toggle}
                                    className={"fas fa-times text-white"}
                                />
                                {/* <Link to="/api/overview" className="mx-auto"> */}
                                {/* <img
                                    alt=""
                                    src={LogoWhite}
                                    width="150"
                                    className=" mx-auto my-3 cursor-pointer"
                                    onClick={() => {
                                        history.push("/api/overview");
                                        toggle();
                                    }}
                                /> */}
                                {/* </Link> */}
                                <NavItem>
                                    <NavLink
                                        to="/api/overview"
                                        className="navlink my-0 mx-3 py-1"
                                        activeClassName="navlink-active"
                                        onClick={() =>
                                            isOpen ? toggle() : null
                                        }
                                    >
                                        API Overview
                                    </NavLink>
                                </NavItem>
                                <NavItem
                                    onClick={() => (isOpen ? toggle() : null)}
                                >
                                    <NavLink
                                        className="navlink my-0 mx-3 py-1"
                                        to="/api/guides"
                                        activeClassName="navlink-active"
                                    >
                                        Guides
                                    </NavLink>
                                </NavItem>
                                <NavItem
                                    onClick={() => (isOpen ? toggle() : null)}
                                >
                                    <NavLink
                                        className="navlink my-0 mx-3 py-1"
                                        to="/api/documentations"
                                        activeClassName="navlink-active"
                                    >
                                        Documentation
                                    </NavLink>
                                </NavItem>
                                <NavItem
                                    onClick={() => (isOpen ? toggle() : null)}
                                >
                                    <NavLink
                                        className="navlink my-0 mx-3 py-1"
                                        to="/api/examples"
                                        activeClassName="navlink-active"
                                    >
                                        Examples
                                    </NavLink>
                                </NavItem>
                                <NavItem
                                    onClick={() => (isOpen ? toggle() : null)}
                                >
                                    <NavLink
                                        className="navlink my-0 mx-3 py-1"
                                        to="/api/tutorial"
                                        activeClassName="navlink-active"
                                    >
                                        Tutorial
                                    </NavLink>
                                </NavItem>
                                <NavItem
                                    onClick={() => (isOpen ? toggle() : null)}
                                >
                                    <NavLink
                                        className="navlink my-0 mx-3 py-1"
                                        activeClassName="navlink-active"
                                        to="/api/plans"
                                    >
                                        Plans and Pricing
                                    </NavLink>
                                </NavItem>
                                {isOpen && (
                                    <>
                                        <NavItem onClick={() => toggle()}>
                                            <NavLink
                                                className="navlink my-0 mx-3 py-1"
                                                to="/api/faq"
                                                activeClassName="navlink-active"
                                            >
                                                FAQ
                                            </NavLink>
                                        </NavItem>
                                        <NavItem onClick={toggle}>
                                            <NavLink
                                                className="navlink my-0 mx-3 py-1"
                                                to="/api/support"
                                                activeClassName="navlink-active"
                                            >
                                                Support
                                            </NavLink>
                                        </NavItem>
                                        <NavItem onClick={toggle}>
                                            <NavLink
                                                className="navlink my-0 mx-3 py-1"
                                                to="/api/timeline"
                                                activeClassName="navlink-active"
                                            >
                                                Timeline
                                            </NavLink>
                                        </NavItem>
                                    </>
                                )}
                                <NavItem
                                    className="mx-auto d-xl-none d-flex"
                                    onClick={toggle}
                                >
                                    <NavLink
                                        className="navlink my-0 mx-3 py-1"
                                        activeClassName="navlink-active"
                                        to="/courses/search"
                                        onClick={() => {
                                            dispatch(ClearCoursesFilter());
                                        }}
                                    >
                                        Golf Course Db
                                    </NavLink>
                                </NavItem>
                                {uid && (
                                    <NavItem
                                        className="mx-auto d-xl-none d-flex"
                                        onClick={toggle}
                                    >
                                        <NavLink
                                            className="navlink my-0 mx-3 py-1"
                                            activeClassName="navlink-active"
                                            to="/main/setting"
                                        >
                                            Settings
                                        </NavLink>
                                    </NavItem>
                                )}
                                <div
                                    className={
                                        isOpen
                                            ? "d-flex mt-auto flex-column justify-content-between"
                                            : "d-none"
                                    }
                                >
                                    {user?.api_plan === "all" && (
                                        <Button
                                            href="/api/plans"
                                            color="site-bgDark"
                                            className="nav-btn  text-site- mx-auto d-xl-none d-flex rounded"
                                            onClick={() => {
                                                history.push("/api/plans");
                                                toggle();
                                            }}
                                        >
                                            {user?.api_plan === "single"
                                                ? "Upgrade your plan"
                                                : "Get an API Key"}
                                        </Button>
                                    )}

                                    {!uid ? (
                                        <Button
                                            className="navlink navbar_button"
                                            activeClass="navlink-active"
                                            onClick={() => {
                                                toggle();
                                                history.push("/auth/signin");
                                            }}
                                        >
                                            Login
                                        </Button>
                                    ) : (
                                        <Button
                                            className="navlink navbar_button"
                                            activeClass="navlink-active"
                                            onClick={() => {
                                                toggle();
                                                dispatch(
                                                    logout(() => {
                                                        history.push(
                                                            "api/overview"
                                                        );
                                                    })
                                                );
                                            }}
                                        >
                                            Logout
                                        </Button>
                                    )}
                                </div>
                            </Nav>

                            {/* <NavItem className="ml-0 mr-auto d-xl-flex d-none">
                                <NavLink
                                    className="navlink "
                                    activeClassName="navlink-active"
                                    to="/landing"
                                >
                                    Golf Course Db
                                </NavLink>
                            </NavItem> */}
                            {/* <div className="d-xl-flex d-none">
                                <div className="d-flex align-items-center ">
                                    <img
                            className="form-control-feedback ml-auto cursor-pointer"
                            src={searchIcon}
                            onClick={toggleSearch}
                        />
                                    <Button
                                        onClick={() => {
                                            history.push("/api/plans");
                                        }}
                                        color="site-bgGreen"
                                        className="nav-btn text-site-bgDark mx-3"
                                    >
                                        {!user?.api_active_until &&
                                        user?.api_plan &&
                                        user?.api_plan !== "All Golf"
                                            ? "Upgrade your plan"
                                            : "Get an API Key"}
                                    </Button>

                                    <NavItem>
                                        {!uid ? (
                                            <Link
                                                className="navlink mx-0 "
                                                activeClass="navlink-active"
                                                to="/auth/signin"
                                            >
                                                Login
                                            </Link>
                                        ) : (
                                            <UserProfileDropdown />
                                        )}
                                    </NavItem>
                                </div>
                            </div> */}
                        </Collapse>

                        <Container fluid className="px-0">
                            <Row className="align-items-center justify-content-between mx-auto w-100">
                                <Col xs="3" className="">
                                    <NavbarToggler
                                        onClick={toggle}
                                        className={
                                            isOpen
                                                ? ""
                                                : "fa fa-ellipsis-v text-white"
                                        }
                                    />
                                </Col>
                                <Col xs="6" className="text-center">
                                    <NavbarBrand
                                        className="navbar-header mx-auto "
                                        onClick={() =>
                                            history.push("/api/overview")
                                        }
                                    >
                                        <img
                                            alt=""
                                            src={Logo}
                                            width="auto"
                                            className=""
                                        />
                                    </NavbarBrand>
                                </Col>
                                <Col xs="3" className="text-right">
                                    <img
                                        className="form-control-feedback ml-auto cursor-pointer"
                                        src={searchIcon}
                                        onClick={toggleSearch}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </RC_Navbar>
                </>
            )}
            <SearchModel
                toggleSearch={toggleSearch}
                searchModal={searchModal}
            />
        </>
    );
};

export default Navbar;
