export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const AUTH_SET_LOADING = "AUTH_SET_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const COUPON_LOADER = "COUPON_LOADER";
export const REG_LOADER = "REGISTER_LOADER";

export const COURSE_HOLES = "COURSE_HOLES";
export const SELECTED_COURSE = "SELECTED_COURSE";
export const COURSES = "COURSES";
export const QUICK_SEARCH = "QUICK_SEARCH";
export const COURSE_LOADER = "COURSE_LOADER";
export const LOAD_MORE_COURSES = "LOAD_MORE_COURSES";

export const STATES = "STATES";
export const CITIES = "CITIES";
