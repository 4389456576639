import React, { useState } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { DocumentationsSidebar } from "../documentation/DocumentationSidebar";

const GettingStarted = () => {
    const [activeSection, setActiveSection] = useState("");
    const history = useHistory();
    // Handle click on the link
    const handleClick = (section) => {
        // console.log(section)
        setActiveSection(section);
        <DocumentationsSidebar activeSections={{ section }} />;
    };

    return (
        <>
            <div>
                <h2 className="fs--20 fw--500 font-poppins">Getting Started</h2>
                <p className="fs--16 fw--400 font-poppins text-muted py-3">
                    You can find the Golfbert API at{" "}
                    <Link
                        to="/api/overview"
                        className="fs--16 fw--400 font-poppins text-site-primary px-2"
                    >
                        https://api.golfbert.com
                    </Link>
                    . The current version of the API is <i>v1</i> which means
                    that all endpoints are prefixed by this version, for
                    example:{" "}
                    <Link
                        to="/api/documentations"
                        className="fs--16 fw--400 font-poppins text-site-primary px-2"
                        onClick={() => handleClick("/v1/courses")}
                    >
                        https://api.golfbert.com/v1/courses
                    </Link>
                    <Switch>
                        {/* Route for /api/documentations */}
                        <Route path="/api/documentations">
                            <DocumentationsSidebar
                                activeSections={activeSection}
                            />
                        </Route>
                        {/* Other routes */}
                    </Switch>
                    . All endpoints are https only and all of them require
                    authentication. See below for more information around how to
                    compose requests.{" "}
                </p>
                <p className="fs--16 fw--400 font-poppins text-muted py-2">
                    To get started, you will need an API token, an access key
                    and a secret key which you will use in every interaction
                    with the API. While this is something you receive as part of
                    a paying engagement, we do have demo accounts (restrictions
                    apply) so that you can experiment. Once you have that
                    information, you can proceed.
                </p>
                <p>
                    <Link
                        to="/api/support"
                        className="fs--16 fw--400 font-poppins text-site-primary py-4"
                    >
                        Contact us for a free demo account
                    </Link>
                </p>
            </div>
        </>
    );
};

export default GettingStarted;
