import React from "react";
import { Col, Row } from "reactstrap";

export const SignInFooter = () => {
    return (
        <div className="py-4">
            <Row className="short-sec">
                <Col>
                    <h5 className="">For Developers – <a href="/api/plans">Get an API key</a></h5>
                </Col>
            </Row>
        </div>
    );
};
