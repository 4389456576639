import { toast } from "react-toastify";
import { RepositoryFactory } from "../../Repository/RepositoryFactory";
import {
    COURSES,
    COURSE_LOADER,
    LOAD_MORE_COURSES,
    QUICK_SEARCH,
} from "../types";

let golfbertRep = RepositoryFactory.get("golfbert");

export const GetCourseById = (courseId, onSuccess) => async (dispatch) => {
    try {
        let { data } = await golfbertRep.getCourseById(courseId);
        if (data.success) {
            onSuccess(data?.data);
            return data?.data;
        }
    } catch (error) {
        console.log("error: ", error);
        toast.error(
            error?.response?.data?.message ||
                error?.data?.message ||
                error.message ||
                "Something Went Wrong"
        );
        console.error("error: ", error);
    }
};
export const GetCourseScorecard = (courseId, onSuccess) => async (dispatch) => {
    try {
        let { data } = await golfbertRep.getCourseScorecardById(courseId);
        if (data.success) {
            onSuccess(data?.data);
        }
    } catch (error) {
        toast.error(
            error?.response?.data?.message ||
                error?.data?.message ||
                error.message ||
                "Something Went Wrong"
        );
        console.error("error: ", error);
    }
};
export const GetCourseHolesById = (courseId, onSuccess) => async (dispatch) => {
    try {
        let { data } = await golfbertRep.getCourseHolesById(courseId);

        if (data.success) {
            onSuccess(data?.data);
        }
    } catch (error) {
        toast.error(
            error?.response?.data?.message ||
                error?.data?.message ||
                error.message ||
                "Something Went Wrong"
        );
        console.error("error: ", error);
    }
};
export const GetHolePolygonsById =
    (courseId, onSuccess) => async (dispatch) => {
        try {
            let { data } = await golfbertRep.getHolePolygonsById(courseId);
            if (data.success) {
                onSuccess(data?.data);
            }
        } catch (error) {
            toast.error(
                error.message ||
                    error?.response?.data?.message ||
                    "Something Went Wrong"
            );
            console.error("error: ", error);
        }
    };

export const getFilteredCourses = (query, onSuccess) => async (dispatch) => {
    dispatch({ type: COURSE_LOADER, payload: true });
    try {
        let { data } = await golfbertRep.getCourses(query);

        if (data) {
            let temp = [...data.data.resources];
            const updatedCourses = await Promise.all(
                temp.map(async (course) => {
                    const hasMap = await dispatch(getHoles(course.id));
                    return { ...course, isMapAvailable: hasMap };
                })
            );

            dispatch({ type: COURSES, payload: updatedCourses });
            dispatch({ type: QUICK_SEARCH, payload: query });
            onSuccess(updatedCourses);

            dispatch({ type: COURSE_LOADER, payload: false });
        }
    } catch (error) {
        console.error("error: ", error);
        toast.error(
            error?.response?.data?.message ||
                error?.data?.message ||
                error.message ||
                "Something Went Wrong"
        );
        dispatch({ type: COURSE_LOADER, payload: false });
    }
};
export const ClearCoursesFilter = () => async (dispatch) => {
    dispatch({ type: COURSES, payload: [] });
    dispatch({
        type: QUICK_SEARCH,
        payload: {
            name: "",
            state: "",
            city: "",
            limit: 25,
            offset: 0,
        },
    });
};
export const GetCoursesOverView = (arr, onSuccess) => async (dispatch) => {
    // console.log("arr: ", arr);
    dispatch({ type: "AUTH_LAODER", payload: true });
    // const golfbert = new Golfbert();
    let courseData = [];

    try {
        await Promise.all(
            arr?.map(async (courseID) => {
                // console.log(" ");
                try {
                    // const course = await golfbert.getCourseById(courseID);
                    let course = {};
                    await dispatch(
                        GetCourseById(courseID, (res) => {
                            course = res;
                        })
                    );
                    // const holes = await golfbert.getCourseHolesById(courseID);
                    let holes = {};

                    await dispatch(
                        GetCourseHolesById(courseID, (data) => {
                            holes = data;
                        })
                    );

                    // const holePolygons = await golfbert.getHolePolygonsById(
                    //     holes.resources[0].id
                    // );
                    let holePolygons = {};

                    if (holes.resources[0].id) {
                        await dispatch(
                            GetHolePolygonsById(
                                holes.resources[0].id,
                                (data) => {
                                    holePolygons = data;
                                }
                            )
                        );
                        const allPolygons = holePolygons.resources.map(
                            (resource) => ({
                                surfaceType: resource.surfacetype,
                                points: resource.polygon,
                            })
                        );

                        let scorecard = {};
                        await dispatch(
                            GetCourseScorecard(courseID, (data) => {
                                scorecard = data;
                            })
                        );
                        const scorecardData = scorecard.holeteeboxes
                            .map((hole) => hole)
                            .sort((a, b) => a.holenumber - b.holenumber);

                        courseData.push({
                            hole: holes.resources[0],
                            course,
                            paths: allPolygons,
                            numberOfHoles: holes.resources.length,
                            scorecard: scorecardData[1],
                        });
                    }
                    onSuccess(courseData);
                } catch (error) {
                    toast.error(
                        error?.response?.data?.message ||
                            error?.data?.message ||
                            error.message ||
                            "Something Went Wrong"
                    );
                }
            })
        );
    } catch (error) {
        toast.error(
            error?.response?.data?.message ||
                error?.data?.message ||
                error.message ||
                "Something Went Wrong"
        );
    } finally {
        dispatch({ type: "AUTH_LAODER", payload: false });
    }
};
export const LoadMoreCourses = (query, onSuccess) => async (dispatch) => {
    dispatch({ type: COURSE_LOADER, payload: true });
    try {
        let { data } = await golfbertRep.getCourses(query);
        if (data.success) {
            let temp = [...data.data.resources];
            const updatedCourses = await Promise.all(
                temp.map(async (course) => {
                    const hasMap = await dispatch(getHoles(course.id));
                    return { ...course, isMapAvailable: hasMap };
                })
            );
            // console.log("updatedCourses: ", updatedCourses);

            dispatch({ type: LOAD_MORE_COURSES, payload: updatedCourses });
            dispatch({ type: QUICK_SEARCH, payload: query });
            onSuccess(data.resources.length);

            dispatch({ type: COURSE_LOADER, payload: false });
        }
    } catch (error) {
        toast.error(
            error?.response?.data?.message ||
                error?.data?.message ||
                error?.message ||
                "Something Went Wrong"
        );
        console.error("error: ", error);
        dispatch({ type: COURSE_LOADER, payload: false });
    }
    // const golfbert = new Golfbert();
    // golfbert
    //     .getCourses(query)
    //     .then(async (data) => {
    //         let temp = [...data.resources];
    //         const updatedCourses = await Promise.all(
    //             temp.map(async (course) => {
    //                 const hasMap = await dispatch(getHoles(course.id));
    //                 return { ...course, isMapAvailable: hasMap };
    //             })
    //         );
    //         dispatch({ type: LOAD_MORE_COURSES, payload: updatedCourses });
    //         dispatch({ type: QUICK_SEARCH, payload: query });
    //         onSuccess(data.resources.length);
    //         dispatch({ type: COURSE_LOADER, payload: false });
    //     })

    //     .catch((err) => {
    //         dispatch({ type: COURSE_LOADER, payload: false });
    //         console.error(err);
    //     });
};
export const getHoles = (courseId) => async (dispatch) => {
    try {
        // const golfbert = new Golfbert();
        // const data = await golfbert.getCourseHolesById(courseId);

        let data = {};
        await dispatch(
            GetCourseHolesById(courseId, (res) => {
                // console.log("res: >>>>", res);
                data = res;
            })
        );
        return data.resources.length == 18;
    } catch (err) {
        toast.error(
            err?.response?.data?.message ||
                err?.data?.message ||
                err.message ||
                "Something Went Wrong"
        );
        console.error(err);

        return false;
    }
};
export const GetSingleCourseData =
    (courseID, pathIndex, onSuccess) => async (dispatch) => {
        // const golfbert = new Golfbert();
        const polygons = {};
        try {
            // Fetch course data
            // const course = await golfbert.getCourseById(courseID);
            let course = {};
            await dispatch(
                GetCourseById(courseID, (data) => {
                    course = data;
                })
            );
            // Fetch course scorecard
            // const scorecardData = await golfbert.getCourseScorecardById(
            //     courseID
            // );
            let scorecardData = {};
            await dispatch(
                GetCourseScorecard(courseID, (data) => {
                    scorecardData = data;
                })
            );
            const scorecard = scorecardData.holeteeboxes
                .map((hole) => hole)
                .sort((a, b) => a.holenumber - b.holenumber);
            // Fetch course holes and their polygons
            // const holesData = await golfbert.getCourseHolesById(courseID);
            let holesData = {};
            await dispatch(
                GetCourseHolesById(courseID, (data) => {
                    holesData = data;
                })
            );
            const holes = holesData.resources;
            const getPolygons = async (holeId) => {
                try {
                    // const data = await golfbert.getHolePolygonsById(holeId);
                    let data = {};
                    await dispatch(
                        GetHolePolygonsById(holeId, (res) => {
                            data = res;
                        })
                    );

                    const allPolygons = data.resources.map((resource) => ({
                        surfaceType: resource.surfacetype,
                        points: resource.polygon,
                    }));
                    polygons[holeId] = allPolygons;
                } catch (err) {
                    console.error(err);
                }
            };
            await Promise.all(holes.map((hole) => getPolygons(hole.id)));
            const hole = await holes.find((hole) => hole.number == pathIndex);
            const golfInfo = await scorecard.filter(
                (ele) => ele.holeid === hole.id
            );
            onSuccess({
                golfInfo,
                hole,
                course,
                polygons,
                scorecard,
                holes,
                paths: polygons[hole?.id],
            });
        } catch (err) {
            toast.error(
                err?.response?.data?.message ||
                    err?.data?.message ||
                    err?.message ||
                    "Something Went Wrong"
            );
            onSuccess("No Hole Found..!");

            console.error(err);
        }
    };
