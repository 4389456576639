import { Navbar } from "reactstrap";

const Auth = (props) => {
    return (
        <>
            <div class="auth-header">
                <Navbar />
            </div>
            {props.children}
        </>
    );
};

export default Auth;
