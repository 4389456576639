import React from "react";

const TeeBoxes = ({ type, height, width }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
        >
            <circle
                cx="10"
                cy="10"
                r="9"
                stroke="#94a1a9"
                stroke-width="2"
                fill="#fff"
            />
            <circle cx="10" cy="10" r="5" fill={type} />
        </svg>
    );
};

export default TeeBoxes;
