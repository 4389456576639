import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import apitutorial1 from "../../assets/img/api-tutorial-1.png";

export const BuildingApp = () => {
    return (
        <>
            <h3 className="fs--24 fw--700 font-poppins">
                IV. Building the App - First Request
            </h3>
            <p className="fs--14 fw--300 font-poppins">
                In this section, we will build the main components of our Golf
                Course Management React App,starting with the First Request tab.
            </p>
            <div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    A. First Request
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    The First Request tab will demonstrate how to make a basic
                    API request to the Golfbert API usingthe provided Golfbert
                    class and display the response data. We will create a new
                    FirstRequestcomponent and incorporate it into our App
                    component.
                </p>
                <p className="fs--14 fw--300 font-poppins">
                    Integrating the Golfbert class into the project: Create a
                    new folder named lib inside the srcdirectory. Then, create a
                    new file named Golfbert.js inside the lib folder and add the
                    providedGolfbert class code.
                </p>
                <h4 className="fs--20 fw--600 font-poppins my-4">
                    Creating the FirstRequest component:
                </h4>
                <p className="fs--14 fw--300 font-poppins">
                    Create a new folder named Pages inside the{" "}
                    <code className="fs--14 fw--300 font-poppins">src</code>{" "}
                    directory, if it doesn't already exist. Then, createa new
                    file named{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        FirstRequest.js
                    </code>{" "}
                    inside the Pages folder and add the following code:
                </p>
            </div>
            <div className="w-100">
                <CodeBlock
                    text={`import React, { useState, useEffect } from 'react';
import Golfbert from '../lib/Golfbert';

const FirstRequest = () => {
    const [data, setData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const golfbert = new Golfbert({
                    apiKey: 'your_golfbert_api_key',
                    accessKey: 'your_aws_access_key',
                    secretKey: 'your_aws_secret_key', 
                });
                const response = await golfbert.status();
                setData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        fetchData();
    }, []);
    
    return (
        <div>
            <h2>First Request</h2>
                {data ? (
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                ) : (
                    <p>Loading data...</p>
                )}
        </div>
    );
};

export default FirstRequest;`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-3">
                Make sure to replace your_golfbert_api_key, your_aws_access_key,
                and your_aws_secret_keywith your actual Golfbert API key, AWS
                access key, and AWS secret key.
            </p>

            <p className="fs--14 fw--300 font-poppins py-3">
                Integrating the{" "}
                <code className="fs--14 fw--300 font-poppins">
                    FirstRequest
                </code>{" "}
                component into the App component: Open the{" "}
                <code className="fs--14 fw--300 font-poppins">src/App.js</code>{" "}
                file andimport the FirstRequest component at the top:
            </p>

            <div className="w-100">
                <CodeBlock
                    text={`import FirstRequest from './Pages/FirstRequest';`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins ">
                Then, replace the{" "}
                <code className="fs--14 fw--300 font-poppins">
                    &lt;p&gt;healthy&lt;/p&gt;
                </code>{" "}
                line inside the first <code>&lt;TabPanel&gt;</code> with the{" "}
                <code className="fs--14 fw--300 font-poppins">
                    &lt;FirstRequest /&gt;
                </code>{" "}
                component:
            </p>
            <div className="w-100">
                <CodeBlock
                    text={`<TabPanel>
    <FirstRequest />
</TabPanel>`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-3">
                Now, when you run the app and navigate to the First Request tab,
                it should display the data fetchedfrom the Golfbert API using
                the Golfbert class. If there are any issues, the console will
                display anerror message.
            </p>
            <div className="w-100 p-4 shadow">
                <img src={apitutorial1} className="img-fluid" />
            </div>
        </>
    );
};
