import Repository from "./Repository";
const contact = "/user/update-contact";
const password = "/user/update-password";

export default {
    updateContactInfo(payload) {
        return Repository.put(`${contact}`, payload);
    },
    updatePasswords(payload) {
        return Repository.put(`${password}`, payload);
    },
};
