import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import ApiImg from "../../assets/img/api-message.png";
import ChangePassword from "../../components/modals/ChangePassword";
import UpdateContact from "../../components/modals/UpdateContact";
import UpdatePaymentInfo from "../../components/modals/UpdatePaymentInfo";
import MyApiPlan from "../../components/setting/MyApiPlan";
import {
    getFetchCustomer,
    getUserSubscription,
} from "../../store/actions/subscribeAction";
import { planCardData } from "../../utils/data";
import SubscriptionStatus from "./SubscriptionStatus";

const Setting = () => {
    const dispatch = useDispatch();
    const { uid, user } = useSelector((state) => state.authUser);
    const [contact, setContact] = useState(false);
    const [password, setPassword] = useState(false);
    const [payment, setPayment] = useState(false);
    const [cardDetails, setCardDetails] = useState("");
    const [subscriptionDetails, setSubscriptionDetails] = useState(-1);
    // console.log("subscriptionDetails: ", subscriptionDetails);

    const apiPlan = planCardData?.find(
        // (ele) => ele?.nickname === subscriptionDetails?.currentSubscription?.nickname
        (ele) => ele?.id === subscriptionDetails?.currentSubscription?.plan_id
    );

    const toggleModal = (event) => {
        event.preventDefault();
        setContact(!contact);
    };
    const passwordToggle = (event) => {
        event.preventDefault();
        setPassword(!password);
    };
    const paymentToggle = (event) => {
        event.preventDefault();
        setPayment(!payment);
    };

    useEffect(() => {
        if (!user?.stripe_customer_id) {
            return setSubscriptionDetails("");
        }
        dispatch(
            getFetchCustomer(user?.stripe_customer_id, (res) => {
                setCardDetails(res);
            })
        );
        dispatch(
            getUserSubscription(user?.stripe_customer_id, (res) => {
                // console.log("res: ", res);
                setSubscriptionDetails(res);
            })
        );
    }, []);

    if (subscriptionDetails === -1) {
        return (
            <div className="vh-100 d-flex justify-content-center align-items-center">
                <Spinner> </Spinner>
            </div>
        );
    } else {
        return (
            <>
                {uid ? (
                    <>
                        <UpdateContact
                            toggleModal={toggleModal}
                            contact={contact}
                            setContact={setContact}
                        />
                        <ChangePassword
                            passwordToggle={passwordToggle}
                            password={password}
                            setPassword={setPassword}
                        />
                        <UpdatePaymentInfo
                            paymentToggle={paymentToggle}
                            payment={payment}
                            setCardDetails={setCardDetails}
                        />
                        <div className="w-100 py-5 px-wide px-lg-narrow setting">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>
                                            Account Settings
                                        </h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6" className="my-3">
                                        <ul className="mb-5">
                                            <li className="mb-4"><h5>Contact Information</h5></li>
                                            <li className="fw--600">{user?.username}</li>
                                            <li>{user?.fullname}</li>
                                            <li>{user?.email}</li>
                                            <li>{user?.address}</li>
                                            {user?.city && (
                                                <li>
                                                    {user?.city}
                                                    {user?.state}
                                                    {user?.zip_code}
                                                </li>
                                            )}
                                            {user?.company && (
                                                <li>{user?.company}</li>
                                            )}{" "}
                                            <li className="mb-4">
                                                {user?.webaddress && (
                                                    <a
                                                        href={user?.webaddress}
                                                        className="link-to fw--500"
                                                    >
                                                        {user?.webaddress}
                                                    </a>
                                                )}
                                            </li>
                                            <li>
                                                <Link
                                                    onClick={toggleModal}
                                                    className="link-to fw--500"
                                                >
                                                    Update contact information
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    onClick={passwordToggle}
                                                    className="link-to fw--500 "
                                                >
                                                    Change Password
                                                </Link>
                                            </li>
                                        </ul>
                                        {cardDetails && (
                                            <ul className="mb-5">
                                                <li className="mb-4"><h5>Payment Information</h5></li>
                                                <li>{cardDetails?.brand}</li>
                                                <li>
                                                    ************
                                                    {cardDetails?.last4}
                                                </li>
                                                <li>
                                                    Expiration:{" "}
                                                    {cardDetails?.exp_month}
                                                    /{cardDetails?.exp_year}
                                                </li>
                                                <li>
                                                    <Link
                                                        onClick={paymentToggle}
                                                        className="link-to fw--500"
                                                    >
                                                        Update payment method
                                                    </Link>
                                                </li>
                                            </ul>
                                        )}
                                    </Col>
                                    <Col md="6" className="my-3">
                                        <ul>
                                            <li className="mb-4"><h5>API Plan</h5></li>
                                            <li>
                                            {!subscriptionDetails?.currentSubscription ? (
                                                <ul className="mb-5">
                                                    <li className="fw--600">Sample Course</li>
                                                    <li>
                                                        Access to our sample golf
                                                        course for testing
                                                    </li>
                                                    <li>Free</li>
                                                    <li>
                                                        Test our sample course for
                                                        free
                                                    </li>
                                                    <li className="mt-4 fw--600">Calls per month</li>
                                                    <li>5,000</li>
                                                    <li className="mb-4">
                                                        Paid plans allow additional
                                                        calls
                                                    </li>

                                                    <Link
                                                        to="/api/plans"
                                                        className="link-to"
                                                    >
                                                        Upgrade to a premium API
                                                        plan
                                                    </Link>
                                                </ul>
                                            ) : (
                                                <MyApiPlan
                                                    apiPlan={apiPlan}
                                                    currentSubscription={
                                                        subscriptionDetails?.currentSubscription
                                                    }
                                                />
                                            )}

                                            {user?.stripe_customer_id && (
                                                <SubscriptionStatus
                                                    subscriptionDetails={
                                                        subscriptionDetails
                                                    }
                                                />
                                            )}
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </>
                ) : (
                    <Redirect to="/auth/signin" />
                )}
            </>
        );
    }
};

export default Setting;
