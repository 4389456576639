import AuthView from "../views/auth/AuthView";
import Forgetpassword from "../views/auth/Forgetpassword";
import SignIn from "../views/auth/SignIn";
import MainView from "../views/MainView";
import Landing from "../views/auth/Landing";

import Api from "../views/Api";
import PlansPricing from "../views/api/PlansPricing";
import Terms from "../views/pages/Terms";
import Privacy from "../views/pages/Privacy";
import License from "../views/pages/License";
import SubscribeSingle from "../views/api/SubscribeSingle";
import SubscribeAll from "../views/api/SubscribeAll";

import Support from "../views/pages/Support";
import FAQ from "../views/FAQ";
import Timeline from "../views/pages/Timeline";
import Setting from "../views/pages/Setting";
import CancelMembership from "../views/pages/CancelMembership";

import { Example } from "../views/api/Example";
import { Tutorial } from "../views/api/Tutorial";
import { Documentations } from "../views/api/Documentations";
import { Guides } from "../views/api/Guides";
import ResetPassword from "../views/auth/ResetPassword";
import StateSearchPage from "../views/pages/courses/StateSearchPage";
import CitySearchPage from "../views/pages/courses/CitySearchPage";
import SubscribeTest from "../views/api/SubscribeTest";
import StatsThisCourse from "../views/pages/courses/StatsThisCourse";
import HoleMap from "../views/pages/courses/HoleMap";
import ScoreCard from "../views/pages/courses/ScoreCard";
import CourseSinglePage from "../views/pages/courses/CourseSinglePage";
import CityCoursesPage from "../views/pages/courses/CityCoursesPage";

let routes = [
    // {
    //     path: "/auth",
    //     component: AuthView,
    //     layout: "auth",
    // },

    // {
    //     path: "/landing",
    //     component: Landing,
    //     layout: "main",
    // },
    {
        path: "/auth/signin",
        component: SignIn,
        layout: "auth",
    },

    // {
    //     path: "/api",
    //     component: Api,
    //     layout: "main",
    // },
    {
        path: "/api/overview",
        component: Landing,
        layout: "main",
        name: "Overview",
        page: "api",
    },
    {
        path: "/api/guides",
        component: Guides,
        layout: "main",
        name: "Guides",
        page: "api",
    },
    {
        path: "/api/documentations",
        component: Documentations,
        layout: "main",
        name: "Documentations",
        page: "api",
    },
    {
        path: "/api/examples",
        component: Example,
        layout: "main",
        name: "Examples",
        page: "api",
    },
    {
        path: "/api/tutorial",
        component: Tutorial,
        layout: "main",
        name: "Tutorial",
        page: "api",
    },
    {
        path: "/api/faq",
        component: FAQ,
        layout: "main",
        name: "FAQ",
        page: "api",
    },
    {
        path: "/api/support",
        component: Support,
        layout: "main",
        name: "Support",
        page: "api",
    },
    {
        path: "/api/plans",
        component: PlansPricing,
        layout: "main",
        name: "Plans and Pricing",
        page: "api",
    },
    {
        path: "/api/timeline",
        component: Timeline,
        layout: "main",
        name: "Timeline",
        page: "api",
    },
    {
        path: "/auth/forgetpassword",
        component: Forgetpassword,
        layout: "auth",
    },
    {
        path: "/api/subscribe/test",
        component: SubscribeTest,
        layout: "main",
    },
    {
        path: "/api/subscribe/single",
        component: SubscribeSingle,
        layout: "main",
    },
    {
        path: "/api/subscribe/all",
        component: SubscribeAll,
        layout: "main",
    },

    {
        path: "/auth/forgetpassword",
        component: Forgetpassword,
        layout: "auth",
    },
    {
        path: "/auth/reset-password",
        component: ResetPassword,
        layout: "auth",
    },
    // {
    //     path: "/api/overview",
    //     component: MainView,
    //     layout: "main",
    // },

    {
        path: "/main/terms",
        component: Terms,
        layout: "main",
    },
    {
        path: "/main/privacy",
        component: Privacy,
        layout: "main",
    },
    {
        path: "/api/license",
        component: License,
        layout: "main",
    },

    // {
    //     path: "/main/support",
    //     component: Support,
    //     layout: "main",
    // },
    // {
    //     path: "/main/timeline",
    //     component: Timeline,
    //     layout: "main",
    // },
    {
        path: "/main/setting",
        component: Setting,
        layout: "main",
    },
    {
        path: "/main/cancel-membership",
        component: CancelMembership,
        layout: "main",
    },
    {
        path: "/courses/search",
        component: StateSearchPage,
        layout: "main",
    },
    {
        path: "/courses/search/:state",
        component: CitySearchPage,
        layout: "main",
    },
    {
        path: "/courses/search/:state/:city",
        component: CityCoursesPage,
        layout: "main",
    },
    {
        path: "/courses/holes/:course_id",
        component: HoleMap,
        layout: "main",
    },
    {
        path: "/courses/scorecard/:course_id",
        component: ScoreCard,
        layout: "main",
    },
    {
        path: "/courses/stats/:citycode",
        component: StatsThisCourse,
        layout: "main",
    },
    {
        path: "/courses/:citycode/:id",
        component: CourseSinglePage,
        layout: "main",
    },
];
export default routes;
