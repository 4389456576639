import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";

export const DevelopmentEnvironment = () => {
    return (
        <div>
            <h3 className="fs--24 fw--700 font-poppins">
                II. Setting up the development environment
            </h3>
            <p className="fs--14 fw--300 font-poppins">
                In this section, we will set up our development environment for
                the Golf Course Management ReactApp. We will create a new React
                project, install necessary packages, and configure the
                GoogleMaps and Golfbert API keys.
            </p>

            <div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    A. Create a new React project using Create React App
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    To begin, open your terminal and run the following command
                    to create a new React project usingCreate React App:
                </p>
                <div className="p-3 bg-site-bgDark w-100">
                    <code className="text-white">
                        npx create-react-app golf-course-management
                    </code>
                </div>
                <p className="fs--14 fw--300 font-poppins py-3">
                    Once the project is created, navigate to the project folder:
                </p>
                <div className="p-3 bg-site-bgDark w-100">
                    <code className="text-white">
                        cd golf-course-management
                    </code>
                </div>

                <h2 className="fs--20 fw--500 font-poppins my-4">
                    B. Install necessary packages
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    Next, we will install the necessary packages for our
                    project. We will need Axios for API requests,React Tabs for
                    the tabbed interface, and React Google Maps for rendering
                    maps.
                </p>
                <p className="fs--14 fw--300 font-poppins py-3">
                    Run the following commands to install these packages:
                </p>
                <div className="p-3 bg-site-bgDark w-100">
                    <code className="text-white">
                        npm install axios <br />
                        npm install react-tabs <br />
                        npm install @react-google-maps/api <br />
                        npm install aws4 <br />
                        npm install isomorphic-unfetch <br />
                        npm install querystring <br />
                    </code>
                </div>

                <h2 className="fs--20 fw--500 font-poppins my-4">
                    C. Update the App component
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    Now that we have all the required packages installed, let's
                    update the src/App.js file to match theprovided App
                    component:
                </p>
                <p className="fs--14 fw--300 font-poppins py-3">
                    Import the necessary components and styles:
                </p>
                <div className="p-3 bg-site-bgDark w-100">
                    <code className="text-white">
                        import React from 'react';
                        <br />
                        import './Style/App.css';
                        <br />
                        import {`Tab, Tabs, TabList, TabPanel`} from
                        'react-tabs';
                        <br />
                        import 'react-tabs/style/react-tabs.css';
                        <br />
                        import Courses from './Pages/Courses';
                        <br />
                        import Scorecard from './Pages/Scorecard';
                        <br />
                        import Hole from './Pages/Hole';
                        <br />
                    </code>
                </div>
                <p className="fs--14 fw--300 font-poppins py-3">
                    Replace the existing App component with the provided one:
                </p>
                <div className="w-100">
                    <CodeBlock className="p-3"
                        text={`function App() {
    return (
        <div className="App">
            <Tabs style={{ paddingTop: 25 }}>
                <TabList>
                    <Tab>First Request</Tab>
                </TabList>
                
                <TabPanel>
                    <p>healthy</p>
                </TabPanel>
            </Tabs>
        </div>
    );
}
export default App;`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <p className="fs--14 fw--300 font-poppins py-3">
                    With the development environment set up, we can now proceed
                    to build our Golf CourseManagement React App.
                </p>
            </div>
        </div>
    );
};
