import React from "react";
import { Col, Container, Row } from "reactstrap";
import Logomain from "../../assets/img/footerLogo.png";
import { Link } from "react-router-dom";

const MainFooter = () => {
    return (
        <div className="main-footer">
            <Container fluid="lg">
                <Row xs={1} sm={2} md={4} className="mt- pt-md-5 pt-4">
                    <Col className="mb-md-0 mb-3">
                        <img className="img-fluid" src={Logomain} alt="" />
                    </Col>
                    <Col className="mb-md-0 mb-md-3 mb-2">
                        <div className="d-flex flex-column main-footer__text">
                            <h4>ABOUT US</h4>
                            <div className="text-muted pt-3 pb-5">
                                <Link
                                    className="text-site-footer-item text-decoration-none d-block fs--16 fw--400"
                                    to="/api/faq"
                                >
                                    <p>Faq</p>
                                </Link>
                                <Link
                                    className="py-1 text-site-footer-item text-decoration-none d-block fs--16 fw--400"
                                    to="/api/support"
                                >
                                    <p>Support</p>
                                </Link>
                                <Link
                                    className="text-site-footer-item text-decoration-none d-block fs--16 fw--400"
                                    to="/api/timeline"
                                >
                                    <p>Timeline</p>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col className="mb-md-0 mb-md-3 mb-2">
                        <div className="d-flex flex-column main-footer__text">
                            <h4>SUPPORT</h4>
                            <div className="text-muted main-footer__text-small pt-3 pb-5">
                                <Link
                                    className="text-site-footer-item text-decoration-none d-block fs--16 fw--400"
                                    to="/api/license"
                                >
                                    <p>API License Agreement</p>
                                </Link>
                                <Link
                                    className="py-1 text-site-footer-item text-decoration-none d-block fs--16 fw--400"
                                    to="/main/privacy"
                                >
                                    <p>Privacy Policy</p>
                                </Link>
                                <Link
                                    className="text-site-footer-item text-decoration-none d-block fs--16 fw--400"
                                    to="/main/terms"
                                >
                                    <p>Terms</p>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col className="mb-md-0 mb-md-3 mb-2">
                        <div className="d-flex flex-column main-footer__text">
                            <h4>CONNECT WITH US</h4>
                            <div className="text-muted pt-3 pb-5">
                                <div className="d-flex main-footer__set-text  py-1 text-site-footer-item">
                                    <i className="fab fa-twitter mr-2"></i>
                                    <Link
                                        className="text-site-footer-item py-0 text-decoration-none d-block fs--16 fw--400"
                                        to={{ pathname: "https://twitter.com/golfbert" }} target="_blank"
                                    >
                                        <p>Twitter</p>
                                    </Link>
                                </div>
                                <div className="d-flex text-site-footer-item py-1">
                                    <i className="far fa-envelope mr-2 mail-icon text-site-footer-item"></i>
                                    <Link
                                        className="text-site-footer-item py-0 text-decoration-none d-block fs--16 fw--400"
                                        to="mailto:apisupport@golfbert.com?Subject=Golfbert API Support Inquiry"
                                    >
                                        <p>apisupport@golfbert.com</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col
                        md="12"
                        className="text-center pb-sm-5 pb-4 mt-3 fs--12 fw--400 text-site-footer-item"
                    >
                        ©2009-2024 Golfbert Data Services. All rights reserved.
                        The content of this page and code samples are provided
                        by and the rights of Golfbert Inc. Use of this site is
                        subject to express Terms of Service. The information you
                        provide is protected in accordance with our Privacy
                        Policy. API License Agreement.
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MainFooter;
