import React from "react";
import Headerlanding from "../../components/global/Headerlanding";

import { Col, Row } from "reactstrap";
import { ExampleCodeUI } from "../../components/ExampleCodeUI";
import CodeSample from "../../components/CodeSample";
import golfMap from "../../assets/img/golfMap.JPG";
import CheckGolfCourse from "../../components/CheckGolfCourse";
import { Link } from "react-router-dom";
import { FurtherDevelopment } from "../../components/FurtherDevelopment";
import ApiNavbar from "../../components/global/ApiNavbar";
import MainFooter from "../../components/global/MainFooter";

function Landing() {
    return (
        <>
            <Headerlanding />

            <Row className="bg-white w-100 m-0 pt-5 px-wide justify-content-center landing-code-sample">
                <Col xs="12">
                    <div className="text-center">
                        <h4 className="fs--22 fw-600 mb-4 font-poppins text-dark">
                            Optimized geodata on the top golf courses in the
                            United States
                        </h4>

                        <h6 className="fs--16 fw--400 text-dark font-poppins">
                        Learn before your play with our golf course database. We've plotted every green, fairway and hazard for thousands of courses. <a href="/courses/search">Research courses</a> before you play with interactive hole maps, photos, tips, stats and more.
                        </h6>
                    </div>
                    <ExampleCodeUI
                        title="Building a golf hole"
                        image={golfMap}
                        courseId={4803}
                        holeId={25506}
                        code={`1. Get all holes for a specific course

awscurl --access_key **** --secret_key ****  -X GET
https://api.golfbert.com/v1/courses/4803/holes -H "x-api-key: ****"
'Response code: 200'
{
  "resources": [
    {
      "id": 25506,
      "number": 18,
      "courseid": 4803,
      "rotation": 2.04479566062,
      "range": {
        "x": {
          "min": -122.2707111,
          "max": -122.269083337
        },
        "y": {
          "min": 47.3838734109,
          "max": 47.3896241104
        }
      },
      "dimensions": {
        "width": 960,
        "height": 960
      },
      "vectors": [
        {
          "type": "Flag",
          "lat": 47.3893912619,
          "long": -122.2692596912384
        },
        {
          "type": "Blue",
          "lat": 47.3838734109,
          "long": -122.269259691
        },
        {
            "type": "White",
          "lat": 47.3843622628,
          "long": -122.269388009
        },
        {
          "type": "Red",
          "lat": 47.3850228683,
          "long": -122.269657507
        }
      ],
      "flagcoords": {
        "lat": 47.38135479698122,
        "long": -122.26435018374639
      }
    }
  ]
}

2. Get hole polygon information for specific hole

awscurl --access_key **** --secret_key **** -X GET
https://api.golfbert.com/v1/holes/25506/polygons -H "x-api-key: ***"
'Response code: 200'
{
  "resources": [
    {
      "holeid": 25506,
      "surfacetype": "Green",
      "polygon": [
        {
          "lat": 47.3814806732001,
          "long": -122.26437270641327
        },
        {
          "lat": 47.38148793784317,
          "long": -122.2643968462944
        },
        {
          "lat": 47.38148975400378,
          "long": -122.26441696286201
        },
        {
          "lat": 47.38148793784317,
          "long": -122.26443707942963
        },
        {
          "lat": 47.381483397441365,
          "long": -122.26445451378822
        },
        {
          "lat": 47.38147340855601,
          "long": -122.26447060704231
        },
        {
          "lat": 47.38145887926487,
          "long": -122.26448804140091
        },
        {
          "lat": 47.381439809564135,
          "long": -122.26449877023697
        },
        {
          "lat": 47.38142073985653,
          "long": -122.26450279355049
        },
        {
          "lat": 47.38139531356897,
          "long": -122.26449877023697
        },
        {
          "lat": 47.38136716302202,
          "long": -122.26448267698288
        },
        {
          "lat": 47.38133356396243,
          "long": -122.26447463035583
        },
        {
          "lat": 47.381290884045065,
          "long": -122.26447463035583
        },
        {
          "lat": 47.38126182535814,
          "long": -122.2644692659378
        },
        {
          "lat": 47.381238215163215,
          "long": -122.26446121931076
        },
        {
          "lat": 47.38122550197696,
          "long": -122.2644491493702
        },
        {
          "lat": 47.38121823729771,
          "long": -122.2644317150116
        },
        {
          "lat": 47.38121188070257,
          "long": -122.26439818739891
        },
        {
          "lat": 47.38120915644726,
          "long": -122.26436868309975
        },
        {
          "lat": 47.38120915644726,
          "long": -122.26433515548706
        },
        {
          "lat": 47.38121823729771,
          "long": -122.26429894566536
        },
        {
          "lat": 47.38124003133243,
          "long": -122.26424127817154
        },
        {
          "lat": 47.38126636577903,
          "long": -122.26418629288673
        },
        {
          "lat": 47.38128725171007,
          "long": -122.26415008306503
        },
        {
          "lat": 47.381299964881435,
          "long": -122.26413533091545
        },
        {
          "lat": 47.38131540229967,
          "long": -122.2641272842884
        },
        {
          "lat": 47.381340828625774,
          "long": -122.26412996649742
        },
        {
          "lat": 47.38136171452732,
          "long": -122.26414069533348
        },
        {
          "lat": 47.3813798761741,
          "long": -122.2641621530056
        },
        {
          "lat": 47.381392589323156,
          "long": -122.26419970393181
        },
        {
          "lat": 47.38140621055085,
          "long": -122.26423993706703
        },
        {
          "lat": 47.38142618834512,
          "long": -122.26429358124733
        },
        {
          "lat": 47.38146432774951,
          "long": -122.26434588432312
        },
        {
          "lat": 47.3814806732001,
          "long": -122.26437270641327
        }
      ]
    }
  ]
}`}
                    />
                </Col>
            </Row>
            <Row className="w-100 m-0 py-5 px-wide px-lg-narrow justify-content-center bg-white extra-pb-y">
                <Col xs="12">
                    <CodeSample />
                </Col>
            </Row>
            <Row className=" w-100 m-0 py-0 px-wide justify-content-center bg-white">
                <Col xs="12">
                    <CheckGolfCourse />
                </Col>
            </Row>

            <Row className=" w-100 m-0 py-5 px-lg-narrow px-wide justify-content-center bg-white">
                <Col xs="12">
                    <FurtherDevelopment />
                </Col>
            </Row>
        </>
    );
}

export default Landing;
