import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import apitutorial4 from "../../assets/img/api-tutorial-4.png";

const CreateHoleMap = () => {
    return (
        <div>
            <h3 className="fs--24 fw--700 font-poppins">
                VII. Create the Hole Map Component
            </h3>
            <p className="fs--14 fw--300 font-poppins py-2">
                In this tutorial section, we will walk you through the creation
                of the Hole component in a Reactapplication. This component is
                designed to fetch and display golf course hole data using
                theGolfbert library. We will go through each step of the code
                and explain the functionality of thecomponent.
            </p>
            <p className="fs--14 fw--300 font-poppins py-2">
                Import the necessary libraries and components:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`import React, { useEffect } from 'react';
import Golfbert from '../lib/Golfbert';
import HoleMap from './HoleMap';`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                We import React, along with the useEffect hook, the Golfbert
                library, and the HoleMap component.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Define the Hole component:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`function Hole (props) { 
    ...
}`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                The Hole component is a functional component that receives props
                as its argument.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Set up the component state:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`let [searchText, setSearchText] = React.useState(18813);
let [holes, setHoles] = React.useState([]);
let [polygons, setPolygons] = React.useState({});`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                We set up three state variables: searchText, holes, and
                polygons. searchText stores the user's inputfor the course ID,
                holes stores the fetched hole data, and polygons stores the
                polygons for each hole.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Create a Golfbert instance:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`const golfbert = new Golfbert({
    apiKey: 'YOUR_GOLFBERT_API_KEY',
    accessKey: 'YOUR_GOLFBERT_ACCESS_KEY',
    secretKey: 'YOUR_GOLFBERT_SECRET_KEY'
});`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                We create a new Golfbert instance with the required API keys and
                credentials.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Fetch hole data on component mount:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`useEffect(() => {
    getHoles();
}, []);
`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                We use the useEffect hook to fetch hole data when the component
                mounts by calling the getHolesfunction.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Define the getPolygons function:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`const getPolygons = (holeId) => {
    golfbert
        .getHolePolygonsById(holeId)
        .then((data) => {
            const allPolygons = data.resources.map((resource) => ({
                surfaceType: resource.surfacetype,
                points: resource.polygon
            }));
            setPolygons((prevState) => ({
                ...prevState,
                [holeId]: allPolygons
            }));
        })
    .catch((err) => console.error(err));
};`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                The getPolygons function fetches polygons for a specific hole
                ID, processes the data, and updatesthe polygons state.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Define the getHoles function:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`const getHoles = () => {
    setHoles([]);
    golfbert
        .getCourseHolesById(searchText)
        .then((data) => {
            setHoles(data.resources);
            data.resources.forEach((hole) => getPolygons(hole.id));
        }) 
        .catch((err) => console.error(err));
}
`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                The getHoles function fetches hole data for the course ID
                specified in searchText, updates theholes state, and calls
                getPolygons for each hole.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Render the component:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`return (
    <div className="course" style={{margin: '25px'}}>
        <div style={{margin: '25px'}}>
            <input type="search" placeholder="Course Id" value={searchText} onChange={ e =
            <button onClick={getHoles}>Search</button>
        </div>
        
        {holes.map((hole) => {
            return (
                <div key={hole.id} style={{margin: '25px', display: 'flex'}}>
                    <div style={{flexGrow: 1, textAlign: 'left'}}>
                        <h2>Hole {hole.number}</h2>
                        <p>ID: {hole.id}</p>>
                        <p>Rotation: {hole.rotation}</p>
                        <p>Rotation (in degrees) {hole.rotation * 180 / Math.PI}</p>
                    </div>
                    <div style={{flexGrow: 3}}>
                        <HoleMap hole={hole} polygons={polygons[hole.id]} />
                    </div>
                </div>
            ) 
        })
        }
    </div>
);`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                Export the Hole component:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`export default Hole;`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                Finally, we export the Hole component so it can be used in other
                parts of the application.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                With these steps, we have successfully created the Hole
                component that fetches and displays golfcourse hole data using
                the Golfbert library. Users can search for golf courses using
                the providedinput field and view the details of each hole, along
                with a visual representation of the hole layout.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Now we will break down the process of creating the HoleMap
                component. This component isresponsible for rendering the map of
                the golf course hole along with its polygons.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Create a file in the{" "}
                <code className="fs--14 fw--300 font-poppins py-1">Pages</code>{" "}
                folder names{" "}
                <code className="fs--14 fw--300 font-poppins py-1">
                    HoleMap.js
                </code>
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Import the required libraries and components:
            </p>

            <div className="w-100 py-2">
                <CodeBlock
                    text={`import React, { useEffect, useRef, ReactElement, useState } from "react";
import ReactDOM from "react-dom";
import {Loader, LoaderOptions} from '@googlemaps/js-api-loader';`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                Define the Wrapper component:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`const Wrapper = ({
    children,
    render,
    callback,
    ...options
}) => {
    const [status, setStatus] = useState('LOADING');
    useEffect(() => {
        const loader = new Loader(options);
        
        const setStatusAndExecuteCallback = (status) => {
            if (callback) callback(status, loader);
            setStatus(status);
        };

        setStatusAndExecuteCallback('LOADING');
        
        loader.load().then(
            () => setStatusAndExecuteCallback('SUCCESS'),
            () => setStatusAndExecuteCallback('FAILURE') );
        }, []);
        
        if (status === 'SUCCESS' && children) return <>{status}{children};
        
        if (render) return render(status);
        
        return <>;
    };`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                The Wrapper component helps manage the Google Maps API loading
                process. It takes in children,render, callback, and options as
                props. The component tracks the loading status of the API
                andupdates the status accordingly. Once the API is loaded, the
                children components are rendered.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Define the findCenter function:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`function findCenter(coords1, coords2) {
    const x = (coords1.lng + coords2.lng) / 2;
    const y = (coords1.lat + coords2.lat) / 2;
    
    return {
        lat: y,
        lng: x 
    };
}`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                The findCenter function calculates the center point between two
                sets of coordinates.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Create the MyMapComponent component:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`function MyMapComponent({ center, zoom, rotation, id, vectors, hole, polygons }) {
    const ref = useRef(null);
    const [map, setMap] = useState();
    
    console.log('polys', polygons)
    
    let flagstick = {
        lat: hole.flagcoords.lat,
        lng: hole.flagcoords.long,
    }

    let minCoords = {
        lat: hole.range.y.min,
        lng: hole.range.x.min,
    }
    
    let holeCenter = findCenter(flagstick, minCoords);   
    
    let newVectors = rotatePointsAroundFarthest(vectors.filter(i => i.type !== 'Flag'), flagstick, rotation); `}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p>
                <code className="fs--14 fw--300 font-poppins">
                    MyMapComponent
                </code>{" "}
                is a functional component that takes center, zoom, rotation, id,
                vectors, hole, andpolygons as props. It uses a ref to create a
                map container and a map state to store the created mapinstance.
                The component calculates the flagstick position and hole center
                using the provided data.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Define the drawPolygons function:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`const drawPolygons = (map, polygons) => {
    polygons.forEach((polygon) => {
        const path = polygon.points.map((point) => ({
            lat: point.lat,
            lng: point.long, 
        }));
        
        const polygonStyle = getPolygonStyle(polygon.surfaceType);
        
        const newPolygon = new window.google.maps.Polygon({
            paths: path,
            strokeColor: polygonStyle.strokeColor,
            strokeOpacity: polygonStyle.strokeOpacity,
            strokeWeight: 2,
            fillColor: polygonStyle.fillColor,
            fillOpacity: polygonStyle.fillOpacity,
        });
        
        newPolygon.setMap(map);
    });
};

useEffect(() => {
    if (ref.current && !map && polygons) {
        const newMap = new window.google.maps.Map(ref.current, {
            zoom,
            center: holeCenter,
            mapId: 90f87356969d889c,
            mapTypeId: "hybrid",
            scaleControl: false,
            mapTypeControl: false,
            panControl: false,
            zoomControl: true,
            rotateControl: false,
            fullscreenControl: false,
            heading: rotation * 180 / Math.PI,
            tilt: 45,
        })
        
        new window.google.maps.Marker({
            position: flagstick,
            rotation: rotation * 180 / Math.PI,
            map: newMap, title: 'Flag',
        });
        
        newVectors.forEach((marker) => {
            new window.google.maps.Marker({
                position: {
                    lat: marker.lat,
                    lng: marker.long,
                },
                rotation: rotation * 180 / Math.PI,
                map: newMap, title: marker.title,
            });
        });
        
        if (polygons) {
            drawPolygons(newMap, polygons)
        }
        
        console.log(newMap.getHeading())
        setMap(newMap);
    }
}, [id, ref, map, center, zoom, rotation, polygons]);

return <div ref={ref} style={{ flexGrow: "1", height: "100%" }} />;
}
`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                In the useEffect hook, the component initializes the Google Maps
                instance with the providedconfigurations and adds markers for
                the flagstick and other vectors. If polygons are available,
                itcalls the drawPolygons function to draw the polygons on the
                map.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Define the getPolygonStyle function:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`const getPolygonStyle = (surfaceType) => {
    switch (surfaceType) {
        case "Green":
            return {
                strokeColor: "#008000",
                fillColor: "#32CD32",
                strokeOpacity: 0.8,
                fillOpacity: 0.6,
            };
        case "Fairway":
            return {
                strokeColor: "#228B22",
                fillColor: "#7CFC00",
                strokeOpacity: 0.8,
                fillOpacity: 0.6,
            };
        case "Sand":
            return {
                strokeColor: "#DAA520",
                fillColor: "#FFFF",
                strokeOpacity: 0.8,
                fillOpacity: 0.6,
            };
        case "Woods":
            return {
                strokeColor: "#8B4513",
                fillColor: "#A0522D",
                strokeOpacity: 0.8,
                fillOpacity: 0.6,
            };
        case "Water":
            return {
                strokeColor: "#0000FF",
                fillColor: "#1E90FF",
                strokeOpacity: 0.8,
                fillOpacity: 0.6,
            };
        default:
            return {
                strokeColor: "#FF0000",
                fillColor: "#FF0000",
                strokeOpacity: 0.8,
                fillOpacity: 0.6,
            };
    }
};`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                The getPolygonStyle function returns the styling for polygons
                based on their surface type.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Implement the HoleMap component:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`export default function HoleMap(props) {
    const { hole, polygons } = props;
    
    const center = { lat: hole.flagcoords.lat, lng: hole.flagcoords.long };
    const zoom = 17;
    return (
        <div style={{ height : '450px', width: '100%' }}>
            <Wrapper apiKey="YOUR_GOOGLE_API_KEY" render={render}>
                <MyMapComponent
                    id={hole.id}
                    center={center}
                    zoom={zoom}
                    rotation={hole.rotation}
                    vectors={hole.vectors}
                    hole={hole}
                    polygons={polygons}
                />
            </Wrapper>
        </div>
    );
}`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                The <code className="fs--14 fw--300 font-poppins">HoleMap</code>{" "}
                component sets the center and zoom values for the map and wraps
                theMyMapComponent inside the Wrapper component. The{" "}
                <code>Wrapper</code> component is responsible forloading the
                Google Maps JavaScript API.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Implement the rotatePointsAroundFarthest function:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`function rotatePointsAroundFarthest(points, flagCoords, angle) {
    // Calculate the distance between each GPS point and the flag coordinates
    const distances = points.map(point => {
        const lat1 = flagCoords.lat;
        const lon1 = flagCoords.lng;
        const lat2 = point.lat;
        const lon2 = point.long;
        const R = 6371; // Earth's radius in km
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon/2) * Math.sin(dLon/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const d = R * c; // Distance in km
        return d;
    });
    
    // Find the GPS point with the maximum distance from the flag coordinates
    const farthestIndex = distances.indexOf(Math.max(...distances));
    const farthestPoint = points[farthestIndex];
    // Rotate the remaining GPS points around the farthest point
    const rotatedPoints = points.map((point, index) => {
        if (index === farthestIndex) return point;
        // Skip the farthest point //console.log(point, farthestPoint, angle)
        return rotatePointByPoint(point, farthestPoint, angle);
    });
    // Return the rotated GPS points
    return rotatedPoints;
}`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                The rotatePointsAroundFarthest function calculates the distance
                between each GPS point and theflag coordinates, finds the
                farthest GPS point, and rotates the remaining GPS points around
                thefarthest point.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Implement the rotatePointByPoint function:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`function rotatePointByPoint(point, rotationPoint, angle) {
    // Convert the angle from degrees to radians
    const radians = angle * Math.PI / 180;
    
    // Calculate the coordinates of the point relative to the rotation point
    const relativeX = point.long - rotationPoint.long;
    const relativeY = point.lat - rotationPoint.lat;
    
    // Apply the rotation matrix to the point
    const rotatedX = relativeX * Math.cos(radians) - relativeY * Math.sin(radians);
    const rotatedY = relativeX * Math.sin(radians) + relativeY * Math.cos(radians);
    
    // Translate the point back to its original position by adding the coordinates of the rotation point
    const x = rotatedX + rotationPoint.long;
    const y = rotatedY + rotationPoint.lat;
    
    // Return the rotated point
    return {
        type: point.type,
        lat: y,
        long: x
    };
}
`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                The rotatePointByPoint function rotates a given point around
                another point (the rotation point) by aspecified angle. This
                function is used by the rotatePointsAroundFarthest function to
                perform therotation for each point.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                With all the functions and components in place, the HoleMap
                component is complete. When usedin an application, it will
                display a map with custom markers and polygons based on the
                dataprovided through the hole and polygons props.
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                To add the Hole component to your App.js file, you need to first
                import the Hole component andthen include it within a TabPanel.
                Here's an example of how you can do this:
            </p>
            <p className="fs--14 fw--300 font-poppins py-1">
                Import the Hole component at the top of your App.js file:
            </p>
            <div className="w-100 py-2">
                <CodeBlock
                    text={`import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Hole from './components/Hole';

function App() {
    return (
        ...
            <TabPanel>
                <Hole />
            </TabPanel>
        ...
    );
}

export default App;`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <p className="fs--14 fw--300 font-poppins py-1">
                This will add the Hole component to your application inside a
                TabPanel. When you click on the"Hole Component" tab, the Hole
                component will be displayed.
            </p>
            <div className="w-100 shadow  p-3">
                <img src={apitutorial4} className="img-fluid" />
            </div>
        </div>
    );
};

export default CreateHoleMap;
