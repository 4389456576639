import React from "react";
import { Link } from "react-router-dom";

const DeprecatedApi = () => {
    return (
        <div>
            <h2 className="fs--20 fw--500 font-poppins">Deprecated API</h2>
            <p className="fs--16 fw--400 font-poppins text-muted">
                There is nothing deprecated at this point but from time to time,
                certain endpoints might change or be removed. API releases will
                usually increment the <i>/vN</i> prefix but older APIs will
                stick around for a sufficient period of time so that customers
                can have adequate time to move off them.{" "}
            </p>
            <p>
                <Link
                    className="fs--16 fw--400 font-poppins text-site-primary cursor-pointer"
                    to="/api/documentations"
                >
                    Next: documentation
                </Link>
            </p>
        </div>
    );
};

export default DeprecatedApi;
