import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";

export const CreateGolfbertClass = () => {
    return (
        <>
            <h3 className="fs--24 fw--700 font-poppins">
                III. Creating the Golfbert Class
            </h3>
            <p className="fs--14 fw--300 font-poppins">
                In this section, we will walk you through the process of
                creating the Golfbert class from scratch.The Golfbert class is
                designed to facilitate communication with the Golfbert API by
                utilizing theprovided AWS keys and API key.
            </p>
            <div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    A. Initialize the Class and Constructor
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    Create a new folder named{" "}
                    <code className="fs--14 fw--300 font-poppins">lib</code>{" "}
                    inside the src directory. Then, create a new file named{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        Golfbert.js
                    </code>{" "}
                    inside the lib folder.
                </p>
                <p className="fs--14 fw--300 font-poppins">
                    Start by importing the necessary libraries at the top of the
                    file:
                </p>
                <div className="w-100">
                    <CodeBlock
                        text={`import aws4 from 'aws4';
import fetch from 'isomorphic-unfetch';
import querystring from 'querystring';`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <p className="fs--14 fw--300 font-poppins py-4">
                    Next, create the Golfbert class and initialize the
                    constructor. The constructor should accept aconfiguration
                    object that contains the API key, AWS access key, AWS secret
                    key, API host, and AWSregion:
                </p>

                <div className="w-100">
                    <CodeBlock
                        text={`Golfbert {
    class
    (config) {
    constructor
    this .apiKey = cmfig.apiKey;
    this -accessKey = cmfig.accessKey;
    this. secretKey = cmfig.secretKey;
    this
    host • api. golfbert. cæ• ;
    -region = cmfig.regim 11 •us-east-I';
    this`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>

                <h2 className="fs--20 fw--500 font-poppins my-4">
                    B. Implement the Request Method
                </h2>

                <p className="fs--14 fw--300 font-poppins">
                    Add a{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        request()
                    </code>{" "}
                    method to the Golfbert class. This method is responsible for
                    making APIrequests to the Golfbert API. It should take an
                    options object as an argument that includes detailslike the
                    HTTP method, path, and any additional headers. Use the{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        aws4.sign()
                    </code>{" "}
                    method to sign therequest using the provided AWS keys and
                    the{" "}
                    <code className="fs--14 fw--300 font-poppins">fetch()</code>{" "}
                    method to make the request:
                </p>

                <div className="w-100">
                    <CodeBlock
                        text={`request(options) {
    const url = https://this.host}options.path;
    const config = aws4.sign(
        {
            ...options,
            service: 'execute-api',
            region: this.region,
            host: this.host,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-api-key': this.apiKey,
            },
        }, 
        { 
            accessKeyId: this.accessKey,
            secretAccessKey: this.secretKey,
        }
    );
    
    return fetch(url, config).then((res) => {
        if (res.ok) {
            return res.json();
        }
        
        const error = new Error(res.statusText);
        error.response = res;
        
        throw error;
    });
`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>

                <h2 className="fs--20 fw--500 font-poppins my-4">
                    C. Add a Status Method
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    As an example of how to use the{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        request()
                    </code>{" "}
                    method, add a{" "}
                    <code className="fs--14 fw--300 font-poppins">
                        status()
                    </code>{" "}
                    method to the Golfbert class.This method sends a GET request
                    to the{" "}
                    <code className="fs--14 fw--300 font-poppins">/status</code>{" "}
                    endpoint of the Golfbert API:
                </p>

                <div className="w-100">
                    <CodeBlock
                        text={`status() {
    return this.request({
        method: 'GET',
        path:/
    status,
    });
        }
                        
                      `}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <h2 className="fs--20 fw--500 font-poppins my-4">
                    D. Export the Class
                </h2>
                <p className="fs--14 fw--300 font-poppins">
                    Finally, export the Golfbert class at the end of the file so
                    it can be used in other parts of yourproject:
                </p>
                <div className="w-100">
                    <CodeBlock
                        text={`export default Golfbert;`}
                        language="jsx"
                        theme={dracula}
                        showLineNumbers={false}
                    />
                </div>
                <p className="fs--14 fw--300 font-poppins py-3">
                    Now that you have created the Golfbert class, you can use it
                    in your project to simplify interactionswith the Golfbert
                    API. Just import the class in the components where you need
                    to make API callsand initialize a new instance with your API
                    key, AWS access key, and AWS secret key.
                </p>
            </div>
        </>
    );
};
