import React from "react";
import { Link } from "react-router-dom";

const Swagger = () => {
    return (
        <div>
            <h2 className="fs--20 fw--500 font-poppins">Swagger</h2>
            <p className="fs--16 fw--400 font-poppins text-muted">
                To maximize interoperability and reach the widest audience
                possible, the Golfbert API exposes a Swagger (
                {/* <Link
                    to=""
                    className="fs--16 fw--400 font-poppins text-site-primary"
                    // href="https://swagger.io"
                >
                    https://swagger.io
                </Link> */}
                <a
                    className="fs--16 fw--400 font-poppins text-site-primary "
                    href="https://swagger.io"
                >
                    https://swagger.io
                </a>
                ) definition file. The Swagger file describes the types of
                assets that you can call, the parameters etc. Swagger provides
                code generations for most popular languages in order to reduce
                the time it takes to build your API client.
            </p>
            <p>
                {/* <Link
                    to=""
                    className="fs--16 fw--400 font-poppins text-site-primary py-3"
                    //  href="https://api.golfbert.com/swagger"
                >
                    Swagger definition file
                </Link> */}
                <a
                    className="fs--16 fw--400 font-poppins text-site-primary py-3 text-decoration-none"
                    href="https://api.golfbert.com/swagger"
                >
                    {" "}
                    Swagger definition file
                </a>
            </p>
        </div>
    );
};

export default Swagger;
