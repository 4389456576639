import ApiModel from "./ApiModel";
import Authentication from "./Authentication";
import DeprecatedApi from "./DeprecatedApi";
import GettingStarted from "./GettingStarted";
import Introduction from "./Introduction";
import Paging from "./Paging";
import RateLimit from "./RateLimit";
import Swagger from "./Swagger";
import TestApiKey from "./TestApiKey";

export const GuidesData = [
    {
        id: "link1",
        section: "introduction",
        name: "Introduction",
        component: <Introduction />,
    },
    {
        id: "link2",
        section: "gettingStarted",
        name: "Getting Started",
        component: <GettingStarted />,
    },
    {
        id: "link3",
        section: "authentication1",
        name: "Authentication",
        component: <Authentication />,
    },
    {
        id: "link4",
        section: "testingApi",
        name: "Testing Your API Token",
        component: <TestApiKey />,
    },
    {
        id: "link5",
        section: "swagger",
        name: "Swagger",
        component: <Swagger />,
    },
    {
        id: "link6",
        section: "apiModel",
        name: "API Model",
        component: <ApiModel />,
    },
    {
        id: "link7",
        section: "paging",
        name: "Paging",
        component: <Paging />,
    },
    {
        id: "link8",
        section: "rateLimits",
        name: "Rate Limits",
        component: <RateLimit />,
    },
    {
        id: "link9",
        section: "deprecatedapi",
        name: "Deprecated API",
        component: <DeprecatedApi />,
    },
];
