import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Container, Row } from "reactstrap";
import CoursesTable from "../../../components/courses/CoursesTable";
import SearchFilter from "../../../components/courses/SearchFilter";
import { getStatesCities } from "../../../store/actions/statesCitiesAction";

function SearchByCourseName() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { states } = useSelector((state) => state.citiesStates);

    const [filterCourseNameData, setFilterCourseNameData] = useState("");

    const SearchCourseNameFilter = (filteredResult) => {
        setFilterCourseNameData(filteredResult);
    };

    useEffect(() => {
        if (location.state && location.state.searchData.length)
            setFilterCourseNameData(location.state?.searchData);
    }, [location.state]);

    useEffect(() => {
        dispatch(getStatesCities("", () => {}));
    }, []);

    return (
        <>
            {/* Search By Filter-- Start */}
            <SearchFilter SearchCourseNameFilter={SearchCourseNameFilter} />
            {/* Search By Filter-- End */}

            <Container className="px-4 px-lg-0 mb-5">
                {filterCourseNameData && filterCourseNameData.length > 0 ? (
                    // Show search results
                    <Row>
                        <Col className="px-md-0">
                            <CoursesTable courseData={filterCourseNameData} />
                        </Col>
                    </Row>
                ) : filterCourseNameData &&
                  filterCourseNameData.length === 0 ? (
                    // Show "No Record Found" when no records are found
                    <Row className="justify-content-center py-5">
                        <p className="mb-0">No Record Found</p>
                    </Row>
                ) : (
                    <>
                        <Row className="py-3 py-lg-4 border-top">
                            <h4>Golf Courses By State</h4>
                        </Row>
                        {/* // Show the default content */}
                        <Row className="px-2 mb-4">
                            <Col md={6} sm={12} className="my-0 px-0">
                                {states.slice(0, 26).map((item, index) => (
                                    <div className="py-1" key={index}>
                                        <Link
                                            to={`/courses/search/${item?.state.replaceAll(
                                                " ",
                                                "_"
                                            )}`}
                                            className="cursor-pointer custom-a-hover"
                                        >
                                            {item?.state} Golf Courses
                                        </Link>{" "}
                                        <small className="text-muted">
                                            ({item?.courses})
                                        </small>
                                    </div>
                                ))}
                            </Col>
                            <Col md={6} sm={12} className="my-0 px-0">
                                {states.slice(26).map((item, index) => (
                                    <div className="py-1" key={index}>
                                        <Link
                                            to={`/courses/search/${item?.state.replaceAll(
                                                " ",
                                                "_"
                                            )}`}
                                            className="cursor-pointer custom-a-hover"
                                        >
                                            {item?.state} Golf Courses
                                        </Link>{" "}
                                        <small className="text-muted">
                                            ({item?.courses})
                                        </small>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </>
    );
}

export default SearchByCourseName;
