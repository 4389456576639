import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { getFilteredCourses } from "../../store/actions/HolesAction";

function SearchModel({ toggleSearch, searchModal }) {
    const { courseLoader } = useSelector((state) => state.course);

    let [quickSearch, setQuickSearch] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();

    const QuickSearchHandleSubmit = async (e) => {
        e.preventDefault();
        const obj = {
            name: quickSearch,
            state: "",
            city: "",
            limit: 25,
            offset: 0,
        };

        dispatch(
            getFilteredCourses(obj, () => {
                console.log("obj:>>> ", obj);
                history.push("/courses/search", {
                    searchData: [],
                    // quickSearch: quickSearch,
                });
                setQuickSearch("");

                toggleSearch();
            })
        );
    };
    return (
        <>
            {/* Search Modal-- Start */}
            <Modal isOpen={searchModal} toggle={toggleSearch}>
                <ModalBody className="p-4 p-md-5">
                    <Form onSubmit={QuickSearchHandleSubmit}>
                        <Row className="align-items-end">
                            <Col lg={8} md={12} className="mb-3">
                                <p
                                    className="mb-5 text-site-primary h5 fw-medium form-cs-style"
                                    onClick={toggleSearch}
                                >
                                    <i class="fas fa-times"><span class="navbar-toggler-icon"></span></i>
                                </p>
                                <div className="input-group input-group-lg">
                                    <input
                                        type="text"
                                        className="form-control text-site-primary fcs-style rounded-0 py-lg-5 py-md-4 px-0 px-md-2"
                                        aria-label="Sizing example input"
                                        placeholder="Search golf courses "
                                        aria-describedby="inputGroup-sizing-lg"
                                        value={quickSearch}
                                        onChange={(e) =>
                                            setQuickSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={12} className="mb-3">
                                <Button
                                    disabled={courseLoader || !quickSearch}
                                    className="bg-light text-site-primary shadow border-0 rounded py-3 py-md-4 px-5"
                                    type="submit"
                                >
                                    {courseLoader ? (
                                        <Spinner size={"sm"}> </Spinner>
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
            {/* Search Modal--End */}
        </>
    );
}

export default SearchModel;
