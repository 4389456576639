import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { CodeBlock, dracula } from "react-code-blocks";
import { useDispatch } from "react-redux";
import {
    GetCourseHolesById,
    GetHolePolygonsById,
} from "../store/actions/HolesAction";
import PolygonMap from "../views/pages/courses/PolygonMap";

export const ExampleCodeUI = (props) => {
    const dispatch = useDispatch();
    let [holes, setHoles] = useState([]);
    let [loader, setLoader] = useState(false);
    // let [polygons, setPolygons] = useState({});
    const [ydsToGreen, setYdsToGreen] = useState("");
    const [ydsToTee, setYdsToTee] = useState("");
    const [hole, setHole] = useState("");
    const [holePolygon, setHolePolygon] = useState("");

    const getPolygons = async (holeId) => {
        dispatch(
            GetHolePolygonsById(holeId, async (data) => {
                const allPolygons = await data.resources.map((resource) => ({
                    surfaceType: resource.surfacetype,
                    points: resource.polygon,
                }));
                setHolePolygon(allPolygons);
                setLoader(false);
            })
        );
    };
    const getHoles = async (courseId) => {
        setHoles([]);
        setLoader(true);

        dispatch(
            GetCourseHolesById(courseId, (data) => {
                const hole = data.resources.find(
                    (hole) => hole.id === props.holeId
                );
                setHole(hole);
                getPolygons(props.holeId);
            })
        );
    };
    useEffect(() => {
        if (props.courseId) {
            getHoles(props.courseId);
        }
    }, []);
    return (
        <>
            <Row className=" m-0 py-4">
                {props.title && (
                    <div>
                        <h4 className="fs--18 fw--600 font-poppins">
                            {props.title}
                        </h4>
                    </div>
                )}

                <Col xs="12">
                    <Row className="example-code py-2">
                        <Col
                            md="8"
                            className="overflow-auto example-code__code-block p-0"
                        >
                            <CodeBlock
                                text={props.code}
                                language="jsx"
                                theme={dracula}
                            />
                        </Col>
                        <Col md="4" className={`p-0 height-small`}>
                            {!props.courseId && (
                                <img
                                    alt=""
                                    src={props.image}
                                    className="img-fluid example-code__image-block "
                                ></img>
                            )}

                            {props.courseId && hole && holePolygon && (
                                <PolygonMap
                                    height={"100%"}
                                    hole={hole}
                                    paths={holePolygon}
                                    zoom={17}
                                    setYdsToGreen={setYdsToGreen}
                                    setYdsToTee={setYdsToTee}
                                ></PolygonMap>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
