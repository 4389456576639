import React, { useEffect, useState } from "react";
import ApiNavbar from "../../components/global/ApiNavbar";
import { Col, Container, Row } from "reactstrap";
import TutorialSidebar from "../../components/global/TutorialSidebar";
import TutorialMainContent from "../../components/TutorialMainContent";
import { Link } from "react-router-dom";

export const Tutorial = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const handleSectionClick = () => {
        setIsExpanded(!isExpanded);
    };

    // const handleDropdownToggle = () => {
    //     setIsDropdownVisible(!isDropdownVisible);
    // };

    const [isOpen, setIsOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
            // console.log(windowWidth);
        };
    }, []);
    useEffect(() => {
        if (windowWidth > 992) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [windowWidth]);
    return (
        <>
            {/* <ApiNavbar /> */}

            <div className="bg-white tutorial">
                {!isOpen && (
                    <>
                        {" "}
                        <div
                            className={`tutorial__header ${
                                isExpanded ? "expanded" : ""
                            }`}
                        >
                            <h6 className="bg-white fs--24 fw--700 font-poppins py-3 px-3">
                                API Tutorial
                            </h6>
                            <div className="" onClick={handleSectionClick}>
                                <h6 className="px-3 py-3 text-site-primary">
                                    Sections
                                </h6>
                            </div>

                            {isExpanded && (
                                <div>
                                    <TutorialSidebar
                                        type="small"
                                        isExpanded={isExpanded}
                                        setIsExpanded={setIsExpanded}
                                        isOpen={isOpen}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}{" "}
                <Container fluid="lg">
                    <Row
                        noGutters
                        className="bg-white w-100  justify-content-center example"
                    >
                        <Col lg="3" xs="12">
                            {isOpen && <TutorialSidebar />}
                        </Col>
                        <Col lg="9" xs="12" className="py-3 px-lg-5">
                            <div className="tutorial">
                                <Col lg="10" xs="12" className=" px-0">
                                    <TutorialMainContent />
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
