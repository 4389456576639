import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { GetCoursesOverView } from "../store/actions/HolesAction";
import PolygonMap from "../views/pages/courses/PolygonMap";

const CheckGolfCourse = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { authLoading } = useSelector((state) => state.authUser);
    const courseList = [8428, 1883, 3496, 17551, 1886, 3914];
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        dispatch(
            GetCoursesOverView(courseList, (res) => {
                // console.log("res: ", res);
                setCourses(res);
            })
        );
    }, []);
    const [halt, setHalt] = useState(true);

    useEffect(() => {
        if (!authLoading) {
            setHalt(false);
        }
    }, [authLoading]);
    return (
        <>
            <Row>
                <Col>
                    <div className="text-center">
                        <h6 className="fs--16 fw--400 font-poppins">
                            In addition to these examples,&nbsp;
                            <Link to="/courses/search" className="text-site-primary">
                                check out any golf course
                            </Link>{" "}
                            to see what can be achieved with our golf course
                            API.
                        </h6>
                    </div>
                </Col>

                <Col xs="12">
                    {halt ? (
                        <div className="w-100 d-flex justify-content-center align-items-center  py-5">
                            {/* <DotPulse size={25} speed={1.3} color="black" /> */}
                            <ShimmerSimpleGallery
                                row={1}
                                col={4}
                                card
                                imageHeight={200}
                            />
                        </div>
                    ) : (
                        <Row className="my-5">
                            {courses?.map(
                                (
                                    {
                                        hole,
                                        paths,
                                        course,
                                        numberOfHoles,
                                        scorecard,
                                    },
                                    index
                                ) => {
                                    return (
                                        <>
                                            <Col lg="2" md="3" sm="4" xs="12">
                                                <div className="text-center landing-map-item">
                                                    {hole &&
                                                        paths.length > 0 && (
                                                            <Link
                                                                to={`/courses/holes/${course?.id}`}
                                                                className="text-site-primary"
                                                            >
                                                                {" "}
                                                                <PolygonMap
                                                                    key={
                                                                        index +
                                                                        3
                                                                    }
                                                                    height={200}
                                                                    hole={hole}
                                                                    paths={
                                                                        paths
                                                                    }
                                                                    zoom={17}
                                                                ></PolygonMap>
                                                            </Link>
                                                        )}

                                                    <div className="py-3">
                                                        <Link
                                                            to={`/courses/holes/${course?.id}`}
                                                            className="text-site-primary"
                                                        >
                                                            <h6 className="fs--14 fw--400 font-poppins">
                                                                {course?.name}
                                                            </h6>
                                                        </Link>{" "}
                                                        <span className="d-block fs--12 fw--400 font-poppins text-muted">
                                                            {
                                                                course.address
                                                                    .city
                                                            }
                                                            {", "}
                                                            {
                                                                course.address
                                                                    .state
                                                            }
                                                        </span>
                                                        {/* <span className="d-block fs--12 fw--400 font-poppins text-muted">
                                                            {`Hole ${numberOfHoles} • ${scorecard?.length} Yds • Par ${scorecard?.par}`}
                                                        </span> */}
                                                    </div>
                                                </div>
                                            </Col>
                                        </>
                                    );
                                }
                            )}
                        </Row>
                    )}
                </Col>

                {/* <Col>
                    <div className="text-center">
                        <h6 className="fs--16 fw--400 text-muted font-poppins">
                        <strong>New Courses Added!</strong> Follow along
                            with the intensive process of creating geodata
                            mapped golf course maps on the {" "}
                            <Link
                                to="/api/timeline"
                                className="text-site-primary"
                            >
                                timeline.
                            </Link>
                        </h6>
                    </div>
                </Col> */}
            </Row>
        </>
    );
};

export default CheckGolfCourse;
