import Repository from "./Repository";
const subscribe = "/payment/subscribe";
const checkCoupon = "/payment/coupon";
const updateCard = "/payment/card";
const userSubscription = "/payment/currentSubscription";

export default {
    subscribePlan(payload) {
        return Repository.post(`${subscribe}`, payload);
    },
//    subscribeDetails(stripe_customer_id) {
//        return Repository.get(`/payment/subscribe/${stripe_customer_id}`);
//    },
    cancelSubscribePlan(user_id) {
        return Repository.delete(`/payment/subscribe/${user_id}`);
    },
    fetchCustomer(stripe_customer_id) {
        return Repository.get(`/payment/customer/${stripe_customer_id}`);
    },
    validateCoupon(coupon) {
        return Repository.post(`${checkCoupon}`, { coupon });
    },
    updateCard(payload) {
        return Repository.post(`${updateCard}`, payload);
    },
    userSubscription(customerId) {
        return Repository.post(`${userSubscription}`, { customerId });
    },
};
