import React from "react";
import Subscribe from "../../components/global/Subscribe";
import { Col, Row } from "reactstrap";

const SubscribeAll = () => {
    return (
        <>
            <Row className=" w-100 h-100 m-0 api-pages ">
                <Col xs="12 ">
                    <Subscribe
                        subtitle="All Courses — $399 per month"
                        price="$399"
                        course="All Golf Courses"
                    />
                </Col>
            </Row>
        </>
    );
};

export default SubscribeAll;
