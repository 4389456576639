import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import { Link } from "react-router-dom";

const Authentication = () => {
    return (
        <div>
            <h2 className="fs--20 fw--500 font-poppins">
                Authentication and Authorization
            </h2>
            <p className="fs--16 fw--400 font-poppins text-muted py-3">
                Every account/api token provided is associated with entitlements
                as far as which courses can be accessed. All that happens behind
                the scenes. If you are not seeing courses that you think you are
                supposed to please{" "}
                <Link
                    to="/api/support"
                    // href="/pages/api/support"
                    className="fs--16 fw--400 font-poppins text-site-primary py-3"
                >
                    contact us.
                </Link>
                .<br />
                {/* </p>
            <p className="fs--16 fw--400 font-poppins text-muted py-3"> */}
                In order to authenticate, every request to the Golfbert API must
                include the API token you received along with a valid AWS
                Signature Version 4 that is derived from the access key and
                secret sent to you. Keep in mind that if you lose your secret
                key, there is no way to recover it. It was emailed to you upon
                registartion, but if you cannot locate it, you will need a new
                one to be created. For information around AWS Signature Version
                4 please take a look here:{" "}
                {/* <Link
                    to="http://docs.aws.amazon.com/general/latest/gr/signature-version-4.html"
                    //  href="http://docs.aws.amazon.com/general/latest/gr/signature-version-4.html"
                    // className="fs--16 fw--400 font-poppins text-site-primary "
                >
                    http://docs.aws.amazon.com/general/latest/gr/signature-version-4.html
                </Link> */}
                <a
                    // className="fs--16 fw--400 font-poppins text-site-primary "
                    href="#"
                >
                    http://docs.aws.amazon.com/
                    <br className="d-sm-none d-block" />
                    general/latest/gr/signature-version-4.html
                </a>
                . You can find libraries that will perform the request signing
                for you for nearly every popular language out there, for example
                nodejs: https://github.com/mhart/aws4, Python:{" "}
                {/* <Link
                    to=""
                    className="fs--16 fw--400 font-poppins text-site-primary "
                    //  href="https://github.com/DavidMuller/aws-requests-auth"
                >
                    https://github.com/DavidMuller/aws-requests-auth
                </Link> */}
                <a
                    className="fs--16 fw--400 font-poppins text-site-primary "
                    href="#"
                >
                    https://github.com/DavidMuller/aws-requests-auth
                </a>
                . The API key provided must also accompany every API request as
                a request header with the name x-api-key.
            </p>
            <p className="fs--16 fw--400 font-poppins text-muted py-3">
                A typical API request might look like this:
            </p>

            <div className="w-100 py-3">
                <CodeBlock
                    text={`GET /v1/ping HTTP/1.1
x-api-key: GFHFJHFH7588ENJ
Content-Type: application/x-www-form-urlencoded
Host: api.golfbert.com
X-Amz-Date: 20161221T201625Z
`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
            <div className="w-100 py-3">
                <CodeBlock
                    text={`Authorization: AWS4-HMAC-SHA256 Credential=GFHFJHFH7588ENJ/20161221/us-east-1/execute-api/aws4_request,
SignedHeaders=content-type;host;x-amz-date;x-api-key,
Signature=4aea424a9a48429e7c608d1642af83fdd01fbf4c7ce3689f6076ecd9ee96820b
`}
                    language="jsx"
                    theme={dracula}
                    showLineNumbers={false}
                />
            </div>
        </div>
    );
};

export default Authentication;
