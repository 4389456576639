import React, { useEffect, useState } from "react";
import ApiNavbar from "../../components/global/ApiNavbar";
import { Col, Row } from "reactstrap";
import TutorialSidebar from "../../components/global/TutorialSidebar";
import TutorialMainContent from "../../components/TutorialMainContent";
import { DocumentationsSidebar } from "../../components/documentation/DocumentationSidebar";
import DocumentationMainContent from "../../components/documentation/DocumentationMainContent";
import { FaBars } from "react-icons/fa";
export const Documentations = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const toggle = () => {
        setOpen(!open);
    };

    const handleSectionClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleDropdownToggle = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const [isOpen, setIsOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
            console.log(windowWidth);
        };
    }, []);
    useEffect(() => {
        if (windowWidth > 1200) {
            setIsOpen(true);
            setOpen(false);
        } else {
            setIsOpen(false);
            setOpen(true);
        }
    }, [windowWidth]);

    return (
        <>
            {/* <ApiNavbar /> */}

            <Row className="bg-white w-100 m-0 ">
                <Col lg="12" xs="12">
                    <Row className="documentation">
                        {/* {isOpen && ( */}
                        {!open && (
                            <>
                                <Col sm="4" md="3" lg="2" className="px-0">
                                    <DocumentationsSidebar
                                        toggle={toggle}
                                        open={open}
                                    />
                                </Col>
                                {/* <div className="d-lg-none d-block docSideBar-min">
                                    <DocumentationsSidebar
                                        toggle={toggle}
                                        open={open}
                                    />
                                </div> */}
                            </>
                        )}

                        {/* )} */}

                        <Col>
                            <DocumentationMainContent
                                toggle={toggle}
                                open={open}
                            />
                            {/* <div className="text-right px-4 py-4"> */}
                            {open && (
                                <span
                                    className="fs--24 text-white cursor-pointer toggleIcon"
                                    onClick={toggle}
                                >
                                    <FaBars />
                                </span>
                            )}
                            {/* </div> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};