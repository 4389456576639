import Repository from "./Repository";
const states = "/state";

export default {
    fetchStates() {
        return Repository.get("/state");
    },
    fetchCities(state) {
        return Repository.get(`/city?state=${state}`);
    },
};
