import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { GetCourseById } from "../../../store/actions/HolesAction";

function HolesPage({ children }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const { course_id } = useParams();

    const courseData = location.state?.course;

    let [course, setCourse] = useState(courseData);

    const handleNavigation = (path) => {
        history.push({
            pathname: path,
            state: { course: course },
        });
    };

    const getCourse = (courseId) => {
        dispatch(
            GetCourseById(courseId, (res) => {
                setCourse(res);
            })
        );
    };
    useEffect(() => {
        getCourse(course_id);
    }, []);
    return (
        <>
            <Container className="px-4 px-lg-0 py-5 bg-transparent font-poppins">
                <Row className="p-0 m-0">
                    <h1><b>{course?.name}</b></h1>
                </Row>
                <Row className="align-items-center justify-content-between flex-md-row flex-column-reverse  py-3 border-top">
                    <Col
                        md={8}
                        className="my-1 mt-auto  px-2 d-flex align-items-end h-100 "
                    >
                        <small
                            className={`fsw-4 ${
                                location?.pathname.includes("/courses/holes/")
                                    ? "fsw-4 border-success"
                                    : ""
                            } p-3 border-5  border-bottom cursor-pointer`}
                            onClick={() =>
                                handleNavigation(`/courses/holes/${course?.id}`)
                            }
                        >
                            <span className="d-sm-inline">Holes</span>{" "}
                            Maps
                        </small>
                        <small
                            className={`fsw-4 ${
                                location?.pathname.includes(
                                    "/courses/scorecard/"
                                )
                                    ? "fsw-4 border-success"
                                    : ""
                            } p-3 border-5  border-bottom cursor-pointer`}
                            onClick={() =>
                                handleNavigation(
                                    `/courses/scorecard/${course?.id}`
                                )
                            }
                        >
                            <span className="d-sm-inline">Scorecard</span>
                        </small>
                    </Col>
                    <Col
                        md={4}
                        className="my-2 px-2 d-flex flex-column align-items-md-end"
                    >
                        <p className="fs--14 text-site-gray mb-1">
                            {course?.address?.street}
                        </p>
                        {/* <a href="" className="fsw-4 text-site-bgGreen mb-1">
                            {course?.address?.city} , {course?.address?.state}
                        </a> */}
                        <div className="d-flex align-items-end">
                            <a
                                // href="#"
                                className="fs--14 text-site-primary mb-1 cursor-pointer"
                                onClick={() => {
                                    history.push(
                                        `/courses/search/${course?.address?.state}/${course?.address?.city}`
                                    );
                                }}
                            >
                                {course?.address?.city}{" "}
                            </a>{" "}
                            <h6 className="text-muted mx-1">,</h6>
                            <a
                                // href="#"
                                onClick={() => {
                                    history.push(
                                        `/courses/search/${course?.address?.state}`
                                    );
                                }}
                                className="fs--14 text-site-primary mb-1 cursor-pointer"
                            >
                                {course?.address?.state}
                            </a>
                        </div>
                        <p className="fs--14 text-site-gray mb-1">
                            {course?.address?.zip}
                        </p>
                        <p className="fs--14 text-site-gray mb-1">
                            <i className="fas fa-phone"></i>{" "}
                            {course?.phonenumber}
                        </p>
                    </Col>
                </Row>
                <Row className="p-0 m-0">{children}</Row>
            </Container>
        </>
    );
}

export default HolesPage;
