import { RepositoryFactory } from "../../Repository/RepositoryFactory";
import { LOGIN } from "../types";

let User = RepositoryFactory.get("user");

export const updateContact =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            const response = await User.updateContactInfo(payload);
            const { data } = response;

            if (data.success) {
                const authUserDataString = JSON.stringify(data.data);
                localStorage.setItem("authUser", authUserDataString);
                let token = JSON.parse(
                    localStorage.getItem("token").toString()
                );
                dispatch({
                    type: LOGIN,
                    payload: {
                        id: data.data.id,
                        ...data.data,
                        token: token,
                    },
                });
                onSuccess();
            }
            return true;
        } catch (error) {
            console.error(error.message);
            return false;
        }
    };

export const updatePassword =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        // console.log({ payload });
        try {
            const response = await User.updatePasswords(payload);
            const { data } = response;
            if (data.success) {
                onSuccess();
            }
        } catch (error) {
            console.error(error.message);
        }
    };
