const initialState = {
    isScrolling: null,
};

export default function layoutReducer(state = initialState, { type, payload }) {
    switch (type) {
        case "SET_IS_SCROLLING":
            return {
                ...state,
                isScrolling: payload,
            };
        default:
            return {
                ...state,
            };
    }
}
