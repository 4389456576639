import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from "@stripe/react-stripe-js";
import { FaInfoCircle } from "react-icons/fa";

export const inputWithoutLogin = [
    {
        type: "text",
        placeholder: "Create an Account ID",
        name: "userName",
        label: "Account ID",
    },
    {
        type: "password",
        name: "password",
        label: "Password",
        placeholder: "Password at least 6 characters",
    },

    {
        type: "password",
        placeholder: "Password again please",
        name: "confirmPassword",
    },
    { type: "email", placeholder: "Enter email address", name: "email", label: "Email Address" },
    { type: "date", placeholder: "Date of birth", name: "dateOfBirth", label: "Date of Birth" },
    { type: "text", placeholder: "Enter first name", name: "firstName", label: "First Name" },
    { type: "text", placeholder: "Enter last name", name: "lastName", label: "Last Name" },
    {
        type: "number",
        name: "phone",
        placeholder: "Enter phone number",
        label: "Phone Number",
    },
    {
        type: "text",
        name: "companyName",
        placeholder: "Enter company name or Self",
        label: "Company",
    },
];
export const inputWhenLogin = [
    { type: "text", placeholder: "First Name", name: "firstName", label: "First Name" },
    { type: "text", placeholder: "Last Name", name: "lastName", label: "Last Name" },
    {
        type: "number",
        name: "phone",
        placeholder: "Enter phone number",
        label: "Phone Number",
    },
    {
        type: "text",
        name: "companyName",
        placeholder: "Enter company name or Self",
        label: "Company",
    },
];
export const getStripeToken = async (elements, stripe) => {
    const cardElement = await elements.getElement(CardNumberElement);
    const cardElement2 = await elements.getElement(CardExpiryElement);
    const cardElement3 = await elements.getElement(CardCvcElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        card: cardElement2,
        card: cardElement3,
    });
    if (error) {
        alert("Card Details not Added");
        return;
    } else {
        const result = await stripe.createToken(cardElement);

        if (result.error) {
            alert(result.error.message);

            return;
        }
        return result.token.id;
    }
};

export const planCardData = [
    {
        header: "Great for golf courses",
        id: "api-single",
        active: true,
        subTitle: "Access to all data for any one golf course in the database",
        currency: "usd",
        nickname: "Single Golf Course",
        recurring: {
            interval: "month",
            trial_period_days: null,
        },
        unit_amount: 900 / 100,
        btnText: "Sign Up",
        path: "/api/subscribe/single",
        icon: <FaInfoCircle />,
        callPerMon: "10,000 calls per month",
        additionalApi: "Additional API calls $0.005 per call",
        accessCourse: "Access to a single course",
        support: "Included Support",
        bussinessDay: "2 business days response time",
        submit: "Submit updates",
        report: "Report errors",
        description: "",
    },
    {
        header: "Great for app developers",
        id: "api-all",
        active: true,
        subTitle: "Access to all data for all golf courses in the database",
        btnText: "Sign Up",
        currency: "usd",
        nickname: "All Golf Courses",
        recurring: {
            interval: "month",
            trial_period_days: null,
        },
        unit_amount: 39900 / 100,
        path: "/api/subscribe/all",
        callPerMon: "100,000 calls per month",
        additionalApi: "Additional API calls $0.005 per call",
        accessCourse: "Access to all courses",
        icon: <FaInfoCircle />,
        support: "Included Support",
        bussinessDay: "2 business days response time",
        newCourse: "5 new course requests per month",
        submit: "Submit updates",
        report: "Report errors",
        description:
            "Included with this plan is access to data for all golf courses in the database. Our staff will continue to add courses and manage the course data.",
    },
    {
        header: "Great for offline applications",
        id: "static-data",
        nickname: "Static Data",
        subTitle: "Get a snapshot of the entire database for use without monthly API fees",
        unit_amount: "120,000*",
        recurring: {
            interval: "one-time fee",
            trial_period_days: null,
        },
        btnText: "Contact us",
        callPerMon: "No API calls needed",
        additionalApi: "You own/host/embed the data",
        accessCourse: "Access to all courses",
        description: "You will receive a copy of the current database in its entirety*.",
        icon: <FaInfoCircle />,
        support: "Included Support",
        bussinessDay: "1 business days response time",
        newCourse: "10 new course requests per month",
        submit: "Submit updates",
        report: "Report errors",
        maintenanceFees: "Optional annual database updates for 20% maintenance fee",
        businessNeed:
            "*Based on purchase of complete dataset for all golf courses in the database. Data can be customized and price reflected to fit your business needs.",
        active: true,
        path: "/api/support",
    },

    // {
    //     header: "Great for golf courses",
    //     title: "Single Golf Course",
    //     subTitle:
    //         "Access to all data for any one golf course in the database",
    //     price: "$9",
    //     month: "per month",
    //     btnText: "Sign Up",
    //     callPerMon: "10,000 calls per month",
    //     additionalApi: "Additional API calls $0.005 per call",
    //     accessCourse: "Access to a single course",
    //     support: "Included Support",
    //     bussinessDay: "2 business days response time",
    //     submit: "Submit updates",
    //     report: "Report errors",
    //     active: true,
    //     path: "/api/subscribe/single",
    // },

    // {
    //     header: "Great for app developers",
    //     title: "All Golf Courses",
    //     subTitle: "Access to all data for all golf courses in the database",
    //     price: "$399",
    //     month: "per month",
    //     btnText: "Sign Up",
    //     callPerMon: "100,000 calls per month",
    //     additionalApi: "Additional API calls $0.005 per call",
    //     accessCourse: "Access to all courses",
    //     description:
    //         "Included with this plan is access to data for all golf courses in the database. Our staff will continue to add courses and manage the course data.",
    //     icon: <FaInfoCircle />,
    //     support: "Included Support",
    //     bussinessDay: "2 business days response time",
    //     newCourse: "5 new course requests per month",
    //     submit: "Submit updates",
    //     report: "Report errors",
    //     active: true,
    //     path: "/api/subscribe/all",
    // },
];
