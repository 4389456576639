import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Element, Link } from "react-scroll";
import { Col, Container, Row } from "reactstrap";
import CoursesTable from "../../../components/courses/CoursesTable";
import SearchFilter from "../../../components/courses/SearchFilter";
import { getStatesCities } from "../../../store/actions/statesCitiesAction";
import { COURSES, QUICK_SEARCH } from "../../../store/types";

function CitySearchPage() {
    const params = useParams();
    const dispatch = useDispatch();
    const { cities } = useSelector((state) => state.citiesStates);

    const [filterCourseNameData, setFilterCourseNameData] = useState([]);
    const [filterState, setFilterState] = useState(true);

    const SearchCourseNameFilter = (filteredResult) => {
        setFilterCourseNameData(filteredResult);
        setFilterState(false);
    };

    const history = useHistory();
    const handleSetActive = (to) => {};
    const handleNavigate = (e, city) => {
        e.preventDefault();
        history.push(
            `/courses/search/${params.state.replaceAll(" ", "_")}/${city}`
        );
    };
    useEffect(() => {
        dispatch({ type: COURSES, payload: [] });
        dispatch({
            type: QUICK_SEARCH,
            payload: {
                name: "",
                state: "",
                city: "",
                limit: 25,
                offset: 0,
            },
        });
        dispatch(getStatesCities("", () => {}));
        dispatch(getStatesCities(params?.state, () => {}));
    }, []);
    return (
        <>
            {/* Search By Filter-- Start */}
            <SearchFilter SearchCourseNameFilter={SearchCourseNameFilter} />
            {/* Search By Filter-- End */}

            <Container className="px-4 px-lg-0 pb-5">
                {filterCourseNameData.length > 0 ? (
                    <Row>
                        <Col className="px-md-0">
                            <CoursesTable courseData={filterCourseNameData} />
                        </Col>
                    </Row>
                ) : (
                    <>
                        {" "}
                        <Row className="py-3 py-lg-4 border-top">
                            <h4>Golf Courses By City</h4>
                        </Row>
                        <Row className="px-2 mb-4">
                            {cities?.map((item) => (
                                <Link
                                    key={item.title}
                                    to={item.title}
                                    className="cursor-pointer"
                                    activeClass="active"
                                    spy={true}
                                    smooth={true}
                                    offset={-80}
                                    onSetActive={handleSetActive}
                                    duration={500}
                                >
                                    {/* {item.title} */}
                                    <p className="px-2 text-site-primary custom-a-hover">
                                        {item.title}
                                    </p>
                                </Link>
                            ))}
                        </Row>
                        {cities?.map((item) => (
                            <>
                                <Element name={item?.title} key={item?.title}>
                                    <Row className="px-2 mb-4 w-100">
                                        <Col>
                                            <Row>
                                                <Col className="px-0 mr-4">
                                                    {item.title}
                                                </Col>
                                            </Row>

                                            <Row className="w-100">
                                                {item.subData?.map(
                                                    (ele, id) => (
                                                        <Col
                                                            xs={12}
                                                            className="px-0 w-100"
                                                        >
                                                            <a
                                                                key={id}
                                                                href=""
                                                                onClick={(e) =>
                                                                    handleNavigate(
                                                                        e,
                                                                        ele?.city
                                                                    )
                                                                }
                                                                className="cursor-pointer custom-a-hover"
                                                            >
                                                                {ele?.city} Golf
                                                                Courses
                                                            </a>
                                                            <small className="text-muted">
                                                                {" "}
                                                                    (
                                                                    {
                                                                        ele?.courses
                                                                    }
                                                                    )
                                                            </small>
                                                        </Col>
                                                    )
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Element>
                            </>
                        ))}
                    </>
                )}
            </Container>
        </>
    );
}

export default CitySearchPage;
