import Repository from "./Repository";
const authUser = "/auth/login";
const registerUser = "/auth/register";
const forget = "/auth/forget";
const reset = "/auth/reset";

export default {
    userLogin(payload) {
        return Repository.post(`${authUser}`, payload);
    },
    registerUser(payload) {
        return Repository.post(`${registerUser}`, payload);
    },
    forgetPassword(payload) {
        return Repository.post(`${forget}`, payload);
    },
    resetPassword(payload) {
        return Repository.post(`${reset}`, payload);
    },
};
