import React from "react";
import { Link } from "react-router-dom";

const MyApiPlan = ({ apiPlan, currentSubscription }) => {
   
    return (
        <div>
            <strong>
                {/* {user?.api_plan === "All Golf"
                    ? "All Golf Courses"
                    : user?.api_plan + " Course"} */}
                {apiPlan?.nickname}
            </strong>
            <p>{apiPlan?.subTitle}</p>
            <strong>${apiPlan?.unit_amount} per month</strong>
            <strong>Calls per month</strong>
            <p className="mb-0">{apiPlan?.callPerMon.split(" ")[0]}</p>
            <p className="text-muted font-italic">{apiPlan?.additionalApi}</p>
            <strong>Course access</strong>
            <p>
                {/* {user.api_plan === "Single Golf"
                    ? "Single Course"
                    : user.api_plan === "All Golf"
                    ? "All Courses"
                    : ""} */}
                {apiPlan?.nickname.replace("Golf", "")}
            </p>
            <strong>Included Support</strong>
            <p className="mb-0">
                response time :
                <b>{apiPlan?.bussinessDay.replace("response time", "")}</b>
            </p>
            <p className="mb-0"> {apiPlan?.submit}</p>
            <p className="mb-3"> {apiPlan?.report}</p>{" "}
            {apiPlan?.nickname === "Single Golf Course" && (
                <Link to="/api/plans" className="link-to fw--500 ">
                    Upgrade to All Courses
                </Link>
            )}
        </div>
    );
};

export default MyApiPlan;
