import React from "react";
import CoursesLayout from './CoursesLayout';
import Stats from '../../../components/courses/Stats';


function StatsThisCourse() {
    return (
        <CoursesLayout>
            <Stats />
        </CoursesLayout>
    );
}

export default StatsThisCourse;
