import React from "react";
import apimodel from "../../assets/img/api-model.png";
const ApiModel = () => {
    return (
        <div>
            <h2 className="fs--20 fw--500 font-poppins">API Model</h2>
            <p className="fs--16 fw--400 font-poppins text-muted">
                The API Model is self-explanatory, but might require some basic
                knowledge of the game of golf. There are endpoints available to
                list courses, get hole information for specific course, get
                teeboxes for each hole etc. Every asset that can be fetched
                individually has a unique id attached to it for easy retrieval
                later on. All APIs that return information stemming from
                unbounded sets (course list, for example) support paging using
                the marker and limit paradigm. See the Paging section below for
                more info.
            </p>
            <p className="fs--16 fw--400 font-poppins text-muted py-3">
                A high level model of the API can be seen here:
            </p>
            <div className="w-100">
                <img src={apimodel} className="img-fluid" />
            </div>
        </div>
    );
};

export default ApiModel;
