import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import GolfCourseTable from "../../../components/GolfCourseTable";
import { GetCourseScorecard } from "../../../store/actions/HolesAction";
import CoursesLayout from "./CoursesLayout";

function ScoreCard() {
    ///scorecard
    const location = useLocation();
    const dispatch = useDispatch();

    const { name, id } = location.state?.course;
    let [teeboxColors, setTeeboxColors] = useState([]);
    let [scorecard, setScorecard] = useState([]);

    const getScorecardofCourses = (courseId) => {
        setTeeboxColors([]);
        setScorecard([]);
        dispatch(
            GetCourseScorecard(courseId, (data) => {
                let colors = data.holeteeboxes.map((teebox) => {
                    return teebox.color;
                });
                const uniqueColors = [...new Set(Object.values(colors))];

                setTeeboxColors(uniqueColors);

                // Get scorecard
                let scorecard = data.holeteeboxes
                    .map((hole) => {
                        return hole;
                    })
                    .sort((a, b) => a.holenumber - b.holenumber);

                setScorecard(scorecard);
            })
        );
    };

    useEffect(() => {
        getScorecardofCourses(id);
    }, []);

    return (
        <CoursesLayout>
            {scorecard && <GolfCourseTable data={scorecard} />}
        </CoursesLayout>
    );
}

export default ScoreCard;
