import { Col, Row } from "reactstrap";

function CardOptions({ selectedOption, setSelectedOption }) {
    return (
        <Row>
            <Col>
                <div className="d-flex align-items-center w-100">
                    {" "}
                    <label className="checkbox-container d-flex ml-1">
                        <input
                            type="checkbox"
                            name="ExistingCard"
                            checked={selectedOption === "ExistingCard"}
                            onClick={(e) => setSelectedOption(e.target.name)}
                        />
                        <div className="checkmark my-auto"></div>
                        <p className="ml-2 mb-0 text-secondary my-auto">
                            Existing Card
                        </p>
                    </label>
                </div>
                <div className="d-flex align-items-center w-100">
                    {" "}
                    <label className="checkbox-container d-flex ml-1">
                        <input
                            type="checkbox"
                            name="NewCard"
                            checked={selectedOption === "NewCard"}
                            onClick={(e) => setSelectedOption(e.target.name)}
                        />
                        <div className="checkmark my-auto"></div>
                        <p className="ml-2 fs--15 mb-0 text-secondary my-auto  ">
                            Use new Card
                        </p>
                    </label>
                </div>
            </Col>
        </Row>
    );
}
export default CardOptions;
